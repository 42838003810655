import PropTypes from 'prop-types';
import React from "react";

import { Confirm, Icon, Popup } from "semantic-ui-react";
import { DeleteIconComponent } from '../iconComponents';

const { func } = PropTypes;

class DeleteAction extends React.Component {
  static propTypes = {
    onConfirm: func
  };

  state = {
    isConfirmationOpen: false
  };

  toggleConfirmation = (open) => {
    return () => {
      this.setState({
        isConfirmationOpen: open
      });
    };
  };

  handleConfirm = () => {
    this.toggleConfirmation(false)();
    this.props.onConfirm();
  };

  handleClick = (e) => {
    e.stopPropagation();
    e.preventDefault();

    this.toggleConfirmation(true)();
  };

  render() {
    const header = "Are you sure you want to delete this template?";
    const message = "This will permanently delete this template.  Any timers created from this template will still be available.";
    return (
      <span>
        <Popup inverted
              //  trigger={ <i className="fa fa-trash" onClick={this.handleClick} /> }
              //  trigger={ <Icon name="trash" onClick={this.handleClick} /> }
              trigger={ <span style={{cursor:'pointer'}} name="trash" onClick={this.handleClick}><DeleteIconComponent/></span> }
               content={ "Delete" }
               position={ "top center" } />
        <Confirm open={this.state.isConfirmationOpen}
                 confirmButton={"Confirm"}
                 content={message}
                 header={header}
                 onCancel={this.toggleConfirmation(false)}
                 onConfirm={this.handleConfirm} />
      </span>
    );
  }
}

export default DeleteAction;