import React, { useState } from "react";
import { Dropdown, Icon, Input, Label } from "semantic-ui-react";
import DatetimePicker from "./datetimePicker";
import moment from "moment-timezone";

const CustomRecurring = (props) => {
  // Constant for display information
  const options = [
    { key: "days", text: "Days", value: "days" },
    { key: "week", text: "Week", value: "week" },
    { key: "month", text: "Month", value: "month" },
    { key: "year", text: "Year", value: "year" },
  ];
  const weekdays = ["M", "T", "W", "T", "F", "S", "S"];
  const monthOptions = [
    { key: "month_option_1", text: "Monthly on day 16", value: "option_1" },
    { key: "month_option_2", text: "Monthly on third Tuesday", value: "option_2" },
  ];

  const [selectedOption, setSelectedOption] = useState(props.templateData.repeatType || "days");
  const [value, setvalue] = useState(props.templateData.repeatEvery || 1);
  const [selectedIndex, setSelectedIndex] = useState(props.templateData.repeatOn || 4);
  const [selectedMonthOption, setSelectedMonthOption] = useState(props.templateData.monthOption || "option_1");

  const handleIncrement = () => (value === 365 ? updateRepeatEveryValue(1) : updateRepeatEveryValue(value + 1));

  const handleDecrement = () => (value === 1 ? updateRepeatEveryValue(365) : updateRepeatEveryValue(value - 1));

  const handleChange = (e) => {
    const newValue = parseInt(e.target.value);
    updateRepeatEveryValue(newValue);
  };

  const updateRepeatEveryValue = (value) => {
    const newValue = value;
    if (!isNaN(newValue) && newValue >= 1 && newValue <= 365) {
      setvalue(newValue);
      // To Update Parent Props
      if (props.onChange) {
        props.onChange("templateData.repeatEvery", newValue);
      }
    }
  };

  const handleSelectWeekdays = (index) => {
    setSelectedIndex(index);
    // To Update Parent Props
    if (props.onChange) {
      
      props.onChange("templateData.repeatOn", index);
    }
  };

  const handleSelect = (event, data) => {
    // setShowWeekdays(data.value === "week" ? true : false);
    let olderValue = selectedOption;
    setSelectedOption(data.value);
    // To Update Parent Props
    if (props.onChange) {
      
      props.onChange("templateData.repeatType", data.value);
      if (olderValue != data.value) {
        props.onChange("templateData.recurrenceStartDate", new Date().toISOString());
      }
    }
  };

  const handleSelectMonth = (event, data) => {
    // setShowWeekdays(data.value === "week" ? true : false);
    let olderValue = selectedOption;
    setSelectedMonthOption(data.value);
    // To Update Parent Props
    if (props.onChange) {
      props.onChange("templateData.monthOption", data.value);
    }
  };

  let showWeekdays = selectedOption == "week" ? true : false;
  let monthSelection = selectedOption == "month" ? true : false;

  if (monthSelection) {
    let firstTime = true;
    if (props.templateData.monthOption) {
      firstTime = false;
    }
    if (firstTime) {
      props.onChange("templateData.monthOption", selectedMonthOption);
    }
    if (props.templateData.recurrenceStartDate) {
      let startDate = new Date(props.templateData.recurrenceStartDate);
      monthOptions[0]["text"] = "Monthly on day " + startDate.getDate();
      let dayNames = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
      const currentDay = startDate.getDay(); // 0 = Sunday, 1 = Monday, etc.
      const currentMonth = startDate.getMonth();
      let occurrence = 0;
      let endDateValue = new Date(startDate.getFullYear(), currentMonth + 1, 0).getDate();
      for (let i = 1; i <= endDateValue; i++) {
        const date = new Date(startDate.getFullYear(), currentMonth, i);
        if (date.getDay() === currentDay && date.getDate() <= startDate.getDate()) {
          occurrence++;
        }
      }
      let messageText = "Monthly on ";
      if(occurrence == 1){
        messageText += "first ";
      }else if(occurrence == 2){
        messageText += "second ";
      }else if(occurrence == 3){
        messageText += "third ";
      }else if(occurrence == 4){
        messageText += "fourth ";
      }else if(occurrence == 5){
        messageText += "fifth ";
      }
      messageText += dayNames[currentDay];

      monthOptions[1]["text"] = messageText;
    }
  }

  return (
    <>
      <div className="field" style={{ border: "1px solid red", padding: "10px" }}>
        <label>Recurrence Start Date & Time</label>
        <DatetimePicker
          dateTime={moment(props.templateData.recurrenceStartDate).tz(props.timeZone)}
          maxDays={365 * 99}
          handleChange={props.handleDraftChangeValueCurry("templateData.recurrenceStartDate")}
        />
      </div>
      <div style={{ display: "flex", alignItems: "center", gap: "8px", marginBottom: "10px", marginTop: "10px" }}>
        <div
          style={{
            fontFamily: "Lato",
            fontStyle: "normal",
            fontWeight: "700",
            fontSize: "14px",
            lineHeight: "17px",
            color: "#222222",
            width: "85px",
          }}
        >
          Repeat every
        </div>
        <div style={{ width: "80px" }}>
          <Input
            value={value}
            onChange={handleChange}
            icon={
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  position: "absolute",
                  right: "6px",
                  top: "3px",
                }}
              >
                <Icon name="chevron up" onClick={handleIncrement} style={{ cursor: "pointer" }} />
                <Icon name="chevron down" onClick={handleDecrement} style={{ cursor: "pointer" }} />
              </div>
            }
          />
        </div>
        <div style={{ width: "130px" }}>
          <Dropdown
            style={{ minWidth: "130px" }}
            placeholder="Select option"
            selection
            options={options}
            value={selectedOption}
            onChange={handleSelect}
          />
        </div>
      </div>
      {showWeekdays && (
        <div style={{ marginTop: "15px", marginBottom: "15px" }}>
          <div
            style={{
              fontFamily: "Lato",
              fontStyle: "normal",
              fontWeight: "700",
              fontSize: "14px",
              lineHeight: "17px",
              color: "#222222",
              marginBottom: "10px",
            }}
          >
            Repeat on
          </div>
          <Label.Group circular>
            {weekdays.map((weekday, index) => (
              <Label
                size={"large"}
                key={"weekdays_" + index}
                onClick={() => handleSelectWeekdays(index + 1)}
                style={{ cursor: "pointer" }}
                color={index + 1 === selectedIndex ? "blue" : null}
              >
                {weekday}
              </Label>
            ))}
          </Label.Group>
        </div>
      )}
      {/* Show Month List  */}
      {monthSelection && (
        <div style={{ marginTop: "15px", marginBottom: "15px" }}>
          <Dropdown
            placeholder="Select option"
            selection
            options={monthOptions}
            value={selectedMonthOption}
            onChange={handleSelectMonth}
          />
        </div>
      )}
    </>
  );
};

export default CustomRecurring;
