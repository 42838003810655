import PropTypes from 'prop-types';
import React from "react";

const { func } = PropTypes;

class Logout extends React.Component {

  static propTypes = {
    logout: func.isRequired
  };

  componentDidMount() {
    console.log("Reached Here...")
    this.props.logout();
  }

  render() {
    return null;
  }
}

export default Logout;
