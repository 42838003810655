// import PropTypes from 'prop-types';
// import React from "react";
// import { Link } from "react-router-dom";
// import { Button, Form, Input, Message } from "semantic-ui-react";
// import css from "../../assets/styles/shared/forms.module.scss";

// const { func, string, bool } = PropTypes;

// class Login extends React.Component {
//   static propTypes = {
//     login: func.isRequired,
//     error: string,
//     inProgress: bool
//   };

//   state = {
//     email: "",
//     password: ""
//   };

//   render() {
//     return (
//       <Form className={ css.loginForm }
//             onSubmit={ this.handleSubmit }
//             error={ !!this.props.error }>
//         <h1>Login</h1>
//         { this.renderError() }
//         <Form.Input label="Email"
//                     type="email"
//                     placeholder="Email Address"
//                     icon="envelope"
//                     iconPosition="left"
//                     onChange={ this.handleChangeCurry("email") } />
//         <Form.Input label="Password"
//                     type="password"
//                     icon="lock"
//                     iconPosition="left"
//                     onChange={ this.handleChangeCurry("password") } />
//         <Link to="forgot_password" className={ css.forgotPassword }>Forgot password?</Link>
//         <Form.Button primary content="Login" />
//       </Form>
//     );
//   }

//   renderError = () => {
//     return (
//       <Message error>
//         <Message.Header>Login Error</Message.Header>
//         <p>{ this.props.error }</p>
//       </Message>
//     );
//   };

//   registerRef = (name) => {
//     return (el) => this[name] = el;
//   };

//   handleChangeCurry = (name) => {
//     return (event) => this.setState({ [name]: event.target.value });
//   };

//   handleSubmit = (event) => {
//     event.preventDefault();

//     // TODO: login via api
//     this.props.login(this.state.email, this.state.password);
//   };
// }

// export default Login;

// import PropTypes from 'prop-types';
// import React from "react";
// import { Link } from "react-router-dom";
// import { Button, Form, Input, Message } from "semantic-ui-react";
// import css from "../../assets/styles/shared/forms.module.scss";

// const { func, string, bool } = PropTypes;

// class Login extends React.Component {
//   static propTypes = {
//     login: func.isRequired,
//     error: string,
//     inProgress: bool
//   };

//   state = {
//     email: "",
//     password: "",
//     errorMessage: ""
//   };

//   render() {
//     return (
//       <Form className={ css.loginForm }
//             onSubmit={ this.handleSubmit }
//             error={ !!this.props.error || !!this.state.errorMessage }>
//         <h1>Login</h1>
//         { this.renderError() }
//         <Form.Input label="Email"
//                     type="email"
//                     placeholder="Email Address"
//                     icon="envelope"
//                     iconPosition="left"
//                     onChange={ this.handleChangeCurry("email") } />
//         <Form.Input label="Password"
//                     type="password"
//                     icon="lock"
//                     iconPosition="left"
//                     onChange={ this.handleChangeCurry("password") } />
//         <Link to="forgot_password" className={ css.forgotPassword }>Forgot password?</Link>
//         <Form.Button primary content="Login" />
//       </Form>
//     );
//   }

//   renderError = () => {
//     return (
//       <Message error>
//         <Message.Header>Login Error</Message.Header>
//         <p>{ this.props.error || this.state.errorMessage }</p>
//       </Message>
//     );
//   };

//   registerRef = (name) => {
//     return (el) => this[name] = el;
//   };

//   handleChangeCurry = (name) => {
//     return (event) => this.setState({ [name]: event.target.value });
//   };

//   handleSubmit = (event) => {
//     event.preventDefault();

//     const { email, password } = this.state;

//     if (!email || !password) {
//       this.setState({ errorMessage: "Please enter both email and password." });
//       return;
//     }

//     // Clear any previous error messages
//     this.setState({ errorMessage: "" });

//     // TODO: login via api
//     this.props.login(email, password);
//   };
// }

// export default Login;

import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";
import { Button, Form, Input, Message } from "semantic-ui-react";
import css from "../../assets/styles/shared/forms.module.scss";
import { ClipLoader } from "react-spinners";

const { func, string, bool } = PropTypes;

class Login extends React.Component {
  static propTypes = {
    login: func.isRequired,
    error: string,
    inProgress: bool,
  };

  state = {
    email: "",
    password: "",
    errorMessage: "",
  };

  render() {
    return (
      <Form
        className={css.loginForm}
        onSubmit={this.handleSubmit}
        error={!!this.props.error || !!this.state.errorMessage}
      >
        <h1>Login</h1>
        {this.renderError()}
        <Form.Input
          label="Email"
          type="email"
          placeholder="Email Address"
          icon="envelope"
          iconPosition="left"
          onChange={this.handleChangeCurry("email")}
        />
        <Form.Input
          label="Password"
          type="password"
          icon="lock"
          iconPosition="left"
          onChange={this.handleChangeCurry("password")}
        />
        <Link to="forgot_password" className={css.forgotPassword}>
          Forgot password?
        </Link>

        <Form.Button
          primary
          content={
            this.props.loader ? (
              <div style={{ display: "flex", gap: "3px" }}>
                Login <ClipLoader color="#fff" size={15} />
              </div>
            ) : (
              "Login"
            )
          }
          disabled={this.props.loader}
        />
      </Form>
    );
  }

  renderError = () => {
    return (
      <Message error>
        <Message.Header>Login Error</Message.Header>
        <p>{this.props.error || this.state.errorMessage}</p>
      </Message>
    );
  };

  registerRef = (name) => {
    return (el) => (this[name] = el);
  };

  handleChangeCurry = (name) => {
    return (event) => {
      this.setState({
        [name]: event.target.value,
        errorMessage: "", // Clear error message on input change
      });
    };
  };

  handleSubmit = (event) => {
    event.preventDefault();

    const { email, password } = this.state;

    if (!email || !password) {
      this.setState({ errorMessage: "Please enter both email and password." });
      return;
    }

    // Clear any previous error messages
    this.setState({ errorMessage: "" });

    // TODO: login via api
    this.props.login(email, password);
  };
}

export default Login;
