export const TIMER_FETCH_SUCCESS = "TIMER_FETCH_SUCCESS";
export const TIMER_FETCH_FAILURE = "TIMER_FETCH_FAILURE";
export const CORPORATE_TIMER_FETCH_SUCCESS = "CORPORATE_TIMER_FETCH_SUCCESS";
export const CORPORATE_TIMER_FETCH_FAILURE = "CORPORATE_TIMER_FETCH_FAILURE";
export const TIMER_SAVE_SUCCESS = "TIMER_SAVE_SUCCESS";
export const TIMER_SAVE_FAILURE = "TIMER_SAVE_FAILURE";
export const TIMER_ARCHIVE_SUCCESS = "TIMER_ARCHIVE_SUCCESS";
export const TIMER_ARCHIVE_FAILURE = "TIMER_ARCHIVE_FAILURE";
export const GUEST_TIMER_SAVE_SUCCESS = "GUEST_TIMER_SAVE_SUCCESS";
export const GUEST_TIMER_SAVE_FAILURE = "GUEST_TIMER_SAVE_FAILURE";
export const TIMER_SAVE_REQUEST = "TIMER_SAVING";
