import { connect } from "react-redux";

import TimerGenerator from "../components/timerGenerator";
import { createGuest } from "../actions/timerActions";

const mapStateToProps = (state, props) => ({
  timer: state.timers.data.guest,
  error: state.timers.error,
  envContext: state.envContext.data,
  user: state.auth.data,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  create: (data, captchaResponse) => {
    dispatch(createGuest(data, captchaResponse));
  }
});

export default connect(mapStateToProps,mapDispatchToProps)(TimerGenerator);
