import PropTypes from "prop-types";
import React from "react";
import _, { first } from "lodash";
import moment from "moment-timezone";
import { Accordion } from "semantic-ui-react";

import HtmlCode from "../editor/htmlCode";
import EditableText from "../editor/editableText";
import LabelPicker from "../editor/labelPicker";
import ColorPicker from "./colorPicker";
import FileUploader from "./fileUploader";
import DatetimePicker from "./datetimePicker";
import TimezonePicker from "./timezonePicker";
import FontSelector from "./fontSelector";
import TimerPreview from "./timerPreview";
import DurationInput from "../editor/durationInput";
import DigitDisplayPicker from "../editor/digitDisplayPicker";
import TimerTypePicker from "../editor/timerTypePicker";
import PlanBadge from "../editor/planBadge";
import timerHelper from "../../helpers/timers/timerHelper";
import UpgradePlanButton from "../billing/upgradePlanButton";
import Datetime from "react-datetime";

// import css from "styles/shared/editor.scss";

import css from "../../assets/styles/shared/editor.module.scss";
// import fontCss from "styles/shared/customFont.scss";

import {
  Form,
  Button,
  Popup,
  Checkbox,
  Divider,
  Icon,
  Container,
  Message,
  Radio,
  Modal,
  Dropdown,
} from "semantic-ui-react";
import {
  DarkInfoIconComponent,
  InfoIconComponent,
  LightningBoltIconComponent,
} from "../iconComponents";
import CustomTimePicker from "./customTimePicker";
import CustomDatePicker from "./customDatePicker";
import CustomRecurring from "./customRecurring";
import FontUploader from "./fontUploader";
import NewFontSelector from "./newFontSelector";
import ColorPickerNew from "./colorPickerNew";
import { ClipLoader } from "react-spinners";

const { object, string, func } = PropTypes;

const sizeOptions = [
  { text: "X-Small", value: "xsmall" },
  { text: "Small", value: "small" },
  { text: "Medium", value: "medium" },
  { text: "Large", value: "large" },
  { text: "X-Large", value: "xlarge" },
];

const recurringOptions = [
  { text: "Daily", value: "daily" },
  { text: "Weekly on Monday", value: "weekly" },
  { text: "Monthly on the 19th", value: "monthly_option_1" },
  { text: "Monthly on 3rd Friday", value: "monthly_option_2" },
  { text: "Every weekday (Monday to Friday)", value: "weekday" },
];

const styleOptions = [
  "basic",
  "basic2",
  "blocks1",
  "blocks2",
  "blocks3",
  "arcs",
  "arcsFilled",
  "arcsReverse",
  "arcsFilledReverse",
  "ticksLarge",
  "ticksSmall",
];

let panels = [];

class Editor extends React.Component {
  static propTypes = {
    target: object.isRequired,
    type: string,
    handleSave: func,
    genUrl: string,
    handleUploadError: func,
  };

  static defaultProps = {
    genUrl: "https://gen.sendtric.com",
  };

  constructor(props) {
    super(props);
    this.state = {
      draft: this.normalizeTarget(this.props.target),
      isModalOpen: true,
      digitEnabled: false,
      labelEnabled: false,
    };
  }

  state = {
    activeIndex: 0,
  };

  handleAccordionClick = (e, titleProps) => {
    const { index } = titleProps;
    const { activeIndex } = this.state;
    const newIndex = activeIndex === index ? -1 : index;

    this.setState({ activeIndex: newIndex });
  };

  hasUnsavedChanges = () => {
    const target = this.props.target;
    const templateData = target.templateData;
    const duration = this.state.draft.duration;

    const nameChanged = this.state.draft.name !== target.name;
    const timerTypeChanged = this.state.draft.timerType !== target.timerType;
    const templateDataChanged = !_.isEqual(
      this.state.draft.templateData,
      templateData
    );
    const fallbackChanged =
      Boolean(this.state.draft.fallback) !== Boolean(target.fallback);
    const allowMultipleLanguageChanged =
      Boolean(this.state.draft.multiple_language) !==
      Boolean(target.multiple_language);

    if (this.props.type === "Template") {
      return nameChanged || templateDataChanged;
    } else if (this.state.draft.timerType === "standard") {
      const dateChanged =
        moment(this.state.draft.endsAt).diff(target.endsAt, "seconds") != 0 ||
        target.timeZone != this.state.draft.timeZone;
      const inferTimezoneChanged =
        Boolean(this.state.draft.inferTimezone) !=
        Boolean(this.props.target.inferTimezone);
      return (
        nameChanged ||
        timerTypeChanged ||
        templateDataChanged ||
        dateChanged ||
        inferTimezoneChanged ||
        fallbackChanged ||
        allowMultipleLanguageChanged
      );
    } else if (this.state.draft.timerType === "perpetual") {
      const durationChanged = duration !== target.duration;
      return (
        nameChanged ||
        timerTypeChanged ||
        templateDataChanged ||
        durationChanged ||
        fallbackChanged ||
        allowMultipleLanguageChanged
      );
    } else if (this.state.draft.timerType === "dynamic") {
      return (
        nameChanged ||
        timerTypeChanged ||
        templateDataChanged ||
        fallbackChanged ||
        allowMultipleLanguageChanged
      );
    } else {
      return true;
    }
  };

  normalizeTarget = (target) => {
    return _.cloneDeep(target);
  };

  handleDraftChangeEventCurry = (key) => {
    return (event, data) => {
      this.setState({ draft: _.set(this.state.draft, key, data.value) });
    };
  };

  handleDraftChangeValueCurry = (key) => {
    return (value) => {
      console.log(
        " handleDraftChangeValueCurry this.state.draft=",
        this.state.draft
      );
      this.setState({ draft: _.set(this.state.draft, key, value) });
      console.log(
        "handleDraftChangeValueCurry this.state.draft=",
        this.state.draft
      );
    };
  };

  // handleLabelChangedCurry = (key) => {
  //   return (value) => {
  //     let newDigits = this.props.digits;
  //     newDigits = _.set(newDigits, [key, "digit_color"],value);
  //     this.props.onChange(newDigits);
  //   };
  // };

  handleLabelChangedCurry = (key, colorType) => {
    return (value) => {
      this.setState((prevState) => {
        const updatedKey =
          colorType === "digit"
            ? `templateData.digits.${key}.digit_color`
            : `templateData.digits.${key}.label_color`;
        const updatedDraft = _.set({ ...prevState.draft }, updatedKey, value);
        return { draft: updatedDraft };
      });
    };
  };

  handleChangeColor = (key, colorType) => {
    return (value) => {
      const updatedColor = value;
      // Invoke the function to update the specific color type (digit_color or label_color)
      const handleLabelChange = this.handleLabelChangedCurry(key, colorType);
      handleLabelChange(updatedColor);
    };
  };

  // latest code

  // handleDraftSetCurryNew("digit_status", !digitEnabled,"digits")}
  handleDraftSetCurryNew = (key, value, type) => {
    return () => {
      if (type === "digits") {
        this.setState((prevState) => ({
          digitEnabled: value,
        }));

        let newDigits = { ...this.state.draft.templateData.digits };

        Object.keys(newDigits).forEach((digit) => {
          if (
            digit === "days" ||
            digit === "hours" ||
            digit === "years" ||
            digit === "minutes" ||
            digit === "seconds"
          ) {
            newDigits[digit] = {
              ...newDigits[digit],
              digit_status: value,
            };
          }
        });

        this.setState({
          draft: {
            ...this.state.draft,
            templateData: {
              ...this.state.draft.templateData,
              digits: newDigits,
            },
          },
        });
      }

      if (type === "labels") {
        this.setState((prevState) => ({
          labelEnabled: value,
        }));

        let newDigits = { ...this.state.draft.templateData.digits };

        Object.keys(newDigits).forEach((digit) => {
          if (
            digit === "days" ||
            digit === "hours" ||
            digit === "years" ||
            digit === "minutes" ||
            digit === "seconds"
          ) {
            newDigits[digit] = {
              ...newDigits[digit],
              label_status: value,
            };
          }
        });

        this.setState({
          draft: {
            ...this.state.draft,
            templateData: {
              ...this.state.draft.templateData,
              digits: newDigits,
            },
          },
        });
      }

      // Handling the new "vertical" type
      if (type === "vertical") {
        this.setState((prevState) => {
          return {
            verticalEnabled: value,
            verticalSideEnabled: value ? prevState.verticalSideEnabled : false,
          };
        });

        this.setState({
          draft: {
            ...this.state.draft,
            templateData: {
              ...this.state.draft.templateData,
              vertical: value,
              vertical_side_label: value
                ? this.state.draft.templateData.vertical_side_label
                : false,
            },
          },
        });
      }

      // Handling the new "vertical side" type
      if (type === "verticalSide") {
        this.setState((prevState) => ({
          verticalSideEnabled: value,
          verticalEnabled: value ? true : prevState.verticalEnabled,
        }));

        this.setState({
          draft: {
            ...this.state.draft,
            templateData: {
              ...this.state.draft.templateData,
              vertical_side_label: value,
              vertical: value ? true : this.state.draft.templateData.vertical,
            },
          },
        });
      }
    };
  };

  handleDraftSetCurry = (key, value) => {
    return () => {
      // This special case to maintain the change of multi-language features.
      if (key === "multiple_language" && value) {
        let newDigits = this.state.draft.templateData.digits;
        newDigits = _.set(newDigits, ["years", "label"], "YEARS");
        newDigits = _.set(newDigits, ["days", "label"], "DAYS");
        newDigits = _.set(newDigits, ["hours", "label"], "HOURS");
        newDigits = _.set(newDigits, ["minutes", "label"], "MINUTES");
        newDigits = _.set(newDigits, ["seconds", "label"], "SECONDS");
        this.setState({
          draft: _.set(this.state.draft, "templateData.digits", newDigits),
        });
      }

      // This is an special case to update the fallback image clear option
      if (key === "fallback") {
        // Reset the fallback Image to null whenever person unselect the option of enable fallback
        if (!value) {
          this.setState({
            draft: _.set(this.state.draft, "templateData.fallbackImage", null),
          });
        }
      }

      this.setState({ draft: _.set(this.state.draft, key, value) });
      if (key === "multiple_language") {
        setTimeout(() => {
          this.handleSave();
        }, 100);
      } else if (key == "templateData.fallback_as_last_frame") {
        // Flag for showing fallback upload images feature as well as multiple language feature
        const fallbackUploadImageOptionStatus =
          this.isFallbackImageFeatureAllowed();
        if (fallbackUploadImageOptionStatus) {
          setTimeout(() => {
            this.handleSave();
          }, 100);
        }
      }
    };
  };

  handleCancel = () => {
    if (this.props.handleCancel) {
      this.props.handleCancel();
    } else {
      const revertedState = this.normalizeTarget(this.props.target);
      this.setState({ draft: revertedState });
    }
  };

  handleSave = () => {
    const options = _.cloneDeep(this.state.draft);

    options.duration = this.state.draft.duration;

    this.props.handleSave(options);
  };

  renderTransparentInput = () => {
    const transparent = this.state.draft.templateData.transparent;
    return (
      <>
        <Checkbox
          label="Transparent background"
          checked={transparent}
          onChange={this.handleDraftSetCurry(
            "templateData.transparent",
            !transparent
          )}
        />
        <PlanBadge
          show={
            this?.props?.user?.features && !this.props.user.features.transparent
          }
          label="STANDARD+"
          popup="Transparent backgrounds are available in our Standard and Power plans."
          inUse={transparent}
        />
      </>
    );
  };

  renderColorPicker = (key, alignment, defaultColor) => {
    const currentColor =
      this.state.draft.templateData.colors[key] || defaultColor;
    const formattedColor = `#${currentColor}`;
    const colorBoxStyle = {
      width: "120px",
      height: "32px",
      borderRadius: "4px",
      border: "1px solid #DCE2E3",
      backgroundColor: "white",
      display: "flex",
      alignItems: "center",
      justifyContent: "left",
      marginRight: "250px",
      color: "#222222",
      paddingLeft: "5px",
    };

    const colorPickerSectionStyle_ = {
      display: "grid",
      gridTemplateColumns: "1fr 1fr",
      gap: "10px",
    };

    const labelStyle = {
      position: "relative",
      top: "12px",
      fontWeight: "bold",
      fontSize: "14px",
      fontFamily: "LATO",
      lineHeight: "16.8px",
    };

    const colorPickerSectionStyle = {
      display: "flex",
      flexDirection: "column",
      gap: "24px",
      width: "96%",
    };

    return (
      <div style={colorPickerSectionStyle}>
        <label style={labelStyle}>{_.upperFirst(key)} </label>
        <div style={colorPickerSectionStyle_}>
          <ColorPicker
            __id={"test" + key}
            color={currentColor}
            label={_.upperFirst(key)}
            orientation="vertical"
            alignment={alignment}
            renderAbove
            handleChangeColor={this.handleDraftChangeValueCurry([
              "templateData",
              "colors",
              key,
            ])}
          />

          <div style={colorBoxStyle}>
            <label htmlFor={"test" + key}>{formattedColor}</label>
          </div>
        </div>
      </div>
    );
  };

  /***************************************latest code****************************/

  renderColorPickerNew = (key, alignment, defaultColor, colorType) => {
    const currentColor =
      this.state.draft.templateData.digits[key][`${colorType}_color`] ||
      defaultColor;
    const formattedColor = `#${currentColor}`;
    const colorBoxStyle = {
      width: "120px",
      height: "32px",
      borderRadius: "4px",
      border: "1px solid #DCE2E3",
      backgroundColor: "white",
      display: "flex",
      alignItems: "center",
      justifyContent: "left",
      color: "#222222",
      paddingLeft: "5px",
    };

    const colorPickerSectionStyle_ = {
      display: "grid",
      gridTemplateColumns: "1fr 1fr",
      gap: "10px",
    };

    const labelStyle = {
      position: "relative",
      top: "13px",
      fontSize: "14px",
      fontFamily: "LATO",
      lineHeight: "16.8px",
    };

    const colorPickerSectionStyle = {
      display: "flex",
      flexDirection: "column",
      gap: "22px",
      width: "96%",
    };

    const handleChangeColor = this.handleChangeColor(key, colorType);

    return (
      <div style={colorPickerSectionStyle}>
        <label style={labelStyle}>{_.upperFirst(key)} </label>
        <div style={colorPickerSectionStyle_}>
          <ColorPickerNew
            __id={"test" + key}
            color={currentColor}
            label={_.upperFirst(key)}
            orientation="vertical"
            alignment={alignment}
            renderAbove
            handleChangeColor={handleChangeColor}
          />
          <div style={colorBoxStyle}>
            <label htmlFor={"test" + key}>{formattedColor}</label>
          </div>
        </div>
      </div>
    );
  };

  renderFontSelector = (key) => {
    return (
      <div className={css.section}>
        <h5>{_.upperFirst(key)}</h5>
        <FontSelector
          value={this.state.draft.templateData.fonts[key]}
          handleChange={this.handleDraftChangeEventCurry([
            "templateData",
            "fonts",
            key,
          ])}
          user={this.props.user}
        />
      </div>
    );
  };

  renderNewFontSelector = (key) => {
    return (
      <div className={css.section}>
        <h5>{_.upperFirst(key)}</h5>
        <NewFontSelector
          fontName={this.state.draft.templateData.fonts[key]}
          updateInBackend={this.handleDraftChangeValueCurry([
            "templateData",
            "fonts",
            key,
          ])}
          fromEditor={true}
        />
      </div>
    );
  };

  renderPreview = () => {
    const fallbackEnabled = this.state.draft.fallback || false;
    return (
      <>
        <div className={css.preview}>
          <TimerPreview
            type={this.props.type}
            timer={this.state.draft}
            genUrl={this.props.genUrl}
            showDimenions={true}
          />
        </div>
        {this.renderExpirationPreview()}
        {fallbackEnabled && this.renderFallbackImagePreview()}
      </>
    );
  };

  renderExpirationPreview = () => {
    if (this.state.draft.templateData.expirationImage) {
      return (
        <div className={css.preview}>
          <TimerPreview
            type={this.props.type}
            timer={this.state.draft}
            genUrl={this.props.genUrl}
            expired={true}
          />
          <br />
          <span>Expiration Preview</span>
        </div>
      );
    } else {
      return null;
    }
  };

  // This Code for displaying Fallback Image Preview
  renderFallbackImagePreview = () => {
    if (this.state.draft.templateData.fallbackImage) {
      return (
        <div className={css.preview}>
          <TimerPreview
            type={this.props.type}
            timer={this.state.draft}
            genUrl={this.props.genUrl}
            fallbackMode={true}
          />
          <br />
          <span>Fallback Image Preview</span>
        </div>
      );
    } else {
      return null;
    }
  };

  renderHTMLCode = () => {
    if (this.props.type !== "Timer") {
      return;
    }

    if (!this.props.target.url) {
      return (
        <>
          <Container
            text
            textAlign="center"
            className={css.upgradeCtaContainer}
          >
            <h2 className={css.upgradeCta}>Ready to publish this timer?</h2>
            <div>
              <UpgradePlanButton draftTimer={this.state.draft} size="huge">
                Upgrade Plan
              </UpgradePlanButton>
            </div>
          </Container>
        </>
      );
    }

    return (
      <HtmlCode
        url={this.props.target.url}
        timerType={this.state.draft.timerType}
      />
    );
  };

  renderTimeOptions = () => {
    if (this.state.draft.timerType === "perpetual") {
      return (
        <div className={css.section}>
          <h4>Timer Duration</h4>
          <DurationInput
            value={this.state.draft.duration}
            max={1000 * 24 * 60 * 60 - 1}
            onChange={this.handleDraftChangeValueCurry("duration")}
          />
        </div>
      );
    } else if (this.state.draft.timerType === "standard") {
      return (
        <div className={css.section}>
          <h4>Timer End Date</h4>
          <TimezonePicker
            timezone={this.state.draft.timeZone}
            handleChange={this.handleDraftChangeEventCurry("timeZone")}
          />

          <div className="field">
            <label>Date & Time</label>
            <DatetimePicker
              dateTime={moment(this.state.draft.endsAt).tz(
                this.state.draft.timeZone
              )}
              maxDays={365 * 99}
              handleChange={this.handleDraftChangeValueCurry("endsAt")}
            />
          </div>
          <div className="field">
            <Checkbox
              label="Adapt to User Time Zone"
              checked={this.state.draft.inferTimezone}
              onChange={this.handleDraftSetCurry(
                ["inferTimezone"],
                !this.state.draft.inferTimezone
              )}
            />
          </div>
        </div>
      );
    }
    // Recurring timer layout
    else if (this.state.draft.timerType === "recurring") {
      return this.renderRecurringLayout();
    } else {
      return null;
    }
  };

  updateRecurringOptions = () => {
    // Update Recurrence Option List
    let startDate = new Date(this.state.draft.endsAt);
    let dayNames = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    // const recurringOptions = [
    //   { text: "Daily", value: "daily" },
    //   { text: "Weekly on Monday", value: "weekly" },
    //   { text: "Monthly on the 19th", value: "monthly_option_1" },
    //   { text: "Monthly on 3rd Friday", value: "monthly_option_2" },
    //   { text: "Every weekday (Monday to Friday)", value: "weekday" },
    // ];
    const startDay = startDate.getDay(); // 0 = Sunday, 1 = Monday, etc.
    const startMonth = startDate.getMonth();
    const startDateValue = startDate.getDate();

    // update Second Option
    recurringOptions[1]["text"] = "Weekly on " + dayNames[startDay];

    // For updating Third Option
    let thirdOption = "Monthly on the " + startDateValue;
    switch (startDateValue) {
      case 1:
      case 21:
      case 31:
        thirdOption += "st";
        break;
      case 2:
      case 22:
        thirdOption += "nd";
        break;
      case 3:
      case 23:
        thirdOption += "rd";
        break;
      default:
        thirdOption += "th";
        break;
    }
    recurringOptions[2]["text"] = thirdOption;

    // FOr updating Fourth option
    let occurrence = 0;
    let endDateValue = new Date(
      startDate.getFullYear(),
      startMonth + 1,
      0
    ).getDate();
    for (let i = 1; i <= endDateValue; i++) {
      const date = new Date(startDate.getFullYear(), startMonth, i);
      if (date.getDay() === startDay && date.getDate() <= startDate.getDate()) {
        occurrence++;
      }
    }
    let fourthOption = "Monthly on ";
    if (occurrence == 1) {
      fourthOption += "first ";
    } else if (occurrence == 2) {
      fourthOption += "second ";
    } else if (occurrence == 3) {
      fourthOption += "third ";
    } else if (occurrence == 4) {
      fourthOption += "fourth ";
    } else if (occurrence == 5) {
      fourthOption += "fifth ";
    }
    fourthOption += dayNames[startDay];

    recurringOptions[3]["text"] = fourthOption;
  };

  checkValidDateSelection = () => {
    let startDate = new Date(this.state.draft.endsAt);
    let recurrenceType = this.state.draft.templateData.recurrence;
    if (recurrenceType == "weekday") {
      let weekday = startDate.getDay();
      if (weekday == 0 || weekday == 6) {
        return false;
      }
    }
    return true;
  };

  renderCustomRecurringLayout = () => {
    return (
      <CustomRecurring
        onChange={this.handleDraftChange}
        templateData={this.state.draft.templateData}
        handleDraftChangeValueCurry={this.handleDraftChangeValueCurry}
        timeZone={this.state.draft.timeZone}
      />
    );
  };

  renderRecurringLayout = () => {
    //Ends On Status
    let endType = this.state.draft.templateData.endType || "never";

    let currentDate = new Date();
    currentDate.setHours(0, 0, 0);
    // Recurrence End Date
    let recurrenceEndDate =
      this.state.draft.templateData.recurrenceEndDate ||
      currentDate.toISOString();
    let firstTimeRecurrenceEndDateStatus = true;
    if (this.state.draft.templateData.recurrenceEndDate) {
      firstTimeRecurrenceEndDateStatus = false;
    }
    if (firstTimeRecurrenceEndDateStatus) {
      this.setState({
        draft: _.set(
          this.state.draft,
          "templateData.recurrenceEndDate",
          recurrenceEndDate
        ),
      });
    }

    this.updateRecurringOptions();

    let recurrenceDropdownLabelText = "Repeat ";

    return (
      <div className={css.section}>
        <h4>Timer Date</h4>
        <TimezonePicker
          timezone={this.state.draft.timeZone}
          handleChange={this.handleDraftChangeEventCurry("timeZone")}
        />
        <div className="field">
          <label>Date & Time</label>
          <DatetimePicker
            dateTime={moment(this.state.draft.endsAt).tz(
              this.state.draft.timeZone
            )}
            maxDays={365 * 99}
            handleChange={this.handleDraftChangeValueCurry("endsAt")}
          />
        </div>
        <div className="field">
          <Checkbox
            label="Adapt to User Time Zone"
            checked={this.state.draft.inferTimezone}
            onChange={this.handleDraftSetCurry(
              ["inferTimezone"],
              !this.state.draft.inferTimezone
            )}
          />
        </div>
        <Form.Field>
          <label>
            Repeat{" "}
            {!this.checkValidDateSelection() && (
              <Popup
                trigger={<Icon style={{ color: "red" }} name="info circle" />}
                content="The selected date must be between Monday and Friday to use this option"
              />
            )}
          </label>
          <Dropdown
            fluid
            selection
            options={recurringOptions}
            placeholder="Choose Repeat..."
            onChange={this.handleDraftChangeEventCurry(
              "templateData.recurrence"
            )}
            value={this.state.draft.templateData.recurrence}
          />
        </Form.Field>
        {/* <Form.Dropdown
          fluid
          selection
          label={recurrenceDropdownLabelText}
          options={recurringOptions}
          placeholder="Choose Repeat..."
          onChange={this.handleDraftChangeEventCurry("templateData.recurrence")}
          value={this.state.draft.templateData.recurrence}
        /> */}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            marginBottom: "10px",
          }}
        >
          <div
            style={{
              fontFamily: "Lato",
              fontStyle: "normal",
              fontWeight: "700",
              fontSize: "14px",
              lineHeight: "17px",
              color: "#222222",
              width: "85px",
            }}
          >
            End On
          </div>
          <Radio
            label="Never"
            value="never"
            checked={endType === "never"}
            name="endRadioGroup"
            onChange={this.handleDraftChangeEventCurry("templateData.endType")}
          />
          <Radio
            label="On"
            value="on"
            name="endRadioGroup"
            checked={endType === "on"}
            onChange={this.handleDraftChangeEventCurry("templateData.endType")}
          />
          {endType === "on" && (
            <CustomDatePicker
              dateTime={moment(recurrenceEndDate)}
              maxDays={365 * 99}
              handleChange={this.handleDraftChangeValueCurry(
                "templateData.recurrenceEndDate"
              )}
              firstTime={firstTimeRecurrenceEndDateStatus}
            />
          )}
        </div>
      </div>
    );
  };

  renderDatetime = () => {
    if (this.props.type !== "Timer") {
      return;
    }

    return (
      <>
        <div className={css.section}>
          <h4>Timer Type</h4>
          <TimerTypePicker
            features={this.props.user.features}
            type={this.state.draft.timerType}
            onChange={this.handleDraftChangeValueCurry("timerType")}
          />
        </div>
        {this.renderTimeOptions()}
        <Divider />
      </>
    );
  };

  renderCustomFontOptions = () => {
    return <FontUploader />;
    // return (
    //   <>
    //     <div className={fontCss.title}>Your fonts</div>
    //     <div className={fontCss.description} style={{ marginTop: "5px" }}>You have not downloaded any font yet</div>
    //     <div style={{ marginTop: "10px" }}>
    //       <Button disabled className={fontCss.addButton}>Add your font</Button>
    //     </div>

    //     <div className={fontCss.infoDiv} style={{ marginTop: "10px" }}>
    //       <span style={{ paddingTop: "4px" }}><LightningBoltIconComponent /></span>
    //       <div className={fontCss.upgradeDiv}>
    //         <span className={fontCss.info}>Uploading custom fonts is available in the paid Enterprise plan.</span>
    //         <div>
    //           <span className={fontCss.upgradeLink}>Upgrade now</span>
    //         </div>
    //       </div>
    //     </div>
    //   </>
    // )
  };

  renderFontSection = () => {
    const fontInfo =
      "We support over 900 fonts from Google Fonts. Search for a font by its name below.";

    return (
      <div className={css.section}>
        <h4>
          Fonts
          <Popup trigger={<Icon name="info circle" />} content={fontInfo} />
        </h4>
        {this.renderCustomFontOptions()}
        {/* {this.renderFontSelector("digits")} */}
        {this.renderNewFontSelector("digits")}
        {/* {this.renderFontSelector("labels")} */}
        {this.renderNewFontSelector("labels")}
      </div>
    );
  };

  renderStyles() {
    const {
      draft: {
        templateData: { vertical },
      },
    } = this.state;
    const verticalEnabled = this.state.draft.templateData.vertical || false;
    const verticalSideEnabled =
      this.state.draft.templateData.vertical_side_label || false;
    const style = this.state.draft.templateData.style || "basic";
    const styleNew = this.state.draft.templateData.style;

    const options = _.map(styleOptions, (style) => {
      const previewTemplate = {
        ...this.state.draft,
        templateData: {
          ...this.state.draft.templateData,
          size: "small",
          style: style,
          vertical: false,
        },
      };
      return {
        key: style,
        text: (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
            }}
          >
            <TimerPreview
              type={this.props.type}
              timer={previewTemplate}
              genUrl={this.props.genUrl}
            />
          </div>
        ),
        value: style,
      };
    });

    const verticalLabel = (
      <label>
        <span
          style={{
            position: "relative",
            right: "10px",
            fontSize: "1rem",
            fontFamily: "Lato",
            fontWeight: "500",
            lineHeight: "14px",
            textAlign: "left",
          }}
        >
          Vertical timer
        </span>
        <Popup />
      </label>
    );

    const verticalSideLabel = (
      <label>
        <span
          style={{
            position: "relative",
            right: "10px",
            fontSize: "1rem",
            fontFamily: "Lato",
            fontWeight: "500",
            lineHeight: "14px",
            textAlign: "left",
          }}
        >
          Side Label
        </span>
        <Popup />
      </label>
    );

    let control = (
      <>
        <label>
          Style <span className="badge badge-new">NEW</span>
          <PlanBadge
            show={
              this?.props?.user?.features && !this.props.user.features.styles
            }
            label="STANDARD+"
            popup="Additional styles are available in our Standard and Power plans."
            inUse={style !== "basic"}
          />
        </label>
        <Form.Dropdown
          fluid
          selection
          lazyLoad={!this.state.styleHover}
          onMouseEnter={() => this.setState({ styleHover: true })}
          onMouseLeave={() => this.setState({ styleHover: false })}
          label={<label></label>}
          options={options}
          onChange={this.handleDraftChangeEventCurry("templateData.style")}
          value={style}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        />
        <Checkbox
          toggle
          label={verticalLabel}
          checked={verticalEnabled}
          onChange={this.handleDraftSetCurryNew(
            "vertical",
            !this.state.verticalEnabled,
            "vertical"
          )}
        />
        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
        {["blocks1", "blocks2", "blocks3"].includes(styleNew) && (
          <Checkbox
            toggle
            label={verticalSideLabel}
            checked={verticalSideEnabled}
            onChange={this.handleDraftSetCurryNew(
              "verticalSide",
              !this.state.verticalSideEnabled,
              "verticalSide"
            )}
          />
        )}
      </>
    );

    return <div className={css.section}>{control}</div>;
  }

  renderButtons() {
    const features = timerHelper.getFeatures(this.state.draft);
    const missingFeatures = timerHelper.getMissingFeatures(features, {
      features: this.props.user && this.props.user.features,
    });

    let canSave = this.hasUnsavedChanges();
    let upsellMessage = null;
    if (
      missingFeatures.length &&
      this.props.type === "Timer" &&
      this.props.target.slug
    ) {
      upsellMessage = "Upgrade your plan to save this timer.";
      canSave = false;
    }
    if (
      this.props.type === "Timer" &&
      this.props.target.url &&
      !this.props.user.subscription
    ) {
      // User does not have a plan
      upsellMessage = "Upgrade your plan to edit published timers.";
      canSave = false;
    }

    const saveButton = (
      <Popup
        basic
        position="bottom center"
        disabled={!upsellMessage}
        trigger={
          <span className={css.buttonWrapper}>
            <Button
              disabled={!canSave || this.props.isSubmitting}
              onClick={this.handleSave}
              className={css.primaryButton}
            >
              {this.props.isSubmitting ? (
                <div style={{ display: "flex", gap: "3px" }}>
                  Save
                  <ClipLoader color="#fff" size={15} />
                </div>
              ) : (
                "Save"
              )}
            </Button>
          </span>
        }
      >
        {upsellMessage}
      </Popup>
    );

    return (
      <div className={css.editorButtons}>
        <Button className={css.cancelButton} onClick={this.handleCancel}>
          Cancel
        </Button>
        {saveButton}
        {upsellMessage ? (
          <UpgradePlanButton
            draftTimer={this.state.draft}
            upsell={upsellMessage}
          >
            Upgrade Plan
          </UpgradePlanButton>
        ) : null}
      </div>
    );
  }

  isSubaccountUser() {
    return this.props.user && this.props.user.subaccount_id != 0;
  }

  isCorporateUser() {
    return (
      (this.props.user && this.props.user.is_company) ||
      (this?.props?.user?.features &&
        this.props.user.features.subaccount &&
        this.props.user.parent_id == 0)
    );
  }

  isFallbackImageFeatureAllowed() {
    return this?.props?.user?.features && this.props.user.features.fallback;
  }

  isEditTemplateStatus() {
    if (this.isSubaccountUser()) {
      return !this.props.user.can_edit_templates;
    }
    return true;
  }

  handleToggleModal = (isOpen) => {
    return () => {
      this.setState({
        ...this.state,
        isModalOpen: isOpen,
      });
    };
  };

  renderLanguageListModal = () => {
    <Modal
      size={"small"}
      open={this.state.isModalOpen}
      onClose={this.handleToggleModal(false)}
      closeOnDimmerClick={false}
    >
      <Modal.Content>
        <h1>Welcome to learn concepts.</h1>
      </Modal.Content>
    </Modal>;
  };

  // render() {
  //   const backgroundLabel = "Background Image";
  //   const expirationLabel = "Expiration Image";
  //   const fallbackImageLabel = "Fallback Image";

  //   let digitEnabled = false;
  //   let labelEnabled = false;

  //   if (this.state.draft.templateData && this.state.draft.templateData.digits && this.state.draft.templateData.digits.days && this.state.draft.templateData.digits.days.digit_status) {
  //     digitEnabled = this.state.draft.templateData.digits.days.digit_status;
  //   }

  //   if (this.state.draft.templateData && this.state.draft.templateData.digits && this.state.draft.templateData.digits.days && this.state.draft.templateData.digits.days.label_status) {
  //     labelEnabled = this.state.draft.templateData.digits.days.label_status;
  //   }

  //   const allowMultipleLanguageLabel = "Support Multiple Language";

  //   const imageInfo = "For best results, use the recommended image dimensions under the timer preview.";
  //   // const fallbackInfo =
  //   // "If fallback mode is enabled, timers are hidden for clients that do not support countdown timers.";

  //   const fallbackAsLastFrameInfo =
  //     "When enabled, the timer will show the fallback image as the last frame after counting down for 60 seconds. If the email is refreshed, the timer will countdown 60 seconds again from the correct time.";

  //   let fallbackInfo =
  //     "You can add an image to replace timers, that could not be displayed. This image will serve as a substitute for the actual timer.";
  //   fallbackInfo += "";
  //   fallbackInfo += "Leaving fields blank will result in the timer being hidden and not displayed to the user.";
  //   const fallbackLabel = (
  //     <label>
  //       Enable Fallback
  //       {/* <Popup trigger={<Icon name="info circle" />} content={fallbackInfo} /> */}
  //       <PlanBadge
  //         show={true}
  //         label="RECOMMENDED"
  //         popup={fallbackInfo}
  //         inUse={false}
  //       />
  //     </label>
  //   );

  //   // individual colors for digits
  //   const digitLabel = (
  //     <label>
  //       <span style={{ position: 'relative', right: '10px', fontSize: '1rem', fontFamily: 'Lato', fontWeight: '500', lineHeight: '14px', textAlign: 'left' }}>Individual colors for digits</span>
  //       <Popup />
  //     </label>
  //   );

  //   // individual colors for labels

  //   const lableLabel = (
  //     <label>
  //       <span style={{ position: 'relative', right: '10px', fontSize: '1rem', fontFamily: 'Lato', fontWeight: '500', lineHeight: '14px', textAlign: 'left' }}>Individual colors for labels</span>
  //       <Popup />
  //     </label>
  //   );

  //   // const digitEnabled = this.state.draft.digit_color || false;
  //   // const labelEnabled = this.state.draft.label_color || false;

  //   const fallbackEnabled = this.state.draft.fallback || false;
  //   const multipleLanguageEnabled = this.state.draft.multiple_language || false;
  //   const fallbackAsLastFrameEnabled = this.state.draft.templateData.fallback_as_last_frame || false;

  //   const fallbackAsLastFrameLabel = (
  //     <label>
  //       Use fallback image as last frame
  //       <Popup trigger={<Icon name="info circle" />} content={fallbackAsLastFrameInfo} />
  //     </label>
  //   );

  //   // Flag for showing fallback upload images feature as well as multiple language feature
  //   const fallbackUploadImageOptionStatus = this.isFallbackImageFeatureAllowed();
  //   let allowMultipleLanguageOptionStatus = this.isCorporateUser() || this.isSubaccountUser();
  //   if (allowMultipleLanguageOptionStatus) {
  //     if (this.props.type == "Template") {
  //       allowMultipleLanguageOptionStatus = false;
  //     }
  //   }

  //   // panels=[
  //   //   {
  //   //     content: "hello"
  //   //   },

  //   // ]
  //   return (
  //     <div className={css.editorContainer}>
  //       <Form className={css.editorOptions}>
  //         {this.renderDatetime()}
  //         <Form.Dropdown
  //           fluid
  //           selection
  //           label="Size"
  //           options={sizeOptions}
  //           placeholder="Choose Size..."
  //           onChange={this.handleDraftChangeEventCurry("templateData.size")}
  //           value={this.state.draft.templateData.size}
  //         />
  //         {/* For stopping style feature according to selection of edit template feature  */}
  //         {this.isEditTemplateStatus() && this.renderStyles()}

  //         {/* Multi-language feature for corporate user only.  */}
  //         {/* {(this.isCorporateUser() || this.isSubaccountUser()) && allowMultipleLanguageOptionStatus && ( */}
  //           <LabelPicker
  //             digits={this.state.draft.templateData.digits}
  //             onChange={this.handleDraftChangeValueCurry("templateData.digits")}
  //             allowMultipleLanguage={allowMultipleLanguageOptionStatus}
  //             multipleLanguageEnabled={multipleLanguageEnabled}
  //             onCheckboxChange={this.handleDraftSetCurry("multiple_language", !multipleLanguageEnabled)}
  //             targetUrl={this.props.target.url}
  //           />
  //         {/* // )} */}

  //         {this.isEditTemplateStatus() && (
  //           <div>
  //             <h4 style={{marginBottom:"0px"}}>Colors</h4>
  //             {digitEnabled ? (
  //               <>
  //                 <span>
  //                   <label style={{ position: "relative", top: "7px", fontWeight: "bold", fontSize: "14px", fontFamily: "LATO", lineHeight: "16.8px" }}>Digits</label>
  //                 </span>
  //                 <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '5px' }}>
  //                   <div style={{ display: "inline-block", width: "95%" }}>
  //                     {this.renderColorPickerNew("years", "center", "ffffff", "digit")}
  //                   </div>
  //                   <div>
  //                     {this.renderColorPickerNew("days", "right", "ffffff", "digit")}
  //                   </div>
  //                 </div>
  //                 <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '5px' }}>
  //                   <div>
  //                     {this.renderColorPickerNew("hours", "center", "ffffff", "digit")}
  //                   </div>
  //                   <div>
  //                     {this.renderColorPickerNew("minutes", "right", "ffffff", "digit")}
  //                   </div>
  //                   <div>
  //                     {this.renderColorPickerNew("seconds", "center", "ffffff", "digit")}
  //                   </div>
  //                 </div>
  //               </>
  //             ) : (
  //               // Original code for "digits" color picker when digitEnabled is false
  //               <>
  //                 <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '5px' }}>
  //                   <div style={{ display: "inline-block", width: "95%" }}>
  //                     {this.renderColorPicker("digits", "center", "ffffff")}
  //                   </div>
  //                 </div>
  //               </>
  //             )}

  //             <Checkbox
  //               toggle
  //               label={digitLabel}
  //               onChange={this.handleDraftSetCurryNew("digit_status", !digitEnabled, "digits")}
  //               checked={digitEnabled}
  //             />

  //             {labelEnabled ? (
  //               // New color pickers for labels
  //               <>
  //                 &nbsp;
  //                 <div>
  //                   <span>
  //                     <label style={{ position: "relative", top: "7px", fontWeight: "bold", fontSize: "14px", fontFamily: "LATO", lineHeight: "16.8px" }}>Labels</label>
  //                   </span>
  //                 </div>

  //                 <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '5px' }}>
  //                   <div>
  //                     {this.renderColorPickerNew("years", "center", "ffffff", "label")}
  //                   </div>
  //                   <div>
  //                     {this.renderColorPickerNew("days", "right", "ffffff", "label")}
  //                   </div>
  //                 </div>
  //                 <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '5px' }}>
  //                   <div>
  //                     {this.renderColorPickerNew("hours", "center", "ffffff", "label")}
  //                   </div>
  //                   <div>
  //                     {this.renderColorPickerNew("minutes", "right", "ffffff", "label")}
  //                   </div>
  //                   <div>
  //                     {this.renderColorPickerNew("seconds", "center", "ffffff", "label")}
  //                   </div>
  //                 </div>
  //               </>
  //             ) : (
  //               // Original code for "labels" color pickers when labelEnabled is false
  //               <>
  //                 <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '5px' }}>
  //                   <div style={{ display: "inline-block", width: "95%" }}>
  //                     {this.renderColorPicker("labels", "center", "ffffff")}
  //                   </div>
  //                 </div>
  //               </>
  //             )}

  //             <Checkbox
  //               toggle
  //               label={lableLabel}
  //               onChange={this.handleDraftSetCurryNew("label_status", !labelEnabled, "labels")}
  //               checked={labelEnabled}
  //             />

  //             {this.renderColorPicker("background", "center", "000000")}
  //             {this.renderColorPicker("accent", "center", "ff0000")}
  //             <br/>
  //             <div>{this.renderTransparentInput()}</div>
  //           </div>
  //         )}
  //         <Divider />
  //         <div className={css.section}>
  //           <h4>Display</h4>
  //           <DigitDisplayPicker
  //             digits={this.state.draft.templateData.digits}
  //             onChange={this.handleDraftChangeValueCurry("templateData.digits")}
  //           />
  //         </div>

  //         {this.isEditTemplateStatus() && this.renderFontSection()}

  //         <div className={css.section}>
  //           <h4>
  //             Images
  //             <Popup trigger={<Icon name="info circle" />} content={imageInfo} />
  //           </h4>
  //           <FileUploader
  //             label={backgroundLabel}
  //             existingFile={this.state.draft.templateData.backgroundImage}
  //             handleChange={this.handleDraftChangeValueCurry("templateData.backgroundImage")}
  //             handleError={this.props.handleUploadError}
  //           />
  //           <FileUploader
  //             label={expirationLabel}
  //             existingFile={this.state.draft.templateData.expirationImage}
  //             handleChange={this.handleDraftChangeValueCurry("templateData.expirationImage")}
  //             handleError={this.props.handleUploadError}
  //           />
  //         </div>
  //         {/* {this.state.draft.timerType !== "perpetual" && this.props.type === "Timer" && ( */}

  //         <div className={css.section}>
  //           <h4>Compatibility</h4>
  //           <Checkbox
  //             toggle
  //             label={fallbackLabel}
  //             onChange={this.handleDraftSetCurry("fallback", !fallbackEnabled)}
  //             checked={fallbackEnabled}
  //           />
  //         </div>
  //         {fallbackEnabled && (
  //           <>
  //             {/* This is new message panel for displaying tooltip */}
  //             <div
  //               style={{
  //                 display: "flex",
  //                 flexDirection: "row",
  //                 alignItems: "flex-start",
  //                 padding: "12px",
  //                 gap: "8px",
  //                 background: "#F7F8F8",
  //                 borderRadius: "4px",
  //               }}
  //             >
  //               <div style={{ paddingTop: "5px" }}>
  //                 <span style={{ paddingTop: "10px" }}>
  //                   <DarkInfoIconComponent />
  //                 </span>
  //               </div>
  //               <div
  //                 style={{
  //                   fontFamily: "Lato",
  //                   fontSize: "12px",
  //                   color: "#606062",
  //                   textAlign: "justify",
  //                   fontWeight: "400",
  //                 }}
  //               >
  //                 <div>
  //                   You can add an image to replace timers, that could not be displayed. This image will serve as a
  //                   substitute for the actual timer.
  //                 </div>
  //                 <div style={{ paddingTop: "12px" }}>
  //                   Leaving fields blank will result in the timer being hidden and not displayed to the user.
  //                 </div>
  //               </div>
  //             </div>
  //             <div className={css.section}>
  //               <FileUploader
  //                 label={fallbackImageLabel}
  //                 existingFile={this.state.draft.templateData.fallbackImage}
  //                 handleChange={this.handleDraftChangeValueCurry("templateData.fallbackImage")}
  //                 handleError={this.props.handleUploadError}
  //                 disableStatus={!fallbackUploadImageOptionStatus}
  //                 fallbackBadgeStatus={fallbackUploadImageOptionStatus}
  //               />
  //             </div>
  //             <div className={css.section}>
  //               <div style={{ fontSize: "14px", lineHeight: "20px", color: "rgba(0, 0, 0, 0.87)" }}>Use as last frame</div>
  //               <Checkbox
  //                 toggle
  //                 label={fallbackAsLastFrameLabel}
  //                 onChange={this.handleDraftSetCurry("templateData.fallback_as_last_frame", !fallbackAsLastFrameEnabled)}
  //                 checked={fallbackAsLastFrameEnabled}
  //               />
  //             </div>

  //           </>)}

  //         {/* )} */}
  //       </Form>
  //       <div className={css.editorTimer}>
  //         <div className={css.editorActions}>
  //           <EditableText name={this.state.draft.name} onChange={this.handleDraftChangeValueCurry("name")} />
  //           {this.renderButtons()}
  //         </div>
  //         <div className={css.editorPreview}>
  //           {this.state.draft.templateData.vertical && <div style={{display:'flex',flexDirection:'row',gap:'20px',justifyContent:'center'}}>
  //             {this.renderPreview()}
  //           </div>}

  //           {!this.state.draft.templateData.vertical && this.renderPreview()}
  //           {this.renderHTMLCode()}
  //         </div>
  //       </div>
  //       {allowMultipleLanguageOptionStatus && this.renderLanguageListModal()}
  //     </div>
  //   );
  // }
  render() {
    const { activeIndex } = this.state;

    const backgroundLabel = "Background Image";
    const expirationLabel = "Expiration Image";
    const fallbackImageLabel = "Fallback Image";

    let digitEnabled = false;
    let labelEnabled = false;

    if (
      this.state.draft.templateData &&
      this.state.draft.templateData.digits &&
      this.state.draft.templateData.digits.days &&
      this.state.draft.templateData.digits.days.digit_status
    ) {
      digitEnabled = this.state.draft.templateData.digits.days.digit_status;
    }

    if (
      this.state.draft.templateData &&
      this.state.draft.templateData.digits &&
      this.state.draft.templateData.digits.days &&
      this.state.draft.templateData.digits.days.label_status
    ) {
      labelEnabled = this.state.draft.templateData.digits.days.label_status;
    }

    const allowMultipleLanguageLabel = "Support Multiple Language";

    const imageInfo =
      "For best results, use the recommended image dimensions under the timer preview.";
    const fallbackAsLastFrameInfo =
      "When enabled, the timer will show the fallback image as the last frame after counting down for 60 seconds. If the email is refreshed, the timer will countdown 60 seconds again from the correct time.";

    let fallbackInfo =
      "You can add an image to replace timers, that could not be displayed. This image will serve as a substitute for the actual timer.";
    fallbackInfo +=
      "Leaving fields blank will result in the timer being hidden and not displayed to the user.";

    const fallbackLabel = (
      <label>
        Enable Fallback
        <PlanBadge
          show={true}
          label="RECOMMENDED"
          popup={fallbackInfo}
          inUse={false}
        />
      </label>
    );

    const digitLabel = (
      <label>
        <span
          style={{
            position: "relative",
            right: "10px",
            fontSize: "1rem",
            fontFamily: "Lato",
            fontWeight: "500",
            lineHeight: "14px",
            textAlign: "left",
          }}
        >
          Individual colors for digits
        </span>
        <Popup />
      </label>
    );

    const lableLabel = (
      <label>
        <span
          style={{
            position: "relative",
            right: "10px",
            fontSize: "1rem",
            fontFamily: "Lato",
            fontWeight: "500",
            lineHeight: "14px",
            textAlign: "left",
          }}
        >
          Individual colors for labels
        </span>
        <Popup />
      </label>
    );

    const fallbackEnabled = this.state.draft.fallback || false;
    const multipleLanguageEnabled = this.state.draft.multiple_language || false;
    const fallbackAsLastFrameEnabled =
      this.state.draft.templateData.fallback_as_last_frame || false;

    const fallbackAsLastFrameLabel = (
      <label>
        Use fallback image as last frame
        <Popup
          trigger={<Icon name="info circle" />}
          content={fallbackAsLastFrameInfo}
        />
      </label>
    );

    const fallbackUploadImageOptionStatus =
      this.isFallbackImageFeatureAllowed();
    let allowMultipleLanguageOptionStatus =
      this.isCorporateUser() || this.isSubaccountUser();
    if (allowMultipleLanguageOptionStatus) {
      if (this.props.type == "Template") {
        allowMultipleLanguageOptionStatus = false;
      }
    }

    return (
      <div className={css.editorContainer}>
        <Form className={css.editorOptions}>
          <Accordion style={{ backgroundColor: "white" }}>
            <Accordion.Title
              active={activeIndex === 0}
              index={0}
              onClick={this.handleAccordionClick}
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                backgroundColor: "#FFFFFF",
                padding: "20px 20px",
                fontSize: "1.2rem",
                borderBottom: "1px solid #e0e0e0",
                cursor: "pointer",
                transition: "background-color 0.3s",
              }}
              //  onMouseEnter={(e) => e.currentTarget.style.backgroundColor = '#e0e0e0'}
              //  onMouseLeave={(e) => e.currentTarget.style.backgroundColor = '#f9f9f9'}
            >
              <span style={{ flexGrow: 1 }}>Timer Configuration</span>
              <Icon name="dropdown" style={{ marginLeft: "auto" }} />
            </Accordion.Title>
            <Accordion.Content active={activeIndex === 0}>
              {this.renderDatetime()}
              <div className={css.section}>
                <Checkbox
                  toggle
                  label={fallbackLabel}
                  onChange={this.handleDraftSetCurry(
                    "fallback",
                    !fallbackEnabled
                  )}
                  checked={fallbackEnabled}
                />
                {fallbackEnabled && (
                  <>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "flex-start",
                        padding: "12px",
                        gap: "8px",
                        background: "#F7F8F8",
                        borderRadius: "4px",
                      }}
                    >
                      <div style={{ paddingTop: "5px" }}>
                        <span style={{ paddingTop: "10px" }}>
                          <DarkInfoIconComponent />
                        </span>
                      </div>
                      <div
                        style={{
                          fontFamily: "Lato",
                          fontSize: "12px",
                          color: "#606062",
                          textAlign: "justify",
                          fontWeight: "400",
                        }}
                      >
                        <div>
                          You can add an image to replace timers, that could not
                          be displayed. This image will serve as a substitute
                          for the actual timer.
                        </div>
                        <div style={{ paddingTop: "12px" }}>
                          Leaving fields blank will result in the timer being
                          hidden and not displayed to the user.
                        </div>
                      </div>
                    </div>
                    <div className={css.section}>
                      <FileUploader
                        label={fallbackImageLabel}
                        existingFile={
                          this.state.draft.templateData.fallbackImage
                        }
                        handleChange={this.handleDraftChangeValueCurry(
                          "templateData.fallbackImage"
                        )}
                        handleError={this.props.handleUploadError}
                        disableStatus={!fallbackUploadImageOptionStatus}
                        fallbackBadgeStatus={fallbackUploadImageOptionStatus}
                      />
                    </div>
                    <div className={css.section}>
                      <div
                        style={{
                          fontSize: "14px",
                          lineHeight: "20px",
                          color: "rgba(0, 0, 0, 0.87)",
                        }}
                      >
                        Use as last frame
                      </div>
                      <Checkbox
                        toggle
                        label={fallbackAsLastFrameLabel}
                        onChange={this.handleDraftSetCurry(
                          "templateData.fallback_as_last_frame",
                          !fallbackAsLastFrameEnabled
                        )}
                        checked={fallbackAsLastFrameEnabled}
                      />
                    </div>
                  </>
                )}
              </div>
            </Accordion.Content>

            <Accordion.Title
              active={activeIndex === 1}
              index={1}
              onClick={this.handleAccordionClick}
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                backgroundColor: "#FFFFFF",
                padding: "20px 20px",
                fontSize: "1.2rem",
                borderBottom: "1px solid #e0e0e0",
                cursor: "pointer",
                transition: "background-color 0.3s",
              }}
              //  onMouseEnter={(e) => e.currentTarget.style.backgroundColor = '#e0e0e0'}
              //  onMouseLeave={(e) => e.currentTarget.style.backgroundColor = '#f9f9f9'}
            >
              <span style={{ flexGrow: 1 }}>Visual Design</span>
              <Icon name="dropdown" style={{ marginLeft: "auto" }} />
            </Accordion.Title>
            <Accordion.Content active={activeIndex === 1}>
              <Form.Dropdown
                fluid
                selection
                label="Size"
                options={sizeOptions}
                placeholder="Choose Size..."
                onChange={this.handleDraftChangeEventCurry("templateData.size")}
                value={this.state.draft.templateData.size}
              />
              {this.isEditTemplateStatus() && this.renderStyles()}

              {this.isEditTemplateStatus() && (
                <div>
                  <h4 style={{ marginBottom: "0px" }}>Colors</h4>
                  {digitEnabled ? (
                    <>
                      <span>
                        <label
                          style={{
                            position: "relative",
                            top: "7px",
                            fontWeight: "bold",
                            fontSize: "14px",
                            fontFamily: "LATO",
                            lineHeight: "16.8px",
                          }}
                        >
                          Digits
                        </label>
                      </span>
                      <div
                        style={{
                          display: "grid",
                          gridTemplateColumns: "1fr 1fr",
                          gap: "5px",
                        }}
                      >
                        <div style={{ display: "inline-block", width: "95%" }}>
                          {this.renderColorPickerNew(
                            "years",
                            "center",
                            "ffffff",
                            "digit"
                          )}
                        </div>
                        <div>
                          {this.renderColorPickerNew(
                            "days",
                            "right",
                            "ffffff",
                            "digit"
                          )}
                        </div>
                      </div>
                      <div
                        style={{
                          display: "grid",
                          gridTemplateColumns: "1fr 1fr",
                          gap: "5px",
                        }}
                      >
                        <div>
                          {this.renderColorPickerNew(
                            "hours",
                            "center",
                            "ffffff",
                            "digit"
                          )}
                        </div>
                        <div>
                          {this.renderColorPickerNew(
                            "minutes",
                            "right",
                            "ffffff",
                            "digit"
                          )}
                        </div>
                        <div>
                          {this.renderColorPickerNew(
                            "seconds",
                            "center",
                            "ffffff",
                            "digit"
                          )}
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div
                        style={{
                          display: "grid",
                          gridTemplateColumns: "1fr 1fr",
                          gap: "5px",
                        }}
                      >
                        <div style={{ display: "inline-block", width: "95%" }}>
                          {this.renderColorPicker("digits", "center", "ffffff")}
                        </div>
                      </div>
                    </>
                  )}

                  <Checkbox
                    toggle
                    label={digitLabel}
                    onChange={this.handleDraftSetCurryNew(
                      "digit_status",
                      !digitEnabled,
                      "digits"
                    )}
                    checked={digitEnabled}
                  />

                  {labelEnabled ? (
                    <>
                      &nbsp;
                      <div>
                        <span>
                          <label
                            style={{
                              position: "relative",
                              top: "7px",
                              fontWeight: "bold",
                              fontSize: "14px",
                              fontFamily: "LATO",
                              lineHeight: "16.8px",
                            }}
                          >
                            Labels
                          </label>
                        </span>
                      </div>
                      <div
                        style={{
                          display: "grid",
                          gridTemplateColumns: "1fr 1fr",
                          gap: "5px",
                        }}
                      >
                        <div>
                          {this.renderColorPickerNew(
                            "years",
                            "center",
                            "ffffff",
                            "label"
                          )}
                        </div>
                        <div>
                          {this.renderColorPickerNew(
                            "days",
                            "right",
                            "ffffff",
                            "label"
                          )}
                        </div>
                      </div>
                      <div
                        style={{
                          display: "grid",
                          gridTemplateColumns: "1fr 1fr",
                          gap: "5px",
                        }}
                      >
                        <div>
                          {this.renderColorPickerNew(
                            "hours",
                            "center",
                            "ffffff",
                            "label"
                          )}
                        </div>
                        <div>
                          {this.renderColorPickerNew(
                            "minutes",
                            "right",
                            "ffffff",
                            "label"
                          )}
                        </div>
                        <div>
                          {this.renderColorPickerNew(
                            "seconds",
                            "center",
                            "ffffff",
                            "label"
                          )}
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div
                        style={{
                          display: "grid",
                          gridTemplateColumns: "1fr 1fr",
                          gap: "5px",
                        }}
                      >
                        <div style={{ display: "inline-block", width: "95%" }}>
                          {this.renderColorPicker("labels", "center", "ffffff")}
                        </div>
                      </div>
                    </>
                  )}

                  <Checkbox
                    toggle
                    label={lableLabel}
                    onChange={this.handleDraftSetCurryNew(
                      "label_status",
                      !labelEnabled,
                      "labels"
                    )}
                    checked={labelEnabled}
                  />

                  {this.renderColorPicker("background", "center", "000000")}
                  {this.renderColorPicker("accent", "center", "ff0000")}
                  <br />
                  <div>{this.renderTransparentInput()}</div>
                </div>
              )}

              <div className={css.section}>
                <h4>
                  Images
                  <Popup
                    trigger={<Icon name="info circle" />}
                    content={imageInfo}
                  />
                </h4>
                <FileUploader
                  label={backgroundLabel}
                  existingFile={this.state.draft.templateData.backgroundImage}
                  handleChange={this.handleDraftChangeValueCurry(
                    "templateData.backgroundImage"
                  )}
                  handleError={this.props.handleUploadError}
                />
                <FileUploader
                  label={expirationLabel}
                  existingFile={this.state.draft.templateData.expirationImage}
                  handleChange={this.handleDraftChangeValueCurry(
                    "templateData.expirationImage"
                  )}
                  handleError={this.props.handleUploadError}
                />
              </div>
            </Accordion.Content>

            <Accordion.Title
              active={activeIndex === 2}
              index={2}
              onClick={this.handleAccordionClick}
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                backgroundColor: "#FFFFFF",
                padding: "20px 20px",
                fontSize: "1.2rem",
                borderBottom: "1px solid #e0e0e0",
                cursor: "pointer",
                transition: "background-color 0.3s",
              }}
              //  onMouseEnter={(e) => e.currentTarget.style.backgroundColor = '#e0e0e0'}
              //  onMouseLeave={(e) => e.currentTarget.style.backgroundColor = '#f9f9f9'}
            >
              <span style={{ flexGrow: 1 }}>Text and Labels</span>
              <Icon name="dropdown" style={{ marginLeft: "auto" }} />
            </Accordion.Title>
            <Accordion.Content active={activeIndex === 2}>
              <LabelPicker
                digits={this.state.draft.templateData.digits}
                onChange={this.handleDraftChangeValueCurry(
                  "templateData.digits"
                )}
                allowMultipleLanguage={allowMultipleLanguageOptionStatus}
                multipleLanguageEnabled={multipleLanguageEnabled}
                onCheckboxChange={this.handleDraftSetCurry(
                  "multiple_language",
                  !multipleLanguageEnabled
                )}
                targetUrl={this.props.target.url}
              />
              <div className={css.section}>
                <h4>Display</h4>
                <DigitDisplayPicker
                  digits={this.state.draft.templateData.digits}
                  onChange={this.handleDraftChangeValueCurry(
                    "templateData.digits"
                  )}
                />
              </div>
              {this.isEditTemplateStatus() && this.renderFontSection()}
            </Accordion.Content>

            {/* <Accordion.Title
              active={activeIndex === 3}
              index={3}
              onClick={this.handleAccordionClick}
              style={{
                display: 'flex', justifyContent: 'space-between', alignItems: 'center',
                backgroundColor: '#FFFFFF',
                padding: '20px 20px',
                fontSize: '1.2rem',
                borderBottom: '1px solid #e0e0e0',
                cursor: 'pointer',
                transition: 'background-color 0.3s'
              }}
            //  onMouseEnter={(e) => e.currentTarget.style.backgroundColor = '#e0e0e0'}
            //  onMouseLeave={(e) => e.currentTarget.style.backgroundColor = '#f9f9f9'}
            >
              <span style={{ flexGrow: 1 }}>Colors</span>
              <Icon name='dropdown' style={{ marginLeft: 'auto' }} />
            </Accordion.Title>
            <Accordion.Content active={activeIndex === 3}>

            </Accordion.Content>

            <Accordion.Title
              active={activeIndex === 4}
              index={4}
              onClick={this.handleAccordionClick}
              style={{
                display: 'flex', justifyContent: 'space-between', alignItems: 'center',
                backgroundColor: '#FFFFFF',
                padding: '20px 20px',
                fontSize: '1.2rem',
                borderBottom: '1px solid #e0e0e0',
                cursor: 'pointer',
                transition: 'background-color 0.3s'
              }}
            //  onMouseEnter={(e) => e.currentTarget.style.backgroundColor = '#e0e0e0'}
            //  onMouseLeave={(e) => e.currentTarget.style.backgroundColor = '#f9f9f9'}
            >
              <span style={{ flexGrow: 1 }}>Display</span>
              <Icon name='dropdown' style={{ marginLeft: 'auto' }} />
            </Accordion.Title>
            <Accordion.Content active={activeIndex === 4}>
              
            </Accordion.Content>

            <Accordion.Title
              active={activeIndex === 5}
              index={5}
              onClick={this.handleAccordionClick}
              style={{
                display: 'flex', justifyContent: 'space-between', alignItems: 'center',
                backgroundColor: '#FFFFFF',
                padding: '20px 20px',
                fontSize: '1.2rem',
                borderBottom: '1px solid #e0e0e0',
                cursor: 'pointer',
                transition: 'background-color 0.3s'
              }}
            //  onMouseEnter={(e) => e.currentTarget.style.backgroundColor = '#e0e0e0'}
            //  onMouseLeave={(e) => e.currentTarget.style.backgroundColor = '#f9f9f9'}
            >
              <span style={{ flexGrow: 1 }}>Font</span>
              <Icon name='dropdown' style={{ marginLeft: 'auto' }} />
            </Accordion.Title>
            <Accordion.Content active={activeIndex === 5}>
            
            </Accordion.Content>

            <Accordion.Title
              active={activeIndex === 6}
              index={6}
              onClick={this.handleAccordionClick}
              style={{
                display: 'flex', justifyContent: 'space-between', alignItems: 'center',
                backgroundColor: '#FFFFFF',
                padding: '20px 20px',
                fontSize: '1.2rem',
                borderBottom: '1px solid #e0e0e0',
                cursor: 'pointer',
                transition: 'background-color 0.3s'
              }}
            //  onMouseEnter={(e) => e.currentTarget.style.backgroundColor = '#e0e0e0'}
            //  onMouseLeave={(e) => e.currentTarget.style.backgroundColor = '#f9f9f9'}
            >
              <span style={{ flexGrow: 1 }}>Images</span>
              <Icon name='dropdown' style={{ marginLeft: 'auto' }} />
            </Accordion.Title>
            <Accordion.Content active={activeIndex === 6}>

            </Accordion.Content>

            <Accordion.Title
              active={activeIndex === 7}
              index={7}
              onClick={this.handleAccordionClick}
              style={{
                display: 'flex', justifyContent: 'space-between', alignItems: 'center',
                backgroundColor: '#FFFFFF',
                padding: '20px 20px',
                fontSize: '1.2rem',
                borderBottom: '1px solid #e0e0e0',
                cursor: 'pointer',
                transition: 'background-color 0.3s'
              }}
            //  onMouseEnter={(e) => e.currentTarget.style.backgroundColor = '#e0e0e0'}
            //  onMouseLeave={(e) => e.currentTarget.style.backgroundColor = '#f9f9f9'}
            >
              <span style={{ flexGrow: 1 }}>Compatibility</span>
              <Icon name='dropdown' style={{ marginLeft: 'auto' }} />
            </Accordion.Title>
            <Accordion.Content active={activeIndex === 7}>

            </Accordion.Content> */}
          </Accordion>
        </Form>
        <div className={css.editorTimer}>
          <div className={css.editorActions}>
            <EditableText
              name={this.state.draft.name}
              onChange={this.handleDraftChangeValueCurry("name")}
            />
            {this.renderButtons()}
          </div>
          <div className={css.editorPreview}>
            {this.state.draft.templateData.vertical && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "20px",
                  justifyContent: "center",
                }}
              >
                {this.renderPreview()}
              </div>
            )}

            {!this.state.draft.templateData.vertical && this.renderPreview()}
            {this.renderHTMLCode()}
          </div>
        </div>
        {allowMultipleLanguageOptionStatus && this.renderLanguageListModal()}
      </div>
    );
  }
}

export default Editor;
