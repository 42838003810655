import {
  UPLOAD_FAILURE
} from "../constants/uploadConstants";

const uploadFailure = (err) => ({ type: UPLOAD_FAILURE, message: err });

export function uploadFailed(err) {
  return (dispatch, getState) => {
    dispatch(uploadFailure(err));
  };
}