import auth, { initialState as authState } from "./authenticationReducer";
import template, { initialState as templateState } from "./templateReducer";
import timers, { initialState as timersState } from "./timersReducer";
import subaccounts, { initialState as subaccountState } from "./accountReducer";
import subaccountDetails, {
  initialState as subaccountDetailsState,
} from "./subaccountReducer";

import toasts, { initialState as toastsState } from "./toastReducer";

export default {
  auth,
  template,
  timers,
  toasts,
  subaccounts,
  subaccountDetails,
};

export const initialStates = {
  auth: authState,
  template: templateState,
  timers: timersState,
  toasts: toastsState,
  subaccounts: subaccountState,
  subaccountDetails: subaccountDetailsState,
};
