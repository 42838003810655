import { useState } from "react";
import React from 'react';
import tableCss from "../../assets/styles/shared/tables.module.scss";
// import gridCss from "../../assets/styles/shared/grids.scss";
import { Button } from "semantic-ui-react";
import { CrossIconComponent } from "../iconComponents";
import { checkUserStatus } from "../../actions/userActions";

export default function InviteUserComponent(props) {
  const [displayStatus, setDisplayStatus] = useState([
    true,
    false,
    false,
    false,
    false
  ]);

  const [errorMessages, setErrorMessages] = useState([
    "",
    "",
    "",
    "",
    ""
  ]);

  const [errorFlag,setErrorFlag] = useState(false);  
  const [, updateState] = React.useState({});
  const forceUpdate = React.useCallback(() => updateState({}), []);

  const [values, setValues] = useState([
    {name:"",email:"",valid:true},
    {name:"",email:"",valid:true},
    {name:"",email:"",valid:true},
    {name:"",email:"",valid:true},
    {name:"",email:"",valid:true}
  ]);

  const getTotalActive = () => {
    let count = 0;
    let statuses = [...displayStatus];
    for (let index = 0; index < statuses.length; index++) {
      if (statuses[index]) {
        count++;
      }
    }
    return count;
  };

  const [id, setId] = useState(props.id);

  const close = (index) => {
    let statuses = [...displayStatus];
    let newValues = [...values];
    let messages = [...errorMessages];
    
    let totalTrue = getTotalActive();
    let length = statuses.length;
    
    for (let i = index; i < length - 1; i++) {
      newValues[i].name = newValues[i + 1].name;
      newValues[i].email = newValues[i + 1].email;
    }
    let i = length - 1;
    let count = length - totalTrue + 1;
    while (true) {
      if (i < 0) {
        break;
      }
      statuses[i] = false;
      newValues[i].name = "";
      newValues[i].email = "";
      errorMessages[i] = "";
      count--;
      i--;
      if (count === 0) {
        break;
      }
    }
    setErrorFlag(false);    
    setDisplayStatus(statuses);
    setValues(newValues);
    setErrorMessages(messages);
    if (props.updateValues) {
        props.updateValues(props.valueKey, newValues);
        props.updateValues(props.statusKey,statuses);
    }    
    forceUpdate();
  }

  const addOne = () => {    
    if(getTotalActive()==5){
      setErrorFlag(true);
      
    }else{
      let statuses = [...displayStatus];
      for (let index = 0; index < statuses.length; index++) {
        if (!statuses[index]) {
          statuses[index] = true;
          break;
        }
      }
      setDisplayStatus(statuses);
      if (props.updateValues) {
          props.updateValues(props.statusKey, statuses);
      }
      setErrorFlag(false);
    }    
    forceUpdate();
  }

  const nameChangeHandler = (e, index) => {
    let newValues = [...values];
    newValues[index].name = e.target.value;
    setValues(newValues);
    if (props.updateValues) {      
      props.updateValues(props.valueKey, newValues);
    }
  }

  const emailChangeHandler = (e, index) => {
    let newValues = [...values];    
    
    newValues[index].email = e.target.value;
    setValues(newValues);
    if (props.updateValues) {      
      
      props.updateValues(props.valueKey, newValues);
    }    
  }

  const emailIsValid = (email) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
  }

  const checkEmailStatus = (index) => {
    
    let email = values[index].email;
    let newValues = [...values];    
    let messages = [...errorMessages];
    if(emailIsValid(email)){
      checkUserStatus(email,props.basicPassword,props.token).then((response)=>{     
        if(response.data.status){
          newValues[index].valid = false;
          messages[index] = "User with this email already exists.";
        }else{
          newValues[index].valid = true;
          messages[index] = "";
        }
        setValues(newValues);
        setErrorMessages(messages);
        if (props.updateValues) {    
          
          props.updateValues(props.valueKey, newValues);
        } 
      });
    }else{
      messages[index] = "Invalid format of email.";
      newValues[index].valid = false;
      setValues(newValues);
      setErrorMessages(messages);
      
      if (props.updateValues) {    
        
        props.updateValues(props.valueKey, newValues);
      } 
    }
    
  }

  return (
    <>
      <table style={{width:'100%',marginTop:'10px'}}>
        <tbody>
          {displayStatus.map((status, index) => {
            return (                        
              <React.Fragment key={id + "_" + index}>
                <tr >
                  {status && (
                    <>
                      <td>
                        <input
                          type="text"
                          value={values[index].email}
                          className={tableCss.userInputText}
                          placeholder="example@gmail.com"
                          onChange={(e) => emailChangeHandler(e, index)}
                          onBlur={()=> checkEmailStatus(index)}
                        />                      
                      </td>
                      <td>
                        <input
                          type="text"
                          value={values[index].name}
                          className={tableCss.userInputText}
                          placeholder="ex. John Smith"
                          onChange={(e) => nameChangeHandler(e, index)}
                        />
                      </td>                    
                      <td style={{width:'20px'}}>
                        {index != 0 && 
                          <Button onClick={()=>close(index)} className={tableCss.crossButton}>
                            <Button.Content>
                              <CrossIconComponent/>
                            </Button.Content>
                          </Button>
                        }
                      </td>
                    </>
                  )}
                </tr>
                {!values[index].valid && 
                  <tr>
                    <td colSpan={'3'}>
                      <p className={tableCss.errorFont2}>{errorMessages[index]}</p>
                    </td>                
                  </tr>
                }
              </React.Fragment>
            );
          })}          
          <tr>
            <td>
              <Button onClick={addOne} className={tableCss.inviteUserButton}>
                + Invite users
              </Button> 
            </td>
            <td></td>
            <td></td>
          </tr>
          {errorFlag && 
            <tr>
              <td colSpan={'3'}>
                <p className={tableCss.errorFont2}>The maximum number of users you can invite is 5 per account.</p>
              </td>
            </tr>
          }
        </tbody>
      </table>
    </>
  );
}