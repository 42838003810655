import React from "react";
import PropTypes from 'prop-types';
import _ from "lodash";

import { Button } from "semantic-ui-react";
import css from "../../assets/styles/editor/digitDisplayPicker.module.scss";

const DigitDisplayPicker = ({ digits, onChange }) => {
  const places = ["years", "days", "hours", "minutes", "seconds"];
  const labels = ["Years", "Days", "Hours", "Minutes", "Seconds"];
  let buttons = [];
  for (let i = 0; i < 5; i++) {
    const hidden = _.get(digits, [places[i], "hidden"], true);
    buttons.push(
      <Button className={hidden ? css.unactiveButton : css.activeButton}
        // primary={!hidden}
        key={i}
        onClick={() => {
          let visible = 0;
          for (let j = 0; j < 5; j++) {
            if (!_.get(digits, [places[j], "hidden"], true)) {
              visible++;
            }
          }
          if (hidden) {
            // Fill in hidden places if the user shows non-adjacent digits
            let minGreater = i;
            let maxLesser = i;
            for (let j = 0; j < 5; j++) {
              if (j > i && !_.get(digits, [places[j], "hidden"], true)) {
                minGreater = j;
                break;
              }
            }
            for (let j = 4; j >= 0; j--) {
              if (j < i && !_.get(digits, [places[j], "hidden"], true)) {
                maxLesser = j;
                break;
              }
            }
            if (maxLesser == i || minGreater == i) {
              for (let j = maxLesser; j < minGreater; j++) {
                digits = _.set(digits, [places[j], "hidden"], false);
              }
            }
          } else if (visible == 1) {
            // Don't allow all digits to be deleted
            return;
          }
          digits = _.set(digits, [places[i], "hidden"], !hidden);
          onChange(digits);
        }}>
        {labels[i]}
      </Button >
    );
  }

  return (
    <Button.Group fluid>
      {buttons}
    </Button.Group>
  );
};

export default DigitDisplayPicker;