import PropTypes from "prop-types";
import React from "react";
import _ from "lodash";
import classNames from "classnames";

import { Card, List, Button, Loader, Divider, Label, Popup, Menu, Dropdown, Icon, Dimmer } from "semantic-ui-react";



// import css from "styles/shared/plans.scss";
// import apiCss from "styles/shared/apiPlans.scss";

import css from '../../assets/styles/shared/plans.module.scss'
import apiCss from '../../assets/styles/shared/apiPlans.module.scss'


import timerHelper from "../../helpers/timers/timerHelper";
import { CaretRightIconComponent, CircleCheckGreenIconComponent } from "../iconComponents";

const { string, func, bool, array } = PropTypes;

class ApiPlans extends React.Component {
  static propTypes = {
    currentPlan: string.isRequired,
    onPlanSelected: func.isRequired,
    planOptions: array.isRequired,
    apiPlanID: string.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      trialCreate: true,
      selectedPlan: 0,
      displayPlanStatus: false,
      displayDimmer:true,
      europePlan:false,
    };
  }

  componentDidMount() {
    console.log("this.props=",this.props);
    let planID = this.props.apiPlanID;
    let selectedPlan = _.filter(this.props.planOptions, (plan, index) => {
      return plan.id == planID;
    });
    if (selectedPlan.length > 0) {
      // Find the index of selected plan
      var index = _.findIndex(this.props.planOptions, function (plan) {
        return plan["id"] == planID;
      });

      let europePlanStatus = selectedPlan[0].features.europe;
      this.setState({ selectedPlan: index, europePlan:europePlanStatus });

      setTimeout(()=>{
        this.setState({ displayDimmer:false });
        this.props.onPlanSelected(selectedPlan[0]);
      },1000);
      
    }
  }

  setTrialCreate(value) {
    // Allow creating timers in trial
    this.setState({
      trialCreate: value == 1,
    });
  }

  handleChoosePlanCurry = (plan) => {
    return (e) => {
      if (plan.id === this.props.currentPlan) {
        return null;
      }

      this.props.onPlanSelected(plan);
    };
  };

  renderPlanButton = (plan) => {
    if (plan.id === this.props.currentPlan) {
      return (
        <Button disabled fluid color="green">
          Current Plan
        </Button>
      );
    } else {
      return (
        <Button fluid primary onClick={this.handleChoosePlanCurry(plan)} className={css.primaryButtonBackColor}>
          Choose Plan
        </Button>
      );
    }
  };

  renderPlanFeature = (enabled, description) => {
    let icon = <List.Icon fitted name="check" className={css.tickButtonColor} verticalAlign="middle" />;
    return (
      <List.Item className={classNames({ [css.planFeature]: true, [css.disabledFeature]: !enabled })}>
        {icon}
        <List.Content verticalAlign="middle">{enabled ? description : <s>{description}</s>}</List.Content>
      </List.Item>
    );
  };

  renderPlanCards = () => {
    return _.map(this.props.planOptions, (plan, index) => {
      let recommended = this.props.apiPlanID == plan.id;
      return (
        <Card key={plan.id} className={css.planCard} raised={recommended}>
          <Card.Content>
            <Card.Header textAlign="center">{plan.name}</Card.Header>

            <Divider horizontal>${plan.price} USD/month</Divider>
            <List divided relaxed>
              {this.renderPlanFeature(true, "Unlimited Timers")}
              {this.renderPlanFeature(true, `${plan.views} views/month`)}
              {this.renderPlanFeature(true, "99.9% Uptime Guarantee")}
            </List>
          </Card.Content>
          <Card.Content>{this.renderPlanButton(plan)}</Card.Content>
        </Card>
      );
    });
  };

  handleItemClick = (e) => {
    this.setState({ selectedPlan: e.target.dataset.index });
  };

  getPlanText = () => {
    let index = parseInt(this.state.selectedPlan);
    if (this.props.planOptions && index < this.props.planOptions.length) {
      let message = this.props.planOptions[index].name;
      return message;
    }
    return "";
  };

  getPlanPrice = () => {
    let index = parseInt(this.state.selectedPlan);
    if (this.props.planOptions && index < this.props.planOptions.length) {
      let price = parseInt(this.props.planOptions[index].price);
      // return price.toLocaleString();
      return price;
    }
    return "";
  };

  getViews = () => {
    let index = parseInt(this.state.selectedPlan);
    if (this.props.planOptions && index < this.props.planOptions.length) {
      return this.getViewInMillions(index);
    }
    return "";
  };

  getViewInMillions = (index) => {
    let views = this.props.planOptions[index].views;
    views = views.replace(/,/g, "");
    views = parseInt(views);
    let totalViews = views / 1000000;
    return totalViews;
  };

  showHidePlans = () => {
    let status = !this.state.displayPlanStatus;
    this.setState({ displayPlanStatus: status });
  };

  processPlan = () => {
    let selectedPlan = _.filter(this.props.planOptions, (plan, index) => {
      return index == this.state.selectedPlan;
    });
    if (selectedPlan.length > 0) {
        this.props.onPlanSelected(selectedPlan[0]);      
    }
  };

  renderNewPlanDesign = () => {

    return (
      <div className={apiCss.outer}>
        <div className={apiCss.left}>
          <h1 className={apiCss.headingFont}>Plans & Pricing</h1>
          <div className={apiCss.features}>
            <p className={apiCss.font1}>The package includes:</p>
            <div className={apiCss.featureRow}>
              <div className={apiCss.pointCol}>
                <CircleCheckGreenIconComponent />
                <p className={apiCss.font2}>Bee Free Plugin</p>
              </div>
              <div className={apiCss.pointCol}>
                <CircleCheckGreenIconComponent />
                <p className={apiCss.font2}>Premium Support</p>
              </div>
            </div>
            <div className={apiCss.featureRow}>
              <div className={apiCss.pointCol}>
                <CircleCheckGreenIconComponent />
                <p className={apiCss.font2}>Open API</p>
              </div>
              <div className={apiCss.pointCol}>
                <CircleCheckGreenIconComponent />
                <p className={apiCss.font2}>White Label</p>
              </div>
            </div>
            <div className={apiCss.featureRow}>
              <div className={apiCss.pointCol}>
                <CircleCheckGreenIconComponent />
                <p className={apiCss.font2}>JS Library</p>
              </div>
              <div className={apiCss.pointCol}>
                <CircleCheckGreenIconComponent />
                <p className={apiCss.font2}>99.9% Uptime</p>
              </div>
            </div>
          </div>
        </div>
        <div className={apiCss.right}>
          <p className={apiCss.font3} style={{ marginBottom: "0px" }}>
            Select your timers monthly views
          </p>
          <p className={apiCss.font4}>Across All Your Customers</p>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "20px",
              background: "#FFFFFF",
              borderRadius: "20px",
              cursor: "pointer",
              position: "relative",
            }}
            onClick={this.showHidePlans}
          >
            <div>
              <p className={apiCss.dropFont1} style={{ marginBottom: "0px" }}>
                {this.getPlanText()}
              </p>
              <p className={apiCss.dropFont2}>{this.getViews()} million</p>
            </div>
            <span>
              <CaretRightIconComponent />
            </span>
            {this.state.displayPlanStatus && (
              <div className={apiCss.planDiv}>
                <ul className={apiCss.planList}>
                  {_.map(this.props.planOptions, (plan, index) => {
                    
                    
                    if(this.state.europePlan){
                      if(!plan.features.europe){
                        return null;
                      }
                    }else{
                      if(plan.features.europe){
                        return null;
                      }
                    }
                    
                    return (
                      <li
                        key={"plan_" + index}
                        className={index == this.state.selectedPlan ? apiCss.selected : null}
                        data-index={index}
                        onClick={this.handleItemClick}
                      >
                        {plan.name + " - " + this.getViewInMillions(index) + " million"}
                      </li>
                    );
                  })}
                </ul>
              </div>
            )}
          </div>
          <div className={apiCss.priceDiv}>
            <p>
              <span className={apiCss.smallFont} style={{ position: "relative", top: "-0.9em" }}>
                {this.state.europePlan ? "€" : "$" }
              </span>
              <span className={apiCss.largeFont}>{this.getPlanPrice()}</span>
              <span className={apiCss.smallFont} style={{ position: "relative", top: "3px" }}>
                .00 per month
              </span>
            </p>
          </div>
          <div className={apiCss.additionalDiv}>
            additional $50 for extra 1 million views
          </div>
          <div>
            <Button onClick={this.processPlan} className={apiCss.choosePlanButton}>
              Choose Plan
            </Button>
          </div>
        </div>
      </div>
    );
  };

  render() {
    return (
      <>
        {/* <Card.Group itemsPerRow={this.props.planOptions.length / 2}>{this.renderPlanCards()}</Card.Group> */}
        {/* <Dimmer active={this.state.displayDimmer} inverted page>
          <Loader inverted>Loading...</Loader>
        </Dimmer> */}
        {this.renderNewPlanDesign()}
      </>
    );
  }
}

export default ApiPlans;
