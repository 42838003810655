import PropTypes from "prop-types";
import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import _ from "lodash";
import BillingContainer from "../../containers/billingContainer";

import css from "../../assets/styles/shared/common.module.scss";
import htmlCss from "../../assets/styles/editor/htmlCode.module.scss";
import tableCss from "../../assets/styles/shared/tables.module.scss";

import {
  Button,
  Message,
  Icon,
  Modal,
  Form,
  Divider,
  Table,
  Accordion,
  Menu,
  Checkbox,
  Tab,
  Pagination,
  Grid,
  Popup,
} from "semantic-ui-react";
import moment from "moment-timezone";

import Plans from "../shared/plans";
import {
  CopyIconComponent,
  DeleteIconComponent,
  InfoIconComponent,
  LeftArrowIconComponent,
  PersonIconComponent,
  RightArrowIconComponent,
  SubaccountSuccessWithInviteUserIconComponent,
  WhiteCopyIconComponent,
} from "../iconComponents";
import InviteUserComponent from "../inviteUser";
import Clipboard from "react-clipboard.js";
import CorporateBrandFontEditor from "./corporateBrandFontEditor";

const { object, bool, array } = PropTypes;

const initialState = {
  isModalOpen: false,
  email: "",
  name: "",
  subaccountUserActivePage: 1,
  totalSubaccountUserPages: 0,
  recordLimit: 5,
  delete_user_id: 0,
  isDeleteModalOpen: false,
  subaccountUserStatus: [true, false, false, false, false],
  subaccountUserDetails: [
    { name: "", email: "", valid: true },
    { name: "", email: "", valid: true },
    { name: "", email: "", valid: true },
    { name: "", email: "", valid: true },
    { name: "", email: "", valid: true },
  ],
  step: 1,
};

class Settings extends React.Component {
  static propTypes = {
    user: object,
    envContext: object,
    subaccount_users: array,
    auth: object,
  };

  state = {};

  constructor(props) {
    super(props);
    this.state = {
      ...initialState,
      newPassword: "",
      passwordConfirmation: "",
      authError: null,
      activeIndex: -1,
      setting: this.props.user.can_edit_templates,
    };
    this.updateValues = this.updateValues.bind(this);
  }

  componentDidMount() {
    if (this.isSubaccountUser()) {
      let id = this.props.user.subaccount_id;
      this.props.fetchSubaccountUsers(id);
    } else {
      this.props.fetchInvoices();
    }
  }

  updateValues(key, values) {
    this.setState({ [key]: values });
  }

  getResetState() {
    return {
      ...initialState,
    };
  }

  isCorporateUser() {
    return (
      this.props?.user?.is_company ||
      (this.props?.user?.features?.subaccount &&
        this?.props?.user?.parent_id == 0)
    );
  }

  isSubaccountUser() {
    return this.props.user.subaccount_id != 0;
  }

  isAPIPlanUser() {
    return this.props.user.is_api_plans;
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.authError !== nextProps.authError) {
      this.setState({
        authError: nextProps.authError,
      });
    }
  }

  isSelfAccount(userid) {
    return this.props.user.id == userid;
  }

  static getDerivedStateFromProps(props, current_state) {
    let changed = false;
    let newStates = { ...current_state };
    if (props.subaccount_users && props.subaccount_users.length > 0) {
      let totalPage = Math.floor(props.subaccount_users.length / 5);
      if (props.subaccount_users.length % 5 != 0) {
        totalPage++;
      }
      newStates.totalSubaccountUserPages = totalPage;
      changed = true;
    }
    if (changed) {
      return newStates;
    }
    return null;
  }

  handleChangeCurry = (name) => {
    return (event) => this.setState({ [name]: event.target.value });
  };

  handleSubmitPasswordChange = () => {
    const { newPassword, passwordConfirmation } = this.state;
    const passwordsMatch = newPassword == passwordConfirmation;
    const passwordsEmpty =
      _.isEmpty(newPassword) || _.isEmpty(passwordConfirmation);

    if (passwordsMatch && !passwordsEmpty) {
      this.props.updatePassword(newPassword);
    } else {
      this.setState({
        authError: "Passwords do not much or are empty.",
      });
    }
  };

  renderInvoiceRows = () => {
    return _.map(this.props.invoices, (invoice) => {
      return (
        <Table.Row key={invoice.id}>
          <Table.Cell>
            {moment.unix(invoice.date).format("MMM Do, YYYY")}
          </Table.Cell>
          <Table.Cell>
            {(invoice.amount_due / 100).toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
            })}
          </Table.Cell>
          <Table.Cell>{_.capitalize(invoice.status)}</Table.Cell>
          <Table.Cell>
            {!_.isEmpty(invoice.invoice_pdf) ? (
              <a href={invoice.invoice_pdf} download>
                Download Invoice
              </a>
            ) : null}
          </Table.Cell>
        </Table.Row>
      );
    });
  };

  renderInvoices = () => {
    if (_.isEmpty(this.props.invoices)) {
      return null;
    }

    return (
      <Fragment>
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Date</Table.HeaderCell>
              <Table.HeaderCell>Amount</Table.HeaderCell>
              <Table.HeaderCell>Status</Table.HeaderCell>
              <Table.HeaderCell>Invoice PDF</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>{this.renderInvoiceRows()}</Table.Body>
        </Table>
      </Fragment>
    );
  };

  renderPasswordChange = () => {
    const { authError } = this.state;

    return (
      <div>
        <Form
          error={!_.isEmpty(authError)}
          onSubmit={this.handleSubmitPasswordChange}
        >
          <Message
            hidden={_.isEmpty(authError)}
            error
            header="Password Error"
            content={`${authError} Please try again.`}
          />
          <Form.Group>
            <Form.Input
              width={8}
              placeholder="New Password"
              type="password"
              icon="lock"
              iconPosition="left"
              error={!_.isEmpty(authError)}
              onChange={this.handleChangeCurry("newPassword")}
            />
          </Form.Group>
          <Form.Group>
            <Form.Input
              width={8}
              placeholder="Confirm Password"
              type="password"
              icon="lock"
              iconPosition="left"
              error={!_.isEmpty(authError)}
              onChange={this.handleChangeCurry("passwordConfirmation")}
            />
          </Form.Group>
          <Button primary content="Submit" />
        </Form>
      </div>
    );
  };

  handleClick = (e, titleProps) => {
    const { index } = titleProps;
    const { activeIndex } = this.state;
    const newIndex = activeIndex === index ? -1 : index;

    this.setState({ activeIndex: newIndex });
  };

  changeSetting = () => {
    let newValue = !this.state.setting;
    this.setState({ setting: newValue });
    this.props.updateSettings(newValue);
  };

  renderAPIKey = () => {
    const copyButton = (
      <Clipboard
        component="div"
        data-clipboard-text={this.props.user.api_token}
        style={{
          background: "#5DC2FA",
          borderRadius: "6px",
          padding: "12px 24px",
          height: "32px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: "8px",
          cursor: "pointer",
        }}
      >
        <span style={{ paddingTop: "4px" }}>
          <WhiteCopyIconComponent />
        </span>
        <span
          style={{
            fontFamily: "Lato",
            fontStyle: "normal",
            fontWeight: "700",
            fontSize: "14px",
            lineHeight: "17px",
            color: "#FFFFFF",
          }}
        >
          Copy
        </span>
      </Clipboard>
    );

    return (
      <div>
        <div
          style={{
            fontFamily: "Lato",
            fontStyle: "normal",
            fontWeight: "600",
            fontSize: "14px",
            lineHeight: "17px",
            color: "#000000",
            marginBottom: "8px",
          }}
        >
          API Key:
        </div>
        <div style={{ display: "flex", gap: "7px" }}>
          <div
            style={{
              border: "1px solid #DCE2E3",
              borderRadius: "4px",
              padding: "8px",
              height: "32px",
              fontFamily: "Lato",
              fontStyle: "normal",
              fontWeight: "600",
              fontSize: "14px",
              lineHeight: "17px",
              color: "#000000",
              background: "#F7F8F8",
            }}
          >
            {this.props.user.api_token}
          </div>
          <Popup
            inverted
            trigger={copyButton}
            content="Copied!"
            position="top center"
            hideOnScroll
          />
        </div>
        <div
          style={{
            fontFamily: "Lato",
            fontStyle: "normal",
            fontWeight: "600",
            fontSize: "14px",
            lineHeight: "17px",
            color: "#000000",
            marginBottom: "8px",
            marginTop: "20px",
          }}
        >
          Documentation:
        </div>
        <div
          style={{
            display: "flex",
            gap: "16px",
            border: "1px solid #DCE2E3",
            borderRadius: "6px",
            width: "fit-content",
          }}
        >
          <div style={{ padding: "16px" }}>
            <p
              style={{
                fontFamily: "Lato",
                fontStyle: "normal",
                fontWeight: "700",
                fontSize: "16px !important",
                lineHeight: "19px",
                color: "#000000",
              }}
            >
              Sendtric
            </p>
            <p
              style={{
                fontFamily: "Lato",
                fontStyle: "normal",
                fontWeight: "400",
                fontSize: "16px !important",
                lineHeight: "19px",
                color: "#606062",
              }}
            >
              Sendtric's API allows creation and editing of countdown timers.
            </p>
            <p>
              <a
                href="https://documenter.getpostman.com/view/23380037/2s7YtWDtD2"
                target="_blank"
                style={{
                  fontFamily: "Lato",
                  fontStyle: "normal",
                  fontWeight: "400",
                  fontSize: "14px !important",
                  lineHeight: "17px",
                  color: "#000000",
                }}
              >
                https://documenter.getpostman.com/view/23380037/2s7YtWDtD2
              </a>
            </p>
          </div>
          <div>
            <img src="/postman.png" />
          </div>
        </div>
      </div>
    );
  };

  render() {
    const { activeIndex } = this.state;
    return (
      <>
        <div>
          <h2 className={css.headingLevel1} style={{ marginBottom: "60px" }}>
            Settings
          </h2>
          <Accordion>
            {this.isCorporateUser() && (
              <>
                {/* Manage Permission  */}
                <Accordion.Title
                  active={activeIndex === 0}
                  index={0}
                  onClick={this.handleClick}
                >
                  <div className={css.accordionDiv}>
                    <p className={css.heading}>Manage permissions</p>
                    <div className={css.heading}>
                      {activeIndex === 0 && <Icon name="angle up" />}
                      {activeIndex !== 0 && <Icon name="angle down" />}
                    </div>
                  </div>
                </Accordion.Title>
                <Accordion.Content active={activeIndex === 0}>
                  <Table>
                    <Table.Body>
                      <Table.Row>
                        <Table.Cell>
                          <Checkbox
                            defaultChecked={this.state.setting}
                            className={htmlCss.option}
                            toggle
                            label="Sub accounts can only use the corporate templates"
                            onChange={this.changeSetting}
                          />
                        </Table.Cell>
                      </Table.Row>
                    </Table.Body>
                    <Table.Footer>
                      <Table.Row>
                        <Table.HeaderCell>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "8px",
                            }}
                          >
                            <span style={{ paddingTop: "5px" }}>
                              <InfoIconComponent />
                            </span>
                            <span className={css.settingText}>
                              Templates include the following settings: Size,
                              style, labels, colors, display, fonts and images.
                            </span>
                          </div>
                        </Table.HeaderCell>
                      </Table.Row>
                    </Table.Footer>
                  </Table>
                </Accordion.Content>

                {/* Manage Brand Fonts */}
                <Accordion.Title
                  active={activeIndex === 6}
                  index={6}
                  onClick={this.handleClick}
                >
                  <div className={css.accordionDiv}>
                    <p className={css.heading}>Brand</p>
                    <div className={css.heading}>
                      {activeIndex === 6 && <Icon name="angle up" />}
                      {activeIndex !== 6 && <Icon name="angle down" />}
                    </div>
                  </div>
                </Accordion.Title>
                <Accordion.Content active={activeIndex === 6}>
                  <CorporateBrandFontEditor
                    createCustomFont={this.props.createCustomFont}
                    fetchFonts={this.props.fetchCustomFont}
                    updateCustomFont={this.props.updateCustomFont}
                    removeCustomFont={this.props.destroyCustomFont}
                    updateUserDefaultFont={this.props.updateUserDefaultFont}
                    handleUploadError={this.props.handleUploadError}
                  />
                </Accordion.Content>
              </>
            )}

            {/* Plan and Billing  */}
            {!this.isSubaccountUser() && (
              <>
                <Accordion.Title
                  active={activeIndex === 1}
                  index={1}
                  onClick={this.handleClick}
                >
                  <div className={css.accordionDiv}>
                    <p className={css.heading}>Plan &amp; Billing</p>
                    <div className={css.heading}>
                      {activeIndex === 1 && <Icon name="angle up" />}
                      {activeIndex !== 1 && <Icon name="angle down" />}
                    </div>
                  </div>
                </Accordion.Title>
                <Accordion.Content active={activeIndex === 1}>
                  <BillingContainer />
                </Accordion.Content>
              </>
            )}

            {!this.isSubaccountUser() && (
              <>
                {/* Invoice Accordion  */}
                <Accordion.Title
                  active={activeIndex === 2}
                  index={2}
                  onClick={this.handleClick}
                >
                  <div className={css.accordionDiv}>
                    <p className={css.heading}>Invoices</p>
                    <div className={css.heading}>
                      {activeIndex === 2 && <Icon name="angle up" />}
                      {activeIndex !== 2 && <Icon name="angle down" />}
                    </div>
                  </div>
                </Accordion.Title>
                <Accordion.Content active={activeIndex === 2}>
                  {this.renderInvoices()}
                </Accordion.Content>
              </>
            )}
            {this.isSubaccountUser() && (
              <>
                {/* Subaccount Account Details */}
                <Accordion.Title
                  active={activeIndex === 4}
                  index={4}
                  onClick={this.handleClick}
                >
                  <div className={css.accordionDiv}>
                    <p className={css.heading}>Your account details</p>
                    <div className={css.heading}>
                      {activeIndex === 4 && <Icon name="angle up" />}
                      {activeIndex !== 4 && <Icon name="angle down" />}
                    </div>
                  </div>
                </Accordion.Title>
                <Accordion.Content active={activeIndex === 4}>
                  {this.renderSubaccountUserList()}
                </Accordion.Content>
              </>
            )}

            {/* Change Password  */}
            <Accordion.Title
              active={activeIndex === 3}
              index={3}
              onClick={this.handleClick}
            >
              <div className={css.accordionDiv}>
                <p className={css.heading}>Change password</p>
                <div className={css.heading}>
                  {activeIndex === 3 && <Icon name="angle up" />}
                  {activeIndex !== 3 && <Icon name="angle down" />}
                </div>
              </div>
            </Accordion.Title>
            <Accordion.Content active={activeIndex === 3}>
              {this.renderPasswordChange()}
            </Accordion.Content>

            {this.isAPIPlanUser() && (
              <>
                {/* API Key Password  */}
                <Accordion.Title
                  active={activeIndex === 5}
                  index={5}
                  onClick={this.handleClick}
                >
                  <div className={css.accordionDiv}>
                    <p className={css.heading}>API Key</p>
                    <div className={css.heading}>
                      {activeIndex === 5 && <Icon name="angle up" />}
                      {activeIndex !== 5 && <Icon name="angle down" />}
                    </div>
                  </div>
                </Accordion.Title>
                <Accordion.Content active={activeIndex === 5}>
                  {this.renderAPIKey()}
                </Accordion.Content>
              </>
            )}
          </Accordion>
        </div>
        <div style={{ marginTop: "50px" }}>
          <h2>Questions?</h2>
          See the <Link to="/faq">FAQs</Link> or contact support at{" "}
          <a href="mailto:support@sendtric.com">support@sendtric.com</a>.
        </div>
      </>
    );
  }

  /* Subaccount User Creation Flow */
  validSubaccountUserFormData = () => {
    return this.totalActiveUser() > 0;
  };

  processSubaccountAction = () => {
    let stepValue = this.state.step;
    if (stepValue == 1) {
      this.handleCreateSubaccountUser();
      this.setState({ step: 2 });
    }
  };

  handleCreateSubaccountUser = () => {
    let userStatus = [...this.state.subaccountUserStatus];
    let users = [];
    for (let index = 0; index < userStatus.length; index++) {
      if (userStatus[index]) {
        let user = { name: "", email: "", password: "admin@1234" };
        user.name = this.state.subaccountUserDetails[index].name;
        user.email = this.state.subaccountUserDetails[index].email;
        users.push(user);
      }
    }
    this.props.createSubaccount(users, this.props.user.subaccount_id);
  };

  openDeleteDialog = (userId) => {
    this.setState({ delete_user_id: userId, isDeleteModalOpen: true });
  };

  renderActions = () => {
    return (
      <div className={tableCss.buttonContainer}>
        <Button basic onClick={this.handleToggleSubaccountUserModal(false)}>
          Cancel
        </Button>
        <Button
          className={tableCss.primaryButton}
          onClick={this.processSubaccountAction}
          disabled={!this.validSubaccountUserFormData()}
        >
          Send invites
        </Button>
      </div>
    );
  };

  handleToggleSubaccountUserModal = (isOpen) => {
    return () => {
      this.setState({
        ...this.getResetState(),
        isModalOpen: isOpen,
      });
    };
  };

  renderSubaccountUserList() {
    return (
      <>
        <Grid>
          <Grid.Row style={{ paddingBottom: "0px" }}>
            <Grid.Column width={8}>
              <div className={tableCss.filterDiv}>
                <div>
                  <p className={tableCss.heading2}>Users</p>
                </div>
                <div>
                  <Button
                    onClick={this.handleToggleSubaccountUserModal(true)}
                    className={tableCss.seeAllButton}
                  >
                    invite user
                  </Button>
                </div>
              </div>
            </Grid.Column>
            <Grid.Column width={8}></Grid.Column>
          </Grid.Row>
          <Grid.Row style={{ paddingTop: "0px" }}>
            <Grid.Column width={8}>
              {this.renderSubaccountUserTableContents()}
            </Grid.Column>
            <Grid.Column width={8}></Grid.Column>
          </Grid.Row>
        </Grid>
        {!_.isEmpty(this.props.subaccount_users) && this.renderModal()}
        {!_.isEmpty(this.props.subaccount_users) &&
          this.renderDeleteUserModal()}
      </>
    );
  }

  renderZeroSubaccountUserState = () => {
    return (
      <div className={tableCss.zero}>
        You do not currently have any subaccount users. Create one with the
        button in the bottom right!
      </div>
    );
  };

  renderSubaccountUserTemplates = () => {
    const orderedSubaccountUsers = _.orderBy(
      this.props.subaccount_users,
      ["updated_at", "name"],
      ["desc", "asc"]
    );
    const beginIndex =
      (this.state.subaccountUserActivePage - 1) * this.state.recordLimit;
    const endIndex = beginIndex + this.state.recordLimit;
    const sliceSubaccountUsers = orderedSubaccountUsers.slice(
      beginIndex,
      endIndex
    );
    return _.map(sliceSubaccountUsers, (user) => {
      return (
        <Table.Row key={"subaccount_user_" + user.id}>
          <Table.Cell className={tableCss.name} style={{ borderTop: "0px" }}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <span style={{ paddingRight: "20px", paddingTop: "5px" }}>
                <PersonIconComponent />
              </span>
              <span>{user.name}</span>
              {this.isSelfAccount(user.id) && (
                <span style={{ paddingLeft: "3px", color: "#606062" }}>
                  {" "}
                  (you)
                </span>
              )}
            </div>
          </Table.Cell>
          <Table.Cell className={tableCss.name} style={{ borderTop: "0px" }}>
            {user.email}
          </Table.Cell>
          <Table.Cell className={tableCss.name} style={{ borderTop: "0px" }}>
            {!this.isSelfAccount(user.id) && (
              <span
                style={{ cursor: "pointer" }}
                onClick={() => this.openDeleteDialog(user.id)}
              >
                <DeleteIconComponent />
              </span>
            )}
          </Table.Cell>
        </Table.Row>
      );
    });
  };

  handleSubaccountUserPaginationChange = (e, { activePage }) =>
    this.setState({ subaccountUserActivePage: activePage });

  renderSubaccountUserTableContents = () => {
    if (_.isEmpty(this.props.subaccount_users)) {
      return this.renderZeroSubaccountUserState();
    } else {
      return (
        <Table basic="very">
          <Table.Body>{this.renderSubaccountUserTemplates()}</Table.Body>
          <Table.Footer style={{ textAlign: "right" }}>
            <Table.Row>
              <Table.HeaderCell colSpan="3" style={{ borderTop: "0px" }}>
                <Pagination
                  defaultActivePage={this.state.subaccountUserActivePage}
                  onPageChange={this.handleSubaccountUserPaginationChange}
                  totalPages={this.state.totalSubaccountUserPages}
                  lastItem={null}
                  firstItem={null}
                  ellipsisItem={{
                    content: <Icon name="ellipsis horizontal" />,
                    icon: true,
                  }}
                  prevItem={{
                    content: (
                      <span>
                        <LeftArrowIconComponent />
                      </span>
                    ),
                    icon: true,
                  }}
                  nextItem={{
                    content: (
                      <span>
                        <RightArrowIconComponent />
                      </span>
                    ),
                    icon: true,
                  }}
                />
              </Table.HeaderCell>
            </Table.Row>
          </Table.Footer>
        </Table>
      );
    }
  };

  handleDeleteToggleModal = (isOpen) => {
    return () => {
      this.setState({
        ...this.getResetState(),
        isDeleteModalOpen: isOpen,
        delete_user_id: 0,
      });
    };
  };

  renderDeleteUserModal = () => {
    return (
      <Modal
        size={"tiny"}
        open={this.state.isDeleteModalOpen}
        onClose={this.handleDeleteToggleModal(false)}
        closeOnDimmerClick={false}
      >
        <Modal.Header>Delete Subaccount User</Modal.Header>
        <Modal.Content>
          <p>Are you sure you want to delete your subaccount user</p>
        </Modal.Content>
        <Modal.Actions>
          <Button negative onClick={this.handleDeleteToggleModal(false)}>
            No
          </Button>
          <Button positive onClick={this.processDeleteSubaccountUser}>
            Yes
          </Button>
        </Modal.Actions>
      </Modal>
    );
  };

  processDeleteSubaccountUser = () => {
    this.props.deleteUser(this.state.delete_user_id);
    this.setState({
      ...this.getResetState(),
      isDeleteModalOpen: false,
      delete_user_id: 0,
    });
  };

  emailIsValid = (email) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };

  totalActiveUser = () => {
    let count = 0;
    let userStatus = [...this.state.subaccountUserStatus];
    let users = [...this.state.subaccountUserDetails];
    for (let index = 0; index < userStatus.length; index++) {
      let email = users[index].email;
      if (
        userStatus[index] &&
        !_.isEmpty(email) &&
        this.emailIsValid(email) &&
        users[index].valid
      ) {
        count++;
      }
    }
    return count;
  };

  renderModal = () => {
    return (
      <Modal
        size={"small"}
        open={this.state.isModalOpen}
        onClose={this.handleToggleSubaccountUserModal(false)}
        closeOnDimmerClick={false}
      >
        <Modal.Content
          style={{ paddingTop: this.state.step == 2 ? "0px" : "32px" }}
          scrolling={this.state.step == 1}
        >
          {this.state.step == 1 && (
            <h1 className={tableCss.headerFont}>Invite users</h1>
          )}
          {this.state.step == 1 && (
            <InviteUserComponent
              id="subaccount_user_form"
              statusKey="subaccountUserStatus"
              valueKey="subaccountUserDetails"
              updateValues={this.updateValues}
              basicPassword={this.props.envContext.basicPassword}
              token={this.props.auth?.data?.jwt}
            />
          )}
          {this.state.step == 2 && (
            <>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  paddingTop: this.state.step == 3 ? "0px" : "10px",
                  justifyContent: "flex-start",
                  minHeight: "200px",
                  alignItems: "center",
                }}
              >
                <span>
                  <SubaccountSuccessWithInviteUserIconComponent />
                </span>
                <h1 className={tableCss.headerFont}>Invites sent!</h1>
                <p className={tableCss.textSmall}>
                  Invites sent to {this.totalActiveUser()} users.
                </p>
                <div>
                  <Button
                    onClick={this.handleToggleSubaccountUserModal(false)}
                    className={tableCss.primaryButton}
                  >
                    Ok
                  </Button>
                </div>
              </div>
            </>
          )}
        </Modal.Content>
        {this.state.step == 1 && (
          <Modal.Actions style={{ background: "none" }}>
            {this.renderActions()}
          </Modal.Actions>
        )}
      </Modal>
    );
  };
}

export default Settings;
