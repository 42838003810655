import moment from "moment-timezone";
import _ from "lodash";

export default {
  getDurationSeconds({ days, hours, minutes }) {
    const daySeconds = days * 24 * 60 * 60;
    const hourSeconds = hours * 60 * 60;
    const minuteSeconds = minutes * 60;

    return daySeconds + hourSeconds + minuteSeconds;
  },

  getDurationObject(duration) {
    const days = Math.floor(duration / (24 * 60 * 60));
    const hours = Math.floor((duration % (24 * 60 * 60)) / (60 * 60));
    const minutes = Math.floor(((duration % (24 * 60 * 60)) % (60 * 60)) / 60);
    return { days, hours, minutes };
  },

  validDuration({ days, hours, minutes }) {
    const validDays = days >= 0 && days < 1000 && days % 1 === 0;
    const validHours = hours >= 0 && hours < 24 && hours % 1 === 0;
    const validMinutes = minutes >= 0 && minutes < 60 && minutes % 1 === 0;

    return validDays && validHours && validMinutes;
  },

  getMissingFeatures(features, user) {
    return _.filter(features || [], (feature) => {
      return !user && !user.features[feature];
    });
  },

  getFeatures(timer) {
    let features = [];
    if (timer.timerType == "dynamic") {
      features.push("dynamic");
      // New Fallback Image Feature is added
    }
    // Recurring timer feature
    if (timer.timerType == "recurring") {
      features.push("recurring");
    }

    if (timer.templateData.style && timer.templateData.style !== "basic") {
      features.push("styles");
    }
    if (timer.templateData.transparent && timer.templateData.transparent) {
      features.push("transparent");
    }

    if (timer.templateData.fallbackImage) {
      features.push("fallback");
    }
    return features;
  },

  getTimerFeatures(timer) {
    let features = [];
    if (timer.timer_type == "dynamic") {
      features.push("dynamic");
    }
    // Recurring timer feature
    if (timer.timer_type == "recurring") {
      features.push("recurring");
    }

    if (timer.templatedata.style && timer.templatedata.style !== "basic") {
      features.push("styles");
    }
    if (timer.templatedata.transparent && timer.templatedata.transparent) {
      features.push("transparent");
    }
    // New Fallback Image Feature is added
    if (timer.templatedata.fallbackImage) {
      features.push("fallback");
    }

    return features;
  },

  validEndTime(dateTime) {
    const now = moment();
    const current = moment(dateTime);

    return current.isAfter(now);
  },
};
