export const SUBACCOUNT_FETCH_SUCCESS = "SUBACCOUNT_FETCH_SUCCESS";
export const SUBACCOUNT_FETCH_FAILURE = "SUBACCOUNT_FETCH_FAILURE";
export const SUBACCOUNT_SAVE_SUCCESS = "SUBACCOUNT_SAVE_SUCCESS";
export const SUBACCOUNT_SAVE_FAILURE = "SUBACCOUNT_SAVE_FAILURE";

export const CORPORATE_USER_FETCH_SUCCESS = "CORPORATE_USER_FETCH_SUCCESS";
export const CORPORATE_USER_FETCH_FAILURE = "CORPORATE_USER_FETCH_FAILURE";
export const CORPORATE_USER_SAVE_SUCCESS = "CORPORATE_USER_SAVE_SUCCESS";
export const CORPORATE_USER_SAVE_FAILURE = "CORPORATE_USER_SAVE_FAILURE";

export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";
export const DELETE_USER_FAILURE = "DELETE_USER_FAILURE";

export const UPDATE_USER_STEP_COUNT_SUCCESS = "UPDATE_USER_STEP_COUNT_SUCCESS";
export const UPDATE_USER_STEP_COUNT_FAILURE = "UPDATE_USER_STEP_COUNT_FAILURE";

// accountConstants.js


