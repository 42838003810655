import { connect } from "react-redux";

import Settings from "../components/settings";
import {
  subscribe,
  fetchInvoices,
  updatePassword,
  updateSettings,
} from "../actions/authenticationActions";
import { deleteUser } from "../actions/accountActions";
import { uploadFailed } from "../actions/uploadActions";
import {
  createSubaccount,
  fetchSubaccountUsers,
} from "../actions/subaccountActions";
import {
  updateUserDefaultFont,
  fetchCustomFont,
  createCustomFont,
  updateCustomFont,
  destroyCustomFont,
} from "../actions/customFontActions";
const mapStateToProps = (state, props) => {
  return {
    user: state.auth?.data ?? {},
    invoices: state.auth?.invoices ?? {},
    envContext: state.envContext?.data ?? {},
    authError: state.auth?.error ?? {},
    auth: state.auth ?? {},
    subaccount_users: state.subaccountDetails?.subaccount_users ?? {},
    custom_fonts: state.subaccountDetails?.custom_fonts ?? {},
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  fetchInvoices: () => {
    dispatch(fetchInvoices());
  },
  subscribe: (stripeToken, plan) => {
    dispatch(subscribe(stripeToken, plan));
  },
  updatePassword: (password) => {
    dispatch(updatePassword(password));
  },
  updateSettings: (status) => {
    dispatch(updateSettings(status));
  },
  fetchSubaccountUsers: (subaccount_id) => {
    dispatch(fetchSubaccountUsers(subaccount_id));
  },
  createSubaccount: (users, subaccount_id) => {
    dispatch(createSubaccount(users, subaccount_id));
  },
  deleteUser: (userid) => {
    dispatch(deleteUser(userid));
  },
  updateUserDefaultFont: (status, digit_font, label_font) => {
    dispatch(updateUserDefaultFont(status, digit_font, label_font));
  },
  fetchCustomFont: () => {
    dispatch(fetchCustomFont());
  },
  createCustomFont: (font_path, font_name) => {
    dispatch(createCustomFont(font_path, font_name));
  },
  updateCustomFont: (id, font_name) => {
    dispatch(updateCustomFont(id, font_name));
  },
  destroyCustomFont: (id) => {
    dispatch(destroyCustomFont(id));
  },
  handleUploadError: (err) => {
    dispatch(uploadFailed(err));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Settings);
