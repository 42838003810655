// import PropTypes from 'prop-types';
// import React from "react";
// // import ReactS3Uploader from "react-s3-uploader";
// import { Button, Loader } from "semantic-ui-react";
// import brandFont from "../../assets/styles/shared/corporateBrandFontEditor.module.scss";
// import { CrossIconComponent } from "../iconComponents";

// const { string, func, array, bool } = PropTypes;

// class BrandFontUploader extends React.Component {

//     static propTypes = {
//         label: string,
//         existingFile: string,
//         handleChange: func,
//         handleError: func,
//         onModalClose: func,
//         saveFont: func,
//     };

//     constructor(props) {
//         super(props)
//         this.state = { progress: null, fileName: "test.ttf", uploadStatus: false };
//     }

//     renderButton() {
//         return (
//             <div className={brandFont.modalPopup}>
//                 {!this.state.uploadStatus &&
//                     <>
//                         <div className={brandFont.modalFirstRow}>
//                             <div className={brandFont.modalFirstTitle}>Upload custom fonts</div>
//                             <span style={{ cursor: "pointer" }} onClick={this.props.onModalClose}><CrossIconComponent /></span>
//                         </div>
//                         <div className={brandFont.modalSecondRow}>
//                             <div className={brandFont.modalSecondText1}>The maximum number of fonts available for uploading <br />is limited to twenty.</div>
//                             <div>
//                                 <Button className={brandFont.modalChooseFontsBtn}
//                                     content={"Choose font"}
//                                     onClick={() => this.uploadInput.click()
//                                     }

//                                 />
//                             </div>
//                             <div className={brandFont.modalSecondText1}>Or drag and drop .ttf</div>
//                         </div>

//                     </>
//                 }
//                 {this.state.uploadStatus &&
//                     <>
//                         <div className={brandFont.modalThirdRow}>
//                             <div className={brandFont.progressCol}>
//                                 <Loader size='large' inline='centered'/>
//                             </div>
//                             <div className={brandFont.uploadText}>
//                                 Uploading font
//                             </div>
//                             <div className={brandFont.fileText}>
//                                 {this.state.fileName}
//                             </div>
//                         </div>
//                         <div className={brandFont.modalCancelRow}>
//                             <Button className={brandFont.modalCancelBtn} onClick={this.props.onModalClose}>Cancel</Button>
//                         </div>
//                     </>
//                 }
//             </div >
//         )
//     };

//     // renderUploader() {
//     //     return (
//     //         <ReactS3Uploader
//     //             signingUrl="/api/v1/uploads/sign"
//     //             signingUrlMethod="GET"
//     //             accept="font/*"
//     //             hidden
//     //             signingUrlQueryParams={this.getQueryParams}
//     //             preprocess={this.handlePreprocess}
//     //             onError={this.handleUploadError}
//     //             onFinish={this.handleUploadComplete}
//     //             onProgress={this.handleProgress}
//     //             inputRef={cmp => this.uploadInput = cmp} />
//     //     )
//     // };

//     render() {
//         return (
//             <>
//                 {this.renderButton()}
//                 {/* {this.renderUploader()} */}
//             </>
//         );
//     }

//     getQueryParams = () => {
//         return { size: this.fileSize }
//     }

//     handlePreprocess = (file, next) => {
//         this.fileSize = file.size;
//         if (this.fileSize > 1024 * 1024) {

//             this.handleUploadError("File too large (Limit 1MB)")
//             if(this.props.onModalClose){
//                 this.props.onModalClose();
//             }
//         } else {

//             if(file.name.toLowerCase().endsWith(".ttf")){
//                 this.setState({ uploadStatus: true, fileName: file.name })
//                 next(file);
//             }else{
//                 this.handleUploadError("Only ttf file format is valid")
//                 if(this.props.onModalClose){
//                     this.props.onModalClose();
//                 }
//             }
//         }
//     }

//     handleProgress = (percent) => {
//         this.setState({ progress: percent })
//     }

//     handleUploadError = (err) => {
//         this.setState({ progress: null, uploadStatus: false, fileName: "" });
//         if(this.props.handleError){
//             this.props.handleError(err);
//         }
//     };

//     handleUploadComplete = (response) => {
//         this.setState({ progress: null, uploadStatus: false, fileName: "" })
//         this.props.handleChange(response.filePath);
//         this.props.saveFont(response.filePath);
//     };
// }

// export default BrandFontUploader;

import { connect } from "react-redux";
import PropTypes from "prop-types";
import React from "react";
import { uploadFile } from "../../actions/authenticationActions"; // Adjust the import path as necessary
import { Button, Loader } from "semantic-ui-react";
import brandFont from "../../assets/styles/shared/corporateBrandFontEditor.module.scss";
import { CrossIconComponent } from "../iconComponents";

const { string, func, bool } = PropTypes;

class BrandFontUploader extends React.Component {
  static propTypes = {
    label: string,
    existingFile: string,
    handleChange: func,
    handleError: func,
    onModalClose: func,
    saveFont: func,
    uploadFile: func.isRequired, // Add uploadFile as a required prop
    progress: PropTypes.number,
  };

  constructor(props) {
    super(props);
    this.state = { progress: null, fileName: "test.ttf", uploadStatus: false };
    this.uploadInput = React.createRef();
  }

  handleFileUpload = (event) => {
    const file = event.target.files[0];
    this.setState({ progress: null, uploadStatus: true, fileName: "" });
    this.props
      .uploadFile(file)
      .then((filePath) => {
        this.setState({ progress: null, uploadStatus: false, fileName: "" });
        if (this.props.handleChange) {
          this.props.handleChange(filePath);
        }
        if (this.props.saveFont) {
          this.props.saveFont(filePath);
        }
        if (this.props.onModalClose) {
          this.props.onModalClose();
        }
      })
      .catch((error) => {
        this.setState({ progress: null, uploadStatus: false, fileName: "" });
        if (this.props.handleError) {
          this.props.handleError(error);
        }
        if (this.props.onModalClose) {
          this.props.onModalClose();
        }
      });
  };

  renderButton() {
    return (
      <div className={brandFont.modalPopup}>
        {!this.state.uploadStatus && (
          <>
            <div className={brandFont.modalFirstRow}>
              <div className={brandFont.modalFirstTitle}>
                Upload custom fonts
              </div>
              <span
                style={{ cursor: "pointer" }}
                onClick={this.props.onModalClose}
              >
                <CrossIconComponent />
              </span>
            </div>
            <div className={brandFont.modalSecondRow}>
              <div className={brandFont.modalSecondText1}>
                The maximum number of fonts available for uploading <br />
                is limited to twenty.
              </div>
              <div>
                <Button
                  className={brandFont.modalChooseFontsBtn}
                  content={"Choose font"}
                  onClick={() => this.uploadInput.current.click()}
                />
              </div>
              <div className={brandFont.modalSecondText1}>
                Or drag and drop .ttf
              </div>
            </div>
          </>
        )}

        {this.state.uploadStatus && (
          <>
            <div className={brandFont.modalThirdRow}>
              <div className={brandFont.progressCol}>
                <Loader size="large" inline="centered" />
              </div>
              <div className={brandFont.uploadText}>Uploading font</div>
              <div className={brandFont.fileText}>{this.state.fileName}</div>
            </div>
            <div className={brandFont.modalCancelRow}>
              <Button
                className={brandFont.modalCancelBtn}
                onClick={this.props.onModalClose}
              >
                Cancel
              </Button>
            </div>
          </>
        )}
        <input
          type="file"
          accept="font/*"
          hidden
          ref={this.uploadInput}
          onChange={this.handleFileUpload}
        />
      </div>
    );
  }

  render() {
    return <>{this.renderButton()}</>;
  }
}

const mapStateToProps = (state) => ({
  progress: state.upload?.progress || null, // Use optional chaining to avoid errors if upload is undefined
});

const mapDispatchToProps = {
  uploadFile,
};

export default connect(mapStateToProps, mapDispatchToProps)(BrandFontUploader);
