import PropTypes from 'prop-types';
import React from "react";
import { Link, Outlet } from "react-router-dom";

import ToastContainer from "../../containers/toastContainer";
import Logo from "./logo";

import "../../assets/styles/global.module.scss";
import css from "../../assets/styles/layout/index.module.scss";

const { object } = PropTypes;

class Layout extends React.Component {

  componentDidMount() {
    this.props.notifyCookies();
  }

  renderFooter = () => {
    console.log("hello there")
    return (
      <footer className="site-footer home-layout">
        <div className="container">
          <div className="row gap-y align-items-center">
            <div className="col-12 col-sm-3">
              <p className="text-center text-lg-left">
                <Logo />
              </p>
            </div>

            <div className="col-12 col-sm-6 text-center copyright">
            {/* Sendtric by Noosa Labs, Inc. &copy; All rights reserved. <a href="/legal/terms.pdf">Terms of Service</a> and <a href="/legal/privacy.pdf">Privacy Policy</a>. */}
            Sendtric by Noosa Labs <a href="/legal/terms.pdf"  target="_blank">Terms of Service</a> and <a href="/legal/privacy.pdf">Privacy Policy</a>.
            </div>

            <div className="col-12 col-sm-3">
              <div className="social text-center text-lg-right">
                <a className="social-facebook" href="https://www.facebook.com/sendtric"  target="_blank"><i className="fa fa-facebook"></i></a>
                <a className="social-twitter" href="https://twitter.com/sendtric"  target="_blank"><i className="fa fa-twitter"></i></a>
              </div>
            </div>
          </div>
        </div>
      </footer>
    );
  };

  renderToastContainer = () => {
    return <ToastContainer location={this.props.location} />;
  };

  render() {
    const { children } = this.props;

    return (
      <div className={ css.layout }>
        {/* { children } */}
        <Outlet />
        { this.renderFooter() }
        { this.renderToastContainer() }
      </div>
    );
  }
}

export default Layout;
