// import { connect } from "react-redux";

// import Register from "../components/register";
// import { register } from "../actions/authenticationActions";
// import getRedirectLocation from "../helpers/getRedirectLocation";

// const mapStateToProps = (state) => ({
//   error: state.auth.error
// });

// const mapDispatchToProps = (dispatch, ownProps) => ({
//   register: (email, password,api_plan_id) => {
//     dispatch(register(email, password,api_plan_id));
//   }
// });

// export default connect(mapStateToProps, mapDispatchToProps)(Register);

import { connect } from "react-redux";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import Register from "../components/register";
import { register, clearAuthError } from "../actions/authenticationActions";
import getRedirectLocation from "../helpers/getRedirectLocation";

const RegisterContainer = () => {
  const error = useSelector((state) => state.auth.error);
  const inProgress = useSelector((state) => state.auth.inProgress);
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);

  const handleRegister = (email, password, api_plan_id) => {
    const redirect = "/register/success";
    dispatch(
      register(email, password, api_plan_id, navigate, { redirect }, setLoader)
    );
  };

  useEffect(() => {
    // Clear the error when the location changes
    dispatch(clearAuthError());
  }, [location, dispatch]);

  return <Register error={error} register={handleRegister} loader={loader} />;
};

export default RegisterContainer;
