// import { LOCATION_CHANGE } from "react-router-redux";
// import _ from "lodash";

// import {
//   TIMER_SAVE_SUCCESS,
//   TIMER_SAVE_FAILURE,
//   TIMER_ARCHIVE_SUCCESS,
//   TIMER_ARCHIVE_FAILURE,
//   TIMER_FETCH_SUCCESS,
//   TIMER_FETCH_FAILURE,
//   GUEST_TIMER_SAVE_SUCCESS,
//   GUEST_TIMER_SAVE_FAILURE,
//   CORPORATE_TIMER_FETCH_SUCCESS,
//   CORPORATE_TIMER_FETCH_FAILURE
// } from "../constants/timerConstants";

// const upsertTimer = (currentTimers, newTimer) => {
//   _.remove(currentTimers, (timer) => {
//     return timer.id === newTimer.id;
//   });

//   currentTimers.push(newTimer);
//   return currentTimers;
// };

// const changeArchiveStatus = (timers, timerId, archived) => {
//   if (archived) {
//     return toggleArchived(timers.active, timers.archived,timers.account_timers, timerId, archived);
//   } else {
//     return toggleArchived(timers.archived, timers.active,timers.account_timers, timerId, archived);
//   }
// };

// const toggleArchived = (previousTimers, updatedTimers,accountTimers, timerId, archived) => {
//   const removed = _.remove(previousTimers, (timer) => {
//     return timer.id === timerId;
//   });
//   removed[0].archived = archived;
//   updatedTimers.push(removed[0]);

//   let index = _.findIndex(accountTimers, (timer) => {
//     return timer.timer_id == timerId;
//   }, 0);

//   accountTimers[index].archived = archived;

//   if (archived) {
//     return { active: previousTimers, archived: updatedTimers,account_timers:accountTimers };
//   } else {
//     return { active: updatedTimers, archived: previousTimers,account_timers:accountTimers };
//   }
// };

// // Guest is only set to the last created timer
// export const initialState = {
//   data: {
//     active: [],
//     archived: [],
//     guest: {},
//     account_timers:[],
//   },
//   subaccounts:[],
//   error: null
// };

// export default (state = initialState, action) => {
//   let updatedData;
//   switch (action.type) {
//     case TIMER_FETCH_SUCCESS:
//       return { ...state, data: action.data, error: null };
//     case TIMER_FETCH_FAILURE:
//       return { ...state, error: action.error };
//     case CORPORATE_TIMER_FETCH_SUCCESS:
//       return { ...state, subaccounts: action.data, error: null };
//     case CORPORATE_TIMER_FETCH_FAILURE:
//       return { ...state, error: action.error };
//     case TIMER_SAVE_SUCCESS:
//       updatedData = upsertTimer(state.data.active, action.data);
//       return { ...state, data: { active: updatedData }, error: null };
//     case TIMER_SAVE_FAILURE:
//       return { ...state, error: action.error };
//     case GUEST_TIMER_SAVE_SUCCESS:
//       return { ...state, data: { guest: action.data }, error: null };
//     case GUEST_TIMER_SAVE_FAILURE:
//       return { ...state, error: action.error };
//     case TIMER_ARCHIVE_SUCCESS:
//       updatedData = changeArchiveStatus(state.data, action.id, action.archived);
//       return { ...state, data: updatedData, error: null };
//     case TIMER_ARCHIVE_FAILURE:
//       return { ...state, error: action.error };
//     default:
//       return state;
//   }
// };

import { LOCATION_CHANGE } from "react-router-redux";
import _ from "lodash";

import {
  TIMER_SAVE_SUCCESS,
  TIMER_SAVE_FAILURE,
  TIMER_ARCHIVE_SUCCESS,
  TIMER_ARCHIVE_FAILURE,
  TIMER_FETCH_SUCCESS,
  TIMER_FETCH_FAILURE,
  GUEST_TIMER_SAVE_SUCCESS,
  GUEST_TIMER_SAVE_FAILURE,
  CORPORATE_TIMER_FETCH_SUCCESS,
  CORPORATE_TIMER_FETCH_FAILURE,
  TIMER_SAVE_REQUEST,
} from "../constants/timerConstants";

const upsertTimer = (currentTimers, newTimer) => {
  const timersWithoutNewTimer = currentTimers.filter(
    (timer) => timer.id !== newTimer.id
  );
  return [...timersWithoutNewTimer, newTimer];
};

const changeArchiveStatus = (timers, timerId, archived) => {
  if (archived) {
    return toggleArchived(
      timers.active,
      timers.archived,
      timers.account_timers,
      timerId,
      archived
    );
  } else {
    return toggleArchived(
      timers.archived,
      timers.active,
      timers.account_timers,
      timerId,
      archived
    );
  }
};

const toggleArchived = (
  previousTimers,
  updatedTimers,
  accountTimers,
  timerId,
  archived
) => {
  const removed = previousTimers.filter((timer) => timer.id !== timerId);
  const updatedTimer = {
    ...previousTimers.find((timer) => timer.id === timerId),
    archived,
  };
  const newUpdatedTimers = [...updatedTimers, updatedTimer];
  const updatedAccountTimers = accountTimers.map((timer) =>
    timer.timer_id === timerId ? { ...timer, archived } : timer
  );

  return {
    active: archived ? removed : newUpdatedTimers,
    archived: archived ? newUpdatedTimers : removed,
    account_timers: updatedAccountTimers,
  };
};

export const initialState = {
  data: {
    active: [],
    archived: [],
    guest: {},
    account_timers: [],
  },
  subaccounts: [],
  error: null,
  isSubmitting: false,
};

export default (state = initialState, action) => {
  let updatedData;
  switch (action.type) {
    case TIMER_SAVE_REQUEST:
      return { ...state, isSubmitting: true, error: null };
    case TIMER_FETCH_SUCCESS:
      return { ...state, data: action.data, error: null };
    case TIMER_FETCH_FAILURE:
      return { ...state, error: action.error };
    case CORPORATE_TIMER_FETCH_SUCCESS:
      return { ...state, subaccounts: action.data, error: null };
    case CORPORATE_TIMER_FETCH_FAILURE:
      return { ...state, error: action.error };
    case TIMER_SAVE_SUCCESS:
      updatedData = {
        ...state.data,
        active: upsertTimer(state.data.active, action.data),
      };
      return { ...state, data: updatedData, isSubmitting: false, error: null };
    case TIMER_SAVE_FAILURE:
      return { ...state, error: action.error };
    case GUEST_TIMER_SAVE_SUCCESS:
      updatedData = {
        ...state.data,
        guest: action.data,
      };
      return { ...state, data: updatedData, error: null };
    case GUEST_TIMER_SAVE_FAILURE:
      return { ...state, error: action.error };
    case TIMER_ARCHIVE_SUCCESS:
      updatedData = changeArchiveStatus(state.data, action.id, action.archived);
      return { ...state, data: updatedData, error: null };
    case TIMER_ARCHIVE_FAILURE:
      return { ...state, error: action.error };
    default:
      return state;
  }
};
