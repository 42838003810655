import PropTypes from "prop-types";
import React from "react";
import _ from "lodash";
import { createBrowserHistory } from "history";
import Editor from "../shared/editor";
const browserHistory = createBrowserHistory();

const { object } = PropTypes;

class Timer extends React.Component {
  static propTypes = {
    timer: object,
    user: object,
  };

  static defaultProps = (function () {
    timer: {
    }
  })();

  componentDidMount() {
    //console.log(" Timer Details");
    //console.log(this.props);
    this.props.fetch();
    if (this.isCorporateUser() || this.isSubaccountUser()) {
      this.props.fetchCustomFont();
    }
  }

  componentDidUpdate(prevProps) {
    // User's plan changed, refetch all timers
    if (prevProps.user.tier != this.props.user.tier) {
      this.props.fetch();
    }
  }

  handleSave = (data) => {
    this.props.update(data);
  };

  handleCancel = () => {
    browserHistory.push("/timers");
  };

  isSubaccountUser() {
    return this.props.user.subaccount_id != 0;
  }

  isCorporateUser() {
    return (
      this.props.user.is_company ||
      (this.props.user.features &&
        this.props.user.features.subaccount &&
        this.props.user.parent_id == 0)
    );
  }

  render() {
    const genUrl = _.get(
      this.props,
      "user.auth.gen_url",
      "https://gen.sendtric.com"
    );

    // This is the place where we write to logic to restrict user to select a particular
    if (!_.isEmpty(this.props.timer)) {
      return (
        <Editor
          type="Timer"
          genUrl={genUrl}
          target={this.props.timer}
          handleSave={this.handleSave}
          handleCancel={this.handleCancel}
          handleUploadError={this.props.handleUploadError}
          user={this.props.user}
          key={this.props.timer.id}
          editTemplateStatus={this.props.user.can_edit_templates}
          isSubmitting={this.props.isSubmitting}
        />
      );
    } else {
      return null;
    }
  }
}

export default Timer;
