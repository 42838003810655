const langs = [
  { text: "Bulgarian", value: "bg" },
  { text: "Czech", value: "cs" },
  { text: "Danish", value: "da" },
  { text: "Dutch", value: "nl" },
  { text: "English", value: "en" },
  { text: "Estonian", value: "et" },
  { text: "Finnish", value: "fi" },
  { text: "French", value: "fr" },
  { text: "German", value: "de" },
  { text: "Greek", value: "el" },
  { text: "Hebrew", value: "he" },
  { text: "Hungarian", value: "hu" },
  { text: "Icelandic", value: "is" },
  { text: "Italian", value: "it" },
  { text: "Japanese", value: "ja" },
  { text: "Korean", value: "kn" },
  { text: "Latvian", value: "lv" },
  { text: "Lithuanian", value: "lt" },
  { text: "Norwegian", value: "no" },
  { text: "Polish", value: "pl" },
  { text: "Portuguese", value: "pt" },
  { text: "Romanian", value: "ro" },
  { text: "Russian", value: "ru" },
  { text: "Serbian", value: "sr" },
  { text: "Simplified Chinese", value: "csl" },
  { text: "Slovak", value: "sk" },
  { text: "Spanish", value: "es" },
  { text: "Swedish", value: "sv" },
  { text: "Turkish", value: "tr" },
];

const labels = {
  bg: ["ГОДИНИ", "ДНИ", "ЧАСОВЕ", "МИНУТИ", "СЕКУНДИ"],
  cs: ["ROKY", "DNÍ", "HODIN", "MINUT", "VTEŘIN"],
  csl: ["年", "日", "时", "分", "秒"],
  da: ["ÅR", "DAGE", "TIMER", "MINUTTER", "SEKUNDER"],
  de: ["JAHRE", "TAGE", "STUNDEN", "MINUTEN", "SEKUNDEN"],
  el: ["χρόνια", "Μέρες", "Ώρες", "Λεπτά", "Δευτερόλεπτα"],
  en: ["YEARS", "DAYS", "HOURS", "MINUTES", "SECONDS"],
  es: ["AÑOS", "DÍAS", "HORAS", "MINUTOS", "SEGUNDOS"],
  et: ["AASTAT", "PÄEVA", "TUNDI", "MINUTIT", "SEKUNDIT"],
  fi: ["VUOTTA", "PÄIVÄÄ", "TUNTIA", "MINUUTTIA", "SEKUNTIA"],
  fr: ["ANS", "JOURS", "HEURES", "MINUTES", "SECONDES"],
  he: ["שנים", "םימי", "תועש", "תוקד", "תוינש"],
  hu: ["ÉV", "NAP", "ÓRA", "PERC", "MÁSODPERC"],
  is: ["ÁR", "DAGAR", "KLUKKUSTUNDIR", "MÍNÚTUR", "SEKÚNDUR"],
  it: ["ANNI", "GIORNI", "ORE", "MINUTI", "SECONDI"],
  ja: ["年", "日", "時間", "分", "秒"],
  lt: ["METAI", "DIENOS", "VALANDOS", "MINUTĖS", "SEKUNDĖS"],
  lv: ["GADI", "DIENAS", "STUNDAS", "MINŪTES", "SEKUNDES"],
  kn: ["연령", "일", "시간", "분", "초"],
  nl: ["JAAR", "DAGEN", "UREN", "MINUTEN", "SECONDEN"],
  no: ["ÅR", "DAGER", "TIMER", "MINUTTER", "SEKUNDER"],
  pl: ["LATA", "DNI", "GODZIN", "MINUT", "SEKUND"],
  pt: ["ANOS", "DIAS", "HORAS", "MINUTOS", "SEGUNDOS"],
  ro: ["ANI", "ZILE", "ORE", "MINUTE", "SECUNDE"],
  ru: ["ГОДА", "ДНЕЙ", "ЧАСОВ", "МИНУТ", "СЕКУНД"],
  sk: ["ROKY", "DNÍ", "HODÍN", "MINÚT", "SEKÚND"],
  sr: ["GODINE", "DAN", "SAT", "MIN", "SEK"],
  sv: ["ÅR", "DAGAR", "TIMMAR", "MINUTER", "SEKUNDER"],
  tr: ["YIL", "GÜN", "SAAT", "DAKİKA", "SANİYE"],
};

module.exports = {
  langs,
  labels,
};
