module.exports = {
  all: [    
    {
        "label": "ABeeZee Regular",
        "value": "ABeeZee"
    },
    {
        "label": "Abel Regular",
        "value": "Abel"
    },
    {
        "label": "Abhaya Libre Regular",
        "value": "AbhayaLibre"
    },
    {
        "label": "Abril Fatface",
        "value": "AbrilFatface"
    },
    {
        "label": "Abyssinica SIL",
        "value": "AbyssinicaSIL"
    },
    {
        "label": "Aclonica Regular",
        "value": "Aclonica"
    },
    {
        "label": "Acme Regular",
        "value": "Acme"
    },
    {
        "label": "Actor Regular",
        "value": "Actor"
    },
    {
        "label": "Adamina Regular",
        "value": "Adamina"
    },
    {
        "label": "Advent Pro Regular",
        "value": "AdventPro"
    },
    {
        "label": "Aguafina Script Regular",
        "value": "AguafinaScript"
    },
    {
        "label": "Akronim Regular",
        "value": "Akronim"
    },
    {
        "label": "Aladin Regular",
        "value": "Aladin"
    },
    {
        "label": "Aldrich Regular",
        "value": "Aldrich"
    },
    {
        "label": "Alef Regular",
        "value": "Alef"
    },
    {
        "label": "Alegreya Regular",
        "value": "Alegreya"
    },
    {
        "label": "Alegreya SC Regular",
        "value": "AlegreyaSC"
    },
    {
        "label": "Alegreya Sans Regular",
        "value": "AlegreyaSans"
    },
    {
        "label": "Alegreya Sans SC Regular",
        "value": "AlegreyaSansSC"
    },
    {
        "label": "Alex Brush Regular",
        "value": "AlexBrush"
    },
    {
        "label": "Alfa Slab One Regular",
        "value": "AlfaSlabOne"
    },
    {
        "label": "Alice Regular",
        "value": "Alice"
    },
    {
        "label": "Alike Regular",
        "value": "Alike"
    },
    {
        "label": "Alike Angular Regular",
        "value": "AlikeAngular"
    },
    {
        "label": "Allan Regular",
        "value": "Allan"
    },
    {
        "label": "Allerta Regular",
        "value": "Allerta"
    },
    {
        "label": "Allerta Stencil Regular",
        "value": "AllertaStencil"
    },
    {
        "label": "Allura Regular",
        "value": "Allura"
    },
    {
        "label": "Almendra Regular",
        "value": "Almendra"
    },
    {
        "label": "Almendra Display Regular",
        "value": "AlmendraDisplay"
    },
    {
        "label": "Almendra SC Regular",
        "value": "AlmendraSC"
    },
    {
        "label": "Amarante Regular",
        "value": "Amarante"
    },
    {
        "label": "Amaranth Regular",
        "value": "Amaranth"
    },
    {
        "label": "Amatic SC Regular",
        "value": "AmaticSC"
    },
    {
        "label": "Amatica SC Regular",
        "value": "AmaticaSC"
    },
    {
        "label": "Amethysta Regular",
        "value": "Amethysta"
    },
    {
        "label": "Amiko Regular",
        "value": "Amiko"
    },
    {
        "label": "Amiri Regular",
        "value": "Amiri"
    },
    {
        "label": "Amita Regular",
        "value": "Amita"
    },
    {
        "label": "Anaheim Regular",
        "value": "Anaheim"
    },
    {
        "label": "Andada Regular",
        "value": "Andada"
    },
    {
        "label": "Andada SC",
        "value": "AndadaSC"
    },
    {
        "label": "Andika Regular",
        "value": "Andika"
    },
    {
        "label": "Angkor Regular",
        "value": "Angkor"
    },
    {
        "label": "Annie Use Your Telescope Regular",
        "value": "AnnieUseYourTelescope"
    },
    {
        "label": "Anonymous Pro Regular",
        "value": "AnonymousPro"
    },
    {
        "label": "Antic Regular",
        "value": "Antic"
    },
    {
        "label": "Antic Didone Regular",
        "value": "AnticDidone"
    },
    {
        "label": "Antic Slab Regular",
        "value": "AnticSlab"
    },
    {
        "label": "Anton Regular",
        "value": "Anton"
    },
    {
        "label": "Antonio Regular",
        "value": "Antonio"
    },
    {
        "label": "Arapey Regular",
        "value": "Arapey"
    },
    {
        "label": "Arbutus Regular",
        "value": "Arbutus"
    },
    {
        "label": "Arbutus Slab Regular",
        "value": "ArbutusSlab"
    },
    {
        "label": "Architects Daughter Regular",
        "value": "ArchitectsDaughter"
    },
    {
        "label": "Archivo Regular",
        "value": "Archivo"
    },
    {
        "label": "Archivo Black Regular",
        "value": "ArchivoBlack"
    },
    {
        "label": "Archivo Narrow Regular",
        "value": "ArchivoNarrow"
    },
    {
        "label": "Archivo VF Beta Regular",
        "value": "ArchivoVFBeta"
    },
    {
        "label": "Aref Ruqaa Regular",
        "value": "ArefRuqaa"
    },
    {
        "label": "Arima Madurai Regular",
        "value": "ArimaMadurai"
    },
    {
        "label": "Arimo Regular",
        "value": "Arimo"
    },
    {
        "label": "Arizonia Regular",
        "value": "Arizonia"
    },
    {
        "label": "Armata Regular",
        "value": "Armata"
    },
    {
        "label": "Arsenal Regular",
        "value": "Arsenal"
    },
    {
        "label": "Artifika Regular",
        "value": "Artifika"
    },
    {
        "label": "Arvo",
        "value": "Arvo"
    },
    {
        "label": "Arya Regular",
        "value": "Arya"
    },
    {
        "label": "Asap Regular",
        "value": "Asap"
    },
    {
        "label": "Asap Condensed Regular",
        "value": "AsapCondensed"
    },
    {
        "label": "Asap VF Beta Regular",
        "value": "AsapVFBeta"
    },
    {
        "label": "Asar Regular",
        "value": "Asar"
    },
    {
        "label": "Asset Regular",
        "value": "Asset"
    },
    {
        "label": "Assistant Regular",
        "value": "Assistant"
    },
    {
        "label": "Astloch Regular",
        "value": "Astloch"
    },
    {
        "label": "Asul Regular",
        "value": "Asul"
    },
    {
        "label": "Athiti Regular",
        "value": "Athiti"
    },
    {
        "label": "Atma Regular",
        "value": "Atma"
    },
    {
        "label": "Atomic Age Regular",
        "value": "AtomicAge"
    },
    {
        "label": "Aubrey Regular",
        "value": "Aubrey"
    },
    {
        "label": "Audiowide Regular",
        "value": "Audiowide"
    },
    {
        "label": "Autour One Regular",
        "value": "AutourOne"
    },
    {
        "label": "Average Regular",
        "value": "Average"
    },
    {
        "label": "Average Sans Regular",
        "value": "AverageSans"
    },
    {
        "label": "Averia Gruesa Libre Regular",
        "value": "AveriaGruesaLibre"
    },
    {
        "label": "Averia Libre Regular",
        "value": "AveriaLibre"
    },
    {
        "label": "Averia Sans Libre Regular",
        "value": "AveriaSansLibre"
    },
    {
        "label": "Averia Serif Libre Regular",
        "value": "AveriaSerifLibre"
    },
    {
        "label": "Bad Script Regular",
        "value": "BadScript"
    },
    {
        "label": "Bahiana Regular",
        "value": "Bahiana"
    },
    {
        "label": "Baloo Regular",
        "value": "Baloo"
    },
    {
        "label": "Baloo Bhai Regular",
        "value": "BalooBhai"
    },
    {
        "label": "Baloo Bhaijaan Regular",
        "value": "BalooBhaijaan"
    },
    {
        "label": "Baloo Bhaina Regular",
        "value": "BalooBhaina"
    },
    {
        "label": "Baloo Chettan Regular",
        "value": "BalooChettan"
    },
    {
        "label": "Baloo Da Regular",
        "value": "BalooDa"
    },
    {
        "label": "Baloo Paaji Regular",
        "value": "BalooPaaji"
    },
    {
        "label": "Baloo Tamma Regular",
        "value": "BalooTamma"
    },
    {
        "label": "Baloo Tammudu Regular",
        "value": "BalooTammudu"
    },
    {
        "label": "Baloo Thambi Regular",
        "value": "BalooThambi"
    },
    {
        "label": "Balthazar Regular",
        "value": "Balthazar"
    },
    {
        "label": "Bangers Regular",
        "value": "Bangers"
    },
    {
        "label": "Barrio Regular",
        "value": "Barrio"
    },
    {
        "label": "Basic Regular",
        "value": "Basic"
    },
    {
        "label": "Battambang Regular",
        "value": "Battambang"
    },
    {
        "label": "Baumans Regular",
        "value": "Baumans"
    },
    {
        "label": "Bayon Regular",
        "value": "Bayon"
    },
    {
        "label": "Belgrano Regular",
        "value": "Belgrano"
    },
    {
        "label": "Bellefair Regular",
        "value": "Bellefair"
    },
    {
        "label": "Belleza Regular",
        "value": "Belleza"
    },
    {
        "label": "BenchNine Regular",
        "value": "BenchNine"
    },
    {
        "label": "Bentham Regular",
        "value": "Bentham"
    },
    {
        "label": "Berkshire Swash Regular",
        "value": "BerkshireSwash"
    },
    {
        "label": "Bevan Regular",
        "value": "Bevan"
    },
    {
        "label": "Bhavuka Regular",
        "value": "Bhavuka"
    },
    {
        "label": "Bigelow Rules Regular",
        "value": "BigelowRules"
    },
    {
        "label": "Bigshot One Regular",
        "value": "BigshotOne"
    },
    {
        "label": "Bilbo Regular",
        "value": "Bilbo"
    },
    {
        "label": "Bilbo Swash Caps Regular",
        "value": "BilboSwashCaps"
    },
    {
        "label": "BioRhyme Regular",
        "value": "BioRhyme"
    },
    {
        "label": "BioRhyme Expanded Regular",
        "value": "BioRhymeExpanded"
    },
    {
        "label": "Biryani Regular",
        "value": "Biryani"
    },
    {
        "label": "Bitter Regular",
        "value": "Bitter"
    },
    {
        "label": "Black Ops One Regular",
        "value": "BlackOpsOne"
    },
    {
        "label": "Bokor Regular",
        "value": "Bokor"
    },
    {
        "label": "Bonbon Regular",
        "value": "Bonbon"
    },
    {
        "label": "Boogaloo Regular",
        "value": "Boogaloo"
    },
    {
        "label": "Bowlby One Regular",
        "value": "BowlbyOne"
    },
    {
        "label": "Bowlby One SC Regular",
        "value": "BowlbyOneSC"
    },
    {
        "label": "Brawler Regular",
        "value": "Brawler"
    },
    {
        "label": "Bree Serif Regular",
        "value": "BreeSerif"
    },
    {
        "label": "Bruno Ace",
        "value": "BrunoAce"
    },
    {
        "label": "Bruno Ace SC",
        "value": "BrunoAceSC"
    },
    {
        "label": "Bubblegum Sans Regular",
        "value": "BubblegumSans"
    },
    {
        "label": "Bubbler One Regular",
        "value": "BubblerOne"
    },
    {
        "label": "Buenard Regular",
        "value": "Buenard"
    },
    {
        "label": "Bungee Regular",
        "value": "Bungee"
    },
    {
        "label": "Bungee Hairline Regular",
        "value": "BungeeHairline"
    },
    {
        "label": "Bungee Inline Regular",
        "value": "BungeeInline"
    },
    {
        "label": "Bungee Outline Regular",
        "value": "BungeeOutline"
    },
    {
        "label": "Bungee Shade Regular",
        "value": "BungeeShade"
    },
    {
        "label": "Butcherman Regular",
        "value": "Butcherman"
    },
    {
        "label": "Butcherman Caps Regular",
        "value": "ButchermanCaps"
    },
    {
        "label": "Butterfly Kids Regular",
        "value": "ButterflyKids"
    },
    {
        "label": "Cabin Regular",
        "value": "Cabin"
    },
    {
        "label": "Cabin Condensed Regular",
        "value": "CabinCondensed"
    },
    {
        "label": "Cabin Sketch Regular",
        "value": "CabinSketch"
    },
    {
        "label": "Cabin VF Beta Regular",
        "value": "CabinVFBeta"
    },
    {
        "label": "Caesar Dressing",
        "value": "CaesarDressing"
    },
    {
        "label": "Cagliostro Regular",
        "value": "Cagliostro"
    },
    {
        "label": "Cairo Regular",
        "value": "Cairo"
    },
    {
        "label": "Calligraffitti Regular",
        "value": "Calligraffitti"
    },
    {
        "label": "Cambay Regular",
        "value": "Cambay"
    },
    {
        "label": "Cambo",
        "value": "Cambo"
    },
    {
        "label": "Candal",
        "value": "Candal"
    },
    {
        "label": "Cantarell Regular",
        "value": "Cantarell"
    },
    {
        "label": "CantataOne-Regular",
        "value": "CantataOne"
    },
    {
        "label": "CantoraOne",
        "value": "CantoraOne"
    },
    {
        "label": "Capriola Regular",
        "value": "Capriola"
    },
    {
        "label": "Cardo",
        "value": "Cardo"
    },
    {
        "label": "Carme Regular",
        "value": "Carme"
    },
    {
        "label": "Carrois Gothic Regular",
        "value": "CarroisGothic"
    },
    {
        "label": "Carrois Gothic SC Regular",
        "value": "CarroisGothicSC"
    },
    {
        "label": "Carter One",
        "value": "CarterOne"
    },
    {
        "label": "Catamaran Regular",
        "value": "Catamaran"
    },
    {
        "label": "Caudex",
        "value": "Caudex"
    },
    {
        "label": "Caveat",
        "value": "Caveat"
    },
    {
        "label": "Caveat Brush",
        "value": "CaveatBrush"
    },
    {
        "label": "Ceviche One",
        "value": "CevicheOne"
    },
    {
        "label": "Changa-Regular",
        "value": "Changa"
    },
    {
        "label": "Changa One",
        "value": "ChangaOne"
    },
    {
        "label": "Chango Regular",
        "value": "Chango"
    },
    {
        "label": "Chathura Regular",
        "value": "Chathura"
    },
    {
        "label": "Chau Philomene One Regular",
        "value": "ChauPhilomeneOne"
    },
    {
        "label": "Chela One",
        "value": "ChelaOne"
    },
    {
        "label": "Chelsea Market",
        "value": "ChelseaMarket"
    },
    {
        "label": "Chenla",
        "value": "Chenla"
    },
    {
        "label": "Cherry Cream Soda Regular",
        "value": "CherryCreamSoda"
    },
    {
        "label": "Cherry Swash",
        "value": "CherrySwash"
    },
    {
        "label": "Chewy Regular",
        "value": "Chewy"
    },
    {
        "label": "Chicle Regular",
        "value": "Chicle"
    },
    {
        "label": "Chivo Regular",
        "value": "Chivo"
    },
    {
        "label": "Chonburi",
        "value": "Chonburi"
    },
    {
        "label": "Cinzel Regular",
        "value": "Cinzel"
    },
    {
        "label": "Cinzel Decorative Regular",
        "value": "CinzelDecorative"
    },
    {
        "label": "Clara-Regular",
        "value": "Clara"
    },
    {
        "label": "Clicker Script",
        "value": "ClickerScript"
    },
    {
        "label": "Codystar",
        "value": "Codystar"
    },
    {
        "label": "Coiny Regular",
        "value": "Coiny"
    },
    {
        "label": "Combo",
        "value": "Combo"
    },
    {
        "label": "Comfortaa Regular",
        "value": "Comfortaa"
    },
    {
        "label": "Coming Soon Regular",
        "value": "ComingSoon"
    },
    {
        "label": "Concert One",
        "value": "ConcertOne"
    },
    {
        "label": "Condiment",
        "value": "Condiment"
    },
    {
        "label": "Content",
        "value": "Content"
    },
    {
        "label": "Contrail One",
        "value": "ContrailOne"
    },
    {
        "label": "Convergence-Regular",
        "value": "Convergence"
    },
    {
        "label": "Cookie-Regular",
        "value": "Cookie"
    },
    {
        "label": "Copse",
        "value": "Copse"
    },
    {
        "label": "Corben",
        "value": "Corben"
    },
    {
        "label": "Cormorant Regular",
        "value": "Cormorant"
    },
    {
        "label": "Cormorant Garamond Regular",
        "value": "CormorantGaramond"
    },
    {
        "label": "Cormorant Infant Regular",
        "value": "CormorantInfant"
    },
    {
        "label": "Cormorant SC Regular",
        "value": "CormorantSC"
    },
    {
        "label": "Cormorant Unicase Regular",
        "value": "CormorantUnicase"
    },
    {
        "label": "Cormorant Upright Regular",
        "value": "CormorantUpright"
    },
    {
        "label": "Courgette Regular",
        "value": "Courgette"
    },
    {
        "label": "Cousine Regular",
        "value": "Cousine"
    },
    {
        "label": "Coustard Regular",
        "value": "Coustard"
    },
    {
        "label": "Covered By Your Grace",
        "value": "CoveredByYourGrace"
    },
    {
        "label": "Crafty Girls Regular",
        "value": "CraftyGirls"
    },
    {
        "label": "Creepster",
        "value": "Creepster"
    },
    {
        "label": "Creepster Caps Regular",
        "value": "CreepsterCaps"
    },
    {
        "label": "Crete Round",
        "value": "CreteRound"
    },
    {
        "label": "Crimson Text Regular",
        "value": "CrimsonText"
    },
    {
        "label": "Croissant One",
        "value": "CroissantOne"
    },
    {
        "label": "Crushed Regular",
        "value": "Crushed"
    },
    {
        "label": "Cuprum Regular",
        "value": "Cuprum"
    },
    {
        "label": "Cutive",
        "value": "Cutive"
    },
    {
        "label": "Cutive Mono Regular",
        "value": "CutiveMono"
    },
    {
        "label": "Damion",
        "value": "Damion"
    },
    {
        "label": "Dancing Script Regular",
        "value": "DancingScript"
    },
    {
        "label": "Dangrek",
        "value": "Dangrek"
    },
    {
        "label": "Dawning of a New Day",
        "value": "DawningofaNewDay"
    },
    {
        "label": "Days One",
        "value": "DaysOne"
    },
    {
        "label": "Dekko",
        "value": "Dekko"
    },
    {
        "label": "Delius-Regular",
        "value": "Delius"
    },
    {
        "label": "Delius Swash Caps",
        "value": "DeliusSwashCaps"
    },
    {
        "label": "Delius Unicase",
        "value": "DeliusUnicase"
    },
    {
        "label": "Della Respira",
        "value": "DellaRespira"
    },
    {
        "label": "Denk One",
        "value": "DenkOne"
    },
    {
        "label": "Devonshire-Regular",
        "value": "Devonshire"
    },
    {
        "label": "Dhurjati",
        "value": "Dhurjati"
    },
    {
        "label": "Dhyana",
        "value": "Dhyana"
    },
    {
        "label": "Didact Gothic Regular",
        "value": "DidactGothic"
    },
    {
        "label": "Digital Numbers",
        "value": "DigitalNumbers"
    },
    {
        "label": "Dinah VAA",
        "value": "Dinah"
    },
    {
        "label": "Diplomata",
        "value": "Diplomata"
    },
    {
        "label": "Diplomata SC",
        "value": "DiplomataSC"
    },
    {
        "label": "Domine",
        "value": "Domine"
    },
    {
        "label": "Donegal One",
        "value": "DonegalOne"
    },
    {
        "label": "Doppio One Regular",
        "value": "DoppioOne"
    },
    {
        "label": "Dorsa",
        "value": "Dorsa"
    },
    {
        "label": "Dosis Regular",
        "value": "Dosis"
    },
    {
        "label": "Dr Sugiyama Regular",
        "value": "DrSugiyama"
    },
    {
        "label": "Droid Sans Regular",
        "value": "DroidSans"
    },
    {
        "label": "Droid Sans Mono Regular",
        "value": "DroidSansMono"
    },
    {
        "label": "Droid Sans Tamil",
        "value": "DroidSansTamil"
    },
    {
        "label": "Droid Serif Regular",
        "value": "DroidSerif"
    },
    {
        "label": "Duru Sans Regular",
        "value": "DuruSans"
    },
    {
        "label": "Dynalight",
        "value": "Dynalight"
    },
    {
        "label": "EB Garamond",
        "value": "EBGaramond"
    },
    {
        "label": "Eagle Lake",
        "value": "EagleLake"
    },
    {
        "label": "Eater",
        "value": "Eater"
    },
    {
        "label": "Eater Caps Regular",
        "value": "EaterCaps"
    },
    {
        "label": "Economica",
        "value": "Economica"
    },
    {
        "label": "Eczar Regular",
        "value": "Eczar"
    },
    {
        "label": "Ek Mukta Regular",
        "value": "EkMukta"
    },
    {
        "label": "El Messiri Regular",
        "value": "ElMessiri"
    },
    {
        "label": "Electrolize",
        "value": "Electrolize"
    },
    {
        "label": "Elsie Regular",
        "value": "Elsie"
    },
    {
        "label": "Elsie Swash Caps Regular",
        "value": "ElsieSwashCaps"
    },
    {
        "label": "Emblema One",
        "value": "EmblemaOne"
    },
    {
        "label": "Emilys Candy",
        "value": "EmilysCandy"
    },
    {
        "label": "Encode Sans Regular",
        "value": "EncodeSans"
    },
    {
        "label": "Encode Sans Condensed Regular",
        "value": "EncodeSansCondensed"
    },
    {
        "label": "Encode Sans Expanded Regular",
        "value": "EncodeSansExpanded"
    },
    {
        "label": "Encode Sans Semi Condensed Regular",
        "value": "EncodeSansSemiCondensed"
    },
    {
        "label": "Encode Sans Semi Expanded Regular",
        "value": "EncodeSansSemiExpanded"
    },
    {
        "label": "Engagement",
        "value": "Engagement"
    },
    {
        "label": "Englebert",
        "value": "Englebert"
    },
    {
        "label": "Enriqueta-Regular",
        "value": "Enriqueta"
    },
    {
        "label": "Erica One",
        "value": "EricaOne"
    },
    {
        "label": "Esteban",
        "value": "Esteban"
    },
    {
        "label": "Euphoria Script",
        "value": "EuphoriaScript"
    },
    {
        "label": "Ewert",
        "value": "Ewert"
    },
    {
        "label": "Exo Regular",
        "value": "Exo"
    },
    {
        "label": "Exo 2",
        "value": "Exo2"
    },
    {
        "label": "Expletus Sans",
        "value": "ExpletusSans"
    },
    {
        "label": "Fanwood Text",
        "value": "FanwoodText"
    },
    {
        "label": "Farsan Regular",
        "value": "Farsan"
    },
    {
        "label": "Fascinate",
        "value": "Fascinate"
    },
    {
        "label": "Fascinate Inline",
        "value": "FascinateInline"
    },
    {
        "label": "Faster One",
        "value": "FasterOne"
    },
    {
        "label": "Fasthand",
        "value": "Fasthand"
    },
    {
        "label": "Fauna One",
        "value": "FaunaOne"
    },
    {
        "label": "Faustina Regular",
        "value": "Faustina"
    },
    {
        "label": "Faustina VF Beta Regular",
        "value": "FaustinaVFBeta"
    },
    {
        "label": "Federant",
        "value": "Federant"
    },
    {
        "label": "Felipa",
        "value": "Felipa"
    },
    {
        "label": "Fenix",
        "value": "Fenix"
    },
    {
        "label": "Finger Paint Regular",
        "value": "FingerPaint"
    },
    {
        "label": "Fira Mono Regular",
        "value": "FiraMono"
    },
    {
        "label": "Fira Sans Regular",
        "value": "FiraSans"
    },
    {
        "label": "Fira Sans Condensed Regular",
        "value": "FiraSansCondensed"
    },
    {
        "label": "Fira Sans Extra Condensed Regular",
        "value": "FiraSansExtraCondensed"
    },
    {
        "label": "Fjalla One",
        "value": "FjallaOne"
    },
    {
        "label": "Fjord One",
        "value": "FjordOne"
    },
    {
        "label": "Flamenco Regular",
        "value": "Flamenco"
    },
    {
        "label": "Flavors",
        "value": "Flavors"
    },
    {
        "label": "Fondamento Regular",
        "value": "Fondamento"
    },
    {
        "label": "Fontdiner Swanky Regular",
        "value": "FontdinerSwanky"
    },
    {
        "label": "Forum",
        "value": "Forum"
    },
    {
        "label": "Francois One Regular",
        "value": "FrancoisOne"
    },
    {
        "label": "Frank Ruhl Libre Regular",
        "value": "FrankRuhlLibre"
    },
    {
        "label": "Freckle Face",
        "value": "FreckleFace"
    },
    {
        "label": "Fredericka the Great",
        "value": "FrederickatheGreat"
    },
    {
        "label": "Fredoka One",
        "value": "FredokaOne"
    },
    {
        "label": "Freehand",
        "value": "Freehand"
    },
    {
        "label": "Fresca-Regular",
        "value": "Fresca"
    },
    {
        "label": "Frijole",
        "value": "Frijole"
    },
    {
        "label": "Fruktur",
        "value": "Fruktur"
    },
    {
        "label": "Fugaz One",
        "value": "FugazOne"
    },
    {
        "label": "GFS Didot Regular",
        "value": "GFSDidot"
    },
    {
        "label": "GFS Neohellenic Regular",
        "value": "GFSNeohellenic"
    },
    {
        "label": "GFS Neohellenic Bold",
        "value": "GFSNeohellenicBold"
    },
    {
        "label": "GFS Neohellenic Bold Italic",
        "value": "GFSNeohellenicBoldItalic"
    },
    {
        "label": "GFS Neohellenic Italic",
        "value": "GFSNeohellenicItalic"
    },
    {
        "label": "Gabriela Regular",
        "value": "Gabriela"
    },
    {
        "label": "Gafata",
        "value": "Gafata"
    },
    {
        "label": "Galada Regular",
        "value": "Galada"
    },
    {
        "label": "Galdeano Regular",
        "value": "Galdeano"
    },
    {
        "label": "Galindo",
        "value": "Galindo"
    },
    {
        "label": "Gemunu Libre Regular",
        "value": "GemunuLibre"
    },
    {
        "label": "Gentium Basic Bold",
        "value": "GenBasB"
    },
    {
        "label": "Gentium Basic Bold Italic",
        "value": "GenBasBI"
    },
    {
        "label": "Gentium Basic Italic",
        "value": "GenBasI"
    },
    {
        "label": "Gentium Basic",
        "value": "GenBasR"
    },
    {
        "label": "Gentium Book Basic Bold",
        "value": "GenBkBasB"
    },
    {
        "label": "Gentium Book Basic Bold Italic",
        "value": "GenBkBasBI"
    },
    {
        "label": "Gentium Book Basic Italic",
        "value": "GenBkBasI"
    },
    {
        "label": "Gentium Book Basic",
        "value": "GenBkBasR"
    },
    {
        "label": "Geo",
        "value": "Geo"
    },
    {
        "label": "Geostar",
        "value": "Geostar"
    },
    {
        "label": "Geostar Fill",
        "value": "GeostarFill"
    },
    {
        "label": "Germania One",
        "value": "GermaniaOne"
    },
    {
        "label": "Gidugu",
        "value": "Gidugu"
    },
    {
        "label": "Gilda Display",
        "value": "GildaDisplay"
    },
    {
        "label": "Give You Glory",
        "value": "GiveYouGlory"
    },
    {
        "label": "Glass Antiqua",
        "value": "GlassAntiqua"
    },
    {
        "label": "Glegoo",
        "value": "Glegoo"
    },
    {
        "label": "Gloria Hallelujah",
        "value": "GloriaHallelujah"
    },
    {
        "label": "Goblin One",
        "value": "GoblinOne"
    },
    {
        "label": "Gochi Hand",
        "value": "GochiHand"
    },
    {
        "label": "Gorditas",
        "value": "Gorditas"
    },
    {
        "label": "Goudy Bookletter 1911",
        "value": "GoudyBookletter1911"
    },
    {
        "label": "Graduate",
        "value": "Graduate"
    },
    {
        "label": "Grand Hotel",
        "value": "GrandHotel"
    },
    {
        "label": "Gravitas One",
        "value": "GravitasOne"
    },
    {
        "label": "Great Vibes",
        "value": "GreatVibes"
    },
    {
        "label": "Griffy",
        "value": "Griffy"
    },
    {
        "label": "Gruppo",
        "value": "Gruppo"
    },
    {
        "label": "Gudea",
        "value": "Gudea"
    },
    {
        "label": "Gurajada",
        "value": "Gurajada"
    },
    {
        "label": "Habibi",
        "value": "Habibi"
    },
    {
        "label": "Halant Regular",
        "value": "Halant"
    },
    {
        "label": "HammersmithOne Regular",
        "value": "HammersmithOne"
    },
    {
        "label": "Hanalei",
        "value": "Hanalei"
    },
    {
        "label": "Hanalei Fill",
        "value": "HanaleiFill"
    },
    {
        "label": "Handlee Regular",
        "value": "Handlee"
    },
    {
        "label": "Hanuman Regular",
        "value": "Hanuman"
    },
    {
        "label": "Happy Monkey",
        "value": "HappyMonkey"
    },
    {
        "label": "Harmattan Regular",
        "value": "Harmattan"
    },
    {
        "label": "HeadlandOne-Regular",
        "value": "HeadlandOne"
    },
    {
        "label": "Heebo Regular",
        "value": "Heebo"
    },
    {
        "label": "Henny Penny",
        "value": "HennyPenny"
    },
    {
        "label": "Hermeneus One",
        "value": "HermeneusOne"
    },
    {
        "label": "Herr Von Muellerhoff Regular",
        "value": "HerrVonMuellerhoff"
    },
    {
        "label": "Hind Regular",
        "value": "Hind"
    },
    {
        "label": "Hind Colombo",
        "value": "HindColombo"
    },
    {
        "label": "Hind Guntur Regular",
        "value": "HindGuntur"
    },
    {
        "label": "Hind Jalandhar",
        "value": "HindJalandhar"
    },
    {
        "label": "Hind Kochi",
        "value": "HindKochi"
    },
    {
        "label": "Hind Madurai Regular",
        "value": "HindMadurai"
    },
    {
        "label": "Hind Mysuru",
        "value": "HindMysuru"
    },
    {
        "label": "Hind Siliguri Regular",
        "value": "HindSiliguri"
    },
    {
        "label": "Hind Vadodara Regular",
        "value": "HindVadodara"
    },
    {
        "label": "Holtwood One SC",
        "value": "HoltwoodOneSC"
    },
    {
        "label": "Homemade Apple Regular",
        "value": "HomemadeApple"
    },
    {
        "label": "Homenaje Regular",
        "value": "Homenaje"
    },
    {
        "label": "IM FELL Double Pica Italic",
        "value": "IMFeDPit28P"
    },
    {
        "label": "IM FELL Double Pica Roman ",
        "value": "IMFeDPrm28P"
    },
    {
        "label": "IM FELL Double Pica SC",
        "value": "IMFeDPsc28P"
    },
    {
        "label": "IM FELL English Italic",
        "value": "IMFeENit28P"
    },
    {
        "label": "IM FELL English Roman",
        "value": "IMFeENrm28P"
    },
    {
        "label": "IM FELL English SC",
        "value": "IMFeENsc28P"
    },
    {
        "label": "IM FELL French Canon Italic",
        "value": "IMFeFCit28P"
    },
    {
        "label": "IM FELL French Canon Roman",
        "value": "IMFeFCrm28P"
    },
    {
        "label": "IM FELL French Canon SC",
        "value": "IMFeFCsc28P"
    },
    {
        "label": "IM FELL Great Primer Italic",
        "value": "IMFeGPit28P"
    },
    {
        "label": "IM FELL Great Primer Roman",
        "value": "IMFeGPrm28P"
    },
    {
        "label": "IM FELL Great Primer SC",
        "value": "IMFeGPsc28P"
    },
    {
        "label": "IM FELL DW Pica Italic",
        "value": "IMFePIit28P"
    },
    {
        "label": "IM FELL DW Pica Roman",
        "value": "IMFePIrm28P"
    },
    {
        "label": "IM FELL DW Pica SC",
        "value": "IMFePIsc28P"
    },
    {
        "label": "Iceberg",
        "value": "Iceberg"
    },
    {
        "label": "Iceland",
        "value": "Iceland"
    },
    {
        "label": "Imprima",
        "value": "Imprima"
    },
    {
        "label": "Inconsolata Regular",
        "value": "Inconsolata"
    },
    {
        "label": "Inder",
        "value": "Inder"
    },
    {
        "label": "Indie Flower Regular",
        "value": "IndieFlower"
    },
    {
        "label": "Inika",
        "value": "Inika"
    },
    {
        "label": "Inknut Antiqua Regular",
        "value": "InknutAntiqua"
    },
    {
        "label": "Irish Grover Regular",
        "value": "IrishGrover"
    },
    {
        "label": "Istok Web Regular",
        "value": "IstokWeb"
    },
    {
        "label": "Italiana",
        "value": "Italiana"
    },
    {
        "label": "Italianno",
        "value": "Italianno"
    },
    {
        "label": "Itim",
        "value": "Itim"
    },
    {
        "label": "Jacques Francois",
        "value": "JacquesFrancois"
    },
    {
        "label": "Jacques Francois Shadow",
        "value": "JacquesFrancoisShadow"
    },
    {
        "label": "Jaldi",
        "value": "Jaldi"
    },
    {
        "label": "JejuGothic",
        "value": "JejuGothic"
    },
    {
        "label": "JejuHallasan",
        "value": "JejuHallasan"
    },
    {
        "label": "JejuMyeongjo",
        "value": "JejuMyeongjo"
    },
    {
        "label": "Jim Nightshade",
        "value": "JimNightshade"
    },
    {
        "label": "Jockey One",
        "value": "JockeyOne"
    },
    {
        "label": "Jolly Lodger",
        "value": "JollyLodger"
    },
    {
        "label": "Jomhuria Regular",
        "value": "Jomhuria"
    },
    {
        "label": "Josefin Sans Regular",
        "value": "JosefinSans"
    },
    {
        "label": "Josefin Slab Regular",
        "value": "JosefinSlab"
    },
    {
        "label": "Joti One",
        "value": "JotiOne"
    },
    {
        "label": "Judson Regular",
        "value": "Judson"
    },
    {
        "label": "Julee-Regular",
        "value": "Julee"
    },
    {
        "label": "Julius Sans One",
        "value": "JuliusSansOne"
    },
    {
        "label": "Junge",
        "value": "Junge"
    },
    {
        "label": "Jura Regular",
        "value": "Jura"
    },
    {
        "label": "Just Another Hand Regular",
        "value": "JustAnotherHand"
    },
    {
        "label": "Just Me Again Down Here",
        "value": "JustMeAgainDownHere"
    },
    {
        "label": "Kadwa",
        "value": "Kadwa"
    },
    {
        "label": "Kalam",
        "value": "Kalam"
    },
    {
        "label": "Kameron",
        "value": "Kameron"
    },
    {
        "label": "Kanit Regular",
        "value": "Kanit"
    },
    {
        "label": "Kantumruy",
        "value": "Kantumruy"
    },
    {
        "label": "Karla",
        "value": "Karla"
    },
    {
        "label": "Karla Tamil Inclined",
        "value": "KarlaTamilInclined"
    },
    {
        "label": "Karla Tamil Upright",
        "value": "KarlaTamilUpright"
    },
    {
        "label": "Karma Regular",
        "value": "Karma"
    },
    {
        "label": "Katibeh Regular",
        "value": "Katibeh"
    },
    {
        "label": "Kaushan Script",
        "value": "KaushanScript"
    },
    {
        "label": "Kavivanar Regular",
        "value": "Kavivanar"
    },
    {
        "label": "Kavoon",
        "value": "Kavoon"
    },
    {
        "label": "Kdam Thmor",
        "value": "KdamThmor"
    },
    {
        "label": "Keania One",
        "value": "KeaniaOne"
    },
    {
        "label": "Kelly Slab",
        "value": "KellySlab"
    },
    {
        "label": "Kenia",
        "value": "Kenia"
    },
    {
        "label": "Khand Regular",
        "value": "Khand"
    },
    {
        "label": "Khmer",
        "value": "Khmer"
    },
    {
        "label": "Khula Regular",
        "value": "Khula"
    },
    {
        "label": "Myanmar Khyay",
        "value": "Khyay"
    },
    {
        "label": "Kite One",
        "value": "KiteOne"
    },
    {
        "label": "Knewave",
        "value": "Knewave"
    },
    {
        "label": "KoPubBatang",
        "value": "KoPubBatang"
    },
    {
        "label": "Kotta One",
        "value": "KottaOne"
    },
    {
        "label": "Koulen",
        "value": "Koulen"
    },
    {
        "label": "Kranky Regular",
        "value": "Kranky"
    },
    {
        "label": "Kreon Regular",
        "value": "Kreon"
    },
    {
        "label": "Kristi Regular",
        "value": "Kristi"
    },
    {
        "label": "Krona One",
        "value": "KronaOne"
    },
    {
        "label": "Kumar One Regular",
        "value": "KumarOne"
    },
    {
        "label": "Kurale Regular",
        "value": "Kurale"
    },
    {
        "label": "La Belle Aurore",
        "value": "LaBelleAurore"
    },
    {
        "label": "Laila Regular",
        "value": "Laila"
    },
    {
        "label": "Lakki Reddy",
        "value": "LakkiReddy"
    },
    {
        "label": "Lalezar Regular",
        "value": "Lalezar"
    },
    {
        "label": "Lancelot",
        "value": "Lancelot"
    },
    {
        "label": "Lateef",
        "value": "LateefRegOT"
    },
    {
        "label": "Lato Regular",
        "value": "Lato"
    },
    {
        "label": "League Script",
        "value": "LeagueScript"
    },
    {
        "label": "Leckerli One",
        "value": "LeckerliOne"
    },
    {
        "label": "Ledger",
        "value": "Ledger"
    },
    {
        "label": "Lekton",
        "value": "Lekton"
    },
    {
        "label": "Lemon Regular",
        "value": "Lemon"
    },
    {
        "label": "Lemonada Regular",
        "value": "Lemonada"
    },
    {
        "label": "Libre Barcode 128 Regular",
        "value": "LibreBarcode128"
    },
    {
        "label": "Libre Barcode 128 Text Regular",
        "value": "LibreBarcode128Text"
    },
    {
        "label": "Libre Barcode 39 Regular",
        "value": "LibreBarcode39"
    },
    {
        "label": "Libre Barcode 39 Extended Regular",
        "value": "LibreBarcode39Extended"
    },
    {
        "label": "Libre Barcode 39 Extended Text Regular",
        "value": "LibreBarcode39ExtendedText"
    },
    {
        "label": "Libre Barcode 39 Text Regular",
        "value": "LibreBarcode39Text"
    },
    {
        "label": "Libre Baskerville",
        "value": "LibreBaskerville"
    },
    {
        "label": "Libre Caslon Text",
        "value": "LibreCaslonText"
    },
    {
        "label": "LibreFranklin-Regular",
        "value": "LibreFranklin"
    },
    {
        "label": "Life Savers",
        "value": "LifeSavers"
    },
    {
        "label": "Lilita One",
        "value": "LilitaOne"
    },
    {
        "label": "Lily Script One",
        "value": "LilyScriptOne"
    },
    {
        "label": "Limelight Regular",
        "value": "Limelight"
    },
    {
        "label": "Linden Hill Regular",
        "value": "LindenHill"
    },
    {
        "label": "Lobster Regular",
        "value": "Lobster"
    },
    {
        "label": "Lobster Two",
        "value": "LobsterTwo"
    },
    {
        "label": "Londrina Outline Regular",
        "value": "LondrinaOutline"
    },
    {
        "label": "Londrina Shadow Regular",
        "value": "LondrinaShadow"
    },
    {
        "label": "Londrina Sketch Regular",
        "value": "LondrinaSketch"
    },
    {
        "label": "Londrina Solid Regular",
        "value": "LondrinaSolid"
    },
    {
        "label": "Lora Regular",
        "value": "Lora"
    },
    {
        "label": "Love Ya Like A Sister Regular",
        "value": "LoveYaLikeASister"
    },
    {
        "label": "Loved by the King",
        "value": "LovedbytheKing"
    },
    {
        "label": "Lovers Quarrel",
        "value": "LoversQuarrel"
    },
    {
        "label": "Luckiest Guy Regular",
        "value": "LuckiestGuy"
    },
    {
        "label": "Lusitana",
        "value": "Lusitana"
    },
    {
        "label": "Lustria",
        "value": "Lustria"
    },
    {
        "label": "Macondo",
        "value": "Macondo"
    },
    {
        "label": "Macondo Swash Caps",
        "value": "MacondoSwashCaps"
    },
    {
        "label": "Mada Regular",
        "value": "Mada"
    },
    {
        "label": "Magra",
        "value": "Magra"
    },
    {
        "label": "Maiden Orange Regular",
        "value": "MaidenOrange"
    },
    {
        "label": "Maitree Regular",
        "value": "Maitree"
    },
    {
        "label": "Mako",
        "value": "Mako"
    },
    {
        "label": "Mallanna",
        "value": "Mallanna"
    },
    {
        "label": "Mandali",
        "value": "Mandali"
    },
    {
        "label": "Manuale Regular",
        "value": "Manuale"
    },
    {
        "label": "Marcellus",
        "value": "Marcellus"
    },
    {
        "label": "Marcellus SC",
        "value": "MarcellusSC"
    },
    {
        "label": "Marck Script",
        "value": "MarckScript"
    },
    {
        "label": "Margarine",
        "value": "Margarine"
    },
    {
        "label": "Marko One",
        "value": "MarkoOne"
    },
    {
        "label": "Marmelad Regular",
        "value": "Marmelad"
    },
    {
        "label": "Martel",
        "value": "Martel"
    },
    {
        "label": "Martel Sans Regular",
        "value": "MartelSans"
    },
    {
        "label": "Marvel",
        "value": "Marvel"
    },
    {
        "label": "Mate-Regular",
        "value": "Mate"
    },
    {
        "label": "Mate SC Regular",
        "value": "MateSC"
    },
    {
        "label": "Maven Pro Regular",
        "value": "MavenPro"
    },
    {
        "label": "Maven Pro VF Beta Regular",
        "value": "MavenProVFBeta"
    },
    {
        "label": "McLaren",
        "value": "McLaren"
    },
    {
        "label": "Meddon",
        "value": "Meddon"
    },
    {
        "label": "MedievalSharp",
        "value": "MedievalSharp"
    },
    {
        "label": "Medula One",
        "value": "MedulaOne"
    },
    {
        "label": "Meera Inimai Regular",
        "value": "MeeraInimai"
    },
    {
        "label": "Megrim",
        "value": "Megrim"
    },
    {
        "label": "Meie Script",
        "value": "MeieScript"
    },
    {
        "label": "Merge One",
        "value": "MergeOne"
    },
    {
        "label": "Merienda",
        "value": "Merienda"
    },
    {
        "label": "Merienda One",
        "value": "MeriendaOne"
    },
    {
        "label": "Merriweather",
        "value": "Merriweather"
    },
    {
        "label": "Merriweather Sans",
        "value": "MerriweatherSans"
    },
    {
        "label": "Mervale Script",
        "value": "MervaleScript"
    },
    {
        "label": "Metal",
        "value": "Metal"
    },
    {
        "label": "Metal Mania",
        "value": "MetalMania"
    },
    {
        "label": "Metamorphous",
        "value": "Metamorphous"
    },
    {
        "label": "Metrophobic Regular",
        "value": "Metrophobic"
    },
    {
        "label": "Miama",
        "value": "Miama"
    },
    {
        "label": "Michroma",
        "value": "Michroma"
    },
    {
        "label": "Milonga",
        "value": "Milonga"
    },
    {
        "label": "Miltonian",
        "value": "Miltonian"
    },
    {
        "label": "Miltonian Tattoo",
        "value": "MiltonianTattoo"
    },
    {
        "label": "Mina Regular",
        "value": "Mina"
    },
    {
        "label": "Miniver",
        "value": "Miniver"
    },
    {
        "label": "Miriam Libre Regular",
        "value": "MiriamLibre"
    },
    {
        "label": "Mirza Regular",
        "value": "Mirza"
    },
    {
        "label": "Miss Fajardose Regular",
        "value": "MissFajardose"
    },
    {
        "label": "Mitr Regular",
        "value": "Mitr"
    },
    {
        "label": "Modak",
        "value": "Modak"
    },
    {
        "label": "Modern Antiqua Regular",
        "value": "ModernAntiqua"
    },
    {
        "label": "Mogra Regular",
        "value": "Mogra"
    },
    {
        "label": "Molengo",
        "value": "Molengo"
    },
    {
        "label": "Molle",
        "value": "Molle"
    },
    {
        "label": "Monda Regular",
        "value": "Monda"
    },
    {
        "label": "Monofett",
        "value": "Monofett"
    },
    {
        "label": "Monoton",
        "value": "Monoton"
    },
    {
        "label": "Monsieur La Doulaise Regular",
        "value": "MonsieurLaDoulaise"
    },
    {
        "label": "Montaga",
        "value": "Montaga"
    },
    {
        "label": "Montez Regular",
        "value": "Montez"
    },
    {
        "label": "Montserrat Regular",
        "value": "Montserrat"
    },
    {
        "label": "Montserrat Extra Bold",
        "value": "Montserrat-ExtraBold"
    },
    {
        "label": "Montserrat Extra Bold Italic",
        "value": "Montserrat-ExtraBoldItalic"
    },
    {
        "label": "Montserrat Alternates Regular",
        "value": "MontserratAlternates"
    },
    {
        "label": "MontserratSubrayada-Regular",
        "value": "MontserratSubrayada"
    },
    {
        "label": "Moul",
        "value": "Moul"
    },
    {
        "label": "Moul Pali",
        "value": "Moulpali"
    },
    {
        "label": "Mountains of Christmas Regular",
        "value": "MountainsofChristmas"
    },
    {
        "label": "Mouse Memoirs",
        "value": "MouseMemoirs"
    },
    {
        "label": "Mplus 1p",
        "value": "Mplus1p"
    },
    {
        "label": "Mr Bedfort",
        "value": "MrBedfort"
    },
    {
        "label": "Mr Dafoe Regular",
        "value": "MrDafoe"
    },
    {
        "label": "Mr De Haviland Regular",
        "value": "MrDeHaviland"
    },
    {
        "label": "Mrs Saint Delafield",
        "value": "MrsSaintDelafield"
    },
    {
        "label": "Mrs Sheppards Regular",
        "value": "MrsSheppards"
    },
    {
        "label": "Mukta Regular",
        "value": "Mukta"
    },
    {
        "label": "Mukta Mahee Regular",
        "value": "MuktaMahee"
    },
    {
        "label": "Mukta Malar Regular",
        "value": "MuktaMalar"
    },
    {
        "label": "Mukta Vaani Regular",
        "value": "MuktaVaani"
    },
    {
        "label": "Muli Regular",
        "value": "Muli"
    },
    {
        "label": "Mystery Quest",
        "value": "MysteryQuest"
    },
    {
        "label": "NATS",
        "value": "NATS"
    },
    {
        "label": "NTR",
        "value": "NTR"
    },
    {
        "label": "NanumBrushScript",
        "value": "NanumBrushScript"
    },
    {
        "label": "NanumGothic",
        "value": "NanumGothic"
    },
    {
        "label": "NanumGothicCoding",
        "value": "NanumGothicCoding"
    },
    {
        "label": "NanumMyeongjo",
        "value": "NanumMyeongjo"
    },
    {
        "label": "NanumPenScript",
        "value": "NanumPenScript"
    },
    {
        "label": "Neucha",
        "value": "Neucha"
    },
    {
        "label": "Neuton Regular",
        "value": "Neuton"
    },
    {
        "label": "New Rocker",
        "value": "NewRocker"
    },
    {
        "label": "News Cycle",
        "value": "NewsCycle"
    },
    {
        "label": "NicoMoji",
        "value": "NicoMoji"
    },
    {
        "label": "Niconne",
        "value": "Niconne"
    },
    {
        "label": "Nixie One",
        "value": "NixieOne"
    },
    {
        "label": "Nobile",
        "value": "Nobile"
    },
    {
        "label": "Nokora Regular",
        "value": "Nokora"
    },
    {
        "label": "Norican Regular",
        "value": "Norican"
    },
    {
        "label": "Nosifer",
        "value": "Nosifer"
    },
    {
        "label": "Nosifer Caps Regular",
        "value": "NosiferCaps"
    },
    {
        "label": "Nothing You Could Do",
        "value": "NothingYouCouldDo"
    },
    {
        "label": "Noticia Text",
        "value": "NoticiaText"
    },
    {
        "label": "Noto Sans",
        "value": "NotoSans"
    },
    {
        "label": "Noto Sans Tamil Regular",
        "value": "NotoSansTamil"
    },
    {
        "label": "Noto Serif",
        "value": "NotoSerif"
    },
    {
        "label": "Nova Cut",
        "value": "NovaCut"
    },
    {
        "label": "Nova Flat",
        "value": "NovaFlat"
    },
    {
        "label": "NovaMono",
        "value": "NovaMono"
    },
    {
        "label": "Nova Oval",
        "value": "NovaOval"
    },
    {
        "label": "Nova Round",
        "value": "NovaRound"
    },
    {
        "label": "Nova Script Regular",
        "value": "NovaScript"
    },
    {
        "label": "Nova Slim",
        "value": "NovaSlim"
    },
    {
        "label": "Nova Square",
        "value": "NovaSquare"
    },
    {
        "label": "Numans",
        "value": "Numans"
    },
    {
        "label": "Nunito Regular",
        "value": "Nunito"
    },
    {
        "label": "Nunito Sans Regular",
        "value": "NunitoSans"
    },
    {
        "label": "OFL Sorts Mill Goudy TT",
        "value": "OFLGoudyStMTT"
    },
    {
        "label": "OdorMeanChey",
        "value": "OdorMeanChey"
    },
    {
        "label": "Offside",
        "value": "Offside"
    },
    {
        "label": "Old Standard TT Regular",
        "value": "OldStandard"
    },
    {
        "label": "Oldenburg",
        "value": "Oldenburg"
    },
    {
        "label": "Oleo Script",
        "value": "OleoScript"
    },
    {
        "label": "Oleo Script Swash Caps",
        "value": "OleoScriptSwashCaps"
    },
    {
        "label": "Open Sans Hebrew",
        "value": "OpenSansHebrew"
    },
    {
        "label": "Open Sans Hebrew Condensed",
        "value": "OpenSansHebrewCondensed"
    },
    {
        "label": "Oranienbaum",
        "value": "Oranienbaum"
    },
    {
        "label": "Orbitron Regular",
        "value": "Orbitron"
    },
    {
        "label": "Oregano",
        "value": "Oregano"
    },
    {
        "label": "Orienta",
        "value": "Orienta"
    },
    {
        "label": "Original Surfer",
        "value": "OriginalSurfer"
    },
    {
        "label": "Oswald Regular",
        "value": "Oswald"
    },
    {
        "label": "Overlock Regular",
        "value": "Overlock"
    },
    {
        "label": "Overlock SC",
        "value": "OverlockSC"
    },
    {
        "label": "Overpass Regular",
        "value": "Overpass"
    },
    {
        "label": "Overpass Mono Regular",
        "value": "OverpassMono"
    },
    {
        "label": "Over the Rainbow",
        "value": "OvertheRainbow"
    },
    {
        "label": "Ovo",
        "value": "Ovo"
    },
    {
        "label": "Oxygen Regular",
        "value": "Oxygen"
    },
    {
        "label": "Oxygen Mono",
        "value": "OxygenMono"
    },
    {
        "label": "PT Mono",
        "value": "PTM55FT"
    },
    {
        "label": "Pacifico Regular",
        "value": "Pacifico"
    },
    {
        "label": "Padauk",
        "value": "Padauk"
    },
    {
        "label": "Palanquin Regular",
        "value": "Palanquin"
    },
    {
        "label": "Palanquin Dark Regular",
        "value": "PalanquinDark"
    },
    {
        "label": "Pangolin Regular",
        "value": "Pangolin"
    },
    {
        "label": "Paprika",
        "value": "Paprika"
    },
    {
        "label": "Parisienne",
        "value": "Parisienne"
    },
    {
        "label": "Passero One",
        "value": "PasseroOne"
    },
    {
        "label": "Passion One Regular",
        "value": "PassionOne"
    },
    {
        "label": "Pathway Gothic One Regular",
        "value": "PathwayGothicOne"
    },
    {
        "label": "Patrick Hand",
        "value": "PatrickHand"
    },
    {
        "label": "Patrick Hand SC",
        "value": "PatrickHandSC"
    },
    {
        "label": "Pattaya Regular",
        "value": "Pattaya"
    },
    {
        "label": "Patua One",
        "value": "PatuaOne"
    },
    {
        "label": "Pavanam Regular",
        "value": "Pavanam"
    },
    {
        "label": "Paytone One Regular",
        "value": "PaytoneOne"
    },
    {
        "label": "Pecita",
        "value": "Pecita"
    },
    {
        "label": "Peddana Regular",
        "value": "Peddana"
    },
    {
        "label": "Peralta",
        "value": "Peralta"
    },
    {
        "label": "Permanent Marker Regular",
        "value": "PermanentMarker"
    },
    {
        "label": "Petit Formal Script",
        "value": "PetitFormalScript"
    },
    {
        "label": "Petrona Regular",
        "value": "Petrona"
    },
    {
        "label": "Phetsarath Regular",
        "value": "Phetsarath"
    },
    {
        "label": "Philosopher Regular",
        "value": "Philosopher"
    },
    {
        "label": "Piedra Regular",
        "value": "Piedra"
    },
    {
        "label": "Pinyon Script",
        "value": "PinyonScript"
    },
    {
        "label": "Pirata One",
        "value": "PirataOne"
    },
    {
        "label": "Plaster",
        "value": "Plaster"
    },
    {
        "label": "Play Regular",
        "value": "Play"
    },
    {
        "label": "Playball",
        "value": "Playball"
    },
    {
        "label": "Playfair Display",
        "value": "PlayfairDisplay"
    },
    {
        "label": "Podkova Regular",
        "value": "Podkova"
    },
    {
        "label": "Podkova VF Beta Regular",
        "value": "PodkovaVFBeta"
    },
    {
        "label": "PoetsenOne",
        "value": "PoetsenOne"
    },
    {
        "label": "Poiret One Regular",
        "value": "PoiretOne"
    },
    {
        "label": "Poller One",
        "value": "PollerOne"
    },
    {
        "label": "Poly-Regular",
        "value": "Poly"
    },
    {
        "label": "Pompiere ",
        "value": "Pompiere"
    },
    {
        "label": "Ponnala",
        "value": "Ponnala"
    },
    {
        "label": "Pontano Sans",
        "value": "PontanoSans"
    },
    {
        "label": "Poppins Regular",
        "value": "Poppins"
    },
    {
        "label": "Port Lligat Sans",
        "value": "PortLligatSans"
    },
    {
        "label": "Port Lligat Slab",
        "value": "PortLligatSlab"
    },
    {
        "label": "PorterSansBlock",
        "value": "PorterSansBlock"
    },
    {
        "label": "Post No Bills Colombo Regular",
        "value": "PostNoBillsColombo"
    },
    {
        "label": "Post No Bills Jaffna Regular",
        "value": "PostNoBillsJaffna"
    },
    {
        "label": "Pragati Narrow",
        "value": "PragatiNarrow"
    },
    {
        "label": "Prata Regular",
        "value": "Prata"
    },
    {
        "label": "Preah Vihear",
        "value": "Preahvihear"
    },
    {
        "label": "Press Start 2P Regular",
        "value": "PressStart2P"
    },
    {
        "label": "Pridi Regular",
        "value": "Pridi"
    },
    {
        "label": "Princess Sofia",
        "value": "PrincessSofia"
    },
    {
        "label": "Prociono",
        "value": "Prociono"
    },
    {
        "label": "Prompt Regular",
        "value": "Prompt"
    },
    {
        "label": "Prosto One",
        "value": "ProstoOne"
    },
    {
        "label": "Proza Libre Regular",
        "value": "ProzaLibre"
    },
    {
        "label": "Puritan Regular",
        "value": "Puritan"
    },
    {
        "label": "Purple Purse",
        "value": "PurplePurse"
    },
    {
        "label": "Quando-Regular",
        "value": "Quando"
    },
    {
        "label": "Quantico",
        "value": "Quantico"
    },
    {
        "label": "Quattrocento",
        "value": "Quattrocento"
    },
    {
        "label": "Quattrocento Sans",
        "value": "QuattrocentoSans"
    },
    {
        "label": "Questrial",
        "value": "Questrial"
    },
    {
        "label": "Quicksand Regular",
        "value": "Quicksand"
    },
    {
        "label": "Quintessential",
        "value": "Quintessential"
    },
    {
        "label": "Qwigley",
        "value": "Qwigley"
    },
    {
        "label": "Racing Sans One",
        "value": "RacingSansOne"
    },
    {
        "label": "Radley Regular",
        "value": "Radley"
    },
    {
        "label": "Rajdhani Regular",
        "value": "Rajdhani"
    },
    {
        "label": "Rakkas Regular",
        "value": "Rakkas"
    },
    {
        "label": "Raleway",
        "value": "Raleway"
    },
    {
        "label": "Raleway Dots ",
        "value": "RalewayDots"
    },
    {
        "label": "Ramabhadra",
        "value": "Ramabhadra"
    },
    {
        "label": "Ramaraja",
        "value": "Ramaraja"
    },
    {
        "label": "Rambla",
        "value": "Rambla"
    },
    {
        "label": "Rammetto One Regular",
        "value": "RammettoOne"
    },
    {
        "label": "Ranchers",
        "value": "Ranchers"
    },
    {
        "label": "Rancho Regular",
        "value": "Rancho"
    },
    {
        "label": "Ranga Regular",
        "value": "Ranga"
    },
    {
        "label": "Rasa Regular",
        "value": "Rasa"
    },
    {
        "label": "Rationale",
        "value": "Rationale"
    },
    {
        "label": "Ravi Prakash",
        "value": "RaviPrakash"
    },
    {
        "label": "Redacted Script Regular",
        "value": "RedactedScript"
    },
    {
        "label": "Redressed Regular",
        "value": "Redressed"
    },
    {
        "label": "Reem Kufi Regular",
        "value": "ReemKufi"
    },
    {
        "label": "Reenie Beanie",
        "value": "ReenieBeanie"
    },
    {
        "label": "Revalia-Regular",
        "value": "Revalia"
    },
    {
        "label": "Rhodium Libre",
        "value": "RhodiumLibre"
    },
    {
        "label": "Ribeye",
        "value": "Ribeye"
    },
    {
        "label": "Ribeye Marrow",
        "value": "RibeyeMarrow"
    },
    {
        "label": "Righteous",
        "value": "Righteous"
    },
    {
        "label": "Risque",
        "value": "Risque"
    },
    {
        "label": "Roboto",
        "value": "Roboto"
    },
    {
        "label": "Roboto Condensed",
        "value": "RobotoCondensed"
    },
    {
        "label": "Roboto Mono",
        "value": "RobotoMono"
    },
    {
        "label": "Roboto Slab Regular",
        "value": "RobotoSlab"
    },
    {
        "label": "Rochester Regular",
        "value": "Rochester"
    },
    {
        "label": "Rock Salt Regular",
        "value": "RockSalt"
    },
    {
        "label": "Rokkitt Regular",
        "value": "Rokkitt"
    },
    {
        "label": "Romanesco",
        "value": "Romanesco"
    },
    {
        "label": "Ropa Sans Regular",
        "value": "RopaSans"
    },
    {
        "label": "Rosario",
        "value": "Rosario"
    },
    {
        "label": "Rosarivo",
        "value": "Rosarivo"
    },
    {
        "label": "Rouge Script",
        "value": "RougeScript"
    },
    {
        "label": "Rounded Mplus 1c",
        "value": "RoundedMplus1c"
    },
    {
        "label": "Rozha One Regular",
        "value": "RozhaOne"
    },
    {
        "label": "Rubik Regular",
        "value": "Rubik"
    },
    {
        "label": "Rubik Mono One Regular",
        "value": "RubikMonoOne"
    },
    {
        "label": "Rubik One Regular",
        "value": "RubikOne"
    },
    {
        "label": "Ruda Regular",
        "value": "Ruda"
    },
    {
        "label": "Rufina",
        "value": "Rufina"
    },
    {
        "label": "Ruge Boogie",
        "value": "RugeBoogie"
    },
    {
        "label": "Ruluko",
        "value": "Ruluko"
    },
    {
        "label": "Rum Raisin",
        "value": "RumRaisin"
    },
    {
        "label": "Ruslan Display",
        "value": "RuslanDisplay"
    },
    {
        "label": "Russo One",
        "value": "RussoOne"
    },
    {
        "label": "Ruthie",
        "value": "Ruthie"
    },
    {
        "label": "Rye Regular",
        "value": "Rye"
    },
    {
        "label": "Sacramento",
        "value": "Sacramento"
    },
    {
        "label": "Sahitya",
        "value": "Sahitya"
    },
    {
        "label": "Sail",
        "value": "Sail"
    },
    {
        "label": "Saira Regular",
        "value": "Saira"
    },
    {
        "label": "Saira Condensed Regular",
        "value": "SairaCondensed"
    },
    {
        "label": "Saira ExtraCondensed Regular",
        "value": "SairaExtraCondensed"
    },
    {
        "label": "Saira SemiCondensed Regular",
        "value": "SairaSemiCondensed"
    },
    {
        "label": "Salsa-Regular",
        "value": "Salsa"
    },
    {
        "label": "Sanchez",
        "value": "Sanchez"
    },
    {
        "label": "Sancreek Regular",
        "value": "Sancreek"
    },
    {
        "label": "Sansation Regular",
        "value": "Sansation"
    },
    {
        "label": "Sansita Regular",
        "value": "Sansita"
    },
    {
        "label": "Sansita One Regular",
        "value": "SansitaOne"
    },
    {
        "label": "Sarabun",
        "value": "Sarabun"
    },
    {
        "label": "Sarala",
        "value": "Sarala"
    },
    {
        "label": "Sarina",
        "value": "Sarina"
    },
    {
        "label": "Sarpanch Regular",
        "value": "Sarpanch"
    },
    {
        "label": "Satisfy Regular",
        "value": "Satisfy"
    },
    {
        "label": "SawarabiGothic",
        "value": "SawarabiGothic"
    },
    {
        "label": "SawarabiMincho",
        "value": "SawarabiMincho"
    },
    {
        "label": "Scada Regular",
        "value": "Scada"
    },
    {
        "label": "Scheherazade",
        "value": "Scheherazade"
    },
    {
        "label": "Schoolbell Regular",
        "value": "Schoolbell"
    },
    {
        "label": "Scope One Regular",
        "value": "ScopeOne"
    },
    {
        "label": "Seaweed Script",
        "value": "SeaweedScript"
    },
    {
        "label": "Secular One Regular",
        "value": "SecularOne"
    },
    {
        "label": "Sedan",
        "value": "Sedan"
    },
    {
        "label": "Sedan SC",
        "value": "SedanSC"
    },
    {
        "label": "Sedgwick Ave Regular",
        "value": "SedgwickAve"
    },
    {
        "label": "Sedgwick Ave Display Regular",
        "value": "SedgwickAveDisplay"
    },
    {
        "label": "SeoulNamsanVertical",
        "value": "SeoulNamsanVertical"
    },
    {
        "label": "Sevillana",
        "value": "Sevillana"
    },
    {
        "label": "Seymour One",
        "value": "SeymourOne"
    },
    {
        "label": "Shadows Into Light",
        "value": "ShadowsIntoLight"
    },
    {
        "label": "Shadows Into Light Two",
        "value": "ShadowsIntoLightTwo"
    },
    {
        "label": "Shanti",
        "value": "Shanti"
    },
    {
        "label": "Share Regular",
        "value": "Share"
    },
    {
        "label": "Share Tech Regular",
        "value": "ShareTech"
    },
    {
        "label": "Share Tech Mono",
        "value": "ShareTechMono"
    },
    {
        "label": "Shojumaru",
        "value": "Shojumaru"
    },
    {
        "label": "Short Stack",
        "value": "ShortStack"
    },
    {
        "label": "Shrikhand Regular",
        "value": "Shrikhand"
    },
    {
        "label": "Siemreap",
        "value": "Siemreap"
    },
    {
        "label": "Sigmar One Regular",
        "value": "SigmarOne"
    },
    {
        "label": "Signika Regular",
        "value": "Signika"
    },
    {
        "label": "Signika Negative Regular",
        "value": "SignikaNegative"
    },
    {
        "label": "Simonetta Regular",
        "value": "Simonetta"
    },
    {
        "label": "Sintony",
        "value": "Sintony"
    },
    {
        "label": "SirinStencil",
        "value": "SirinStencil"
    },
    {
        "label": "Sitara",
        "value": "Sitara"
    },
    {
        "label": "Six Caps",
        "value": "SixCaps"
    },
    {
        "label": "Skranji",
        "value": "Skranji"
    },
    {
        "label": "Slabo 13px",
        "value": "Slabo13px"
    },
    {
        "label": "Slabo 27px",
        "value": "Slabo27px"
    },
    {
        "label": "Slackey Regular",
        "value": "Slackey"
    },
    {
        "label": "Smokum Regular",
        "value": "Smokum"
    },
    {
        "label": "Smythe",
        "value": "Smythe"
    },
    {
        "label": "Sniglet Regular",
        "value": "Sniglet"
    },
    {
        "label": "Snippet",
        "value": "Snippet"
    },
    {
        "label": "Snowburst One",
        "value": "SnowburstOne"
    },
    {
        "label": "Sofadi One Regular",
        "value": "SofadiOne"
    },
    {
        "label": "Sofia",
        "value": "Sofia"
    },
    {
        "label": "Sonsie One",
        "value": "SonsieOne"
    },
    {
        "label": "Sorts Mill Goudy Regular",
        "value": "SortsMillGoudy"
    },
    {
        "label": "Souliyo Unicode",
        "value": "Souliyo"
    },
    {
        "label": "Source Code Pro Regular",
        "value": "SourceCodePro"
    },
    {
        "label": "Source Sans Pro Regular",
        "value": "SourceSansPro"
    },
    {
        "label": "Source Serif Pro Regular",
        "value": "SourceSerifPro"
    },
    {
        "label": "Space Mono Regular",
        "value": "SpaceMono"
    },
    {
        "label": "Special Elite Regular",
        "value": "SpecialElite"
    },
    {
        "label": "Spicy Rice",
        "value": "SpicyRice"
    },
    {
        "label": "Spinnaker",
        "value": "Spinnaker"
    },
    {
        "label": "Spirax-Regular",
        "value": "Spirax"
    },
    {
        "label": "Squada One",
        "value": "SquadaOne"
    },
    {
        "label": "Sree Krushnadevaraya",
        "value": "SreeKrushnadevaraya"
    },
    {
        "label": "Sriracha",
        "value": "Sriracha"
    },
    {
        "label": "Stalemate",
        "value": "Stalemate"
    },
    {
        "label": "Stalin One",
        "value": "StalinOne"
    },
    {
        "label": "Stalinist One",
        "value": "StalinistOne"
    },
    {
        "label": "Stardos Stencil Regular",
        "value": "StardosStencil"
    },
    {
        "label": "Stint Ultra Condensed",
        "value": "StintUltraCondensed"
    },
    {
        "label": "Stint Ultra Expanded",
        "value": "StintUltraExpanded"
    },
    {
        "label": "Stoke Regular",
        "value": "Stoke"
    },
    {
        "label": "Strait",
        "value": "Strait"
    },
    {
        "label": "Strong",
        "value": "Strong"
    },
    {
        "label": "Sue Ellen Francisco ",
        "value": "SueEllenFrancisco"
    },
    {
        "label": "Suez One Regular",
        "value": "SuezOne"
    },
    {
        "label": "Sumana",
        "value": "Sumana"
    },
    {
        "label": "Sunshiney Regular",
        "value": "Sunshiney"
    },
    {
        "label": "Supermercado Regular",
        "value": "SupermercadoOne"
    },
    {
        "label": "Sura",
        "value": "Sura"
    },
    {
        "label": "Suranna",
        "value": "Suranna"
    },
    {
        "label": "Suravaram",
        "value": "Suravaram"
    },
    {
        "label": "Suwannaphum Regular",
        "value": "Suwannaphum"
    },
    {
        "label": "Swanky and Moo Moo",
        "value": "SwankyandMooMoo"
    },
    {
        "label": "Syncopate Regular",
        "value": "Syncopate"
    },
    {
        "label": "Tangerine Regular",
        "value": "Tangerine"
    },
    {
        "label": "Taprom",
        "value": "Taprom"
    },
    {
        "label": "Tauri-Regular",
        "value": "Tauri"
    },
    {
        "label": "Taviraj Regular",
        "value": "Taviraj"
    },
    {
        "label": "TeamGB Sans Light",
        "value": "TeamGBSansLight",
        "uid":"330845"
    },
    {
        "label": "TeamGB Sans Regular",
        "value": "TeamGBSansRegular",
        "uid":"330845"
    },
    {
        "label": "TeamGB Sans Medium",
        "value": "TeamGBSansMedium",
        "uid":"330845"
    },
    {
        "label": "TeamGB Sans Bold",
        "value": "TeamGBSansBold",
        "uid":"330845"
    },
    {
        "label": "TeamGB Sans Heavy",
        "value": "TeamGBSansHeavy",
        "uid":"330845"
    },
    {
        "label": "Teko Regular",
        "value": "Teko"
    },
    {
        "label": "Telex Regular",
        "value": "Telex"
    },
    {
        "label": "Tenali Ramakrishna",
        "value": "TenaliRamakrishna"
    },
    {
        "label": "Tenor Sans",
        "value": "TenorSans"
    },
    {
        "label": "Text Me One",
        "value": "TextMeOne"
    },
    {
        "label": "Thabit",
        "value": "Thabit"
    },
    {
        "label": "TharLon",
        "value": "Tharlon"
    },
    {
        "label": "The Girl Next Door",
        "value": "TheGirlNextDoor"
    },
    {
        "label": "Tienne Regular",
        "value": "Tienne"
    },
    {
        "label": "Tillana Regular",
        "value": "Tillana"
    },
    {
        "label": "Timmana",
        "value": "Timmana"
    },
    {
        "label": "Tinos Regular",
        "value": "Tinos"
    },
    {
        "label": "Titan One",
        "value": "TitanOne"
    },
    {
        "label": "Titillium Web Regular",
        "value": "TitilliumWeb"
    },
    {
        "label": "Trade Winds",
        "value": "TradeWinds"
    },
    {
        "label": "Trirong Regular",
        "value": "Trirong"
    },
    {
        "label": "Trocchi Regular",
        "value": "Trocchi"
    },
    {
        "label": "Trochut",
        "value": "Trochut"
    },
    {
        "label": "Trykker Regular",
        "value": "Trykker"
    },
    {
        "label": "Tuffy Regular",
        "value": "Tuffy"
    },
    {
        "label": "Tulpen One",
        "value": "TulpenOne"
    },
    {
        "label": "Ubuntu Regular",
        "value": "Ubuntu"
    },
    {
        "label": "Ubuntu Condensed",
        "value": "UbuntuCondensed"
    },
    {
        "label": "Ubuntu Mono",
        "value": "UbuntuMono"
    },
    {
        "label": "Ultra Regular",
        "value": "Ultra"
    },
    {
        "label": "Uncial Antiqua",
        "value": "UncialAntiqua"
    },
    {
        "label": "Underdog",
        "value": "Underdog"
    },
    {
        "label": "Unica One",
        "value": "UnicaOne"
    },
    {
        "label": "Unkempt Regular",
        "value": "Unkempt"
    },
    {
        "label": "Unlock Regular",
        "value": "Unlock"
    },
    {
        "label": "Unna Regular",
        "value": "Unna"
    },
    {
        "label": "VT323 Regular",
        "value": "VT323"
    },
    {
        "label": "Vampiro One",
        "value": "VampiroOne"
    },
    {
        "label": "Varela",
        "value": "Varela"
    },
    {
        "label": "Varela Round Regular",
        "value": "VarelaRound"
    },
    {
        "label": "Varta",
        "value": "Varta"
    },
    {
        "label": "Vast Shadow Regular",
        "value": "VastShadow"
    },
    {
        "label": "Vesper Libre",
        "value": "VesperLibre"
    },
    {
        "label": "Vibur",
        "value": "Vibur"
    },
    {
        "label": "Vidaloka ",
        "value": "Vidaloka"
    },
    {
        "label": "Viga-Regular",
        "value": "Viga"
    },
    {
        "label": "Voces Regular",
        "value": "Voces"
    },
    {
        "label": "Volkhov Regular",
        "value": "Volkhov"
    },
    {
        "label": "Vollkorn Regular",
        "value": "Vollkorn"
    },
    {
        "label": "Voltaire",
        "value": "Voltaire"
    },
    {
        "label": "Waiting for the Sunrise",
        "value": "WaitingfortheSunrise"
    },
    {
        "label": "Wallpoet",
        "value": "Wallpoet"
    },
    {
        "label": "Walter Turncoat Regular",
        "value": "WalterTurncoat"
    },
    {
        "label": "Warnes",
        "value": "Warnes"
    },
    {
        "label": "Wellfleet",
        "value": "Wellfleet"
    },
    {
        "label": "Wendy One",
        "value": "WendyOne"
    },
    {
        "label": "Wire One",
        "value": "WireOne"
    },
    {
        "label": "Work Sans",
        "value": "WorkSans"
    },
    {
        "label": "Yaldevi Colombo Regular",
        "value": "YaldeviColombo"
    },
    {
        "label": "Yanone Kaffeesatz Regular",
        "value": "YanoneKaffeesatz"
    },
    {
        "label": "Yantramanav Regular",
        "value": "Yantramanav"
    },
    {
        "label": "Yatra One Regular",
        "value": "YatraOne"
    },
    {
        "label": "Yellowtail Regular",
        "value": "Yellowtail"
    },
    {
        "label": "Yeseva One Regular",
        "value": "YesevaOne"
    },
    {
        "label": "Yesteryear",
        "value": "Yesteryear"
    },
    {
        "label": "Yrsa Regular",
        "value": "Yrsa"
    },
    {
        "label": "Zeyada",
        "value": "Zeyada"
    },
    {
        "label": "Zilla Slab",
        "value": "ZillaSlab"
    },
    {
        "label": "Zilla Slab Highlight",
        "value": "ZillaSlabHighlight"
    },
  ],
};
