import PropTypes from "prop-types";
import React from "react";
import _ from "lodash";
import classNames from "classnames";

import { Card, List, Button, Loader, Divider, Label, Popup } from "semantic-ui-react";



// import css from "styles/shared/plans.scss";


import css from '../../assets/styles/shared/plans.module.scss'
import timerHelper from "../../helpers/timers/timerHelper";

const { string, func, bool, array } = PropTypes;

const planDescriptions = {
  Micro: "Starting Up",
  Standard: "Growing Businesses",
  Power: "For Professionals",
};

class Plans extends React.Component {
  static propTypes = {
    currentPlan: string.isRequired,
    onPlanSelected: func.isRequired,
    planOptions: array.isRequired,
    usedFeatures: array,
    draftFeatures: array,
  };

  constructor(props) {
    super(props);

    this.state = {
      trialCreate: true,
    };
  }

  setTrialCreate(value) {
    // Allow creating timers in trial
    this.setState({
      trialCreate: value == 1,
    });
  }

  handleChoosePlanCurry = (plan) => {
    return (e) => {
      if (plan.id === this.props.currentPlan) {
        return null;
      }

      this.props.onPlanSelected(plan);
    };
  };

  renderPlanButton = (plan) => {
    if (plan.id === this.props.currentPlan) {
      return (
        <Button disabled fluid color="green">
          Current Plan
        </Button>
      );
    } else {
      return (
        <Button fluid primary onClick={this.handleChoosePlanCurry(plan)} className={css.primaryButtonBackColor}>
          Choose Plan
        </Button>
      );
    }
  };

  renderPlanFeature = (enabled, description, featureId) => {
    const used = _.includes(this.props.usedFeatures, featureId);
    const usedDraft = _.includes(this.props.draftFeatures, featureId);

    let icon = <List.Icon fitted name="check" className={css.tickButtonColor} verticalAlign="middle" />;
    if (!enabled) {
      if (usedDraft) {
        icon = (
          <Popup basic trigger={<List.Icon fitted name="exclamation circle" color="red" verticalAlign="middle" />}>
            The timer you're editing requires this feature.
          </Popup>
        );
      } else if (used) {
        icon = (
          <Popup basic trigger={<List.Icon fitted name="exclamation circle" color="red" verticalAlign="middle" />}>
            This plan is missing a feature used by your timers.
          </Popup>
        );
      } else {
        icon = <List.Icon fitted name="x" verticalAlign="middle" />;
      }
    }

    return (
      <List.Item className={classNames({ [css.planFeature]: true, [css.disabledFeature]: !enabled })}>
        {icon}
        <List.Content verticalAlign="middle">{enabled ? description : <s>{description}</s>}</List.Content>
      </List.Item>
    );
  };

  renderPlanCards = () => {
    let hasRecommended = false;
    return _.map(this.props.planOptions, (plan, index) => {
      const missingFeatures = timerHelper.getMissingFeatures(
        this.props.usedFeatures.concat(this.props.draftFeatures),
        plan
      );
      
      let recommended = false;
      if (!missingFeatures.length && index >= 1 && !hasRecommended) {
        recommended = true;
        hasRecommended = true;
      }
      
      return (
        <Card key={plan.id} className={css.planCard} raised={recommended}>
          <Card.Content>
            {recommended ? <Label as="div" corner="left" color="blue" icon="star" /> : null}

            <Card.Header textAlign="center">{plan.name}</Card.Header>
            <Card.Meta textAlign="center">{planDescriptions[plan.name]}</Card.Meta>
            <Divider horizontal>${plan.price} USD/month</Divider>
            <List divided relaxed>
              {this.renderPlanFeature(true, "Unlimited Timers")}
              {this.renderPlanFeature(true, `${plan.views} views/month`)}
              {this.renderPlanFeature(true, "99.9% Uptime Guarantee")}
              {this.renderPlanFeature(true, "Full Label/Langauge Customization", "")}
              {this.renderPlanFeature(true, "Background Images")}
              {this.renderPlanFeature(true, "Expired Timer Images")}
              {this.renderPlanFeature(true, "Perpetual Timers")}
              {this.renderPlanFeature(plan.features.styles, "Additional Timer Styles", "styles")}
              {this.renderPlanFeature(plan.features.transparent, "Transparent Backgrounds", "transparent")}
              {this.renderPlanFeature(plan.features.dynamic, "Dynamic Timers", "dynamic")}
              {this.renderPlanFeature(plan.features.fallback, "Fallback Image", "fallback")}
	            {this.renderPlanFeature(plan.features.recurring, "Recurring Timers", "recurring")}
            </List>
          </Card.Content>
          <Card.Content>{this.renderPlanButton(plan)}</Card.Content>
        </Card>
      );
    });
  };

  render() {
    return (
      <div>
        <Card.Group itemsPerRow={this.props.planOptions.length}>{this.renderPlanCards()}</Card.Group>
      </div>
    );
  }
}

export default Plans;
