import PropTypes from "prop-types";
import React from "react";
import moment from "moment-timezone";

// import css from "styles/shared/timerPreview.scss";


import css from '../../assets/styles/shared/timerPreview.module.scss'

const { string, object, bool } = PropTypes;

class TimerPreview extends React.Component {
  state = {};

  static propTypes = {
    timer: object.isRequired,
    type: string.isRequired,
    genUrl: string.isRequired,
    expired: bool,
    fallbackMode: bool,
    showDimenions: bool,
  };

  handleContextMenu = (e) => {
    e.preventDefault();
  };

  renderDimensions() {
    if (this.props.showDimenions && this.state.width && this.state.height) {
      return (
        <>
          <br />
          <span>
            {this.state.width} x {this.state.height} px
          </span>
        </>
      );
    }
  }

  proceedWithRecurringTimer = (endDate1, endDate2, endType) => {
    if (endType == "on") {
      let recurrenceEndDate = new Date(endDate2);
      recurrenceEndDate.setHours(endDate1.getHours(), endDate1.getMinutes(), endDate1.getSeconds());
      return endDate1.getTime() <= recurrenceEndDate.getTime();
    }
    return true;
  };

  olderGenerateEndDateForMonthlyOption2 = (currentDate, recurrenceStartDate) => {
    
    if (currentDate.getTime() < recurrenceStartDate.getTime()) {
      return recurrenceStartDate;
    } else {
      let occurrence = 0;
      let startMonth = recurrenceStartDate.getMonth();
      let startDay = recurrenceStartDate.getDay();
      let endDateValue = new Date(recurrenceStartDate.getFullYear(), startMonth + 1, 0).getDate();
      for (let i = 1; i <= endDateValue; i++) {
        const date = new Date(recurrenceStartDate.getFullYear(), startMonth, i);
        if (date.getDay() === startDay && date.getDate() <= recurrenceStartDate.getDate()) {
          occurrence++;
        }
      }
      if (occurrence == 5) {
        occurrence--;
      }
      
      let currentYear = currentDate.getFullYear();
      let currentMonth = currentDate.getMonth();
      let count = 0;
      let endDateRange = new Date(currentYear, 1, 0);

      endDateRange.setMonth(currentMonth + 1);
      endDateRange.setDate(0);
      
      endDateValue = endDateRange.getDate();
      for (let i = 1; i <= endDateValue; i++) {
        const date = new Date(endDateRange.getFullYear(), endDateRange.getMonth(), i);
        if (date.getDay() === startDay) {
          count++;
          if (count == occurrence) {
            currentDate = date;
            break;
          }
        }
      }
      currentDate.setHours(
        recurrenceStartDate.getHours(),
        recurrenceStartDate.getMinutes(),
        recurrenceStartDate.getSeconds(),
        0
      );
      return currentDate;
    }
  };

  generateEndDateForMonthlyOption2 = (currentDate, recurrenceStartDate) => {
    
    if (currentDate.getTime() < recurrenceStartDate.getTime()) {
      return recurrenceStartDate;
    } else {
      let startDate = recurrenceStartDate.getDate();
      let occurrence = Math.floor((startDate - 1) / 7 + 1);
    
      let endDate = new Date(currentDate.getTime());
      let currentMonth = currentDate.getMonth();
      let currentYear = currentDate.getFullYear();
      let weekDay = recurrenceStartDate.getDay();

      let currentOccurrence = occurrence;

      let notfound = true;
      while (notfound) {
        let day = 1;
        let count = 0;
        let monthDate = new Date(currentYear, currentMonth + 1, 0);
        let endDay = monthDate.getDate();
        while (day <= endDay) {
          let dateValue = new Date(currentYear, currentMonth, day);
    
          if (dateValue.getDay() == weekDay) {
            count++;
            if (currentOccurrence == count) {
              endDate = dateValue;
              endDate.setHours(
                recurrenceStartDate.getHours(),
                recurrenceStartDate.getMinutes(),
                recurrenceStartDate.getSeconds(),
                0
              );
    
              notfound = false;
              break;
            }
          }
          day++;
        }
        if (notfound) {
          if (currentOccurrence == 5) {
            currentOccurrence = currentOccurrence - 1;
          } else {
            notfound = false;
          }
        } else {
          if (currentDate.getTime() <= endDate.getTime()) {
            notfound = false;
          } else {
            notfound = true;
            currentOccurrence = occurrence;
            currentMonth++;
            if (currentMonth == 12) {
              currentMonth = 0;
              currentYear++;
            }
          }
        }
      }
      return endDate;
    }
  };

  generateEndDateForWeekly = (currentDate, recurrenceStartDate) => {};

  generateEndDateForWeekdayOption = (currentDate, recurrenceStartDate) => {
    if (currentDate.getTime() < recurrenceStartDate.getTime()) {
      return recurrenceStartDate;
    } else {
      let endDate = new Date();
      endDate.setHours(
        recurrenceStartDate.getHours(),
        recurrenceStartDate.getMinutes(),
        recurrenceStartDate.getSeconds(),
        0
      );
      if (currentDate.getTime() > endDate.getTime()) {
        let startDay = endDate.getDay();
        let daysToAdd = 1;
        if (startDay == 5) {
          daysToAdd = 3;
        } else if (startDay == 6) {
          daysToAdd = 2;
        }
        endDate.setDate(endDate.getDate() + daysToAdd);
      }
      return endDate;
    }
  };

  renderImage() {

    let templateJson;
    let recurrence = "";
    let recurrenceEndDate = "";
    let endType = "";
    if (this.props.timer.templateData) {
      templateJson = JSON.stringify(this.props.timer.templateData);
      recurrence = this.props.timer.templateData.recurrence;
      endType = this.props.timer.templateData.endType;
      if (endType == "on") {
        recurrenceEndDate = this.props.timer.templateData.recurrenceEndDate;
      }
    } else if (this.props.timer.templatedata) {
      let templateString = JSON.parse(this.props.timer.templatedata);
      templateJson = JSON.stringify(templateString);
      recurrence = this.props.timer.templatedata.recurrence;
      endType = this.props.timer.templatedata.endType;
      if (endType == "on") {
        recurrenceEndDate = this.props.timer.templatedata.recurrenceEndDate;
      }
    }

    const encodedTemplate = encodeURIComponent(templateJson);
    let previewUrl = `${this.props.genUrl}/template?t=${encodedTemplate}`;

    let expired = this.props.expired;
    let fallbackMode = this.props.fallbackMode;
    let type = this.props.type;
    let timerType = this.props.timer.timerType
      ? this.props.timer.timerType
      : this.props.timer.timer_type
      ? this.props.timer.timer_type
      : undefined;
    let endsAt = this.props.timer.endsAt
      ? this.props.timer.endsAt
      : this.props.timer.ends_at
      ? this.props.timer.ends_at
      : undefined;
    let duration = this.props.timer.duration;


    if (expired) {
      previewUrl = `${this.props.genUrl}/templateExpiration?t=${encodedTemplate}`;
    } else if (fallbackMode) {
      previewUrl = `${this.props.genUrl}/templateFallback?t=${encodedTemplate}`;
    }

    if (type == "Timer" && timerType == "standard") {
      const unixValue = moment(endsAt).unix() || "";
      previewUrl = `${previewUrl}&to=${unixValue}`;
    } else if (type == "Timer" && timerType == "perpetual") {
      previewUrl = `${previewUrl}&duration=${duration}`;
    } else if (type == "Timer" && timerType == "recurring") {
      let recurrenceStartDate = new Date(endsAt);
      
      if (recurrence == "daily") {
        let currentDate = new Date();
      
        // Flow 1 When Current Date is Lower than Recurrence Start Date
        if (currentDate.getTime() < recurrenceStartDate.getTime()) {
          if (this.proceedWithRecurringTimer(recurrenceStartDate, recurrenceEndDate, endType)) {
            const unixValue = moment(recurrenceStartDate).unix() || "";
            previewUrl = `${previewUrl}&to=${unixValue}`;
          } else {
            previewUrl = `${previewUrl}&duration=0`;
          }
        } else {
          // Flow 2 When Current Date is Bigger than Recurrence Start Date
          let currentDate1 = new Date(currentDate);
          currentDate1.setHours(
            recurrenceStartDate.getHours(),
            recurrenceStartDate.getMinutes(),
            recurrenceStartDate.getSeconds(),
            0
          );
          if (currentDate.getTime() > currentDate1.getTime()) {
            currentDate.setDate(currentDate.getDate() + 1);
          }
          currentDate.setHours(
            recurrenceStartDate.getHours(),
            recurrenceStartDate.getMinutes(),
            recurrenceStartDate.getSeconds(),
            0
          );
          if (this.proceedWithRecurringTimer(currentDate, recurrenceEndDate, endType)) {
            const unixValue = moment(currentDate).unix() || "";
            previewUrl = `${previewUrl}&to=${unixValue}`;
          } else {
            previewUrl = `${previewUrl}&duration=0`;
          }
        }
      } else if (recurrence == "weekly") {
        let currentDate = new Date();
        let endDate = new Date();
        if (currentDate.getTime() < recurrenceStartDate.getTime()) {
          endDate = recurrenceStartDate;
        } else {
          let startWeekday = recurrenceStartDate.getDay();
          let currentWeekday = currentDate.getDay();

          let count = 0;
          if (startWeekday != currentWeekday) {
            let weekDays = [0, 1, 2, 3, 4, 5, 6];
            let index = currentWeekday;
            while (index < weekDays.length) {
              if (index == weekDays.length - 1) {
                index = 0;
              } else {
                index++;
              }
              count++;
              if (weekDays[index] == startWeekday) {
                break;
              }
            }
          }
          endDate.setHours(
            recurrenceStartDate.getHours(),
            recurrenceStartDate.getMinutes(),
            recurrenceStartDate.getSeconds(),
            0
          );
          let daysAddFlag = true;
          if (count == 0) {
            count = 7;
            if (currentDate.getTime() < endDate.getTime()) {
              daysAddFlag = false;
            }
          }
          if (daysAddFlag) {
            endDate.setDate(endDate.getDate() + count);
          }
        }
        
        if (this.proceedWithRecurringTimer(endDate, recurrenceEndDate, endType)) {
          const unixValue = moment(endDate).unix() || "";
          previewUrl = `${previewUrl}&to=${unixValue}`;
        } else {
          previewUrl = `${previewUrl}&duration=0`;
        }
      } else if (recurrence == "monthly_option_1") {
        let currentDate = new Date();
        if (currentDate.getTime() < recurrenceStartDate.getTime()) {
          if (this.proceedWithRecurringTimer(recurrenceStartDate, recurrenceEndDate, endType)) {
            const unixValue = moment(recurrenceStartDate).unix() || "";
            previewUrl = `${previewUrl}&to=${unixValue}`;
          } else {
            previewUrl = `${previewUrl}&duration=0`;
          }
        } else {
          let dateValue = recurrenceStartDate.getDate();
          let endDate = new Date(currentDate.getTime());
          endDate.setDate(dateValue);
          endDate.setHours(
            recurrenceStartDate.getHours(),
            recurrenceStartDate.getMinutes(),
            recurrenceStartDate.getSeconds(),
            0
          );
          if (currentDate.getTime() > endDate.getTime()) {
            currentDate.setMonth(currentDate.getMonth() + 1);
            currentDate.setDate(dateValue);
            currentDate.setHours(
              recurrenceStartDate.getHours(),
              recurrenceStartDate.getMinutes(),
              recurrenceStartDate.getSeconds(),
              0
            );
          } else {
            currentDate = endDate;
          }

          if (this.proceedWithRecurringTimer(currentDate, recurrenceEndDate, endType)) {
            const unixValue = moment(currentDate).unix() || "";
            previewUrl = `${previewUrl}&to=${unixValue}`;
          } else {
            previewUrl = `${previewUrl}&duration=0`;
          }
        }
      } else if (recurrence == "monthly_option_2") {
        let currentDate = this.generateEndDateForMonthlyOption2(new Date(), recurrenceStartDate);
        if (this.proceedWithRecurringTimer(currentDate, recurrenceEndDate, endType)) {
          const unixValue = moment(currentDate).unix() || "";
          previewUrl = `${previewUrl}&to=${unixValue}`;
        } else {
          previewUrl = `${previewUrl}&duration=0`;
        }
      } else if (recurrence == "weekday") {
        let startWeekDay = recurrenceStartDate.getDay();
        if (startWeekDay == 0 || startWeekDay == 6) {
          // Error Message will be dispay here...
          previewUrl = `${previewUrl}&duration=0`;
        } else {
          let currentDate = this.generateEndDateForWeekdayOption(new Date(), recurrenceStartDate);
          if (this.proceedWithRecurringTimer(currentDate, recurrenceEndDate, endType)) {
            const unixValue = moment(currentDate).unix() || "";
            previewUrl = `${previewUrl}&to=${unixValue}`;
          } else {
            previewUrl = `${previewUrl}&duration=0`;
          }
        }
      }
      
    }

    return (
      <img
        src={previewUrl}
        className={css.previewImage}
        onContextMenu={this.handleContextMenu}
        draggable={false}
        ref={(ref) => (this.image = ref)}
        onLoad={() => {
          if (this.image) {
            this.setState({ width: this.image.width, height: this.image.height });
          }
        }}
      />
    );
  }


  render() {
    if (this.props.showDimenions) {
      return (
        <>
          {this.renderImage()}
          {this.renderDimensions()}
        </>
      );
    } else {
      return this.renderImage();
    }
  }
}

export default TimerPreview;
