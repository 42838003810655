import PropTypes from "prop-types";
import React from "react";
import _ from "lodash";

import css from "../../assets/styles/shared/common.module.scss";
// import htmlCss from "../../assets/styles/editor/htmlCode.module.css";
import tableCss from "../../assets/styles/shared/tables.module.scss";

import {
  Dimmer,
  Loader,
  Button,
  Message,
  Icon,
  Modal,
  Grid,
  Progress,
  Input,
  Form,
  Radio,
  Dropdown,
} from "semantic-ui-react";
import moment from "moment-timezone";

import Plans from "../shared/plans";
import ApiPlans from "../shared/apiPlans";
import NewPlans from "../shared/newPlans";

const { object, bool, array } = PropTypes;
const taxRegions = [
  { key: "au_abn", value: "au_abn", text: "Australia (au_abn)" },
  { key: "eu_vat_at", value: "eu_vat_at", text: "Austria (eu_vat)" },
  { key: "eu_vat_be", value: "eu_vat_be", text: "Belgium (eu_vat)" },
  { key: "eu_vat_bg", value: "eu_vat_bg", text: "Bulgaria (eu_vat)" },
  { key: "ca_bn", value: "ca_bn", text: "Canada (ca_bn)" },
  { key: "ca_gst_hst", value: "ca_gst_hst", text: "Canada (ca_gst_hst)" },
  { key: "ca_pst_bc", value: "ca_pst_bc", text: "Canada (ca_pst_bc)" },
  { key: "ca_pst_mb", value: "ca_pst_mb", text: "Canada (ca_pst_mb)" },
  { key: "ca_pst_sk", value: "ca_pst_sk", text: "Canada (ca_pst_sk)" },
  { key: "ca_qst", value: "ca_qst", text: "Canada (ca_qst)" },
  { key: "cl_tin", value: "cl_tin", text: "Chile (cl_tin)" },
  { key: "eu_vat_hr", value: "eu_vat_hr", text: "Croatia (eu_vat)" },
  { key: "eu_vat_cy", value: "eu_vat_cy", text: "Cyprus (eu_vat)" },
  { key: "eu_vat_cz", value: "eu_vat_cz", text: "Czech Republic (eu_vat)" },
  { key: "eu_vat_dk", value: "eu_vat_dk", text: "Denmark (eu_vat)" },
  { key: "eu_vat_ee", value: "eu_vat_ee", text: "Estonia (eu_vat)" },
  { key: "eu_vat_fi", value: "eu_vat_fi", text: "Finland (eu_vat)" },
  { key: "eu_vat_fr", value: "eu_vat_fr", text: "France (eu_vat)" },
  { key: "eu_vat_de", value: "eu_vat_de", text: "Germany (eu_vat)" },
  { key: "eu_vat_el", value: "eu_vat_el", text: "Greece (eu_vat)" },
  { key: "eu_vat_hu", value: "eu_vat_hu", text: "Hungary (eu_vat)" },
  { key: "is_vat", value: "is_vat", text: "Iceland (is_vat)" },
  { key: "in_gst", value: "in_gst", text: "India (in_gst)" },
  { key: "eu_vat_ie", value: "eu_vat_ie", text: "Ireland (eu_vat)" },
  { key: "eu_vat_it", value: "eu_vat_it", text: "Italy (eu_vat)" },
  { key: "eu_vat_lv", value: "eu_vat_lv", text: "Latvia (eu_vat)" },
  { key: "eu_vat_lt", value: "eu_vat_lt", text: "Lithuania (eu_vat)" },
  { key: "eu_vat_lu", value: "eu_vat_lu", text: "Luxembourg (eu_vat)" },
  { key: "eu_vat_mt", value: "eu_vat_mt", text: "Malta (eu_vat)" },
  { key: "mx_rfc", value: "mx_rfc", text: "Mexico (mx_rfc)" },
  { key: "eu_vat_nl", value: "eu_vat_nl", text: "Netherlands (eu_vat)" },
  { key: "nz_gst", value: "nz_gst", text: "New Zealand (nz_gst)" },
  { key: "no_vat", value: "no_vat", text: "Norway (no_vat)" },
  { key: "eu_vat_pl", value: "eu_vat_pl", text: "Poland (eu_vat)" },
  { key: "eu_vat_pt", value: "eu_vat_pt", text: "Portugal (eu_vat)" },
  { key: "eu_vat_ro", value: "eu_vat_ro", text: "Romania (eu_vat)" },
  { key: "ru_inn", value: "ru_inn", text: "Russia (ru_inn)" },
  { key: "ru_kpp", value: "ru_kpp", text: "Russia (ru_kpp)" },
  { key: "sa_vat", value: "sa_vat", text: "Saudi Arabia (sa_vat)" },
  { key: "sg_gst", value: "sg_gst", text: "Singapore (sg_gst)" },
  { key: "eu_vat_sk", value: "eu_vat_sk", text: "Slovakia (eu_vat)" },
  { key: "eu_vat_si", value: "eu_vat_si", text: "Slovenia (eu_vat)" },
  { key: "za_vat", value: "za_vat", text: "South Africa (za_vat)" },
  { key: "kr_brn", value: "kr_brn", text: "South Korea (kr_brn)" },
  { key: "es_cif", value: "es_cif", text: "Spain (es_cif)" },
  { key: "eu_vat_es", value: "eu_vat_es", text: "Spain (eu_vat)" },
  { key: "eu_vat_se", value: "eu_vat_se", text: "Sweden (eu_vat)" },
  { key: "ch_vat", value: "ch_vat", text: "Switzerland (ch_vat)" },
  { key: "th_vat", value: "th_vat", text: "Thailand (th_vat)" },
  { key: "tr_tin", value: "tr_tin", text: "Turkey (tr_tin)" },
  { key: "ae_trn", value: "ae_trn", text: "United Arab Emirates (ae_trn)" },
  { key: "eu_vat_xi", value: "eu_vat_xi", text: "United Kingdom (eu_vat)" },
  { key: "gb_vat", value: "gb_vat", text: "United Kingdom (gb_vat)" },
  { key: "others", value: "others", text: "Others" },
];

class Billing extends React.Component {
  static propTypes = {
    user: object,
    inProgress: bool,
    envContext: object,
    usedFeatures: array,
    draftFeatures: array,
  };

  constructor(props) {
    super(props);

    this.state = {
      isConfirmationModalOpen: false,
      selectedPlan: null,
      showModal: false, // Controls visibility of the Tax ID modal
      taxId: "", // Holds the input value for the Tax ID
      selection: "", // 'personal' or 'business'
      country: "", // For the dropdown selection
      showTaxIdInput: false,
      businessName: "", // Add state to capture business name
      dropdownValue: "",
      searchQuery: "",
      filteredOptions: taxRegions,
      isDropdownOpen: false,
      isOpen: false,
    };
  }

  handleDropdownOpen = () => {
    this.setState({ isDropdownOpen: true });
  };

  handleDropdownClose = () => {
    this.setState({ isDropdownOpen: false });
  };

  handleRadioChange = (e, { value }) => {
    // Reset showTaxIdInput when switching from Business to Personal
    const showTaxIdInput =
      value === "business" && this.state.country === "others";
    this.setState({ selection: value, showTaxIdInput, searchQuery: "" });
  };

  handleDropdownChange = (e, { value }) => {
    if (value === "others") {
      this.setState({
        country: value,
        dropdownValue: value,
        showTaxIdInput: true,
        isDropdownOpen: false,
        isOpen: false,
        searchQuery: "",
      });
    } else {
      this.setState({
        country: value,
        dropdownValue: value,
        showTaxIdInput: false,
        isDropdownOpen: false,
        isOpen: false,
        searchQuery: "",
      });
    }
  };

  searchOperation = (e, { searchQuery }) => {
    this.setState({ searchQuery });
    const filtered = taxRegions.filter((option) =>
      option.text.toLowerCase().includes(searchQuery.toLowerCase())
    );
    this.setState({
      searchQuery,
      isOpen: true, // Open the dropdown when searching
      filteredOptions:
        filtered.length > 0
          ? filtered
          : [{ key: "others", value: "others", text: "Others" }],
    });
  };

  // addNewValue = (e, data) => {
  //   console.log(e, data);
  // }

  // In your Dropdown component

  // handleDropdownChange = (e, { value }) => {
  //   console.log(" Fired: ",value)
  //   if (value === 'others') {
  //     console.log("hiiiiiiii")
  //     this.setState({ country: value,dropdownValue: value, showTaxIdInput: true ,isDropdownOpen: false,searchQuery: ''  }); // Show tax ID input when "Others" is
  //   } else {
  //     console.log("value",value);
  //     this.setState({ country: value, dropdownValue: value,showTaxIdInput: false, isDropdownOpen: false ,searchQuery: ''  });
  //     console.log("this country",this.state.country);
  //   }
  // };

  handleTaxIdChange = (e, { value }) => this.setState({ taxId: value });

  handleSubmit = () => {
    const { selection, selectedPlan, taxId, country, businessName } =
      this.state;
    const isBusiness = selection === "business";

    // Determine if business is selected and either a specific country is chosen or "Others" with a tax ID.
    if (selection === "personal") {
      // For personal selection, pass an empty string as taxId.
      this.updateStripeCustomerWithTaxId(selectedPlan, "", false, "", "");
    } else if (isBusiness) {
      // For business selection, pass the actual taxId and country, including handling "Others".
      // Additionally, pass the business name for business selections.
      this.updateStripeCustomerWithTaxId(
        selectedPlan,
        taxId,
        isBusiness,
        country,
        businessName
      );
    }

    this.closeModal();
  };

  handleBack = () => {
    // Reset to initial view, keeping the modal open
    this.setState({
      showTaxIdInput: false,
      taxId: "",
      country: "",
      selection: "",
    });
  };

  // Add methods to handle the Tax ID modal actions
  handleTaxIdSubmit = async () => {
    const { selectedPlan, taxId } = this.state;
    if (taxId) {
      try {
        // Call your Ruby backend to update the customer with the provided tax ID
        await this.updateStripeCustomerWithTaxId(selectedPlan, taxId);
      } catch (error) {
        // Handle any errors from the update here
        console.error("Error updating customer with tax ID:", error);
      }
    }

    this.closeModal();
  };

  handleSkip = () => {
    // Proceed without a Tax ID
    // this.props.createStripeCheckoutSession(this.state.selectedPlan);
    this.updateStripeCustomerWithTaxId(this.state.selectedPlan, "");
    this.closeModal();
  };

  handleTaxIdChange = (e) => {
    this.setState({ taxId: e.target.value });
  };

  closeModal = () => {
    this.setState({ showModal: false });
  };

  closeTaxIdModal = () => {
    this.setState({ showTaxIdModal: false });
  };

  updateStripeCustomerWithTaxId = async (
    selectedPlan,
    taxId,
    isBusiness,
    country,
    businessName
  ) => {
    const apiUrl = process.env.REACT_APP_DOMAIN_URL;
    console.log("apiUrl", apiUrl);

    const url = apiUrl + "/api/v1/subscriptions/create_checkout_session";

    const body = JSON.stringify({
      selectedPlan: selectedPlan,
      tax_id: taxId,
      business_selected: isBusiness,
      country: country,
      business_name: businessName, // Send the business name as well
    });

    console.log("body=", body);
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: this.props.authData.data.jwt,
      },
      body: body,
    };

    try {
      const response = await fetch(url, requestOptions);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      window.location.href = data.session_url;
    } catch (error) {
      console.error("There was a problem with the fetch operation:", error);
    }
  };

  handleBusinessNameChange = (e) =>
    this.setState({ businessName: e.target.value });

  // getCheckout() {
  //   if (!this.stripeClient) {
  //     try {
  //       console.log("Stripe Checkout", "StripeCheckout" in window);
  //       if ("StripeCheckout" in window) {
  //         if (typeof window["StripeCheckout"] === "undefined") {
  //           if (typeof window["StripeCheckout"].configure === "undefined") {
  //             console.log("i am nested inside get checkout");
  //             this.stripeClient = window["StripeCheckout"].configure({
  //               key: this.props.user.auth.stripe_key,
  //               image: "/logo_circle.png",
  //               locale: "auto",
  //               token: (token) => {
  //                 this.props.subscribe(token.id, this.state.selectedPlan);
  //               },
  //             });
  //           }
  //         }
  //       }
  //     } catch (ex) {
  //       console.log("Exception: ", ex);
  //     }
  //   }
  //   return this.stripeClient;
  // }

  getCheckout() {
    if (!this.stripeClient) {
      try {
        if ("StripeCheckout" in window) {
          this.stripeClient = window["StripeCheckout"].configure({
            key: this.props.user.auth.stripe_key,
            image: "/logo_circle.png",
            locale: "auto",
            token: (token) => {
              this.props.subscribe(token.id, this.state.selectedPlan);
            },
          });
        }
      } catch (ex) {
        console.log("Exception: ", ex);
      }
    }
    return this.stripeClient;
  }

  componentDidMount() {
    this.getCheckout();
    this.props.fetch();
  }

  getSubscriptionEnd = () => {
    const { subscription } = this.props.user;
    return moment(subscription.current_period_end * 1000);
  };

  getTrialEnd = () => {
    const { subscription } = this.props.user;
    if (subscription.trial_end) {
      return moment(subscription.trial_end * 1000);
    }
  };

  handleCancel = () => {
    this.props.subscribe(null, null);
  };

  handleReactivate = () => {
    this.props.subscribe(null, this.props.user.subscription.plan);
  };

  handleCloseConfirmationModal = () => {
    this.setState({
      isConfirmationModalOpen: false,
    });
  };

  handleNewPlan = (plan) => {
    // New Payment flow
    this.setState({
      selectedPlan: plan.id,
      showModal: true,
    });
    //this.updateStripeCustomerWithTaxId(plan.id,"");
  };

  handlePlanSelected = (plan) => {
    this.setState({
      selectedPlan: plan.id,
      isConfirmationModalOpen: true,
    });
  };

  handlePlanConfirmed = () => {
    this.handleCloseConfirmationModal();
    this.props.subscribe(null, this.state.selectedPlan);
  };

  renderSubscriptionActions = () => {
    const { subscription } = this.props.user;
    const end = this.getSubscriptionEnd();
    if (subscription.trial_end && !subscription.card) {
      return this.renderTrialMessage();
    }

    return subscription.cancel
      ? this.renderReactivateMessage(end)
      : this.renderCancelMessage(end);
  };

  renderTrialMessage = () => {
    const end = this.getTrialEnd();
    return (
      <Message warning attached="bottom">
        <Icon name="warning" />
        Your free trial will end on {end.format("LL")}. Please add a payment
        method to continue.&nbsp;
        <Button
          compact
          color="green"
          size="tiny"
          onClick={this.handleChangePaymentMethod}
        >
          Add Payment Method
        </Button>
      </Message>
    );
  };

  renderReactivateMessage = (end) => {
    return (
      <Message warning attached="bottom">
        <Icon name="warning" />
        Your subscription is currently canceled and will end on{" "}
        {end.format("LL")}.&nbsp;
        <Button
          compact
          color="green"
          size="tiny"
          onClick={this.handleReactivate}
        >
          Reactivate
        </Button>
      </Message>
    );
  };

  renderCancelMessage = (end) => {
    return (
      <Message positive attached="bottom">
        <Icon name="checkmark" />
        Your subscription will renew on {end.format("LL")}.
      </Message>
    );
  };

  renderPlans = (callback) => {
    // plans
    let currentPlan = false;
    if (this.props.user.features.legacy) {
      currentPlan = this.props.user.features.legacy;
    }
    // console.log("current plan=", currentPlan);
    // console.log("this.props.envContext=", this.props.user.auth.stripe_key);
    // console.log("this.state.selectedPlan=",this.state);

    let allActivePlans = _.filter(
      this.props.user.auth.plans,
      function (plan, i) {
        if (currentPlan) {
          return plan.features.legacy || i == 0 || plan.features.annual;
        } else {
          return !plan.features.legacy || i == 0;
        }
      }
    );
    // api plans
    let apiPlan = false;
    if (this.props.user.tier == "trial" && this.props.user.api_plan_id != "") {
      apiPlan = true;
    }
    if (apiPlan) {
      console.log("this.props.user.api_plans", this.props);
      allActivePlans = _.filter(
        this.props.user.auth.api_plans,
        function (plan, i) {
          return true;
        }
      );
      return (
        <ApiPlans
          currentPlan={this.props.user.tier}
          onPlanSelected={callback}
          inProgress={this.props.inProgress}
          // planOptions={this.props.envContext.plans}
          planOptions={allActivePlans}
          apiPlanID={this.props.user.api_plan_id}
        />
      );
    }
    console.log(this.props.user, "user<<<<<<<<<<<<<<");
    return (
      <NewPlans
        currentPlan={this.props.user.tier}
        subscription={this.props.user?.subscription?.plan}
        onPlanSelected={callback}
        inProgress={this.props.inProgress}
        // planOptions={this.props.envContext.plans}
        planOptions={allActivePlans}
        usedFeatures={this.props.usedFeatures}
        draftFeatures={this.props.draftFeatures}
      />
    );
  };

  handleChangePaymentMethod = () => {
    this.setState(
      {
        selectedPlan: null,
      },
      () => {
        const { subscription } = this.props.user;

        let planName = subscription.nickname
          ? subscription.nickname
          : "Enterprise";
        this.getCheckout().open({
          name: "Sendtric",
          description: planName + " Plan",
          zipCode: true,
          email: this.props.user.email,
          billingAddress: true,
        });
      }
    );
  };

  renderChangePaymentMethod = () => {
    const { subscription } = this.props.user;

    if (subscription.cancel) {
      return null;
    }
    return (
      <Button
        compact
        className={tableCss.cancelSubscriptionButton}
        style={{ paddingLeft: "0px" }}
        onClick={this.handleChangePaymentMethod}
      >
        {this.renderChangePaymentMethodText()}
      </Button>
    );
  };

  renderChangePaymentMethodText = () => {
    const { subscription } = this.props.user;
    if (subscription.card) {
      return "Change Payment Method";
    } else {
      return "Add Payment Method";
    }
  };

  renderManaged = (end) => {
    return (
      <div>
        <Message positive attached="bottom">
          <Icon name="checkmark" />
          Your enterprise subscription is active until {end.format("LL")}.
          Please email{" "}
          <a href="mailto:support@sendtric.com">support@sendtric.com</a> to make
          changes.
        </Message>
      </div>
    );
  };

  renderCancelButton = () => {
    return (
      <Button
        compact
        className={tableCss.cancelSubscriptionButton}
        onClick={this.handleCancel}
      >
        Cancel Subscription
      </Button>
    );
  };

  handleContact = () => {
    window.location = "mailto:sales@sendtric.com";
  };

  renderActive = () => {
    const { subscription } = this.props.user;
    // let status = subscription.cancel ? "Cancelled" : `Active (${subscription.card || "No Payment Method"})`;
    let status = "";
    if (subscription.cancel) {
      const timestamp = subscription.current_period_end;
      const formattedDate = moment.unix(timestamp).format("MMM-DD");

      status = formattedDate;
    } else {
      if (subscription.card) {
        let values = subscription.card.split(" ");
        status = values[0] + " ****" + values[1];
      } else {
        status = "No Payment Method";
      }
    }
    if (subscription.trial_end) {
      status = "Free Trial";
    }
    const end = this.getSubscriptionEnd();

    let corporateAccount = false;
    if (this.props.user.features.managed) {
      // return this.renderManaged(end);
      corporateAccount = true;
    }
    let consumeView = subscription.views;
    let totalView = subscription.view_allowance;

    let consumeViewText = consumeView.toLocaleString("en-US");
    let totalViewText = totalView.toLocaleString("en-US");

    let euroPlan = this.props.user.features.europe;

    return (
      <div>
        <p className={css.headingLevel2}>Your current plan</p>
        {/* {this.renderSubscriptionActions()} */}
        <Grid>
          <Grid.Row
            style={{ paddingBottom: corporateAccount ? "2px" : "10px" }}
          >
            <Grid.Column width={8}>
              <p className={css.boldText}>
                {subscription.nickname ? subscription.nickname : "Enterprise"}
                <span className={css.lightText}>
                  ({subscription.interval}ly)
                </span>
              </p>
              <p className={css.lightText}>
                {euroPlan ? "€" : "$"} {subscription.price / 100}{" "}
                {euroPlan ? "EUR" : "USD"} / {subscription.interval}
              </p>
            </Grid.Column>
            <Grid.Column width={8}>
              <p className={css.boldText}>Billing</p>
              <p className={css.lightText}>{status}</p>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row
            style={{
              paddingBottom: "10px",
              paddingTop: corporateAccount ? "2px" : "12px",
            }}
          >
            <Grid.Column width={8}>
              <div className={tableCss.filterDiv}>
                <div>
                  {!corporateAccount && (
                    <>
                      {!subscription.cancel && (
                        <a
                          className={tableCss.primaryLinkButton}
                          href="#planArea"
                        >
                          Upgrade Plan
                        </a>
                      )}
                      {!subscription.cancel && this.renderCancelButton()}
                    </>
                  )}
                  {corporateAccount && (
                    <>
                      <Button
                        compact
                        className={tableCss.cancelSubscriptionButton}
                        style={{ paddingLeft: "0px" }}
                        onClick={this.handleContact}
                      >
                        Contact my account manager
                      </Button>
                    </>
                  )}
                </div>
              </div>
            </Grid.Column>
            <Grid.Column width={8}>
              {this.renderChangePaymentMethod()}
            </Grid.Column>
          </Grid.Row>
          <Grid.Row style={{ paddingBottom: "20px" }}>
            <Grid.Column width={16}>
              <div className={tableCss.filterDiv}>
                <div>
                  <p className={css.boldText} style={{ fontWeight: 400 }}>
                    Views
                  </p>
                </div>
                <div>
                  <p style={{ textAlign: "right" }}>
                    {consumeViewText} / {totalViewText}
                  </p>
                </div>
              </div>
              {/* <Progress size='tiny' percent={percentage} color='blue' /> */}
              <Progress
                size="tiny"
                value={consumeView}
                total={totalView}
                color="blue"
                style={{ marginBottom: "0px" }}
              />
              <p style={{ marginTop: "7px" }} className={tableCss.renewText}>
                {status != "Cancelled" && (
                  <>Renews automatically on {end.format("LL")}</>
                )}
              </p>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        {/* <p>
          Status: <b>{status}</b>&nbsp;
          {this.renderChangePaymentMethod()}
          {!subscription.cancel && this.renderCancelButton()}
        </p> */}
        {!this.props.user.features.managed && (
          <>
            <p id="planArea" className={css.headingLevel2}>
              Plans
            </p>
            <hr />
            {this.renderPlans(this.handlePlanSelected)}
          </>
        )}
      </div>
    );
  };

  renderInactive = () => {
    return this.renderPlans(this.handleNewPlan);
  };

  renderConfirmationModal = () => {
    console.log("this.props.user renderConfirmationModal", this.props.user);
    const { subscription } = this.props.user;
    const card = _.get(subscription, "card", "");
    const cancelled = _.get(subscription, "cancel", false);
    const message = cancelled
      ? "This will reactivate your subscription."
      : "This will change your active plan.";

    return (
      <Modal
        size="tiny"
        open={this.state.isConfirmationModalOpen}
        onClose={this.handleCloseConfirmationModal}
      >
        <Modal.Header>Plan Confirmation</Modal.Header>
        <Modal.Content>
          {message}
          <br />
          <br />
          Your current card will be used: <strong>{card}</strong>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={this.handleCloseConfirmationModal}>Cancel</Button>
          <Button positive onClick={this.handlePlanConfirmed}>
            Confirm
          </Button>
        </Modal.Actions>
      </Modal>
    );
  };

  renderSubscription() {
    const { subscription } = this.props.user;

    if (subscription) {
      return this.renderActive();
    } else {
      return this.renderInactive();
    }
  }

  render() {
    const {
      showModal,
      country,
      selectedCountry,
      isOpen,
      searchQuery,
      showTaxIdInput,
      filteredOptions,
      isDropdownOpen,
      dynamicOptions,
      selection,
    } = this.state;

    // const filteredOptions = searchQuery.trim() === '' ? taxRegions : [{ key: 'others', value: 'others', text: 'Others' }];

    return (
      <>
        <Dimmer active={this.props.inProgress} inverted page>
          <Loader inverted>Processing Subscription</Loader>
        </Dimmer>
        {this.renderSubscription()}
        {this.renderConfirmationModal()}

        <Modal
          open={showModal}
          onClose={() => this.setState({ showModal: false })}
        >
          <Modal.Header>Select your option</Modal.Header>
          <Modal.Content>
            <Form>
              <Form.Field>
                <Radio
                  label="Personal"
                  name="radioGroup"
                  value="personal"
                  checked={selection === "personal"}
                  onChange={this.handleRadioChange}
                />
                &nbsp; &nbsp; &nbsp;
                <Radio
                  label="Business"
                  name="radioGroup"
                  value="business"
                  checked={selection === "business"}
                  onChange={this.handleRadioChange}
                />
              </Form.Field>
              {selection === "business" && (
                <Form.Dropdown
                  placeholder={"Select a country"}
                  selection
                  search
                  style={{ width: "100%" }}
                  onBlur={() => {
                    this.setState({
                      searchQuery: "",
                      isOpen: false,
                      filteredOptions: taxRegions,
                    });
                  }}
                  // value={this.state.dropdownValue}
                  searchQuery={searchQuery}
                  onClick={() => this.setState({ isOpen: !isOpen })}
                  onSearchChange={this.searchOperation}
                  onChange={this.handleDropdownChange}
                  open={isOpen}
                  noResultsMessage={null}
                  options={filteredOptions}
                >
                  {/* <Dropdown.Menu>
                    {filteredOptions.map((option) => (
                      <Dropdown.Item
                        key={option.key}
                        text={option.text}
                        value={option.value}
                      />
                    ))}
                  </Dropdown.Menu> */}
                </Form.Dropdown>
              )}
              &nbsp; &nbsp;
              {showTaxIdInput && (
                <Grid>
                  <Grid.Row columns={2}>
                    <Grid.Column>
                      Business name:
                      <Input
                        placeholder="Business Name"
                        value={this.state.businessName}
                        onChange={this.handleBusinessNameChange}
                        fluid
                      />
                    </Grid.Column>
                    <Grid.Column>
                      Tax ID:
                      <Input
                        placeholder="Tax ID"
                        value={this.state.taxId}
                        onChange={this.handleTaxIdChange}
                        fluid
                      />
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              )}
            </Form>
          </Modal.Content>

          <Modal.Actions>
            <Button onClick={this.handleSubmit}>Submit</Button>
          </Modal.Actions>
        </Modal>
      </>
    );
  }
}

export default Billing;
