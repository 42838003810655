import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";

import { createBrowserHistory } from "history";

import _ from "lodash";
import moment from "moment";

import css from "../../assets/styles/templateList/index.module.scss";
import tableCss from "../../assets/styles/shared/tables.module.scss";
import gridCss from "../../assets/styles/shared/grids.module.scss";

import DeleteAction from "./deleteAction";
import TimerPreview from "../shared/timerPreview";
import {
  Button,
  Table,
  Header,
  Popup,
  Icon,
  Grid,
  GridColumn,
  Form,
  Input,
  Select,
  Pagination,
  Tab,
  Dropdown,
} from "semantic-ui-react";
import {
  EditIconComponent,
  LeftArrowIconComponent,
  NewCopyIconComponent,
  RightArrowIconComponent,
} from "../iconComponents";
import CreateTimerButtonContainer from "../../containers/createTimerButtonContainer";
import { useNavigate } from "react-router";

const browserHistory = createBrowserHistory();

const { array, object } = PropTypes;

const options = [
  { key: "template_list_sort_1", text: "Last Updated", value: "descending" },
  { key: "template_list_sort_2", text: "Last Created", value: "ascending" },
];

class Template extends React.Component {
  static propTypes = {
    templates: array,
    envContext: object,
    user: object,
  };

  state = {
    isConfirmDeleteOpen: false,
    searchText: "",
    activePage: 1,
    totalPages: 0,
    recordLimit: 6,
    sortOptions: [
      {
        key: "template_list_sort_1",
        text: "Creation date",
        value: "createdAt",
        sort: "desc",
      },
      {
        key: "template_list_sort_2",
        text: "Updated date",
        value: "updatedAt",
        sort: "desc",
      },
    ],
    currentSort: {
      key: "template_list_sort_1",
      text: "Creation date",
      value: "createdAt",
      sort: "desc",
    },
  };

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.props.fetch();
  }

  isCorporateUser() {
    return (
      this.props.user.is_company ||
      (this.props.user.features &&
        this.props.user.features.subaccount &&
        this.props.user.parent_id == 0)
    );
  }

  isSubaccountUser() {
    return this.props.user.subaccount_id != 0;
  }

  isEditTemplateFeature() {
    return this.isSubaccountUser() && this.props.user.can_edit_templates;
  }

  static getDerivedStateFromProps(props, current_state) {
    let changed = false;
    let newStates = { ...current_state };
    if (props.templates.length > 0) {
      let filteredTemplates = props.templates;
      let totalPage = Math.floor(filteredTemplates.length / 6);
      if (filteredTemplates.length % 6 != 0) {
        totalPage++;
      }
      newStates.totalPages = totalPage;
      changed = true;
    }
    if (changed) {
      return newStates;
    }
    return null;
  }

  formatDate = (date) => {
    return moment(date).format("MM/DD/YYYY");
  };

  handleDelete = (templateId) => {
    return () => {
      this.props.destroy(templateId);
    };
  };

  handleCopy = (template) => {
    return (e) => {
      e.stopPropagation();
      e.preventDefault();
      this.props.copy(template);
    };
  };

  handleEdit = (templateId) => {
    return () => {
      this.props.navigate(`/template/${templateId}`);
    };
  };

  // renderZeroState = () => {
  //   return (
  //     <div className={tableCss.zero}>
  //       You do not currently have any templates. Create one with the button in
  //       the top right!
  //     </div>
  //   );
  // };

  renderTemplates = () => {
    const genUrl = _.get(
      this.props,
      "user.auth.data.auth.gen_url",
      "https://gen.sendtric.com"
    );
    let orderedTemplates = _.orderBy(
      this.props.templates,
      ["updated_at", "name"],
      ["desc", "asc"]
    );
    return _.map(orderedTemplates, (template) => {
      return (
        <Table.Row key={template.id}>
          <Table.Cell className={tableCss.name}>
            {template.name}
            <Popup
              basic
              position="right center"
              trigger={<Icon name="eye" />}
              content={
                <TimerPreview
                  type="Template"
                  timer={template}
                  genUrl={genUrl}
                />
              }
            />
          </Table.Cell>
          <Table.Cell>{this.formatDate(template.createdAt)}</Table.Cell>
          <Table.Cell>{this.formatDate(template.updatedAt)}</Table.Cell>
          <Table.Cell className={tableCss.actions}>
            <Popup
              inverted
              trigger={
                <Icon name="edit" onClick={this.handleEdit(template.id)} />
              }
              content={"Edit"}
              position={"top center"}
            />
            <Popup
              inverted
              trigger={<Icon name="copy" onClick={this.handleCopy(template)} />}
              content={"Copy"}
              position={"top center"}
            />
            <DeleteAction onConfirm={this.handleDelete(template.id)} />
          </Table.Cell>
        </Table.Row>
      );
    });
  };

  renderTableContents = () => {
    // if (_.isEmpty(this.props.templates)) {
    //   return this.renderZeroState();
    // } else {
    return (
      <Table>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Name</Table.HeaderCell>
            <Table.HeaderCell>Created</Table.HeaderCell>
            <Table.HeaderCell>Last Modified</Table.HeaderCell>
            <Table.HeaderCell>Actions</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>{this.renderTemplates()}</Table.Body>
      </Table>
    );
    // }
  };

  searchTextHandler = (e) => {
    this.setState({ searchText: e.target.value });
  };

  handlePaginationChange = (e, { activePage }) => {
    this.setState({ activePage: activePage });
  };

  calculateTotalPages = (length, limit) => {
    let totalPage = Math.floor(length / limit);
    if (length % limit != 0) {
      totalPage++;
    }
    return totalPage;
  };

  sortChangeHandler = (event, data) => {
    this.setState({ currentSort: data });
  };

  renderGridContents = (options = "") => {
    if (false) {
      // return this.renderZeroState();
      return false;
    } else {
      let templates = this.props.templates;

      if (this.isCorporateUser()) {
        templates = _.filter(templates, (template) => {
          return template.subaccount_id == 0;
        });
      } else if (this.isSubaccountUser()) {
        if (options == "corporate") {
          templates = _.filter(templates, (template) => {
            return template.subaccount_id == 0;
          });
        } else if (options == "you") {
          templates = _.filter(templates, (template) => {
            return template.subaccount_id == this.props.user.subaccount_id;
          });
        }
      }

      if (this.state.searchText && this.state.searchText.length != "") {
        templates = _.filter(templates, (template) => {
          return template.name
            .toLowerCase()
            .includes(this.state.searchText.toLowerCase());
        });
      }

      let totalTemplate = templates.length;
      let totalPages = this.calculateTotalPages(
        totalTemplate,
        this.state.recordLimit
      );
      let displayTotalTemplate = this.state.recordLimit;
      if (displayTotalTemplate > totalTemplate) {
        displayTotalTemplate = totalTemplate;
      } else {
        let count = this.state.recordLimit * this.state.activePage;

        if (count > totalTemplate) {
          displayTotalTemplate =
            totalTemplate -
            this.state.recordLimit * (this.state.activePage - 1);
        }
      }
      let currentActivePage =
        this.state.activePage > totalPages ? 1 : this.state.activePage;

      // Logic to filtered Items according to their page size
      let beginIndex = (currentActivePage - 1) * this.state.recordLimit;
      let endIndex = beginIndex + displayTotalTemplate;

      // Sort Logic must be placed here...
      let copyTemplates = [...templates]; // copy of templates array
      copyTemplates?.sort((value1, value2) => {
        const sortValue = this.state.currentSort.value;
        if (sortValue.toLowerCase().indexOf("at") != -1) {
          return (
            new Date(value2[sortValue]).getTime() -
            new Date(value1[sortValue]).getTime()
          );
        } else {
          return parseInt(value2[sortValue]) - parseInt(value1[sortValue]);
        }
      });

      templates = _.slice(templates, beginIndex, endIndex);

      return (
        <>
          <Grid>
            <Grid.Row columns={2}>
              <GridColumn>
                <div className={gridCss.gridSearchBox}>
                  <Input
                    className={gridCss.gridSearchText}
                    icon
                    iconPosition="left"
                    placeholder="Search templates"
                  >
                    <input
                      value={this.state.searchText}
                      onChange={this.searchTextHandler}
                    />
                    <Icon name="search" />
                  </Input>
                </div>
              </GridColumn>
              <GridColumn>
                <div className={gridCss.gridSortBox}>
                  <span style={{ paddingRight: "16px" }}>Sort by</span>
                  <Dropdown
                    placeholder="Sort"
                    defaultValue={this.state.currentSort.value}
                    onChange={this.sortChangeHandler}
                    selection
                    options={this.state.sortOptions}
                  />
                </div>
              </GridColumn>
            </Grid.Row>
            <Grid.Row columns={3}>
              {this.renderGridTemplates(templates, options)}
            </Grid.Row>
          </Grid>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div>
              <span className={tableCss.tableCol}>
                {displayTotalTemplate} out of {totalTemplate}
              </span>
            </div>
            <div>
              <Pagination
                defaultActivePage={currentActivePage}
                onPageChange={this.handlePaginationChange}
                totalPages={totalPages}
                lastItem={null}
                firstItem={null}
                ellipsisItem={{
                  content: <Icon name="ellipsis horizontal" />,
                  icon: true,
                }}
                prevItem={{
                  content: (
                    <span>
                      <LeftArrowIconComponent />
                    </span>
                  ),
                  icon: true,
                }}
                nextItem={{
                  content: (
                    <span>
                      <RightArrowIconComponent />
                    </span>
                  ),
                  icon: true,
                }}
              />
            </div>
          </div>
        </>
      );
    }
  };

  isEditTemplateAvailable(options) {
    if (options == "corporate" || options == "you") {
      return !this.props.user.can_edit_templates;
    }
    return true;
  }

  renderGridTemplates = (templates, options = "") => {
    const genUrl = _.get(
      this.props,
      "user.auth.gen_url",
      "https://gen.sendtric.com"
    );

    // let orderedTemplates = _.orderBy(templates, [this.state.currentSort.value, "name"], [this.state.currentSort.sort, "asc"]);
    return _.map(templates, (template) => {
      return (
        <Grid.Column
          key={"grid_row_template_" + template.id}
          className={gridCss.gridColumn}
        >
          <div className={gridCss.gridTimerContainer}>
            <div className={gridCss.gridImageContainer}>
              <TimerPreview
                type="Template"
                timer={template}
                genUrl={this.props.user.auth?.gen_url}
              />
            </div>
            <div className={gridCss.gridTimerDetails}>
              <div className={gridCss.gridHeadingAndActionRow}>
                <p className={gridCss.gridTimerHeading}>{template.name}</p>
              </div>
              <div className={gridCss.gridCreatedDateRow}>
                <p
                  className={gridCss.gridTextStyle}
                  style={{ paddingRight: "50px" }}
                >
                  Created
                </p>
                <p className={gridCss.gridTextStyle}>
                  {this.formatDate(template.createdAt)}
                </p>
              </div>
              <div className={gridCss.gridUpdatedDateRow}>
                <p
                  className={gridCss.gridTextStyle}
                  style={{ paddingRight: "27px" }}
                >
                  Last modified
                </p>
                <p className={gridCss.gridTextStyle}>
                  {this.formatDate(template.updatedAt)}
                </p>
              </div>
            </div>
            <div className={gridCss.gridActions}>
              <div className={gridCss.actions}>
                {this.isEditTemplateAvailable(options) && (
                  <>
                    {options != "corporate" && (
                      <Popup
                        inverted
                        // trigger={<Icon name="edit" onClick={this.handleEdit(template.id)} />}
                        trigger={
                          <span
                            style={{ cursor: "pointer" }}
                            onClick={this.handleEdit(template.id)}
                          >
                            <EditIconComponent />
                          </span>
                        }
                        content={"Edit"}
                        position={"top center"}
                      />
                    )}
                    {options != "corporate" && (
                      <DeleteAction
                        onConfirm={this.handleDelete(template.id)}
                      />
                    )}
                    <Popup
                      inverted
                      // trigger={<Icon name="copy" onClick={this.handleCopy(template)} />}
                      trigger={
                        <span
                          style={{ cursor: "pointer" }}
                          onClick={this.handleCopy(template)}
                        >
                          <NewCopyIconComponent />
                        </span>
                      }
                      content={"Copy"}
                      position={"top center"}
                    />
                  </>
                )}
              </div>
              <div className={gridCss.useActions}>
                <CreateTimerButtonContainer templateId={template.id + ""}>
                  Use
                </CreateTimerButtonContainer>
              </div>
            </div>
          </div>
        </Grid.Column>
      );
    });
  };

  renderTable = (status) => {
    return () => {
      // return <Tab.Pane as='div' attached={false}>{this.renderTableContents(status)}</Tab.Pane>;
      return (
        <Tab.Pane as="div" attached="false">
          {this.renderGridContents(status)}
        </Tab.Pane>
      );
    };
  };

  renderTabs = () => {
    let tables = [
      { menuItem: "Corporate", render: this.renderTable("corporate") },
      { menuItem: "Created by you", render: this.renderTable("you") },
    ];
    if (this.isEditTemplateFeature()) {
      tables = [
        { menuItem: "Corporate", render: this.renderTable("corporate") },
      ];
    }
    return <Tab menu={{ secondary: true, pointing: true }} panes={tables} />;
  };

  render() {
    return (
      <div id="template-list" className={tableCss.tableContainer}>
        <Header as="h1">Templates</Header>
        <div className={tableCss.tableActions}>
          {!this.isEditTemplateFeature() && (
            <Link to="/template/new">
              {/* <Button color="green">
                <Icon name="plus" />
                Create New
              </Button> */}
              <Button className={css.primaryButton}>Create New</Button>
            </Link>
          )}
        </div>
        {this.isSubaccountUser() && this.renderTabs()}

        {!this.isSubaccountUser() && this.renderGridContents()}
      </div>
    );
  }
}

const TemplateList = (props) => {
  const navigate = useNavigate();
  return <Template {...props} navigate={navigate} />;
};

export default TemplateList;
