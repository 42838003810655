import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { Message } from "semantic-ui-react";

const RegisterSuccess = (props) => {
  const [jumpURL, setJumpURL] = useState("");

  useEffect(() => {
    let path = window.location.href;
    console.log("path register success=", path);
    if (path.includes("staging-app.sendtric.com/register")) {
      setJumpURL("https://staging.sendtric.com");
    } else if (path.includes("app.sendtric.com/register")) {
      setJumpURL("https://www.sendtric.com");
    } else if (path.includes("sendtric.com/register")) {
      setJumpURL("https://www.sendtric.com");
    } else if (path.includes("http://localhost:3001/register")) {
      setJumpURL("http://localhost:3001");
    }
  }, []);

  return (
    <Message positive>
      <Message.Header>Sign Up Successful!</Message.Header>
      <p>Please check your email for activating your account.</p>
      {jumpURL !== "" && <a href={jumpURL}>Return to Home</a>}
      {jumpURL === "" && <Link to="/">Return to Home</Link>}
    </Message>
  );
};

// class RegisterSuccess extends React.Component {
//   render() {
//     return (
//       <Message positive>
//         <Message.Header>Sign Up Successful!</Message.Header>
//         <p>Please check your email for activating your account.</p>
//         <Link to="/">Return to Home</Link>
//       </Message>
//     );
//   }
// }

export default RegisterSuccess;
