import {
  COOKIE_NOTIFICATION_PROMPTED
} from "../constants/toastConstants";

const cookieNotificationPrompted = () => ({ type: COOKIE_NOTIFICATION_PROMPTED });

export function notifyCookies() {
  return (dispatch, getState) => {
    dispatch(cookieNotificationPrompted());
  };
}