import { connect } from "react-redux";

import AppLayout from "../components/appLayout";

const mapStateToProps = (state) => ({
  user: state.auth.data,
  fullWidth: true,
});

export default connect(mapStateToProps)(AppLayout);
