import React from "react";
import _ from "lodash";

import { Popup, Button } from "semantic-ui-react";
import css from "../../assets/styles/editor/timerTypePicker.module.scss";
import PlanBadge from "../editor/planBadge";

const handleTypeClickCurry = (onChange, type) => {
  return (e) => {
    onChange(type);
    e.preventDefault();
  };
};

const TimerTypePicker = ({ type, features, onChange }) => {
  let standardButton =
    type === "standard" ? css.activeButton : css.unactiveButton;
  // New changes for Recurring Timer
  let recurringButton =
    type === "recurring" ? css.activeButton : css.unactiveButton;
  let perpetualButton =
    type === "perpetual" ? css.activeButton : css.unactiveButton;
  let dynamicButton =
    type === "dynamic" ? css.activeButton : css.unactiveButton;

  const buttonStyle = { marginRight: "5px" };
  return (
    <Button.Group className="buttonGroupWithGap" style={{ width: "100%" }}>
      <Popup
        basic
        trigger={
          <Button
            className={standardButton}
            // primary={type === "standard"}
            onClick={handleTypeClickCurry(onChange, "standard")}
            style={buttonStyle}
          >
            Standard
          </Button>
        }
        content={"Count down to a specified end date and time."}
      />

      <Popup
        basic
        trigger={
          <Button
            className={recurringButton}
            // primary={type === "perpetual"}
            onClick={handleTypeClickCurry(onChange, "recurring")}
            style={buttonStyle}
          >
            Recurring
            <PlanBadge
              show={features && !features.recurring}
              label="POWER"
              inUse={type === "recurring"}
            />
          </Button>
        }
        content={
          <>
            Timers that repeat automatically.
            {features && !features.recurring
              ? " Recurring timers are available in our Power plan."
              : null}
          </>
        }
      />

      <Popup
        basic
        trigger={
          <Button
            className={perpetualButton}
            // primary={type === "perpetual"}
            onClick={handleTypeClickCurry(onChange, "perpetual")}
            style={buttonStyle}
          >
            Perpetual
          </Button>
        }
        content={"Always count down the same amount of time."}
      />

      <Popup
        basic
        trigger={
          <Button
            className={dynamicButton}
            // primary={type === "dynamic"}
            onClick={handleTypeClickCurry(onChange, "dynamic")}
            style={buttonStyle}
          >
            Dynamic
            <PlanBadge
              show={features && !features?.dynamic}
              label="POWER"
              inUse={type === "dynamic"}
            />
          </Button>
        }
        content={
          <>
            Pass your own timestamp dynamically. Great for transactional emails!
            {features && !features.dynamic
              ? " Dynamic timers are available in our Power plan."
              : null}
          </>
        }
      />
    </Button.Group>
  );
};

export default TimerTypePicker;
