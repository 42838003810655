import PropTypes from "prop-types";
import React from "react";
import _, { template } from "lodash";
import { createBrowserHistory } from "history";

import Editor from "../shared/editor";
const browserHistory = createBrowserHistory();

const { object } = PropTypes;
const defaultTemplate = {
  id: "new",
  name: "New Template",
  templateData: {
    size: "medium",
    digits: {
      years: {
        label: "YEARS",
        hidden: true,
        digit_color: "ffffff",
        label_color: "ffffff",
        label_status: false,
        digit_status: false,
      },
      days: {
        label: "DAYS",
        hidden: false,
        digit_color: "ffffff",
        label_color: "ffffff",
        label_status: false,
        digit_status: false,
      },
      hours: {
        label: "HOURS",
        hidden: false,
        digit_color: "ffffff",
        label_color: "ffffff",
        label_status: false,
        digit_status: false,
      },
      minutes: {
        label: "MINUTES",
        hidden: false,
        digit_color: "ffffff",
        label_color: "ffffff",
        label_status: false,
        digit_status: false,
      },
      seconds: {
        label: "SECONDS",
        hidden: false,
        digit_color: "ffffff",
        label_color: "ffffff",
        label_status: false,
        digit_status: false,
      },
    },
    colors: {
      digits: "ffffff",
      labels: "ffffff",
      background: "000000",
      accent: "ff0000",
    },
    fonts: {
      labels: "NotoSans",
      digits: "DroidSansMono",
    },
    backgroundImage: null,
    expirationImage: null,
    transparent: false,
  },
};

class Template extends React.Component {
  constructor(props) {
    super(props);
  }

  static propTypes = {
    template: object,
    user: object,
    pageId: PropTypes.string,
    isTemplateSubmitting: PropTypes.bool, // Add this line
  };

  static defaultProps = {
    template: _.cloneDeep(defaultTemplate),
  };

  componentDidMount() {
    this.props.fetchCustomFont();
    this.props.fetch();
  }

  handleSave = (data) => {
    this.props.save(data);
  };

  handleCancel = () => {
    browserHistory.push("/templates");
  };

  render() {
    const genUrl = _.get(
      this.props,
      "user.auth.gen_url",
      "https://gen.sendtric.com"
    );

    // let templateInformation = {...this.props.template};
    let templateInformation = _.cloneDeep(this.props.template);

    if (this.props.pageId) {
      templateInformation.id = this.props.pageId;
    }

    if (templateInformation.id === "new") {
      let userDetails = { ...this.props.user };

      if (userDetails.use_default_status) {
        if (userDetails.default_label_font != "") {
          templateInformation.templateData["fonts"]["labels"] =
            userDetails.default_label_font;
        }
        if (userDetails.default_digit_font != "") {
          templateInformation.templateData["fonts"]["digits"] =
            userDetails.default_digit_font;
        }
      }
    }

    return (
      <Editor
        type="Template"
        genUrl={genUrl}
        target={templateInformation}
        handleSave={this.handleSave}
        handleCancel={this.handleCancel}
        handleUploadError={this.handleUploadError}
        user={this.props.user}
        key={this.props.template.id}
        isSubmitting={this.props.isTemplateSubmitting}
      />
    );
  }
}

export default Template;
