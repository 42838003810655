import {
  SUBACCOUNT_SAVE_SUCCESS,
  SUBACCOUNT_SAVE_FAILURE,
  SUBACCOUNT_FETCH_SUCCESS,
  SUBACCOUNT_FETCH_FAILURE,
  CORPORATE_USER_SAVE_SUCCESS,
  CORPORATE_USER_SAVE_FAILURE, 
  CORPORATE_USER_FETCH_SUCCESS,
  CORPORATE_USER_FETCH_FAILURE,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAILURE,
  UPDATE_USER_STEP_COUNT_SUCCESS,
  UPDATE_USER_STEP_COUNT_FAILURE,
 
} from "../constants/accountConstants";

import generateApiClient from "../helpers/generateApiClient";
import getErrorMessage from "../helpers/getErrorMessage";

const saveSuccess = (data) => ({ type: SUBACCOUNT_SAVE_SUCCESS, data });
const saveFailure = (error) => ({ type: SUBACCOUNT_SAVE_FAILURE, error });

const fetchSuccess = (data) => ({ type: SUBACCOUNT_FETCH_SUCCESS, data });
const fetchFailure = (error) => ({ type: SUBACCOUNT_FETCH_FAILURE, error });

const registerCorporateSuccess = (data) => ({ type: CORPORATE_USER_SAVE_SUCCESS, data });
const registerCorporateFailure = (error) => ({ type: CORPORATE_USER_SAVE_FAILURE, error });

const fetchCorporateSuccess = (data) => ({ type: CORPORATE_USER_FETCH_SUCCESS, data });
const fetchCorporateFailure = (error) => ({ type: CORPORATE_USER_FETCH_FAILURE, error });

const deleteUserSuccess = (data) => ({ type: DELETE_USER_SUCCESS, data });
const deleteUserFailure = (error) => ({ type: DELETE_USER_FAILURE, error });

const updateStepCountSuccess = (data) => ({ type: UPDATE_USER_STEP_COUNT_SUCCESS,data});
const updateStepCountFailure = (error) => ({ type: UPDATE_USER_STEP_COUNT_FAILURE,error});





export function fetch() {
  return (dispatch, getState) => {
    const client = generateApiClient(getState);
   
    return client.get("/api/v1/corporate_subaccount")
      .then((response) => {
        dispatch(fetchSuccess(response.data));        
      }).catch((error) => {
        dispatch(fetchFailure(getErrorMessage(error)));
      });
  };
}

export function fetchCorporate() {
  return (dispatch, getState) => {
    const client = generateApiClient(getState);     
    return client.get("/api/v1/subaccounts/corporate")
      .then((response) => {
        dispatch(fetchCorporateSuccess(response.data));        
      }).catch((error) => {
        dispatch(fetchCorporateFailure(getErrorMessage(error)));
      });
  };
}

/*
export function create(name,email,password,subaccount_name) {
  return (dispatch, getState) => {
    const client = generateApiClient(getState);
    const params = {
      name,
      email,
      password,
      subaccount_name
    };

    Just for updating concepts

    return client.post("/api/v1/subaccounts", params)
      .then((response) => {
        dispatch(saveSuccess(response.data));  
      }).catch((error) => {
        dispatch(saveFailure(getErrorMessage(error)));
      });
  };
}
*/

export function create(subaccount_name,users) {
  return (dispatch, getState) => {
    const client = generateApiClient(getState);
    const params = {
      subaccount_name,
      users
    };

    return client.post("/api/v1/subaccounts", params)
      .then((response) => {
        dispatch(saveSuccess(response.data));  
      }).catch((error) => {
        dispatch(saveFailure(getErrorMessage(error)));
      });
  };
}

export function createCorporate(users) {
  return (dispatch, getState) => {
    const client = generateApiClient(getState);
    const params = {
      users
    };

    return client
      .post("/api/v1/subaccounts/corporate", params)
      .then((response) => {        
        dispatch(registerCorporateSuccess(response.data));
      })
      .catch((error) => {
        dispatch(registerCorporateFailure(getErrorMessage(error)));
      });
  };
}

export function deleteUser(user_id) {
  return (dispatch, getState) => {
    const client = generateApiClient(getState);
    const params = {
      user_id
    };

    return client
      .post("/api/v1/subaccounts/delete_user", params)
      .then((response) => {        
        dispatch(deleteUserSuccess(response.data));
      })
      .catch((error) => {
        dispatch(deleteUserFailure(getErrorMessage(error)));
      });
  };
}

export function updateStepCount(step_count){
  return (dispatch, getState) => {
    const client = generateApiClient(getState);
    const params = {
      step_count
    };

    return client
      .post("/api/v1/update_step_count", params)
      .then((response) => {        
        dispatch(updateStepCountSuccess(response.data));
      })
      .catch((error) => {
        dispatch(updateStepCountFailure(getErrorMessage(error)));
      });
  };

}


