// import _ from "lodash";
// import { connect } from "react-redux";

// import Template from "../components/template";
// import { save, destroy, fetch } from "../actions/templateActions";
// import { uploadFailed } from "../actions/uploadActions";
// import { fetchCustomFont } from "../actions/customFontActions";

// const findTemplate = (templates, pageId) => {
//   return _.find(templates, (template) => {
//     const templateId = _.get(template, "id", "").toString();
//     return templateId === pageId;
//   });
// };

// const mapStateToProps = (state, props) => ({
//   template: findTemplate(state.template.data, props.params.id),
//   envContext: state.envContext.data,
//   user: state.auth.data,
// });

// const mapDispatchToProps = (dispatch, ownProps) => ({
//   save: (templateData) => {
//     dispatch(save(templateData));
//   },
//   destroy: (templateId) => {
//     dispatch(destroy(templateId));
//   },
//   fetch: () => {
//     dispatch(fetch())
//   },
//   handleUploadError: (err) => {
//     dispatch(uploadFailed(err));
//   },
//   fetchCustomFont:()=>{
//     dispatch(fetchCustomFont());
//   },
// });

// export default connect(mapStateToProps, mapDispatchToProps)(Template);

// import React from "react";
// import { useParams } from "react-router-dom";
// import { connect } from "react-redux";
// import _ from "lodash";
// import Template from "../components/template";
// import { save, destroy, fetch } from "../actions/templateActions";
// import { uploadFailed } from "../actions/uploadActions";
// import { fetchCustomFont } from "../actions/customFontActions";

// const findTemplate = (templates, pageId) => {
//   return _.find(templates, (template) => {
//     const templateId = _.get(template, "id", "").toString();
//     return templateId === pageId;
//   });
// };

// const TemplateContainer = (props) => {
//   const { id } = useParams();
//   return <Template {...props} pageId={id} />;
// };

// const mapStateToProps = (state, props) => {
//   const pageId = props.pageId || null;

//   console.log("props:", props);

//   return {
//     template: findTemplate(state.template.data, pageId),
//     // envContext: state.envContext.data,
//     user: state.auth.data,
//   };
// };

// const mapDispatchToProps = (dispatch) => ({
//   save: (templateData) => {
//     dispatch(save(templateData));
//   },
//   destroy: (templateId) => {
//     dispatch(destroy(templateId));
//   },
//   fetch: () => {
//     dispatch(fetch());
//   },
//   handleUploadError: (err) => {
//     dispatch(uploadFailed(err));
//   },
//   fetchCustomFont: () => {
//     dispatch(fetchCustomFont());
//   },
// });

// export default connect(mapStateToProps, mapDispatchToProps)(TemplateContainer);

import React from "react";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import _ from "lodash";
import Template from "../components/template";
import { save, destroy, fetch } from "../actions/templateActions";
import { uploadFailed } from "../actions/uploadActions";
import { fetchCustomFont } from "../actions/customFontActions";

// Helper function to find the template
const findTemplate = (templates, pageId) => {
  return _.find(templates, (template) => {
    const templateId = _.get(template, "id", "").toString();
    return templateId === pageId;
  });
};

// TemplateContainer Component
const TemplateContainer = (props) => {
  const { id } = useParams(); // Get pageId from useParams
  return (
    <Template
      {...props}
      pageId={id}
      isTemplateSubmitting={props.isTemplateSubmitting}
    />
  );
};

// mapStateToProps function
const mapStateToProps = (state, props) => {
  const pageId = props.pageId || null; // Use the pageId passed via props
  console.log("mapStateToProps props:", props);

  return {
    template: findTemplate(state.template.data, pageId), // Find template by pageId
    user: state.auth.data,
    isTemplateSubmitting: state.template.isTemplateSubmitting,
  };
};

// mapDispatchToProps function
const mapDispatchToProps = (dispatch) => ({
  save: (templateData) => {
    dispatch(save(templateData));
  },
  destroy: (templateId) => {
    dispatch(destroy(templateId));
  },
  fetch: () => {
    dispatch(fetch());
  },
  handleUploadError: (err) => {
    dispatch(uploadFailed(err));
  },
  fetchCustomFont: () => {
    dispatch(fetchCustomFont());
  },
});

// Connect the component to Redux
const ConnectedTemplateContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(TemplateContainer);

// Pass pageId from useParams to mapStateToProps via props
const Wrapper = () => {
  const { id } = useParams(); // Get pageId from useParams
  return <ConnectedTemplateContainer pageId={id} />;
};

export default Wrapper;
