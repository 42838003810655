import React from "react";
import { Link } from "react-router-dom";

import css from "../../assets/styles/layout/navLink.module.scss";

class NavLink extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <Link
        {...this.props}
        className={`${css.navLink} ${this.props.activeClass}`}
      />
    );
  }
}

export default NavLink;
