import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Dropdown, Form } from "semantic-ui-react";
// import brandFont from "styles/shared/corporateBrandFontEditor.scss";
import fonts from "../../assets/fonts/fontOptions";

import brandFont from "../../assets/styles/shared/corporateBrandFontEditor.module.scss";

export default function NewFontSelector(props) {
  //fontName={labelFont}  updateInBackend={handleUpdateDefaultFont}
  const [selectedItems, setSelectedItems] = useState([]);
  const userAllFonts = useSelector(
    (state) => state.subaccountDetails.custom_fonts
  );
  const userDetails = useSelector((state) => state.auth.data);
  const [fontList, setFontList] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [isOpen, setIsOpen] = useState(false); // Track dropdown open/close state

  const isCorporateUser = () => {
    return (
      userDetails?.is_company ||
      (userDetails?.features?.subaccount && userDetails?.parent_id == 0)
    );
  };

  const isSubaccountUser = () => {
    return userDetails?.subaccount_id != 0;
  };

  useEffect(() => {
    let fontsArr = [];
    let id = 0;
    if (isCorporateUser() || isSubaccountUser()) {
      if (userAllFonts.length > 0) {
        let allFontItems = userAllFonts.map((fontDetails, index) => {
          id++;
          return {
            id: id,
            label: fontDetails.font_name,
            value: fontDetails.font_path,
          };
        });
        let allFonts = { name: "Your fonts", items: allFontItems };
        fontsArr.push(allFonts);
      }
    }

    let filteredFonts = fonts.all.filter((font) => {
      if (font["uid"]) {
        return (
          font["uid"] == userDetails?.id ||
          font["uid"] == userDetails?.parent_id
        );
      } else {
        return true;
      }
    });
    let allFontItems = filteredFonts.map((fontDetails, index) => {
      id++;
      return { id: id, label: fontDetails.label, value: fontDetails.value };
    });
    let allFonts = { name: "All fonts", items: allFontItems };
    fontsArr.push(allFonts);
    setFontList(fontsArr);
  }, [userAllFonts]);

  const handleItemSelected = (itemName) => {
    setSelectedItems([itemName]); // Replace the array with a single item
  };

  // const dropdownRef = useRef();

  const handleItemClick = (itemName, itemValue) => {
    setSearchQuery(itemName);
    setIsOpen(false);
    if (props.updateInBackend) {
      props.updateInBackend(itemValue);
    }
    handleItemSelected(itemName);
  };

  const handleDropdownOpen = () => {
    setIsOpen(true);
  };

  const handleDropdownClose = () => {
    setIsOpen(false);
    setSearchQuery(""); // Clear search query when closing dropdown
  };

  const searchOperation = (e, data) => {
    setSearchQuery(data.searchQuery);
    if (!isOpen) {
      setIsOpen(true);
    }
  };

  const filteredFonts = fontList
    .map((font) => ({
      ...font,
      items: font.items.filter((item) =>
        item.label.toLowerCase().includes(searchQuery.toLowerCase())
      ),
    }))
    .filter((font) => font.items.length > 0);

  // Code to compare selectedValue
  if (props.fontName != "") {
    try {
      filteredFonts.forEach((font, index) => {
        font.items.forEach((item, index) => {
          if (item.value == props.fontName) {
            if (selectedItems[0] != item.label) {
              setSelectedItems([item.label]);
              throw new Error("Exit Loop");
            }
          }
        });
      });
    } catch (error) {}
  }

  return (
    <Form.Dropdown
      text={selectedItems[0] || "Select an item"}
      selection
      search
      style={{ width: props.fromEditor ? "100%" : "300px" }}
      onBlur={() => {
        setSearchQuery("");
        setIsOpen(false);
      }} // Clear search query on blur
      searchQuery={searchQuery}
      onClick={() => setIsOpen(!isOpen)}
      onSearchChange={searchOperation}
      open={isOpen}
      // Assign the ref to the Dropdown component
    >
      <Dropdown.Menu className={brandFont.customDropdownMenu}>
        {filteredFonts.map((font) => (
          <React.Fragment key={font.name}>
            <Dropdown.Header
              content={font.name}
              className={brandFont.customDropdownMenuHeader}
            />
            {font.items.map((item) => (
              <Dropdown.Item
                key={item.id}
                content={item.label}
                onClick={() => handleItemClick(item.label, item.value)}
                className={brandFont.customDropdownMenuItem}
                style={{
                  fontWeight: selectedItems.includes(item.label)
                    ? "bold"
                    : "normal",
                  backgroundColor: selectedItems.includes(item.label)
                    ? "lightblue"
                    : "white",
                  color: selectedItems.includes(item.label) ? "blue" : "black", // Change text color to blue for selected items
                  cursor: "pointer", // Add cursor pointer here
                }}
              />
            ))}
          </React.Fragment>
        ))}
      </Dropdown.Menu>
    </Form.Dropdown>
  );
}
