import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import classNames from "classnames";

import css from "../../assets/styles/layout/logo.module.scss";
import { useSelector } from "react-redux";

const Logo = (props) => {
  const currentUser = useSelector((state) => state.auth.data);
  const [isTrialUser, setIsTrialUser] = useState(false);
  const [jumpURL, setJumpURL] = useState("http://localhost:3001/");

  useEffect(() => {
    let path = window.location.href;
    let status = false;
    if (currentUser != null) {
      status = currentUser.tier === "trial";
      if (status) {
        if (path.includes("staging-app")) {
          setJumpURL(
            "https://staging.sendtric.com?email=" + btoa(currentUser.email)
          );
        } else if (path.includes("app.sendtric.com")) {
          setJumpURL(
            "https://www.sendtric.com?email=" + btoa(currentUser.email)
          );
        } else if (path.includes("localhost")) {
          status = false;
        }
      } else if (path.includes("sendtric.com")) {
        setJumpURL("/");
      }
    } else {
      if (
        path.includes("staging-app.sendtric.com") ||
        path.includes("staging-app.sendtric.com/register")
      ) {
        setJumpURL("https://staging.sendtric.com");
        status = true;
      } else if (
        path.includes("app.sendtric.com") ||
        path.includes("app.sendtric.com/register")
      ) {
        setJumpURL("https://www.sendtric.com");
        status = true;
      }
    }
    setIsTrialUser(status);
  }, [currentUser]);

  return (
    <>
      {isTrialUser && (
        <a href={jumpURL} className={classNames(css.logo, props.className)}>
          <img src="/logo.png" style={{ marginTop: "-10px", width: "120px" }} />
        </a>
      )}
      {!isTrialUser && (
        <Link to={jumpURL} className={classNames(css.logo, props.className)}>
          <img src="/logo.png" style={{ marginTop: "-10px", width: "120px" }} />
        </Link>
      )}
    </>
  );
};

export default Logo;
