import React from "react";

import { Popup } from "semantic-ui-react";

import css from "../../assets/styles/editor/planBadge.module.scss";
import classNames from "classnames";

const PlanBadge = ({ show, label, popup, inUse, ...props }) => {
  if (!show) {
    return null;
  }

  const badgeClass = classNames({
    badge: true,
    [css.planBadge]: !inUse,
    [css.planWarningBadge]: inUse,
  });

  return (
    <Popup basic disabled={!popup} trigger={<span className={badgeClass}>{label}</span>} {...props}>
      {popup}
    </Popup>
  );
};

export default PlanBadge;