import { LOCATION_CHANGE } from "react-router-redux";
import _ from "lodash";

import {
  SUBACCOUNT_SAVE_FAILURE,
  SUBACCOUNT_SAVE_SUCCESS,
  SUBACCOUNT_FETCH_SUCCESS,
  SUBACCOUNT_FETCH_FAILURE,
  CORPORATE_USER_SAVE_SUCCESS,
  CORPORATE_USER_SAVE_FAILURE,  
  CORPORATE_USER_FETCH_SUCCESS,
  CORPORATE_USER_FETCH_FAILURE,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAILURE,
 
} from "../constants/accountConstants";





export const initialState = {
  data: [],
  users:[],
  error: null
};

export default (state = initialState, action) => {  
  switch (action.type) {
    case SUBACCOUNT_SAVE_SUCCESS:
      let new_data = [...state.data];
      let action_data = {...action.data,total_timer_count:0,total_views:0,paid_views:0};
      new_data.push(action_data);
      return { ...state, data:new_data, error: null };
    case SUBACCOUNT_SAVE_FAILURE:
      return { ...state, error: action.error };
    case SUBACCOUNT_FETCH_SUCCESS:
      return { ...state, data: action.data, error: null };
    case SUBACCOUNT_FETCH_FAILURE:
      return { ...state, error: action.error };
    
    case CORPORATE_USER_SAVE_SUCCESS:
      let new_users = [...action.data];
      let users_list = [...state.users];
      for(let user of new_users){
        users_list.push(user);
      }
      return { ...state, users: users_list }
    case CORPORATE_USER_SAVE_FAILURE:
      return { ...state , error:action.error}
    case CORPORATE_USER_FETCH_SUCCESS:
      return { ...state, users: action.data, error: null };
    case CORPORATE_USER_FETCH_FAILURE:
      return { ...state , error:action.error}
    case DELETE_USER_SUCCESS:
      let new_users_list = [...state.users];
      new_users_list = _.filter(new_users_list,function(user){
        return user.id != action.data.id;
      });
      return { ... state, users:new_users_list };
    case DELETE_USER_FAILURE:
      return { ...state , error:action.error}
    default:
      return state;
  }
};
