import React, { useEffect,useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { updateUser } from '../../actions/authenticationActions'; // Adjust the import according to your file structure
import { ClipLoader } from 'react-spinners';
const PostPaymentRedirect = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const redirect = "/dashboard";
        dispatch(updateUser(navigate, { redirect }));
      } catch (error) {
        console.error('Error fetching user data:', error);
        // Handle error, e.g., show notification, redirect to an error page, etc.
      }
    };

    fetchUserData();
  }, [dispatch, navigate]);

  return (
    <div>
      {loading ? (
        <div style={styles.loaderContainer}>
          <ClipLoader color={"#123abc"} loading={loading} size={50} />
          <p style={styles.loadingText}>Your payment is processing, please wait...</p>
        </div>
      ) : (
        <p style={styles.successText}>Your payment has been successfully done! Redirecting to dashboard...</p>
      )}
    </div>
  );
};

const styles = {
  loaderContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh', // Full height of the viewport
    textAlign: 'center'
  },
  loadingText: {
    marginTop: '20px',
    fontSize: '16px'
  },
  successText: {
    textAlign: 'center',
    fontSize: '16px'
  }
};


export default PostPaymentRedirect;
