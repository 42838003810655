import PropTypes from 'prop-types';
import React from "react";
import _ from "lodash";
import moment from "moment-timezone";

import { Form, Dropdown } from "semantic-ui-react";
import zones from "../../helpers/timezone";

const { string, func } = PropTypes;

class TimezonePicker extends React.Component {
  static propTypes = {
    timezone: string,
    handleChange: func
  };

  state = { options: [] };

  componentDidMount() {
    const mytz = moment.tz.guess()
    if (mytz && !_.includes(zones, mytz)) {
      zones.push(mytz)
    }
    if (this.props.timezone && !_.includes(zones, this.props.timezone)) {
      zones.push(this.props.timezone)
    }

    const timezoneOptions = _.map(zones, (tz) => {
      const timezone = moment.tz(tz);

      return {
        text: `${tz.replace(/_/g, ' ')} (UTC ${timezone.format("Z")})`,
        value: tz,
        offset: timezone.utcOffset()
      };
    });

    const sortedTimezones = _.orderBy(timezoneOptions, ["offset", "text"], ["asc", "desc"]);

    this.setState({ options: sortedTimezones })
  }

  render() {
    return (
      <Form.Dropdown selection search fluid
        label="Time Zone"
        options={this.state.options}
        value={this.props.timezone}
        onChange={this.props.handleChange} />
    );
  }
}

export default TimezonePicker;