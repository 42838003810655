import PropTypes from "prop-types";
import React from "react";
import _ from "lodash";
import classNames from "classnames";

import {
  Card,
  List,
  Button,
  Loader,
  Divider,
  Label,
  Popup,
  Icon,
} from "semantic-ui-react";
// import { Link } from "react-router";

// import css from "styles/shared/plans.scss";
// import plan_css from "styles/shared/planButton.scss";

import plan_css from "../../assets/styles/shared/planButton.module.scss";

import timerHelper from "../../helpers/timers/timerHelper";
import {
  BlackCrossIconComponent,
  BlackTickIconComponent,
  BlueInfoIconComponent,
  GrayAboutIconComponent,
  GrayCrossIconComponent,
  GrayTickIconComponent,
  GreenAboutIconComponent,
  GreenPinIconComponent,
  GreenTickIconComponent,
  PinkAboutIconComponent,
  PinkCrossIconComponent,
  PinkTickIconComponent,
  RedCrossIconComponent,
  SaveGreenIconComponent,
} from "../iconComponents";

const { string, func, bool, array } = PropTypes;

const planDescriptions = {
  Micro: "Starting Up",
  Standard: "Growing Businesses",
  Power: "For Professionals",
};

class NewPlans extends React.Component {
  static propTypes = {
    currentPlan: string.isRequired,
    subscription: string.isRequired,
    onPlanSelected: func.isRequired,
    planOptions: array.isRequired,
    usedFeatures: array,
    draftFeatures: array,
  };

  constructor(props) {
    super(props);

    this.state = {
      trialCreate: true,
      monthlySelected: true,
    };

    this.updateTheMonthlySelection();
  }

  updateTheMonthlySelection = () => {
    setTimeout(() => {
      let index = _.findIndex(this.props.planOptions, (plan) => {
        return plan.id === this.props.currentPlan;
      });

      if (index != -1) {
        let plan = this.props.planOptions[index];
        if (plan.features.annual) {
          this.setState({ monthlySelected: false });
        }
      }
    }, 500);
  };

  setTrialCreate(value) {
    // Allow creating timers in trial
    this.setState({
      trialCreate: value == 1,
    });
  }

  handleChoosePlanCurry = (plan) => {
    return (e) => {
      if (plan.id === this.props.subscription) {
        return null;
      }

      this.props.onPlanSelected(plan);
    };
  };

  renderPlanButton = (plan) => {
    let plan_name = plan.name;

    if (plan.id === this.props.subscription) {
      return (
        <Button disabled fluid className={plan_css.getStartedButton}>
          Current Plan
        </Button>
      );
    } else {
      return (
        <>
          {plan_name.toLowerCase() == "micro" && (
            <Button
              fluid
              onClick={this.handleChoosePlanCurry(plan)}
              className={plan_css.microStartedButton}
            >
              Choose Plan
            </Button>
          )}
          {plan_name.toLowerCase() == "standard" && (
            <Button
              fluid
              onClick={this.handleChoosePlanCurry(plan)}
              className={plan_css.standardStartedButton}
            >
              Choose Plan
            </Button>
          )}
          {plan_name.toLowerCase() == "power" && (
            <Button
              fluid
              onClick={this.handleChoosePlanCurry(plan)}
              className={plan_css.powerStartedButton}
            >
              Choose Plan
            </Button>
          )}
          {/* {plan_name == "" && <Button fluid primary onClick={this.handleChoosePlanCurry(plan)} className={plan_css.getStartedButton}>
                        Choose Plan
                    </Button>} */}
        </>
      );
    }
  };

  renderNewPlanFeature = (
    plan_name,
    enabled,
    description,
    featureId,
    info = ""
  ) => {
    const used = _.includes(this.props.usedFeatures, featureId);
    const usedDraft = _.includes(this.props.draftFeatures, featureId);

    let icon = <BlackTickIconComponent />;
    if (plan_name.toLowerCase() == "micro") {
      icon = <GrayTickIconComponent />;
    } else if (plan_name.toLowerCase() == "standard") {
      icon = <PinkTickIconComponent />;
    } else if (plan_name.toLowerCase() == "power") {
      icon = <GreenTickIconComponent />;
    }

    if (!enabled) {
      if (usedDraft) {
        if (
          plan_name.toLowerCase() == "micro" ||
          plan_name.toLowerCase() == "standard"
        ) {
          icon = (
            <Popup
              basic
              trigger={<Icon color="red" name="exclamation circle" />}
              content={"The timer you're editing requires this feature."}
            />
          );
        }
      } else if (used) {
        if (
          plan_name.toLowerCase() == "micro" ||
          plan_name.toLowerCase() == "standard"
        ) {
          icon = (
            <Popup
              basic
              trigger={<Icon color="red" name="exclamation circle" />}
              content={"This plan is missing a feature used by your timers."}
            />
          );
        }
      } else {
        icon = <BlackCrossIconComponent />;
        if (plan_name.toLowerCase() == "micro") {
          icon = <GrayCrossIconComponent />;
        } else if (plan_name.toLowerCase() == "standard") {
          icon = <PinkCrossIconComponent />;
        }
      }
    }

    let infoIcon = "";
    if (info != "") {
      infoIcon = (
        <Popup
          basic
          trigger={
            <i>
              {plan_name.toLowerCase() == "micro" && <GrayAboutIconComponent />}
              {plan_name.toLowerCase() == "standard" && (
                <PinkAboutIconComponent />
              )}
              {plan_name.toLowerCase() == "power" && (
                <GreenAboutIconComponent />
              )}
              {plan_name == "" && <BlueInfoIconComponent />}
            </i>
          }
          content={info}
        />
      );
    }

    // ...

    return (
      <div className={plan_css.featuresFont}>
        {icon}
        <span
          style={{ marginLeft: !enabled && (usedDraft || used) ? "-8px" : "0" }}
        >
          {enabled ? (
            description
          ) : (
            <s style={{ color: "#999999" }}>{description}</s>
          )}
        </span>
        {infoIcon}
      </div>
    );
  };

  processButtonSelection = () => {
    this.setState({ monthlySelected: !this.state.monthlySelected });
  };

  renderPerpetualFeature = (plan_name = "") => {
    const info =
      "Timers that always count down the same amount of time, and do not have a specific end date.";

    return (
      <div className={plan_css.featuresFont}>
        <span>
          {plan_name.toLowerCase() == "micro" && <GrayTickIconComponent />}
          {plan_name.toLowerCase() == "standard" && <PinkTickIconComponent />}
          {plan_name.toLowerCase() == "power" && <GreenTickIconComponent />}
          {plan_name == "" && <BlackTickIconComponent />}
        </span>
        <span> Perpetual Timers</span>
        <Popup
          basic
          trigger={
            <i>
              {plan_name.toLowerCase() == "micro" && <GrayAboutIconComponent />}
              {plan_name.toLowerCase() == "standard" && (
                <PinkAboutIconComponent />
              )}
              {plan_name.toLowerCase() == "power" && (
                <GreenAboutIconComponent />
              )}
              {plan_name == "" && <BlueInfoIconComponent />}
            </i>
          }
          content={info}
        />
      </div>
    );
  };

  renderUniqueUserFeature = (plan_name = "") => {
    const info =
      "Timers that are unique to a user. Great when you need timers that are specialized for each user!";

    return (
      <div className={plan_css.featuresFont}>
        <span>
          {plan_name.toLowerCase() == "micro" && <GrayTickIconComponent />}
          {plan_name.toLowerCase() == "standard" && <PinkTickIconComponent />}
          {plan_name.toLowerCase() == "power" && <GreenTickIconComponent />}
          {plan_name == "" && <BlackTickIconComponent />}
        </span>
        <span> Unique User Timers</span>
        <Popup
          basic
          trigger={
            <i>
              {plan_name.toLowerCase() == "micro" && <GrayAboutIconComponent />}
              {plan_name.toLowerCase() == "standard" && (
                <PinkAboutIconComponent />
              )}
              {plan_name.toLowerCase() == "power" && (
                <GreenAboutIconComponent />
              )}
              {plan_name == "" && <BlueInfoIconComponent />}
            </i>
          }
          content={info}
        />
      </div>
    );
  };

  renderPremiumSupportFeature = (plan_name = "") => {
    const info = "Get premium support.";

    return (
      <div className={plan_css.featuresFont}>
        {plan_name.toLowerCase() == "power" && (
          <>
            <span>
              <GreenTickIconComponent />
            </span>
            <span> Premium Support</span>
          </>
        )}
        {plan_name.toLowerCase() != "power" && <span>&nbsp;</span>}
      </div>
    );
  };

  // Euro check must be here...
  isEuropeTimeZone = () => {
    let AllTimeZones = {
      Europe: [
        "Central European Standard Time",
        "Greenwich Mean Time",
        "Moscow Standard Time",
        "Eastern European Standard Time",
        "Western European Summer Time",
        "Eastern European Summer Time",
        "Central European Summer Time",
        "Western European Standard Time",
      ],
    };

    let DateString = new Date().toString();
    let current_timezone = "";
    Object.keys(AllTimeZones).forEach((region) => {
      if (current_timezone === "") {
        AllTimeZones[region].forEach((timezone) => {
          if (DateString.indexOf(timezone) > -1 && current_timezone === "") {
            current_timezone = region;
          }
        });
      }
    });
    return current_timezone !== "";
  };

  renderNewPlanCards = () => {
    let europeZoneStatus = this.isEuropeTimeZone();

    let currencySymbol = "$";

    _.each(this.props.planOptions, (plan, i) => {
      if (this.props.currentPlan == plan.id) {
        europeZoneStatus = plan.features.europe;
      }
    });

    let allPlans = _.filter(this.props.planOptions, (plan, i) => {
      let status = false;
      if (this.state.monthlySelected) {
        status = !plan.features.annual;
      } else {
        status = plan.features.annual;
      }

      if (europeZoneStatus) {
        currencySymbol = "€";
        return status && plan.features.europe;
      } else {
        let monthlyMicroPlan =
          plan.name == "Micro" &&
          !plan.features.annual &&
          this.state.monthlySelected &&
          !plan.features.europe;
        return monthlyMicroPlan || (status && !plan.features.europe);
      }
    });

    return _.map(allPlans, (plan, i) => {
      let headingClasses = classNames(
        plan_css.heading2,
        plan_css.microHeading2
      );

      if (plan.name.toLowerCase() == "standard") {
        headingClasses = classNames(
          plan_css.heading2,
          plan_css.standardHeading2
        );
      } else if (plan.name.toLowerCase() == "power") {
        headingClasses = classNames(plan_css.heading2, plan_css.powerHeading2);
      }

      let planKey =
        (this.state.monthlySelected ? "month_plan_" : "year_plan_") + i;
      return (
        <>
          {
            <div key={planKey} className="col-12 col-md-4">
              <div className={classNames(plan_css.planCard)}>
                <p className={plan_css.heading1}>{plan.name}</p>

                <div className={headingClasses}>
                  <span>{currencySymbol}</span>
                  <span className={plan_css.price}>{plan.price}</span>
                  <span>/{plan.features.annual ? "yr" : "mo"}</span>
                  {plan.features.annual && (
                    <div className={plan_css.saveOption}>
                      {" "}
                      <span className={plan_css.saveGreen}>
                        <SaveGreenIconComponent />
                      </span>{" "}
                      <span className={plan_css.saveText}>
                        Save {plan.save}%
                      </span>
                    </div>
                  )}
                </div>
                <div
                  className={plan_css.font1}
                  style={{ marginBottom: "10px" }}
                >
                  billed {plan.features.annual ? "annually" : "monthly"}
                </div>
                <div className={plan_css.features}>
                  <div className={plan_css.featuresFont}>
                    {" "}
                    <span>
                      {plan.name.toLowerCase() == "micro" && (
                        <GrayTickIconComponent />
                      )}
                      {plan.name.toLowerCase() == "standard" && (
                        <PinkTickIconComponent />
                      )}
                      {plan.name.toLowerCase() == "power" && (
                        <GreenTickIconComponent />
                      )}
                    </span>{" "}
                    <span>Unlimited Countdown Timers</span>
                  </div>
                  <div className={plan_css.featuresFont}>
                    {" "}
                    <span>
                      {plan.name.toLowerCase() == "micro" && (
                        <GrayTickIconComponent />
                      )}
                      {plan.name.toLowerCase() == "standard" && (
                        <PinkTickIconComponent />
                      )}
                      {plan.name.toLowerCase() == "power" && (
                        <GreenTickIconComponent />
                      )}
                    </span>{" "}
                    <span>{plan.views} Countdown Views</span>
                  </div>
                  <div className={plan_css.featuresFont}>
                    {" "}
                    <span>
                      {plan.name.toLowerCase() == "micro" && (
                        <GrayTickIconComponent />
                      )}
                      {plan.name.toLowerCase() == "standard" && (
                        <PinkTickIconComponent />
                      )}
                      {plan.name.toLowerCase() == "power" && (
                        <GreenTickIconComponent />
                      )}
                    </span>{" "}
                    <span>Full Label + Language Customization</span>
                  </div>
                  <div className={plan_css.featuresFont}>
                    {" "}
                    <span>
                      {plan.name.toLowerCase() == "micro" && (
                        <GrayTickIconComponent />
                      )}
                      {plan.name.toLowerCase() == "standard" && (
                        <PinkTickIconComponent />
                      )}
                      {plan.name.toLowerCase() == "power" && (
                        <GreenTickIconComponent />
                      )}
                    </span>{" "}
                    <span>Full Color Customization</span>
                  </div>
                  <div className={plan_css.featuresFont}>
                    {" "}
                    <span>
                      {plan.name.toLowerCase() == "micro" && (
                        <GrayTickIconComponent />
                      )}
                      {plan.name.toLowerCase() == "standard" && (
                        <PinkTickIconComponent />
                      )}
                      {plan.name.toLowerCase() == "power" && (
                        <GreenTickIconComponent />
                      )}
                    </span>{" "}
                    <span>Custom Background Images</span>
                  </div>
                  <div className={plan_css.featuresFont}>
                    {" "}
                    <span>
                      {plan.name.toLowerCase() == "micro" && (
                        <GrayTickIconComponent />
                      )}
                      {plan.name.toLowerCase() == "standard" && (
                        <PinkTickIconComponent />
                      )}
                      {plan.name.toLowerCase() == "power" && (
                        <GreenTickIconComponent />
                      )}
                    </span>{" "}
                    <span>Custom Expired Timer Images</span>
                  </div>
                  <div className={plan_css.featuresFont}>
                    {" "}
                    <span>
                      {plan.name.toLowerCase() == "micro" && (
                        <GrayTickIconComponent />
                      )}
                      {plan.name.toLowerCase() == "standard" && (
                        <PinkTickIconComponent />
                      )}
                      {plan.name.toLowerCase() == "power" && (
                        <GreenTickIconComponent />
                      )}
                    </span>{" "}
                    <span>900+ Font Options</span>
                  </div>
                  {this.renderPerpetualFeature(plan.name)}
                  {this.renderUniqueUserFeature(plan.name)}
                  {this.renderNewPlanFeature(
                    plan.name,
                    plan.features.styles,
                    "Additional Timer Styles",
                    "styles",
                    "Additional timer styles make your campaigns stand out!"
                  )}
                  {this.renderNewPlanFeature(
                    plan.name,
                    plan.features.transparent,
                    "Transparent Backgrounds",
                    "transparent"
                  )}
                  {this.renderNewPlanFeature(
                    plan.name,
                    plan.features.dynamic,
                    "Dynamic Timers",
                    "dynamic",
                    "Timers that can be created with a provided timestamp in the URL.  Perfect for transactional emails!"
                  )}
                  {this.renderNewPlanFeature(
                    plan.name,
                    plan.features.recurring,
                    "Recurring Timers",
                    "recurring",
                    "Timers that repeat automatically."
                  )}
                  {this.renderNewPlanFeature(
                    plan.name,
                    plan.features.fallback,
                    "Fallback Image",
                    "fallback",
                    "Show an image when the email client can’t show the timer. Never lose the real estate of your emails again!"
                  )}
                  {this.renderPremiumSupportFeature(plan.name)}
                </div>
                <div
                  style={{
                    textAlign: "center",
                    marginTop: "20px",
                    marginBottom: "20px",
                  }}
                >
                  {this.renderPlanButton(plan)}
                </div>
              </div>
            </div>
          }
        </>
      );
    });
  };

  render() {
    return (
      <div>
        <div className={plan_css.planDiv} style={{ marginBottom: "10px" }}>
          <div className={plan_css.planButtonDiv}>
            <span
              onClick={this.processButtonSelection}
              className={
                this.state.monthlySelected
                  ? plan_css.notSelectedButton
                  : plan_css.selectedButton
              }
            >
              Annual
            </span>
            <span
              onClick={this.processButtonSelection}
              className={
                this.state.monthlySelected
                  ? plan_css.selectedButton
                  : plan_css.notSelectedButton
              }
            >
              Monthly
            </span>
          </div>
        </div>
        <div className={plan_css.savePlanDiv}>
          <span>
            <GreenPinIconComponent />
          </span>
          <span>Save more with an annual plan</span>
        </div>
        <div className={plan_css.rowContainer}>{this.renderNewPlanCards()}</div>
      </div>
    );
  }
}

export default NewPlans;
