import moment from "moment-timezone";
import _ from "lodash";

const beginning = 1528579800000; // We don't care about timezones before January 1, 2015

const canonicalNames = Object.keys(moment.tz._zones)
  .map(function (k) { return moment.tz._zones[k].split('|')[0]; })
  .filter(function (z) { return z.indexOf('/') >= 0; });

var bestZones = {};

var zoneData = _.map(Object.keys(moment.tz._zones), (name) => {
  let zone = moment.tz.zone(name);
  let relevantOffset = _.findIndex(zone.untils, (until) => {
    return until >= beginning;
  })
  let relevantData = [
    zone.name.split('/')[0],
    zone.abbrs.slice(relevantOffset),
    zone.untils.slice(relevantOffset),
    zone.offsets.slice(relevantOffset)
  ].toString();
  if (!bestZones[relevantData] || bestZones[relevantData].population < zone.population) {
    bestZones[relevantData] = zone;
  }
  return { name: relevantData, zone: zone };
});

var available = _.filter(zoneData, (data) => {
  return (data.zone.population >= bestZones[data.name].population ||
    (data.zone.population >= bestZones[data.name].population * 1 / 5 && data.zone.population > 100000));
})

available = _.map(available, "zone");


export default _.map(available, "name").sort();