import PropTypes from 'prop-types';
import React from "react";

// import fonts from "fonts/fontOptions";

// Then import the virtualized Select HOC
// import VirtualizedSelect from 'react-virtualized-select'

const { string, func } = PropTypes;

class FontSelector extends React.Component {
  static propTypes = {
    handleChange: func,
    value: string
  };


  constructor(props) {
    super(props);
  }

  handleChange = (selected) => {
    this.props.handleChange(null, selected);
  };

  render() {
    return (
      // <VirtualizedSelect
      //   value={this.props.value.replace(/\s/g, '')}
      //   onChange={this.handleChange}
      //   options={fonts.all.filter((font)=>{
      //     if(font["uid"]){
      //       return font["uid"] == this.props.user.id || font["uid"] == this.props.user.parent_id;
      //     }else{
      //       return true;
      //     }
      //   })}
      //   clearable={false}
      // />
      <></>
    );
  }
}

export default FontSelector;
