// import React from "react";

// import { Accordion } from "semantic-ui-react";

// import css from "../../assets/styles/faq/index.module.scss";

// const panels = [
//   {
//     title: "What are templates?",
//     content: "Templates can be used to create a base layout design for your timers.  Once you create a template, you can then generate a timer based on the template.  For example, if you have two different types of email designs, you could use two timer templates to match each of them.  That way you do not have to create timer layouts from scratch!  You can still change any part of the layout for an individual timer once a timer is created from a template."
//   },
//   {
//     title: "What is Fallback mode?",
//     content: "When fallback mode is enabled, we will automatically detect if a user may not load a timer correctly (such as iOS 15 with Mail Privacy Protection enabled) and hide the timer. We recommend leaving this option on."
//   },
//   {
//     title: "How do I use my own background image?",
//     content: "When creating a template, you have the option to upload a background image.  Use this if you want to use your own background design for the countdown.  When you select a size for your timer or template, make note of the timer size dimensions shown in the preview space.  Your background image must match these dimensions if you want your image to scale correctly.  We recommend saving an image of the preview and using this to create your custom background image.  Then you can design around the numbers and labels to ensure everything will line up correctly.  Keep in mind as well that if you'd like to use your own labels in your background image, you can just clear out the labels in our editor!"
//   },
//   {
//     title: "What is a countdown 'view'?",
//     content: "A view occurs when a user loads a countdown (for example when a user opens your email with our countdown).  You can see how many views each timer has in the timers list.  You can also see your total views on your dashboard."
//   },
//   {
//     title: "How do I make sure the countdown is set in the right time zone?",
//     content: "When you create a timer, make sure that you select the right date and time for the target time zone for your users. You can do this using the time zone drop down in the timer creation modal.  The time zone will default to your current time zone.  Make a mistake and set the wrong time?  No problem!  You can always adjust the time on the timer editor page even after your email campaign is sent."
//   },
//   {
//     title: "I made a mistake, I sent out a timer with the wrong styling or end time! What can I do?",
//     content: "With our system you can easily change anything about the timer even after it is sent.  No 'Oops!' correction email necessary!"
//   },
//   {
//     title: "How can I change the language?",
//     content: "Since you can customize the labels however you'd like, you can change the language of the labels directly!  If you need to launch an email campaign for multiple regions, we recommend creating a timer for each region so you can segment your campaign.  There is no reliable way to automatically set the lanaguage for each user depending on their location (e.g. GMail does not support this)."
//   },
//   {
//     title: "How reliable is Sendtric?",
//     content: "We know how important it is that the countdown is served reliably and quickly.  That is why we have an extremely robust infrastructure to ensure that your timer will always function as expected.  If you have any questions, feel free to email us."
//   },
//   {
//     title: "I have another question, or I need some extra customization.",
//     content: {
//       content: (
//         <span>Please email us at <a href='mailto:support@sendtric.com'>support@sendtric.com</a> and we would be happy to assist!</span>
//       ),
//       key: "8",
//     }
//   }
// ];

// class Faq extends React.Component {
//   render() {
//     return (
//       <div className={ css.faqPage }>
//         <h2>FAQs</h2>
//         <div>
//           <Accordion panels={panels} fluid styled />
//         </div>
//         <br/><br/>
//         <p>
//           Check out our <a href="https://www.sendtric.com/help/" target="_blank" rel="noopener noreferrer">Help Site</a> for more useful information and guides!
//         </p>
//       </div>
//     );
//   }
// }

// export default Faq;


import React from "react";
import { Accordion, Icon } from "semantic-ui-react";
import css from "../../assets/styles/faq/index.module.scss";

class Faq extends React.Component {
  state = { activeIndex: -1 };

  handleTitleClick = (e, { index }) => {
    const { activeIndex } = this.state;
    const newIndex = activeIndex === index ? -1 : index;

    this.setState({ activeIndex: newIndex });
  };

  render() {
    const { activeIndex } = this.state;

    const panels = [
      {
        title: "What are templates?",
        content: "Templates can be used to create a base layout design for your timers.  Once you create a template, you can then generate a timer based on the template.  For example, if you have two different types of email designs, you could use two timer templates to match each of them.  That way you do not have to create timer layouts from scratch!  You can still change any part of the layout for an individual timer once a timer is created from a template."
      },
      {
        title: "What is Fallback mode?",
        content: "When fallback mode is enabled, we will automatically detect if a user may not load a timer correctly (such as iOS 15 with Mail Privacy Protection enabled) and hide the timer. We recommend leaving this option on."
      },
      {
        title: "How do I use my own background image?",
        content: "When creating a template, you have the option to upload a background image.  Use this if you want to use your own background design for the countdown.  When you select a size for your timer or template, make note of the timer size dimensions shown in the preview space.  Your background image must match these dimensions if you want your image to scale correctly.  We recommend saving an image of the preview and using this to create your custom background image.  Then you can design around the numbers and labels to ensure everything will line up correctly.  Keep in mind as well that if you'd like to use your own labels in your background image, you can just clear out the labels in our editor!"
      },
      {
        title: "What is a countdown 'view'?",
        content: "A view occurs when a user loads a countdown (for example when a user opens your email with our countdown).  You can see how many views each timer has in the timers list.  You can also see your total views on your dashboard."
      },
      {
        title: "How do I make sure the countdown is set in the right time zone?",
        content: "When you create a timer, make sure that you select the right date and time for the target time zone for your users. You can do this using the time zone drop down in the timer creation modal.  The time zone will default to your current time zone.  Make a mistake and set the wrong time?  No problem!  You can always adjust the time on the timer editor page even after your email campaign is sent."
      },
      {
        title: "I made a mistake, I sent out a timer with the wrong styling or end time! What can I do?",
        content: "With our system you can easily change anything about the timer even after it is sent.  No 'Oops!' correction email necessary!"
      },
      {
        title: "How can I change the language?",
        content: "Since you can customize the labels however you'd like, you can change the language of the labels directly!  If you need to launch an email campaign for multiple regions, we recommend creating a timer for each region so you can segment your campaign.  There is no reliable way to automatically set the lanaguage for each user depending on their location (e.g. GMail does not support this)."
      },
      {
        title: "How reliable is Sendtric?",
        content: "We know how important it is that the countdown is served reliably and quickly.  That is why we have an extremely robust infrastructure to ensure that your timer will always function as expected.  If you have any questions, feel free to email us."
      },
      {
        title: "I have another question, or I need some extra customization.",
        content: "Please email us at support@sendtric.com and we would be happy to assist!"
      }
      
    ];

    return (
      <div className={css.faqPage}>
        <h2>FAQs</h2>
        <div>
          <Accordion fluid styled>
            {panels.map((panel, index) => (
              <div key={index}>
                <Accordion.Title
                  active={activeIndex === index}
                  index={index}
                  onClick={this.handleTitleClick}
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    cursor: "pointer",
                    padding: "20px",
                    borderBottom: "1px solid #ddd",
                  }}
                >
                  <span>{panel.title}</span>
                  <Icon name={activeIndex === index ? "angle down" : "angle right"} />
                </Accordion.Title>
                <Accordion.Content active={activeIndex === index}>
                  {panel.content}
                </Accordion.Content>
              </div>
            ))}
          </Accordion>
        </div>
        <br /><br />
        <p>
          Check out our <a href="https://www.sendtric.com/help/" target="_blank" rel="noopener noreferrer">Help Site</a> for more useful information and guides!
        </p>
      </div>
    );
  }
}

export default Faq;













