import React, { useState } from "react";

import { Form, Button, Popup, Tab, Checkbox } from "semantic-ui-react";
import ClipboardButton from "react-clipboard.js";

import css from "../../assets/styles/editor/htmlCode.module.scss";

const generateHtml = (url, alignment = "left") => {
  const baseHtml = "<img src=\"" + url + "\" style=\"display: block;max-width:100%;\" />";

  if (alignment === "left") {
    return baseHtml;
  } else {
    return `<table align="${alignment}">
  <tbody>
    <tr>
      <td>${baseHtml}</td>
    </tr>
  </tbody>
</table>`;
  }
};

const Code = ({ url, description }) => {
  const [alignment, setAlignment] = useState("center");
  const [useHttps, setUseHttps] = useState(true); // Older one is false Now I am making it true
  const httpUrl = !useHttps ? url.replace("https:", "http:") : url.replace("http:", "https:");
  const timerCode = generateHtml(httpUrl, alignment);
  const copyButton = (
    <ClipboardButton data-clipboard-text={timerCode}>
      Copy Code
    </ClipboardButton>
  );

  return (
    <Tab.Pane key="standard">
      <div className={css.htmlTab}>
        <div className={css.htmlOptions}>
          <Button.Group className={css.option} inline fluid>
            <Button className={alignment === "left" ? css.activeButton : css.unactiveButton} onClick={() => setAlignment("left")}>
              Align Left
            </Button>
            <Button className={alignment === "center" ? css.activeButton : css.unactiveButton} onClick={() => setAlignment("center")}>
              Align Center
            </Button>
            <Button className={alignment === "right" ? css.activeButton : css.unactiveButton} onClick={() => setAlignment("right")}>
              Align Right
            </Button>
          </Button.Group>
          <Checkbox defaultChecked={useHttps} className={css.option} toggle  label="Enable HTTPS" onChange={() => setUseHttps(!useHttps)} />
        </div>
        <span className={css.htmlDescription}>{description}</span>
        <textarea readOnly className={css.timerCode} value={timerCode}></textarea>
        <Popup trigger={copyButton}
          content="Copied!"
          hideOnScroll
          className={css.primaryButton} />
      </div>
    </Tab.Pane>
  );
};

const StandardCode = ({ url }) => {
  const description = "Copy and paste this code into your HTML email template.";
  return <Code url={url} description={description} />;
};

const UniqueCode = ({ url }) => {
  const urlString = `${url}?k={UNIQUE IDENTIFIER}`;
  const description = "Use this code when you want timers that are specialized for each of your users.  Replace {UNIQUE IDENTIFIER} with a unique value for each user (e.g. user id, email address).";
  return <Code url={urlString} description={description} />;
};

const DynamicCode = ({ url }) => {
  const urlString = `${url}?to={UNIX TIMESTAMP}`;
  const description = "Replace {UNIX TIMESTAMP} with a valid unix timestamp (in seconds) generated by your email software or web application.";
  return <Code url={urlString} description={description} />;
};

const HtmlCode = ({ timerType, url }) => {
  let tabPanel;

  const htmlPane = { menuItem: "HTML Code", render: () => <StandardCode url={url} /> };
  const uniquePane = { menuItem: "Unique User Code", render: () => <UniqueCode url={url} /> };
  const dynamicPane = { menuItem: "Dynamic Code", render: () => <DynamicCode url={url} /> };

  if (timerType === "perpetual") {
    const panes = [htmlPane, uniquePane];
    tabPanel = <Tab key="perpetual-panel" panes={panes} />;
  } else if (timerType === "standard") {
    const panes = [htmlPane];
    tabPanel = <Tab key="standard-panel" panes={panes} />;
  } else if (timerType === "dynamic") {
    const panes = [dynamicPane];
    tabPanel = <Tab key="dynamic-panel" panes={panes} />;
  }else if( timerType === "recurring"){
    const panes = [htmlPane];
    tabPanel = <Tab key="recurring-panel" panes={panes}/>;
  }

  return (
    <div className={css.htmlCode}>
      {tabPanel}
    </div>
  );
};

export default HtmlCode;