import PropTypes from "prop-types";
import React from "react";
import _ from "lodash";
import moment from "moment-timezone";

import { Form, Modal, Button, Popup, Icon } from "semantic-ui-react";
import DatetimePicker from "../shared/datetimePicker";
import CustomDatePicker from "../shared/customDatePicker";
import TimezonePicker from "../shared/timezonePicker";
import TimerTypePicker from "../editor/timerTypePicker";
import DurationInput from "../editor/durationInput";

import timerHelper from "../../helpers/timers/timerHelper";
import UpgradePlanButton from "../billing/upgradePlanButton";

import css from "../../assets/styles/timerList/index.module.scss";
import { ClipLoader } from "react-spinners";

const { array, func, bool, object, any, string } = PropTypes;

const initialState = {
  isModalOpen: false,
  templateId: "",
  name: "",
  endsAt: null,
  timeZone: null,
  duration: 0,
  timerType: "standard",
};

class CreateTimerButton extends React.Component {
  static propTypes = {
    templates: array,
    handleCreateTimer: func,
    disabled: bool,
    user: object,
    children: any,
    size: string,
    isSaving: bool,
  };

  constructor(props) {
    super(props);
    this.state = initialState;
  }

  getResetState() {
    const timezone = moment.tz.guess();
    const now = moment().tz(timezone);
    const nearestHour =
      now.minute() || now.second() || now.millisecond()
        ? now.add(1, "hour").startOf("hour")
        : now.startOf("hour");

    moment.tz.setDefault(timezone);

    return {
      ...initialState,
      endsAt: nearestHour,
      timeZone: timezone,
    };
  }

  componentDidMount() {
    this.props.fetch();
  }

  validFormData = () => {
    if (_.isEmpty(this.state.name)) {
      return false;
    }
    if (
      this.state.timerType === "standard" &&
      !timerHelper.validEndTime(this.state.endsAt)
    ) {
      return false;
    }
    return true;
  };

  handleCreateTimer = () => {
    console.log("i am here in handle");
    if (this.validFormData()) {
      this.props.handleCreateTimer(this.state);
      this.handleToggleModal(false)();
    }
  };

  handleChangeEventCurry = (key) => {
    return (event, data) => {
      this.setState({ [key]: data.value });
    };
  };

  handleDateChanged = (date) => {
    this.setState({
      endsAt: date,
    });
  };

  handleToggleModal = (isOpen) => {
    return () => {
      if (this.props.disabled) {
        return null;
      }

      this.setState({
        ...this.getResetState(),
        isModalOpen: isOpen,
        templateId: this.props.templateId ? this.props.templateId : "",
      });
    };
  };

  renderTemplateFeatureWarning = (template) => {
    const features = timerHelper.getFeatures(template);
    const missingFeatures = timerHelper.getMissingFeatures(features, {
      features: this.props.user.features,
    });
    if (missingFeatures.length != 0 && this.props.user.subscription) {
      return (
        <Popup
          basic
          position="right center"
          trigger={<Icon color="red" name="exclamation circle" />}
          content={"This template uses features not included in your plan."}
        />
      );
    }
  };

  isCorporateUser() {
    return (
      this.props.user.is_company ||
      (this.props.user.features &&
        this.props.user.features.subaccount &&
        this.props.user.parent_id == 0)
    );
  }

  isSubaccountUser() {
    return this.props.user.subaccount_id != 0;
  }

  renderModal = () => {
    let templates = [...this.props.templates];

    let selectedTemplateId = this.state.templateId;

    if (
      this.isCorporateUser() ||
      (this.isSubaccountUser() && this.props.user.can_edit_templates)
    ) {
      templates = _.filter(templates, function (template) {
        return template.subaccount_id == 0;
      });
    }

    let templateOptions = _.map(templates, (template) => {
      return {
        text: template.name,
        value: template.id.toString(),
        icon: this.renderTemplateFeatureWarning(template),
      };
    });

    if (this.isSubaccountUser() && this.props.user.can_edit_templates) {
      if (!selectedTemplateId) {
        if (templates.length > 0) {
          selectedTemplateId = templates[0].id.toString();
          // Fixes according to corproate channel
          this.setState({ templateId: selectedTemplateId });
        }
      }
    } else {
      templateOptions.unshift({ text: "(No Template)", value: "", icon: null });
    }

    let template = _.find(
      templates,
      (t) => this.state.templateId == t.id.toString()
    ) || { templateData: {} };

    return (
      <Modal
        size={"small"}
        open={this.state.isModalOpen}
        onClose={this.handleToggleModal(false)}
        closeOnDimmerClick={false}
      >
        <Modal.Header>Create New Timer</Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <Form onSubmit={this.handleCreateTimer}>
              <Form.Input
                placeholder="(e.g. Weekly Sale x/x/xxxx)"
                label="Timer Name"
                onChange={this.handleChangeEventCurry("name")}
              />
              <Form.Dropdown
                search
                selection
                label={
                  <label>
                    Choose Template{this.renderTemplateFeatureWarning(template)}{" "}
                  </label>
                }
                options={templateOptions}
                value={selectedTemplateId}
                onChange={this.handleChangeEventCurry("templateId")}
              />
              <Form.Field>
                <label>Timer Type</label>
                <TimerTypePicker
                  features={this.props.user.features}
                  type={this.state.timerType}
                  onChange={(type) => this.setState({ timerType: type })}
                />
              </Form.Field>
              {this.renderTimerTypeOptions()}
              {this.renderTimerActions()}
            </Form>
          </Modal.Description>
        </Modal.Content>
      </Modal>
    );
  };

  getDraftTimer = () => {
    const template = _.find(
      this.props.templates,
      (t) => this.state.templateId == t.id
    ) || { templateData: {} };
    return { ...template, timerType: this.state.timerType };
  };

  renderTimerActions = () => {
    const draft = this.getDraftTimer();
    const features = timerHelper.getFeatures(draft);
    const missingFeatures = timerHelper.getMissingFeatures(features, {
      features: this.props.user.features,
    });

    // const condition =
    // this.props.user.can_edit_templates && this.isSubaccountUser()
    // ? !this.state.templateId
    // : false;
    // console.log(condition);

    const createButton = (
      <Popup
        basic
        position="bottom center"
        disabled={missingFeatures.length == 0 || !this.props.user.subscription}
        trigger={
          <span className={css.buttonWrapper}>
            <Button
              className={css.primaryButton}
              disabled={
                // !this.validFormData() ||
                // (missingFeatures.length != 0 && this.props.user.subscription) ||
                // this.props.isSaving ||
                // (this.props.user.can_edit_templates && this.isSubaccountUser())
                //   ? !this.state.templateId
                //   : false
                !this.validFormData() ||
                (missingFeatures.length !== 0 &&
                  this.props.user.subscription) ||
                this.props.isSaving ||
                (this.props.user.can_edit_templates && this.isSubaccountUser()
                  ? !this.state.templateId
                  : false)
              }
            >
              {this.props.isSaving ? (
                <div style={{ display: "flex", gap: "3px" }}>
                  Create
                  <ClipLoader color="#fff" size={15} />
                </div>
              ) : (
                "Create"
              )}
            </Button>
          </span>
        }
      >
        Upgrade your plan to create this timer.
      </Popup>
    );
    return (
      <>
        <Button basic onClick={this.handleToggleModal(false)}>
          Cancel
        </Button>
        {createButton}
        {missingFeatures.length != 0 && this.props.user.subscription ? (
          <UpgradePlanButton
            draftTimer={draft}
            upsell={"Upgrade your plan to create this timer."}
          >
            Upgrade Plan
          </UpgradePlanButton>
        ) : null}
      </>
    );
  };

  renderTimerTypeOptions = () => {
    if (this.state.timerType === "perpetual") {
      return this.renderPerpetualOptions();
    } else if (this.state.timerType === "standard") {
      return this.renderStandardOptions();
    } else {
      return null;
    }
  };

  renderPerpetualOptions = () => {
    return (
      <DurationInput
        value={this.state.duration}
        max={1000 * 24 * 60 * 60 - 1}
        onChange={(duration) => this.setState({ duration })}
      />
    );
  };

  renderStandardOptions = () => {
    return (
      <Form.Group widths="equal">
        <TimezonePicker
          timezone={this.state.timeZone}
          handleChange={this.handleChangeEventCurry("timeZone")}
        />
        <div className="field">
          <label>When does your timer end?</label>
          <DatetimePicker
            dateTime={moment(this.state.endsAt).tz(this.state.timeZone)}
            maxDays={365 * 99}
            handleChange={this.handleDateChanged}
          />
        </div>
      </Form.Group>
    );
  };

  render() {
    return (
      <>
        <Button
          className={css.primaryButton}
          disabled={this.props.disabled}
          onClick={this.handleToggleModal(true)}
          size={this.props.size}
        >
          {this.props.children}
        </Button>
        {this.renderModal()}
      </>
    );
  }
}

export default CreateTimerButton;
