import axios from "axios";
//import ReactOnRails from "react-on-rails";
  
export async function checkUserStatus(email_id,basicPassword,token) {
    const headers = {
        "Accept": "application/json",
        "Content-Type": "application/json",
        "X-BasicPassword": basicPassword,
    };

    if (token) {
        headers["Authorization"] = `Bearer ${token}`;
    } else {
        headers["Authorization"] = "";
    }

    const client =  axios.create({
      baseURL: 'http://localhost:3000', // Base URL for your requests
      timeout: 20000,
      headers: headers,
      responseType: "json"
    });
    
    return client.get("/api/v1/check_user?email=" + email_id);
}