// import PropTypes from 'prop-types';
// import React from "react";
// import classnames from "classnames";
// import _ from "lodash";

// import { Message } from "semantic-ui-react";
// import CSSTransition from "react-transition-group/CSSTransition";
// import TransitionGroup from "react-transition-group/TransitionGroup";
// // import cssFade from "../../assets/styles/transitions/fade.scss";
// import cssToast from "../../assets/styles/toasts/index.module.scss";

// const { array, object } = PropTypes;

// class Toasts extends React.Component {
//   static propTypes = {
//     toasts: array,
//     location: object
//   };

//   componentDidMount() {
//     const activationStatus = _.get(this.props, "location.query.activation", "");
//     const resetStatus = _.get(this.props, "location.query.reset", "");

//     if (activationStatus === "success") {
//       this.props.userActivationSuccess();
//     } else if (activationStatus === "failed") {
//       this.props.userActivationFailure();
//     }

//     if (resetStatus === "failed") {
//       this.props.resetPasswordFailure();
//     }
//   }

//   handleRemoveToast = (id) => {
//     return () => {
//       this.props.removeToast(id);
//     };
//   };

//   renderToasts = () => {
//     const toasts = this.props.toasts;

//     return _.map(toasts, (toast) => {
//       if (_.get(toast, "autoDismiss", true)) {
//         setTimeout(() => {
//           this.handleRemoveToast(toast.id)();
//         }, 5000);
//       }

//       return (
//         <CSSTransition key={toast.id} timeout={500} classNames="fade">
//           <div>
//             <Message key={toast.id}
//                      info={toast.type === "info"}
//                      warning={toast.type === "warning"}
//                      success={toast.type === "success"}
//                      error={toast.type === "error"}
//                      onDismiss={this.handleRemoveToast(toast.id)}
//                      onClick={this.handleRemoveToast(toast.id)}
//                      header={toast.header}
//                      content={toast.message} />
//           </div>
//         </CSSTransition>
//       );
//     });
//   };

//   render() {
//     return (
//       <div className={cssToast.toast}>
//         <TransitionGroup>
//           { this.renderToasts() }
//         </TransitionGroup>
//       </div>
//     );
//   }
// }

// export default Toasts;

import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import _ from "lodash";
import { Message } from "semantic-ui-react";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { useLocation } from "react-router-dom";
import cssToast from "../../assets/styles/toasts/index.module.scss";

const Toasts = (props) => {
  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const email = params.get("email");
    const pathname = location.pathname;

    if (
      pathname.includes("/account_activations/") &&
      pathname.endsWith("/edit")
    ) {
      setTimeout(() => {
        if (email) {
          showToast({
            id: "activation-success",
            type: "success",
            header: "Account Activated",
            message: `Account for ${decodeURIComponent(
              email
            )} has been activated successfully.`,
            autoDismiss: true,
          });
        } else {
          showToast({
            id: "activation-failure",
            type: "error",
            header: "Activation Failed",
            message: `Account activation failed. Please try again.`,
            autoDismiss: true,
          });
        }
      }, 3000);
    }
  }, [location]);

  const showToast = (toast) => {
    if (toast.autoDismiss) {
      setTimeout(() => {
        props.removeToast(toast.id);
      }, 5000);
    }
  };

  const handleRemoveToast = (toastId) => {
    setTimeout(() => {
      props.removeToast(toastId);
    }, 5000);
  };

  const renderToasts = () => {
    return _.map(props?.toasts, (toast) => {
      return (
        <CSSTransition key={toast.id} timeout={300} classNames="fade">
          <div>
            <Message
              key={toast.id}
              info={toast.type === "info"}
              warning={toast.type === "warning"}
              success={toast.type === "success"}
              error={toast.type === "error"}
              onDismiss={() => handleRemoveToast(toast.id)}
              onClick={handleRemoveToast(toast.id)}
              header={toast.header}
              content={toast.message}
              closeIcon
            />
          </div>
        </CSSTransition>
      );
    });
  };

  return (
    <div className={cssToast.toast}>
      <TransitionGroup>{renderToasts()}</TransitionGroup>
    </div>
  );
};

Toasts.propTypes = {
  forgotPasswordSuccess: PropTypes.func,
  toasts: PropTypes.array,
  removeToast: PropTypes.func,
};

export default Toasts;
