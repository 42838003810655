import { push } from "react-router-redux";
import generateApiClient from "../helpers/generateApiClient";
import getErrorMessage from "../helpers/getErrorMessage";
import {
  TEMPLATE_FETCH_SUCCESS,
  TEMPLATE_FETCH_FAILURE,
  TEMPLATE_SAVE_SUCCESS,
  TEMPLATE_SAVE_FAILURE,
  TEMPLATE_DESTROY_SUCCESS,
  TEMPLATE_DESTROY_FAILURE,
  TEMPLATE_SAVE_REQUEST,
} from "../constants/templateConstants";

const fetchSuccess = (data) => ({ type: TEMPLATE_FETCH_SUCCESS, data });
const fetchFailure = (error) => ({ type: TEMPLATE_FETCH_FAILURE, error });

const saveSuccess = (data) => ({ type: TEMPLATE_SAVE_SUCCESS, data });
const saveFailure = (error) => ({ type: TEMPLATE_SAVE_FAILURE, error });

const destroySuccess = (id) => ({ type: TEMPLATE_DESTROY_SUCCESS, id });
const destroyFailure = (id) => ({ type: TEMPLATE_DESTROY_FAILURE, id });

export function fetch() {
  return (dispatch, getState) => {
    const client = generateApiClient(getState);

    return client
      .get("/api/v1/templates")
      .then((response) => {
        dispatch(fetchSuccess(response.data));
      })
      .catch((error) => {
        dispatch(fetchFailure(getErrorMessage(error)));
      });
  };
}

export function save(templateData) {
  return (dispatch, getState) => {
    const client = generateApiClient(getState);
    const params = {
      name: templateData.name,
      data: templateData.templateData,
    };

    dispatch({ type: TEMPLATE_SAVE_REQUEST });

    if (templateData.id === "new") {
      // Creating
      return client
        .post("/api/v1/templates", params)
        .then((response) => {
          dispatch(saveSuccess(response.data));
          const redirect = `/template/${response.data.id}`;
          dispatch(push(redirect));
        })
        .catch((error) => {
          dispatch(saveFailure(getErrorMessage(error)));
        });
    } else {
      // Updating
      return client
        .put("/api/v1/templates/" + templateData.id, params)
        .then((response) => {
          dispatch(saveSuccess(response.data));
        })
        .catch((error) => {
          console.log("error", error, "<<<<<<<<<");
          dispatch(saveFailure(getErrorMessage(error)));
        });
    }
  };
}
export function destroy(templateId, { redirect = "/templates" } = {}) {
  return (dispatch, getState) => {
    const client = generateApiClient(getState);

    return client
      .delete("/api/v1/templates/" + templateId)
      .then((_response) => {
        dispatch(destroySuccess(templateId));
        dispatch(push(redirect));
      })
      .catch((error) => {
        dispatch(destroyFailure(getErrorMessage(error)));
      });
  };
}
