import _ from "lodash";

export default {
  getCookieValue(cookieKey) {
    const cookie = _.get(document, "cookie", "").split("; ");
    const cookieData = _.find(cookie, (c) => {
      return c.split("=")[0] === cookieKey;
    });

    let cookieValue = "";

    if (cookieData) {
      cookieValue = cookieData.split("=");
      cookieValue.shift();
      cookieValue = cookieValue.join("");
    }

    return cookieValue;
  },

  setCookie(key, value) {
    const d = new Date();
    d.setTime(d.getTime() + (5 * 365 * 24 * 60 * 60 * 1000));
    const expires = `expires=${d.toUTCString()}`;

    document.cookie = `${key}=${value}; ${expires}`;
  },
};
