// import { connect } from "react-redux";

// import Login from "../components/login";
// import { login } from "../actions/authenticationActions";
// import getRedirectLocation from "../helpers/getRedirectLocation";

// const mapStateToProps = (state) => ({
//   error: state.auth.error,
//   inProgress: state.auth.inProgress,
// });

// const mapDispatchToProps = (dispatch, ownProps) => ({
//   login: (email, password) => {
//     const redirect = getRedirectLocation(ownProps.location);
//     dispatch(login(email, password, { redirect }));
//   }
// });

// export default connect(mapStateToProps, mapDispatchToProps)(Login);

import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import Login from "../components/login";
import { login, clearAuthError } from "../actions/authenticationActions";
import getRedirectLocation from "../helpers/getRedirectLocation";

const LoginContainer = () => {
  const [loader, setLoader] = useState(false);
  const error = useSelector((state) => state.auth.error);
  const inProgress = useSelector((state) => state.auth.inProgress);
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const handleLogin = (email, password) => {
    // const redirect = getRedirectLocation(window.location);
    const redirect = "/dashboard";
    dispatch(login(email, password, navigate, { redirect }, setLoader));
  };

  useEffect(() => {
    // Clear the error when the location changes
    dispatch(clearAuthError());
  }, [location, dispatch]);

  return (
    <Login
      error={error}
      inProgress={inProgress}
      login={handleLogin}
      loader={loader}
    />
  );
};

export default LoginContainer;
