import _ from "lodash";
import { connect } from "react-redux";

import Dashboard from "../components/dashboard";
import {
  archive,
  fetch,
  fetchCorporateSubaccount,
} from "../actions/timerActions";
import { fetch as fetchSubaccountDetails } from "../actions/subaccountActions";
import { updateStepCount } from "../actions/accountActions";

import { subscribe } from "../actions/authenticationActions";

const mapStateToProps = (state, props) => {
  const activeTimers = _.get(state, "timers.data.active", []);
  const archivedTimers = _.get(state, "timers.data.archived", []);
  const loads =
    _.sumBy(activeTimers, "views") + _.sumBy(archivedTimers, "views");
  const corporateSubaccounts = _.get(state, "timers.subaccounts", []);
  const freeOverrage =
    _.some(activeTimers, (t) => {
      return t.disableAt != null;
    }) ||
    _.some(archivedTimers, (t) => {
      return t.disableAt != null;
    });

  console.log("state auth=", state.auth);
  return {
    activeTimers: activeTimers.length ?? {},
    totalLoads: loads ?? {},
    user: state.auth?.data ?? {},
    envContext: state?.envContext?.data ?? {},
    freeOverrage: freeOverrage ?? {},
    corporateSubaccounts: corporateSubaccounts ?? {},
    timers: state.timers?.data.account_timers ?? {},
    subaccountDetails: state?.subaccountDetails ?? {},
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  archive: (timerId) => {
    dispatch(archive(timerId, true));
  },
  fetch: () => {
    dispatch(fetch());
  },
  fetchCorporateSubaccount: () => {
    dispatch(fetchCorporateSubaccount());
  },
  subscribe: (stripeToken, plan) => {
    dispatch(subscribe(stripeToken, plan));
  },
  fetchSubaccountDetails: (subaccount_id) => {
    dispatch(fetchSubaccountDetails(subaccount_id));
  },
  updateStepCount: (step_count) => {
    dispatch(updateStepCount(step_count));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
