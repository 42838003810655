import React, { useState } from "react";

import { Input, Icon } from "semantic-ui-react";

import css from "../../assets/styles/editor/editableText.module.scss";

const TextHeader = ({ name, toggleEdit }) => {
  return (
    <div className={css.textHeader} onClick={toggleEdit}>
      {name} <Icon name="pencil" size="small" />
    </div>
  );
};

const TextInput = ({ name, onChange, toggleEdit }) => {
  return (
    <Input fluid autoFocus
      className={css.textHeader}
      onChange={onChange}
      onBlur={toggleEdit}
      value={name} />
  );
};

const EditableText = ({ name, onChange }) => {
  const [editing, setEditing] = useState(false);

  return editing ? <TextInput name={name} toggleEdit={() => setEditing(false)} onChange={(e, data) => onChange(data.value)} /> :
    <TextHeader name={name} toggleEdit={() => setEditing(true)} />;
};

export default EditableText;