import React, { useState } from "react";
import PropTypes from 'prop-types';
import _ from "lodash";

import { Input, Form } from "semantic-ui-react";
// import css from "../../assets/styles/editor/labelPicker.module.css";

const { any, func } = PropTypes;

const BlurNumInput = ({ startingValue, max, placeholder, onChange, label }) => {
  const [value, setValue] = useState(startingValue);
  const [focused, setFocused] = useState(false);

  let strValue = String(value);

  if (strValue === "0" && startingValue === "") {
    strValue = "";
  }

  if (focused) {
    strValue = strValue.replace(/^0+/, "");
  }

  return (
    <Form.Input fluid
      label={label}
      placeholder={placeholder}
      value={strValue}
      onChange={(e, data) => {
        if (!focused) {
          onChange(data.value);
          return;
        }
        if (parseInt(data.value) < 0) {
          return;
        }
        const newValue = Math.min(max, parseInt(data.value.replace(/[^0-9]/g, "")) || 0);
        setValue(newValue);
      }
      }
      onFocus={() => setFocused(true)}
      onBlur={() => { setFocused(false); onChange(value); }}
    />
  );
};

const onDigitChangeCurry = (days, hours, minutes, seconds, sig, max, onChange) => {
  return (newValue) => {
    let val = days * 60 * 60 * 24 + hours * 60 * 60 + minutes * 60 + seconds + sig * newValue;
    val = Math.min(max, val);
    val = Math.max(0, val);
    onChange(val);
  };
};

const DurationInput = ({ value, max, onChange }) => {
  const days = Math.floor(value / 60 / 60 / 24) || "";
  const maxDays = Math.floor(max / 60 / 60 / 24);
  let hours = Math.floor(value / 60 / 60) % 24;
  let maxHours = 23;
  if (days == "") {
    hours = hours || "";
    maxHours = Math.floor(max / 60 / 60);
  }
  let minutes = Math.floor(value / 60) % 60;
  let maxMinutes = 59;
  if (hours === "") {
    minutes = minutes || "";
    maxMinutes = Math.floor(max / 60);
  }
  let seconds = Math.floor(value) % 60;
  let maxSeconds = 59;
  if (minutes === "") {
    seconds = seconds || "";
    maxSeconds = Math.floor(max);
  }

  return (
    <Form.Group widths='equal'>
      <>
        <BlurNumInput key={days}
          startingValue={days}
          max={maxDays}
          onChange={onDigitChangeCurry(0, hours, minutes, seconds, 60 * 60 * 24, max, onChange)}
          label="Days" />
      </>
      <>
        <BlurNumInput key={hours}
          startingValue={hours}
          max={maxHours}
          onChange={onDigitChangeCurry(days, 0, minutes, seconds, 60 * 60, max, onChange)}
          label="Hours" />
      </><>
        <BlurNumInput key={minutes}
          startingValue={minutes}
          max={maxMinutes}
          onChange={onDigitChangeCurry(days, hours, 0, seconds, 60, max, onChange)}
          label="Minutes" />
      </>
      <>
        <BlurNumInput key={seconds}
          startingValue={seconds}
          max={maxSeconds}
          onChange={onDigitChangeCurry(days, hours, minutes, 0, 1, max, onChange)}
          label="Seconds" />
      </>
    </Form.Group>
  );
};

export default DurationInput;