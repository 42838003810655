import { push } from "react-router-redux";

import generateApiClient from "../helpers/generateApiClient";
import getErrorMessage from "../helpers/getErrorMessage";
import {
  TIMER_FETCH_SUCCESS,
  TIMER_FETCH_FAILURE,
  TIMER_SAVE_SUCCESS,
  TIMER_SAVE_FAILURE,
  TIMER_ARCHIVE_SUCCESS,
  TIMER_ARCHIVE_FAILURE,
  GUEST_TIMER_SAVE_SUCCESS,
  GUEST_TIMER_SAVE_FAILURE,
  CORPORATE_TIMER_FETCH_SUCCESS,
  CORPORATE_TIMER_FETCH_FAILURE,
  TIMER_SAVE_REQUEST,
} from "../constants/timerConstants";

const fetchSuccess = (data) => ({ type: TIMER_FETCH_SUCCESS, data });
const fetchFailure = (error) => ({ type: TIMER_FETCH_FAILURE, error });

const fetchCorporateSuccess = (data) => ({
  type: CORPORATE_TIMER_FETCH_SUCCESS,
  data,
});
const fetchCorporateFailure = (error) => ({
  type: CORPORATE_TIMER_FETCH_FAILURE,
  error,
});

const saveSuccess = (data) => ({ type: TIMER_SAVE_SUCCESS, data });
const saveFailure = (error) => ({ type: TIMER_SAVE_FAILURE, error });

const guestSaveSuccess = (data) => ({ type: GUEST_TIMER_SAVE_SUCCESS, data });
const guestSaveFailure = (error) => ({ type: GUEST_TIMER_SAVE_FAILURE, error });

const archiveSuccess = (id, archived) => ({
  type: TIMER_ARCHIVE_SUCCESS,
  id,
  archived,
});
const archiveFailure = (id) => ({ type: TIMER_ARCHIVE_FAILURE, id });

export function fetch() {
  return (dispatch, getState) => {
    const client = generateApiClient(getState);

    return client

      .get("/api/v1/timers")
      .then((response) => {
        dispatch(fetchSuccess(response.data));
      })
      .catch((error) => {
        dispatch(fetchFailure(getErrorMessage(error)));
      });
  };
}

export function fetchCorporateSubaccount() {
  return (dispatch, getState) => {
    const client = generateApiClient(getState);

    return client
      .get("/api/v1/corporate_subaccount")
      .then((response) => {
        dispatch(fetchCorporateSuccess(response.data));
      })
      .catch((error) => {
        dispatch(fetchCorporateFailure(getErrorMessage(error)));
      });
  };
}

// export function create({ timerType, name, templateId, endsAt, duration, timeZone }) {
//   return (dispatch, getState) => {
//     const client = generateApiClient(getState);
//     const params = {
//       timerType,
//       name,
//       templateId,
//       duration,
//       endsAt,
//       timeZone: timeZone,
//     };

//     return client
//       .post("/api/v1/timers", params)
//       .then((response) => {
//         dispatch(saveSuccess(response.data));
//         const redirect = `/timer/${response.data.id}`;
//         dispatch(push(redirect));
//       })
//       .catch((error) => {
//         dispatch(saveFailure(getErrorMessage(error)));
//       });
//   };
// }

const apiUrl = process.env.REACT_APP_DOMAIN_URL;
export function create(
  { timerType, name, templateId, endsAt, duration, timeZone },
  navigate,
  setIsSaving
) {
  return (dispatch, getState) => {
    const client = generateApiClient(getState);
    const params = {
      timerType,
      name,
      templateId,
      duration,
      endsAt,
      timeZone,
    };
    setIsSaving(true);
    return client
      .post("/api/v1/timers", params)
      .then((response) => {
        dispatch(saveSuccess(response.data));
        const redirect = `/timer/${response.data.id}`;
        console.log(redirect, "hey");
        navigate(redirect);
        setIsSaving(false);
      })
      .catch((error) => {
        dispatch(saveFailure(getErrorMessage(error)));
        setIsSaving(false);
      });
  };
}

export function createGuest(data, captchaResponse) {
  return (dispatch, getState) => {
    const client = generateApiClient(getState);
    const params = {
      email: data.email,
      timerData: {
        colors: data.colors,
        language: data.language,
        endsAt: data.endsAt,
        timeZone: data.timeZone,
      },
      captchaResponse: captchaResponse,
    };

    return client
      .post("/api/v1/timers/guest", params)
      .then((response) => {
        dispatch(guestSaveSuccess(response.data));
      })
      .catch((error) => {
        dispatch(guestSaveFailure(getErrorMessage(error)));
      });
  };
}

export function update(timerData) {
  console.log("timerData=", timerData);
  return (dispatch, getState) => {
    const client = generateApiClient(getState);
    console.log("client=", client);

    let imageURL = "";
    if (timerData.fallback) {
      if (timerData.templateData.fallbackImage) {
        imageURL = timerData.templateData.fallbackImage;
      }
    } else {
      timerData.templateData.fallbackImage = null;
    }
    let multipleLanguageFeature = false;
    if (timerData.multiple_language) {
      multipleLanguageFeature = timerData.multiple_language;
    }
    if (timerData.timerType == "recurring") {
      let timerTemplateData = timerData.templateData;
      if (timerTemplateData.recurrence == "weekday") {
        let recurrenceStartDate = new Date(timerData.endsAt);
        let startWeekDay = recurrenceStartDate.getDay();
        if (startWeekDay == 0 || startWeekDay == 6) {
          return dispatch(
            saveFailure(
              getErrorMessage({
                message:
                  "The selected date must be between Monday and Friday to use this option",
              })
            )
          );
        }
      }
    }
    const params = {
      timerType: timerData.timerType,
      name: timerData.name,
      templateData: timerData.templateData,
      endsAt: timerData.endsAt,
      timeZone: timerData.timeZone,
      duration: timerData.duration,
      inferTimezone: timerData.inferTimezone,
      fallback: timerData.fallback,
      fallbackImageURL: imageURL,
      multiple_language: multipleLanguageFeature,
    };
    const timerId = timerData.id;
    dispatch({ type: TIMER_SAVE_REQUEST });
    return client
      .put("/api/v1/timers/" + timerId, params)
      .then((response) => {
        dispatch(saveSuccess(response.data));
      })
      .catch((error) => {
        dispatch(saveFailure(getErrorMessage(error)));
      });
  };
}

export function archive(timerId, archived, { redirect = "/timers" } = {}) {
  return (dispatch, getState) => {
    const client = generateApiClient(getState);
    const params = { archived };

    return client
      .put("/api/v1/timers/" + timerId + "/archive", params)
      .then((_response) => {
        dispatch(archiveSuccess(timerId, archived));
        dispatch(push(redirect));
      })
      .catch((error) => {
        dispatch(archiveFailure(getErrorMessage(error)));
      });
  };
}
