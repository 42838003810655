import {
  CUSTOM_FONT_FETCH_SUCCESS,
  CUSTOM_FONT_FETCH_FAILURE,
  CUSTOM_FONT_SAVE_SUCCESS,
  CUSTOM_FONT_SAVE_FAILURE,
  UPDATE_USER_DEFAULT_FONT_SUCCESS,
  UPDATE_USER_DEFAULT_FONT_FAILURE,
  CUSTOM_FONT_UPDATE_SUCCESS,
  CUSTOM_FONT_UPDATE_FAILURE,
  CUSTOM_FONT_DELETE_SUCCESS,
  CUSTOM_FONT_DELETE_FAILURE,
} from "../constants/customFontConstants";

import generateApiClient from "../helpers/generateApiClient";
import getErrorMessage from "../helpers/getErrorMessage";

const saveCustomFontSuccess = (data) => ({ type: CUSTOM_FONT_SAVE_SUCCESS, data });
const saveCustomFontFailure = (error) => ({ type: CUSTOM_FONT_SAVE_FAILURE, error });

const fetchCustomFontSuccess = (data) => ({ type: CUSTOM_FONT_FETCH_SUCCESS, data });
const fetchCustomFontFailure = (error) => ({ type: CUSTOM_FONT_FETCH_FAILURE, error });

const updateCustomFontSuccess = (data) => ({ type: CUSTOM_FONT_UPDATE_SUCCESS, data });
const updateCustomFontFailure = (error) => ({ type: CUSTOM_FONT_UPDATE_FAILURE, error });

const destroyCustomFontSuccess = (id) => ({ type: CUSTOM_FONT_DELETE_SUCCESS, id });
const destroyCustomFontFailure = (error) => ({ type: CUSTOM_FONT_DELETE_FAILURE, error });

const updateUserDefaultFontSuccess = (data) => ({ type: UPDATE_USER_DEFAULT_FONT_SUCCESS, data });
const updateUserDefaultFontFailure = (error) => ({ type: UPDATE_USER_DEFAULT_FONT_FAILURE, error });


export function fetchCustomFont() {
  return (dispatch, getState) => {
    const client = generateApiClient(getState);

    return client.get("/api/v1/custom_fonts")
      .then((response) => {
        dispatch(fetchCustomFontSuccess(response.data));
      }).catch((error) => {
        dispatch(fetchCustomFontFailure(getErrorMessage(error)));
      });
  };
}

export function createCustomFont(font_path, font_name) {
  return (dispatch, getState) => {
    const client = generateApiClient(getState);
    const params = {
      font_path,
      font_name
    };

    return client.post("/api/v1/custom_fonts", params)
      .then((response) => {        
        dispatch(saveCustomFontSuccess(response.data));
      }).catch((error) => {
        dispatch(saveCustomFontFailure(getErrorMessage(error)));
      });
  };
}

export function updateCustomFont(id, font_name) {
  return (dispatch, getState) => {
    const client = generateApiClient(getState);
    const params = {
      font_name,
    };

    return client.put("/api/v1/custom_fonts/" + id, params)
      .then((response) => {
        dispatch(updateCustomFontSuccess(response.data));
      }).catch((error) => {
        dispatch(updateCustomFontFailure(getErrorMessage(error)));
      });
  };
}

export function updateUserDefaultFont(status, digit_font, label_font) {
  return (dispatch, getState) => {
    const client = generateApiClient(getState);
    const params = {
      status,
      digit_font,
      label_font
    };

    return client
      .post("/api/v1/update_user_font", params)
      .then((response) => {
        let responseData = {...response.data}
        responseData["defaultStatus"] = status;
        responseData["digit_font"] = digit_font;
        responseData["label_font"] = label_font;
        dispatch(updateUserDefaultFontSuccess(responseData));
      })
      .catch((error) => {
        dispatch(updateUserDefaultFontFailure(getErrorMessage(error)));
      });
  };
}

export function destroyCustomFont(fontID) {
  return (dispatch, getState) => {
    const client = generateApiClient(getState);

    return client.delete("/api/v1/custom_fonts/" + fontID)
      .then((_response) => {        
        dispatch(destroyCustomFontSuccess(fontID));        
      }).catch((error) => {
        dispatch(destroyCustomFontFailure(getErrorMessage(error)));
      });
  };
}