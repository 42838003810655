import { connect } from "react-redux";

import TemplateList from "../components/templateList";
import { fetch, destroy, save } from "../actions/templateActions";

const mapStateToProps = (state, props) => ({
  templates: state.template?.data ?? {},
  envContext: state.envContext?.data ?? {},
  user: state.auth?.data ?? {},
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  fetch: () => {
    dispatch(fetch());
  },
  copy: (template) => {
    let templateClone = Object.assign({}, template);
    templateClone.name = template.name + " (copy)";
    templateClone.id = "new";
    dispatch(save(templateClone));
  },
  destroy: (templateId) => {
    dispatch(destroy(templateId));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(TemplateList);
