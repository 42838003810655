import {
  SUBACCOUNT_DETAILS_FETCH_FAILURE,
  SUBACCOUNT_DETAILS_FETCH_SUCCESS,
  SUBACCOUNT_USER_CREATE_SUCCESS,
  SUBACCOUNT_USER_CREATE_FAILURE,
  SUBACCOUNT_USER_DETAILS_FETCH_SUCCESS,
  SUBACCOUNT_USER_DETAILS_FETCH_FAILURE,
  SUBACCOUNT_TIMERS_FETCH_SUCCESS,
  SUBACCOUNT_TIMERS_FETCH_FAILURE,
  SUBACCOUNT_NAME_UPDATE_SUCCESS,
  SUBACCOUNT_NAME_UPDATE_FAILURE,
} from "../constants/subaccountConstants";

import _ from "lodash";
import generateApiClient from "../helpers/generateApiClient";
import getErrorMessage from "../helpers/getErrorMessage";
import { SUB_ACCOUNT_DETAILS_REQUEST } from "../constants/authenticationConstants";

const fetchSuccess = (data) => ({
  type: SUBACCOUNT_DETAILS_FETCH_SUCCESS,
  data,
});
const fetchFailure = (error) => ({
  type: SUBACCOUNT_DETAILS_FETCH_FAILURE,
  error,
});

const fetchSubaccountUserSuccess = (data) => ({
  type: SUBACCOUNT_USER_DETAILS_FETCH_SUCCESS,
  data,
});
const fetchSubaccountUserFailure = (error) => ({
  type: SUBACCOUNT_USER_DETAILS_FETCH_FAILURE,
  error,
});

const registerSubaccountSuccess = (data) => ({
  type: SUBACCOUNT_USER_CREATE_SUCCESS,
  data,
});
const registerSubaccountFailure = (error) => ({
  type: SUBACCOUNT_USER_CREATE_FAILURE,
  error,
});

const fetchSubaccountTimersSuccess = (data) => ({
  type: SUBACCOUNT_TIMERS_FETCH_SUCCESS,
  data,
});
const fetchSubaccountTimersFailure = (error) => ({
  type: SUBACCOUNT_TIMERS_FETCH_FAILURE,
  error,
});

const updateSubaccountNameSuccess = (data) => ({
  type: SUBACCOUNT_NAME_UPDATE_SUCCESS,
  data,
});
const updateSubaccountNameFailure = (data) => ({
  type: SUBACCOUNT_NAME_UPDATE_FAILURE,
  data,
});

export function fetchSubaccountUsers(subaccount_id) {
  return (dispatch, getState) => {
    const client = generateApiClient(getState);
    return client
      .get("/api/v1/subaccounts/subaccount_users?subaccountid=" + subaccount_id)
      .then((response) => {
        dispatch(fetchSubaccountUserSuccess(response.data));
      })
      .catch((error) => {
        dispatch(fetchSubaccountUserFailure(getErrorMessage(error)));
      });
  };
}

export function fetchSubaccountTimers(subaccount_id) {
  return (dispatch, getState) => {
    const client = generateApiClient(getState);
    return client
      .get(
        "/api/v1/subaccounts/subaccount_timers?subaccountid=" + subaccount_id
      )
      .then((response) => {
        dispatch(fetchSubaccountTimersSuccess(response.data));
      })
      .catch((error) => {
        dispatch(fetchSubaccountTimersFailure(getErrorMessage(error)));
      });
  };
}

export function fetch(subaccount_id) {
  return (dispatch, getState) => {
    const client = generateApiClient(getState);
    dispatch({ type: SUB_ACCOUNT_DETAILS_REQUEST });
    return client
      .get(
        "/api/v1/subaccounts/subaccount_details?subaccountid=" + subaccount_id
      )
      .then((response) => {
        dispatch(fetchSuccess(response.data));
      })
      .catch((error) => {
        dispatch(fetchFailure(getErrorMessage(error)));
      });
  };
}

export function createSubaccount(users, subaccount_id) {
  return (dispatch, getState) => {
    const client = generateApiClient(getState);
    const params = {
      users,
      subaccount_id,
    };
    return client
      .post("/api/v1/subaccounts/subaccount", params)
      .then((response) => {
        dispatch(registerSubaccountSuccess(response.data));
      })
      .catch((error) => {
        dispatch(registerSubaccountFailure(getErrorMessage(error)));
      });
  };
}

export function updateSubaccountName(subaccount_id, name) {
  return (dispatch, getState) => {
    const client = generateApiClient(getState);
    const params = {
      subaccount_id,
      name,
    };
    return client
      .post("/api/v1/subaccounts/update_subaccount", params)
      .then((response) => {
        dispatch(updateSubaccountNameSuccess(response.data));
      })
      .catch((error) => {
        dispatch(updateSubaccountNameFailure(getErrorMessage(error)));
      });
  };
}
