import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { ChromePicker } from 'react-color';
import css from '../../assets/styles/shared/colorPickerNew.module.scss'

const { string, func, bool } = PropTypes;

class ColorPickerNew extends Component {
    static propTypes = {
        color: string,
        handleChangeColor: func,
        orientation: string,
        alignment: string,
        renderAbove: bool,
    };

    static defaultProps = {
        orientation: 'horizontal',
        alignment: 'center',
        renderAbove: false,
    };

    constructor(props) {
        super(props);

        this.state = {
            showPicker: false,
        };

        this.colorPickerRef = React.createRef();
    }

    componentDidMount() {
        document.addEventListener('click', this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.handleClickOutside);
    }

    handleClickOutside = (event) => {
        if (this.colorPickerRef.current && !this.colorPickerRef.current.contains(event.target)) {
            this.handleClosePicker();
        }
    };

    handleOpenPicker = () => {
        this.setState({ showPicker: true });
    };

    handleClosePicker = () => {
        this.setState({ showPicker: false });
    };

    render() {
        const { color, handleChangeColor, orientation, alignment, renderAbove } = this.props;
        const { showPicker } = this.state;

        const bgColor = {
            backgroundColor: `#${color}`,
        };

        const orientationClass = orientation === 'vertical' ? css.swatchVertical : css.swatchHorizontal;
        const containerAlignmentClass = showPicker
            ? this.props.alignment === 'right'
                ? css.alignRight
                : this.props.alignment === 'center'
                    ? css.alignCenter
                    : null
            : null;

        return (
            <div className={classnames(orientationClass)} ref={this.colorPickerRef}>
                <div className={css.swatch} onClick={this.handleOpenPicker}>
                    <div className={css.color} style={bgColor} />
                </div>
                {showPicker && (
                    <div className={classnames(css.popover, containerAlignmentClass, { [css.renderAbove]: renderAbove })}>
                        <div className={css.cover} onClick={this.handleClosePicker} />
                        <ChromePicker
                            disableAlpha
                            color={`#${color}`}
                            onChangeComplete={(c) => handleChangeColor(c.hex.replace('#', ''))}
                        />
                    </div>
                )}
                 <input type="checkbox" style={{display:"none"}} id={this.props.__id} onChange={this.handleOpenPicker} checked={showPicker}/>
            </div>
        );
    }
}

export default ColorPickerNew;
