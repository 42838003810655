export const AUTH_IN_PROGRESS = "AUTH_IN_PROGRESS";
export const AUTH_LOGIN_SUCCESS = "AUTH_LOGIN_SUCCESS";
export const AUTH_LOGIN_FAILURE = "AUTH_LOGIN_FAILURE";
export const AUTH_LOGOUT_SUCCESS = "AUTH_LOGOUT_SUCCESS";
export const AUTH_LOGOUT_FAILURE = "AUTH_LOGOUT_FAILURE";
export const AUTH_REGISTER_SUCCESS = "AUTH_REGISTER_SUCCESS";
export const AUTH_REGISTER_FAILURE = "AUTH_REGISTER_FAILURE";
export const FORGOT_PASSWORD_REQUEST = "FORGOT_PASSWORD_REQUEST";
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_FAILURE = "FORGOT_PASSWORD_FAILURE";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_FAILURE = "RESET_PASSWORD_FAILURE";
export const BETA_SIGNUP_SUCCESS = "BETA_SIGNUP_SUCCESS";
export const BETA_SIGNUP_FAILURE = "BETA_SIGNUP_FAILURE";
export const AUTH_SUBSCRIBE_SUCCESS = "AUTH_SUBSCRIBE_SUCCESS";
export const AUTH_SUBSCRIBE_FAILURE = "AUTH_SUBSCRIBE_FAILURE";
export const AUTH_INVOICES_SUCCESS = "AUTH_INVOICES_SUCCESS";
export const AUTH_INVOICES_FAILURE = "AUTH_INVOICES_FAILURE";
export const UPDATE_PASSWORD_SUCCESS = "UPDATE_PASSWORD_SUCCESS";
export const UPDATE_PASSWORD_FAILURE = "UPDATE_PASSWORD_FAILURE";
export const UPDATE_SETTINGS_SUCCESS = "UPDATE_SETTINGS_SUCCESS";
export const UPDATE_SETTINGS_FAILURE = "UPDATE_SETTINGS_FAILURE";
export const SUBSCRIBE_UPDATE_SUCCESS = "SUBSCRIBE_UPDATE_SUCCESS";
export const SUBSCRIBE_UPDATE_FAILURE = "SUBSCRIBE_UPDATE_FAILURE";
export const UPDATE_ENV = "UPDATE_ENV";
export const USER_FETCH_SUCCESS = "USER_FETCH_SUCCESS";
export const USER_FETCH_FAILURE = "USER_FETCH_FAILURE";
export const USER_ACCOUNT_ACTIVATION_SUCCESS =
  "USER_ACCOUNT_ACTIVATION_SUCCESS";
export const USER_ACCOUNT_ACTIVATION_FAILURE =
  "USER_ACCOUNT_ACTIVATION_FAILURE";
export const UPLOAD_FILE_PROGRESS = "UPLOAD_FILE_PROGRESS";
export const UPLOAD_FILE_SUCCESS = "UPLOAD_FILE_SUCCESS";
export const UPLOAD_FILE_FAILURE = "UPLOAD_FILE_FAILURE";
export const CLEAR_AUTH_ERROR = "CLEAR_AUTH_ERROR";
export const SUB_ACCOUNT_DETAILS_REQUEST = "SUB_ACCOUNT_DETAILS_REQUEST";
export const UPDATE_CAN_EDIT_TEMPLATES = "UPDATE_CAN_EDIT_TEMPLATES";
