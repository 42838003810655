// import _ from "lodash";
// import { connect } from "react-redux";

// import Timer from "../components/timer";
// import { update, archive, fetch } from "../actions/timerActions";
// import { uploadFailed } from "../actions/uploadActions";
// import { fetchCustomFont } from "../actions/customFontActions";

// const findTimer = (timers, pageId) => {
//   return _.find(timers, (timer) => {
//     const timerId = _.get(timer, "id", "").toString();
//     return timerId === pageId;
//   });
// };

// const mapStateToProps = (state, props) => ({
//   timer: findTimer(state.timers.data.active, props.params.id) ?? {},
//   envContext: state.envContext?.data ?? {},
//   user: state.auth?.data ?? {},
// });

// const mapDispatchToProps = (dispatch, ownProps) => ({
//   update: (timerData) => {
//     dispatch(update(timerData));
//   },
//   archive: (timerId) => {
//     dispatch(archive(timerId, true));
//   },
//   fetch: () => {
//     dispatch(fetch());
//   },
//   handleUploadError: (err) => {
//     dispatch(uploadFailed(err));
//   },
//   fetchCustomFont:()=>{
//     dispatch(fetchCustomFont());
//   },
// });

// export default connect(mapStateToProps, mapDispatchToProps)(Timer);

import { connect } from "react-redux";
import { useParams } from "react-router-dom";

import Timer from "../components/timer";
import { update, archive, fetch } from "../actions/timerActions";
import { uploadFailed } from "../actions/uploadActions";
import { fetchCustomFont } from "../actions/customFontActions";
import { useState } from "react";

const findTimer = (timers, pageId) => {
  return timers.find((timer) => {
    const timerId = timer.id.toString();
    return timerId === pageId;
  });
};

const TimerContainer = (props) => {
  const { id } = useParams();
  const timer = findTimer(props.timers.active, id);
  return (
    <Timer
      {...props}
      timer={timer}
      user={props.user}
      fetch={props.fetch}
      fetchCustomFont={props.fetchCustomFont}
      update={props.update}
      handleUploadError={props.handleUploadError}
      envContext={props.envContext}
      isSubmitting={props.isSubmitting}
    />
  );
};

const mapStateToProps = (state) => {
  console.log(state);
  return {
    timers: state.timers.data,
    envContext: state.envContext?.data ?? {},
    user: state.auth?.data ?? {},
    isSubmitting: state.timers.isSubmitting,
  };
};

const mapDispatchToProps = (dispatch) => ({
  update: (timerData) => {
    dispatch(update(timerData));
  },
  archive: (timerId) => {
    dispatch(archive(timerId, true));
  },
  fetch: () => {
    dispatch(fetch());
  },
  handleUploadError: (err) => {
    dispatch(uploadFailed(err));
  },
  fetchCustomFont: () => {
    dispatch(fetchCustomFont());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(TimerContainer);
