import { connect } from "react-redux";
import _ from "lodash";
import SubaccountDetails from "../components/subaccount";
import {
  createSubaccount,
  fetch,
  fetchSubaccountUsers,
  fetchSubaccountTimers,
  updateSubaccountName,
} from "../actions/subaccountActions";
import { archive } from "../actions/timerActions";
import { deleteUser } from "../actions/accountActions";

const mapStateToProps = (state, props) => {
  console.log(state, "sub acc state");
  console.log(props, "sub acc props");

  return {
    subaccount: state.subaccountDetails.subaccount,
    subaccount_users: state.subaccountDetails.subaccount_users,
    subaccount_timers: state.subaccountDetails.subaccount_timers,
    envContext: state.subaccounts.data,
    // envContext: state.envContext.data,
    user: state.auth.data,
    auth: state.auth,
    errorStatus: state.subaccountDetails.errorStatus,
    error: state.subaccountDetails.error,
    isSubAccountLoading: state.subaccountDetails.subAccountLoader,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  fetch: (subaccount_id) => {
    dispatch(fetch(subaccount_id));
  },
  fetchSubaccountUsers: (subaccount_id) => {
    dispatch(fetchSubaccountUsers(subaccount_id));
  },
  fetchSubaccountTimers: (subaccount_id) => {
    dispatch(fetchSubaccountTimers(subaccount_id));
  },
  archive: (timerId, status) => {
    dispatch(archive(timerId, status));
  },
  createSubaccount: (users, subaccount_id) => {
    dispatch(createSubaccount(users, subaccount_id));
  },
  deleteUser: (userid) => {
    dispatch(deleteUser(userid));
  },
  updateSubaccountName: (subaccount_id, name) => {
    dispatch(updateSubaccountName(subaccount_id, name));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(SubaccountDetails);
