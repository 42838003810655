import { LOCATION_CHANGE } from "react-router-redux";
import _ from "lodash";

import {
  SUBACCOUNT_DETAILS_FETCH_SUCCESS,
  SUBACCOUNT_DETAILS_FETCH_FAILURE,
  SUBACCOUNT_USER_CREATE_SUCCESS,
  SUBACCOUNT_USER_CREATE_FAILURE,
  SUBACCOUNT_USER_DETAILS_FETCH_SUCCESS,
  SUBACCOUNT_USER_DETAILS_FETCH_FAILURE,
  SUBACCOUNT_TIMERS_FETCH_SUCCESS,
  SUBACCOUNT_TIMERS_FETCH_FAILURE,
  SUBACCOUNT_NAME_UPDATE_FAILURE,
  SUBACCOUNT_NAME_UPDATE_SUCCESS,
} from "../constants/subaccountConstants";

import {
  DELETE_USER_FAILURE,
  DELETE_USER_SUCCESS,
} from "../constants/accountConstants";

import {
  CUSTOM_FONT_FETCH_SUCCESS,
  CUSTOM_FONT_FETCH_FAILURE,
  CUSTOM_FONT_SAVE_SUCCESS,
  CUSTOM_FONT_SAVE_FAILURE,
  CUSTOM_FONT_UPDATE_SUCCESS,
  CUSTOM_FONT_UPDATE_FAILURE,
  CUSTOM_FONT_DELETE_SUCCESS,
  CUSTOM_FONT_DELETE_FAILURE,
} from "../constants/customFontConstants";
import { SUB_ACCOUNT_DETAILS_REQUEST } from "../constants/authenticationConstants";

export const initialState = {
  subaccount: null,
  subaccount_users: [],
  subaccount_timers: [],
  custom_fonts: [],
  error: null,
  errorStatus: false,
  subAccountLoader: false,
};

const removeCustomFont = (fonts, fontId) => {
  _.remove(fonts, (font) => {
    return font.id === fontId;
  });
  return fonts;
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SUB_ACCOUNT_DETAILS_REQUEST:
      return { ...state, subAccountLoader: true, error: null };
    case SUBACCOUNT_DETAILS_FETCH_SUCCESS:
      return {
        ...state,
        subaccount: action.data,
        subAccountLoader: false,
        error: null,
      };
    case SUBACCOUNT_DETAILS_FETCH_FAILURE:
      return { ...state, error: action.error, subAccountLoader: false };
    case SUBACCOUNT_USER_DETAILS_FETCH_SUCCESS:
      return { ...state, subaccount_users: action.data, error: null };
    case SUBACCOUNT_USER_DETAILS_FETCH_FAILURE:
      return { ...state, error: action.error };
    case SUBACCOUNT_TIMERS_FETCH_SUCCESS:
      return { ...state, subaccount_timers: action.data, error: null };
    case SUBACCOUNT_TIMERS_FETCH_FAILURE:
      return { ...state, error: action.error };
    case SUBACCOUNT_USER_CREATE_SUCCESS:
      let new_subaccount_users = [...action.data];
      let users_list = [...state.subaccount_users];
      for (let user of new_subaccount_users) {
        users_list.push(user);
      }
      return { ...state, subaccount_users: users_list };
    case SUBACCOUNT_USER_CREATE_FAILURE:
      return { ...state, error: action.error };
    case DELETE_USER_SUCCESS:
      let new_users_list = [...state.subaccount_users];
      new_users_list = _.filter(new_users_list, function (user) {
        return user.id != action.data.id;
      });
      return { ...state, subaccount_users: new_users_list };
    case DELETE_USER_FAILURE:
      return { ...state, error: action.error };
    case SUBACCOUNT_NAME_UPDATE_SUCCESS:
      return { ...state, subaccount: action.data, error: null };
    case SUBACCOUNT_NAME_UPDATE_FAILURE:
      // console.log("error log:",action.data);
      return { ...state, error: action.data };
    default:
      return state;
    case CUSTOM_FONT_FETCH_SUCCESS:
      return { ...state, custom_fonts: action.data };
    case CUSTOM_FONT_FETCH_FAILURE:
      return { ...state, error: action.data };
    case CUSTOM_FONT_SAVE_SUCCESS:
      let fonts = [...state.custom_fonts];
      fonts.push(action.data);
      return { ...state, custom_fonts: fonts };
    case CUSTOM_FONT_SAVE_FAILURE:
      return { ...state, error: action.data };
    case CUSTOM_FONT_UPDATE_SUCCESS:
      let all_fonts = [...state.custom_fonts];
      const index = _.findIndex(all_fonts, { id: action.data.id });
      if (index != -1) {
        all_fonts[index] = action.data;
      }
      return { ...state, custom_fonts: all_fonts };
    case CUSTOM_FONT_UPDATE_FAILURE:
      return { ...state, error: action.error };
    case CUSTOM_FONT_DELETE_SUCCESS:
      let updateFonts = [...state.custom_fonts];
      updateFonts = removeCustomFont(updateFonts, action.id);
      return { ...state, custom_fonts: updateFonts };
    case CUSTOM_FONT_DELETE_FAILURE:
      return { ...state, error: action.error };
  }
};
