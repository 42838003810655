import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import moment from "moment";

//import { withRouter } from 'react-router';

import { createBrowserHistory } from "history";

import tableCss from "../../assets/styles/shared/tables.module.scss";
import gridCss from "../../assets/styles/shared/grids.module.scss";

import {
  Button,
  Table,
  Header,
  Popup,
  Icon,
  Grid,
  GridColumn,
  Form,
  Input,
  Select,
  Modal,
  Pagination,
  Divider,
  ModalDescription,
} from "semantic-ui-react";
import {
  BlueEllipseIconComponent,
  DeleteIconComponent,
  ExternalLinkIconComponent,
  GreyEllipseIconComponent,
  LeftArrowIconComponent,
  PersonIconComponent,
  RightArrowIconComponent,
  SubaccountSuccessIconComponent,
  SubaccountSuccessWithInviteUserIconComponent,
} from "../iconComponents";
import InviteUserComponent from "../inviteUser";
import { useNavigate } from "react-router";
const browserHistory = createBrowserHistory();

const initialState = {
  isModalOpen: false,
  isCorporateModalOpen: false,
  isDeleteModalOpen: false,
  name: "",
  email: "",
  corporate_user_name: "",
  subaccount_email: "",
  subaccount_name: "",
  corporateActivePage: 1,
  totalCorporatePages: 0,
  recordLimit: 5,
  subaccountActivePage: 1,
  totalSubaccountPages: 0,
  searchSubaccountText: "",
  step: 1,
  corporateStep: 1,
  processButtonText: "Next",
  cancelButtonText: "Cancel",
  duplicateSubaccountNameStatus: false,
  subaccountWithUser: false,
  subaccountUserStatus: [true, false, false, false, false],
  subaccountUserDetails: [
    { name: "", email: "", valid: true },
    { name: "", email: "", valid: true },
    { name: "", email: "", valid: true },
    { name: "", email: "", valid: true },
    { name: "", email: "", valid: true },
  ],
  corporateUserStatus: [true, false, false, false, false],
  corporateUserDetails: [
    { name: "", email: "", valid: true },
    { name: "", email: "", valid: true },
    { name: "", email: "", valid: true },
    { name: "", email: "", valid: true },
    { name: "", email: "", valid: true },
  ],
  delete_user_id: 0,
};

const { array, object } = PropTypes;

class AccountListDetails extends React.Component {
  static propTypes = {
    subaccounts: array,
    users: array,
    user: object,
    envContext: object,
    auth: object,
  };

  constructor(props) {
    super(props);
    this.state = { ...initialState };
    this.updateValues = this.updateValues.bind(this);
  }

  updateValues(key, values) {
    this.setState({ [key]: values });
  }

  componentDidMount() {
    this.props.fetch();
    this.props.fetchCorporate();
  }

  static getDerivedStateFromProps(props, current_state) {
    let changed = false;
    let newStates = { ...current_state };
    if (props.users.length > 0) {
      const filteredUsers = _.filter(props.users, function (user) {
        return user.subaccount_id == 0;
      });
      let totalPage = Math.floor(filteredUsers.length / 5);
      if (filteredUsers.length % 5 != 0) {
        totalPage++;
      }
      newStates.totalCorporatePages = totalPage;
      changed = true;
    }
    if (props.subaccounts.length > 0) {
      let totalPage = Math.floor(props.subaccounts.length / 5);
      if (props.subaccounts.length % 5 != 0) {
        totalPage++;
      }
      newStates.totalSubaccountPages = totalPage;
      changed = true;
    }
    if (changed) {
      return newStates;
    }
    return null;
  }

  calculateTotalPages = (length, limit) => {
    let totalPage = Math.floor(length / limit);
    if (length % limit != 0) {
      totalPage++;
    }
    return totalPage;
  };

  getResetState() {
    return {
      ...initialState,
    };
  }

  formatDate = (date) => {
    return moment(date).format("MM/DD/YYYY");
  };

  handleToggleModal = (isOpen) => {
    return () => {
      this.setState({
        ...this.getResetState(),
        isModalOpen: isOpen,
      });
    };
  };

  isSelfAccount(userid) {
    return this.props.user.id == userid;
  }

  isCorporateOwnerAccount(parent_id) {
    return parent_id == 0;
  }

  handleToggleCorporateModal = (isOpen) => {
    return () => {
      this.setState({
        ...this.getResetState(),
        isCorporateModalOpen: isOpen,
      });
    };
  };

  handleDeleteToggleModal = (isOpen) => {
    return () => {
      this.setState({
        ...this.getResetState(),
        isDeleteModalOpen: isOpen,
        delete_user_id: 0,
      });
    };
  };

  validFormData = () => {
    if (this.state.step == 1) {
      if (_.isEmpty(this.state.subaccount_name)) {
        return false;
      }
    } else if (this.state.step == 2) {
      return this.totalActiveUser() > 0;
    }
    return true;
  };

  emailIsValid = (email) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };

  validCorporateFormData = () => {
    return this.totalActiveCorporateUser() > 0;
  };

  totalActiveUser = () => {
    let count = 0;
    let userStatus = [...this.state.subaccountUserStatus];
    let users = [...this.state.subaccountUserDetails];
    for (let index = 0; index < userStatus.length; index++) {
      let email = users[index].email;
      if (
        userStatus[index] &&
        !_.isEmpty(email) &&
        this.emailIsValid(email) &&
        users[index].valid
      ) {
        count++;
      }
    }
    return count;
  };

  totalActiveCorporateUser = () => {
    let count = 0;
    let userStatus = [...this.state.corporateUserStatus];
    let users = [...this.state.corporateUserDetails];

    for (let index = 0; index < userStatus.length; ) {
      let email = users[index].email;
      if (
        userStatus[index] &&
        !_.isEmpty(email) &&
        this.emailIsValid(email) &&
        users[index].valid
      ) {
        count++;
      }
      index++;
    }
    return count;
  };

  preventDefaultSubmit = (event) => {
    event.preventDefault();
  };

  handleCreateCorporateUser = () => {
    // event.preventDefault();
    // this.props.createCorporate(this.state.corporate_user_name,this.state.email,"admin@1234");
    // setTimeout(() => {
    //     this.setState({
    //         ...this.getResetState(),
    //         isModalOpen: false,
    //     });
    // }, 100);
    let userStatus = [...this.state.corporateUserStatus];
    let users = [];
    for (let index = 0; index < userStatus.length; index++) {
      if (userStatus[index]) {
        let user = { name: "", email: "", password: "admin@1234" };
        user.name = this.state.corporateUserDetails[index].name;
        user.email = this.state.corporateUserDetails[index].email;
        users.push(user);
      }
    }
    this.props.createCorporate(users);
  };

  handleChangeEventCurry = (key) => {
    return (event, data) => {
      this.setState({ [key]: data.value });
    };
  };

  handleSubaccountNameChangeEventHandler = (event) => {
    this.setState({ subaccount_name: event.target.value });
  };

  cancelSubaccountActions = () => {
    if (this.state.step == 1) {
      this.setState({
        ...this.getResetState(),
        isModalOpen: false,
      });
    } else if (this.state.step == 2) {
      this.setState({ step: 3, subaccountWithUser: false });
      this.props.create(this.state.subaccount_name, []);
    }
  };

  renderSubaccountActions = () => {
    return (
      <>
        <div className={tableCss.buttonContainer}>
          <Button
            basic
            style={{ width: "166px" }}
            onClick={this.cancelSubaccountActions}
          >
            {this.state.cancelButtonText}
          </Button>
          <Button
            className={tableCss.primaryButton}
            style={{ width: "166px" }}
            disabled={!this.validFormData()}
            onClick={this.processAction}
          >
            {this.state.processButtonText}
          </Button>
        </div>
      </>
    );
  };

  processAction = () => {
    let stepValue = this.state.step;
    if (stepValue == 1) {
      let result = _.filter(this.props.subaccounts, (subaccount) => {
        return (
          subaccount.name.toLowerCase() ==
          this.state.subaccount_name.toLowerCase()
        );
      });
      if (result.length > 0) {
        this.setState({ duplicateSubaccountNameStatus: true });
      } else {
        this.setState({ step: 2 });
        this.setState({ cancelButtonText: "Add users later" });
      }
    } else if (stepValue == 2) {
      this.handleCreateSubaccount();
      this.setState({ step: 3, subaccountWithUser: true });
    }
  };

  processCorporateAction = () => {
    let stepValue = this.state.corporateStep;
    if (stepValue == 1) {
      this.handleCreateCorporateUser();
      this.setState({ corporateStep: 2 });
    }
  };

  handleCreateSubaccount = () => {
    let userStatus = [...this.state.subaccountUserStatus];
    let users = [];
    for (let index = 0; index < userStatus.length; index++) {
      if (userStatus[index]) {
        let user = { name: "", email: "", password: "admin@1234" };
        user.name = this.state.subaccountUserDetails[index].name;
        user.email = this.state.subaccountUserDetails[index].email;
        users.push(user);
      }
    }
    this.props.create(this.state.subaccount_name, users);
  };

  renderCorporateActions = () => {
    return (
      <div className={tableCss.buttonContainer}>
        <Button basic onClick={this.handleToggleCorporateModal(false)}>
          Cancel
        </Button>
        <Button
          className={tableCss.primaryButton}
          onClick={this.processCorporateAction}
          disabled={!this.validCorporateFormData()}
        >
          Send invites
        </Button>
      </div>
    );
  };

  renderModal = () => {
    return (
      <Modal
        size={"small"}
        open={this.state.isModalOpen}
        onClose={this.handleToggleModal(false)}
        closeOnDimmerClick={false}
      >
        {/* <Modal.Header className={tableCss.headerFont} style={{border:'none',paddingBottom:'10px'}}>Add sub account</Modal.Header> */}
        <Modal.Content
          style={{ paddingTop: this.state.step == 3 ? "0px" : "32px" }}
          scrolling={this.state.step == 2}
        >
          {(this.state.step == 1 || this.state.step == 2) && (
            <h1 className={tableCss.headerFont}>Add sub account</h1>
          )}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              paddingTop: this.state.step == 3 ? "0px" : "10px",
              justifyContent: "flex-start",
              minHeight: "200px",
              alignItems: "center",
            }}
          >
            {(this.state.step == 1 || this.state.step == 2) && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  gap: "8px",
                  marginBottom: "20px",
                }}
              >
                <span>
                  {this.state.step == 1 && <BlueEllipseIconComponent />}
                  {this.state.step == 2 && <BlueEllipseIconComponent />}
                </span>
                <span>
                  {this.state.step == 1 && <GreyEllipseIconComponent />}
                  {this.state.step == 2 && <BlueEllipseIconComponent />}
                </span>
              </div>
            )}
            {this.state.step == 1 && (
              <table style={{ width: "300px", marginTop: "10px" }}>
                <tbody>
                  <tr>
                    <td>
                      <label
                        className={
                          this.state.duplicateSubaccountNameStatus
                            ? tableCss.errorFont1
                            : tableCss.font1
                        }
                        htmlFor="subaccount_name"
                      >
                        Name of sub account
                      </label>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <input
                        placeholder="ex. Coca-cola Japan"
                        label="Name of sub account"
                        id="subaccount_name"
                        value={this.state.subaccount_name}
                        className={
                          this.state.duplicateSubaccountNameStatus
                            ? tableCss.errorInputText
                            : tableCss.inputText
                        }
                        onChange={this.handleSubaccountNameChangeEventHandler}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {this.state.duplicateSubaccountNameStatus && (
                        <p className={tableCss.errorFont2}>
                          Sub account with such name exists already. Create
                          another name
                        </p>
                      )}
                    </td>
                  </tr>
                </tbody>
              </table>
            )}
            {this.state.step == 2 && (
              <InviteUserComponent
                id="subaccount_user_form"
                statusKey="subaccountUserStatus"
                valueKey="subaccountUserDetails"
                updateValues={this.updateValues}
                basicPassword={this.props.envContext.basicPassword}
                token={this.props.auth.data.jwt}
              />
            )}
            {this.state.step == 3 && (
              <>
                <span>
                  {this.state.subaccountWithUser ? (
                    <SubaccountSuccessWithInviteUserIconComponent />
                  ) : (
                    <SubaccountSuccessIconComponent />
                  )}
                </span>
                <h1 className={tableCss.headerFont}>Sub account created!</h1>
                {!this.state.subaccountWithUser && (
                  <p className={tableCss.textSmall}>
                    You can invite users later on sub account page.
                  </p>
                )}
                {this.state.subaccountWithUser && (
                  <p className={tableCss.textSmall}>
                    Invite sent to {this.totalActiveUser()} users.
                  </p>
                )}
                <div>
                  <Button
                    onClick={this.handleToggleModal(false)}
                    className={tableCss.primaryButton}
                  >
                    Ok
                  </Button>
                </div>
              </>
            )}
          </div>
        </Modal.Content>
        {(this.state.step == 1 || this.state.step == 2) && (
          <Modal.Actions style={{ background: "none" }}>
            {this.renderSubaccountActions()}
          </Modal.Actions>
        )}
      </Modal>
    );
  };

  processDeleteCorporateUser = () => {
    this.props.deleteUser(this.state.delete_user_id);
    this.setState({
      ...this.getResetState(),
      isDeleteModalOpen: false,
      delete_user_id: 0,
    });
  };

  renderDeleteUserModal = () => {
    return (
      <Modal
        size={"tiny"}
        open={this.state.isDeleteModalOpen}
        onClose={this.handleDeleteToggleModal(false)}
        closeOnDimmerClick={false}
      >
        <Modal.Header>Delete Corporate User</Modal.Header>
        <Modal.Content>
          <p>Are you sure you want to delete your corporate user</p>
        </Modal.Content>
        <Modal.Actions>
          <Button negative onClick={this.handleDeleteToggleModal(false)}>
            No
          </Button>
          <Button positive onClick={this.processDeleteCorporateUser}>
            Yes
          </Button>
        </Modal.Actions>
      </Modal>
    );
  };

  renderCorporateUserModal = () => {
    return (
      <Modal
        size={"small"}
        open={this.state.isCorporateModalOpen}
        onClose={this.handleToggleCorporateModal(false)}
        closeOnDimmerClick={false}
      >
        <Modal.Content
          style={{ paddingTop: this.state.corporateStep == 2 ? "0px" : "32px" }}
          scrolling={this.state.corporateStep == 1}
        >
          {this.state.corporateStep == 1 && (
            <h1 className={tableCss.headerFont}>Invite users</h1>
          )}
          {this.state.corporateStep == 1 && (
            <InviteUserComponent
              id="corporate_user_form"
              statusKey="corporateUserStatus"
              valueKey="corporateUserDetails"
              updateValues={this.updateValues}
              basicPassword={this.props.envContext.basicPassword}
              token={this.props.auth?.data?.jwt}
            />
          )}
          {this.state.corporateStep == 2 && (
            <>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  paddingTop: this.state.step == 3 ? "0px" : "10px",
                  justifyContent: "flex-start",
                  minHeight: "200px",
                  alignItems: "center",
                }}
              >
                <span>
                  <SubaccountSuccessWithInviteUserIconComponent />
                </span>
                <h1 className={tableCss.headerFont}>Invites sent!</h1>
                <p className={tableCss.textSmall}>
                  Invites sent to {this.totalActiveCorporateUser()} users.
                </p>
                <div>
                  <Button
                    onClick={this.handleToggleCorporateModal(false)}
                    className={tableCss.primaryButton}
                  >
                    Ok
                  </Button>
                </div>
              </div>
            </>
          )}
          {/* <Modal.Description>
                    <Form onSubmit={this.handleCreateCorporateUser}>
                        <Form.Input
                        placeholder="ex. John wick"
                        label="Name"
                        onChange={this.handleChangeEventCurry("corporate_user_name")}
                        /> 
                        <Form.Input
                        type="email"
                        icon="envelope"
                        iconPosition="left"
                        placeholder="ex. example@example.com"
                        label="Email"
                        onChange={this.handleChangeEventCurry("email")}
                        />                  
                        {this.renderCorporateActions()}
                    </Form>
                    </Modal.Description> */}
        </Modal.Content>
        {this.state.corporateStep == 1 && (
          <Modal.Actions style={{ background: "none" }}>
            {this.renderCorporateActions()}
          </Modal.Actions>
        )}
      </Modal>
    );
  };

  renderZeroState = () => {
    return (
      <div className={tableCss.zero}>
        You do not currently have any subaccounts. Create one with the button in
        the top right!
      </div>
    );
  };

  jumpToDetails = (subaccountId) => {
    return () => {
      //this.props.history.push("/subaccount/" + subaccountId);
      this.props.navigate("/subaccount/" + subaccountId);
    };
  };

  renderTemplates = () => {
    let orderedSubaccounts = _.orderBy(
      this.props.subaccounts,
      ["updated_at", "name"],
      ["desc", "asc"]
    );
    let beginIndex = 0;

    if (
      this.state.searchSubaccountText &&
      this.state.searchSubaccountText.length != ""
    ) {
      orderedSubaccounts = _.filter(orderedSubaccounts, (subaccount) => {
        return subaccount.name
          .toLowerCase()
          .includes(this.state.searchSubaccountText.toLowerCase());
      });
      // this.setState({subaccountActivePage:1});
    } else {
      beginIndex =
        (this.state.subaccountActivePage - 1) * this.state.recordLimit;
    }

    const endIndex = beginIndex + this.state.recordLimit;
    const sliceSubaccounts = orderedSubaccounts.slice(beginIndex, endIndex);

    return _.map(sliceSubaccounts, (subaccount) => {
      return (
        <Table.Row key={"subaccount_row_" + subaccount.id}>
          <Table.Cell className={tableCss.name}>{subaccount.name}</Table.Cell>
          <Table.Cell>{subaccount.total_timer_count}</Table.Cell>
          <Table.Cell>{subaccount.total_views}</Table.Cell>
          <Table.Cell>{subaccount.paid_views}</Table.Cell>
          <Table.Cell className={tableCss.actions}>
            <span
              className={tableCss.pointer}
              onClick={this.jumpToDetails(subaccount.id)}
            >
              <ExternalLinkIconComponent />
            </span>
          </Table.Cell>
        </Table.Row>
      );
    });
  };

  renderTableContents = () => {
    if (_.isEmpty(this.props.subaccounts)) {
      return this.renderZeroState();
    } else {
      let totalSubaccount = this.props.subaccounts.length;
      let totalPages = this.calculateTotalPages(
        totalSubaccount,
        this.state.recordLimit
      );
      if (
        this.state.searchSubaccountText &&
        this.state.searchSubaccountText.length != ""
      ) {
        let filteredSubaccounts = _.filter(
          this.props.subaccounts,
          (subaccount) => {
            return subaccount.name
              .toLowerCase()
              .includes(this.state.searchSubaccountText.toLowerCase());
          }
        );
        totalPages = this.calculateTotalPages(
          filteredSubaccounts.length,
          this.state.recordLimit
        );
        totalSubaccount = filteredSubaccounts.length;
      }
      let currentActivePage =
        this.state.subaccountActivePage > totalPages
          ? 1
          : this.state.subaccountActivePage;
      let displayTotalSubaccount = this.state.recordLimit;
      if (displayTotalSubaccount > totalSubaccount) {
        displayTotalSubaccount = totalSubaccount;
      } else {
        let count = this.state.recordLimit * this.state.subaccountActivePage;
        if (count > totalSubaccount) {
          displayTotalSubaccount =
            totalSubaccount -
            this.state.recordLimit * (this.state.subaccountActivePage - 1);
        }
      }
      return (
        <>
          <Table basic>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Name</Table.HeaderCell>
                <Table.HeaderCell>Active timers</Table.HeaderCell>
                <Table.HeaderCell>Total views</Table.HeaderCell>
                <Table.HeaderCell>
                  Views
                  <span className={tableCss.secondary}>
                    (this billing cycle)
                  </span>
                </Table.HeaderCell>
                <Table.HeaderCell></Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              <Table.Row className={tableCss.tableRowBackground}>
                <Table.HeaderCell className={tableCss.tableCol} colSpan={"5"}>
                  {this.props.subaccounts.length} total sub accounts
                </Table.HeaderCell>
              </Table.Row>
              {this.renderTemplates()}
            </Table.Body>
          </Table>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div>
              <span className={tableCss.tableCol}>
                {displayTotalSubaccount} out of {totalSubaccount}
              </span>
            </div>
            <div>
              <Pagination
                defaultActivePage={currentActivePage}
                onPageChange={this.handleSubaccountPaginationChange}
                totalPages={totalPages}
                lastItem={null}
                firstItem={null}
                ellipsisItem={{
                  content: <Icon name="ellipsis horizontal" />,
                  icon: true,
                }}
                prevItem={{
                  content: (
                    <span>
                      <LeftArrowIconComponent />
                    </span>
                  ),
                  icon: true,
                }}
                nextItem={{
                  content: (
                    <span>
                      <RightArrowIconComponent />
                    </span>
                  ),
                  icon: true,
                }}
              />
            </div>
          </div>
        </>
      );
    }
  };

  renderZeroCorporateState = () => {
    return (
      <div className={tableCss.zero}>
        You do not currently have any corporate users. Create one with the
        button in the top right!
      </div>
    );
  };

  openDeleteDialog = (userId) => {
    this.setState({ delete_user_id: userId, isDeleteModalOpen: true });
  };

  renderCorporateTemplates = () => {
    const filteredUsers = _.filter(this.props.users, function (user) {
      return user.subaccount_id == 0;
    });
    const orderedUsers = _.orderBy(
      filteredUsers,
      ["updated_at", "email"],
      ["desc", "asc"]
    );
    const beginIndex =
      (this.state.corporateActivePage - 1) * this.state.recordLimit;
    const endIndex = beginIndex + this.state.recordLimit;
    const sliceUsers = orderedUsers.slice(beginIndex, endIndex);
    return _.map(sliceUsers, (user) => {
      return (
        <Table.Row key={"user_" + user.id}>
          <Table.Cell className={tableCss.name} style={{ borderTop: "0px" }}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <span style={{ paddingRight: "20px", paddingTop: "5px" }}>
                <PersonIconComponent />
              </span>
              <span>{user.name}</span>
              {this.isSelfAccount(user.id) && (
                <span style={{ paddingLeft: "3px", color: "#606062" }}>
                  {" "}
                  (
                  {this.isCorporateOwnerAccount(user.parent_id)
                    ? "owner"
                    : "you"}
                  )
                </span>
              )}
            </div>
          </Table.Cell>
          <Table.Cell className={tableCss.name} style={{ borderTop: "0px" }}>
            {user.email}
          </Table.Cell>
          <Table.Cell className={tableCss.name} style={{ borderTop: "0px" }}>
            {!this.isSelfAccount(user.id) &&
              !this.isCorporateOwnerAccount(user.parent_id) && (
                <span
                  style={{ cursor: "pointer" }}
                  onClick={() => this.openDeleteDialog(user.id)}
                >
                  <DeleteIconComponent />
                </span>
              )}
          </Table.Cell>
        </Table.Row>
      );
    });
  };

  handleCorporatePaginationChange = (e, { activePage }) =>
    this.setState({ corporateActivePage: activePage });

  handleSubaccountPaginationChange = (e, { activePage }) =>
    this.setState({ subaccountActivePage: activePage });

  renderCorporateTableContents = () => {
    const filteredUsers = _.filter(this.props.users, function (user) {
      return user.subaccount_id == 0;
    });
    if (_.isEmpty(filteredUsers)) {
      return this.renderZeroCorporateState();
    } else {
      return (
        <>
          <Table basic="very">
            {/* <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>Name</Table.HeaderCell>     
                                <Table.HeaderCell>Email id</Table.HeaderCell>       
                            </Table.Row>
                        </Table.Header> */}
            <Table.Body>{this.renderCorporateTemplates()}</Table.Body>
            {/* <Table.Footer style={{textAlign:'right'}}>
                            <Table.Row>
                                <Table.HeaderCell colSpan='45'>
                                    
                                </Table.HeaderCell>
                            </Table.Row>
                        </Table.Footer> */}
          </Table>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <Pagination
              defaultActivePage={this.state.corporateActivePage}
              onPageChange={this.handleCorporatePaginationChange}
              totalPages={this.state.totalCorporatePages}
              lastItem={null}
              firstItem={null}
              ellipsisItem={{
                content: <Icon name="ellipsis horizontal" />,
                icon: true,
              }}
              prevItem={{
                content: (
                  <span>
                    <LeftArrowIconComponent />
                  </span>
                ),
                icon: true,
              }}
              nextItem={{
                content: (
                  <span>
                    <RightArrowIconComponent />
                  </span>
                ),
                icon: true,
              }}
            />
          </div>
        </>
      );
    }
  };

  searchSubaccountTextHandler = (e) => {
    let searchText = e.target.value;
    this.setState({ searchSubaccountText: searchText });
  };

  render() {
    return (
      <>
        <Header as="h1">Accounts</Header>
        <div id="template-list" className={tableCss.tableContainer}>
          <div className={tableCss.filterDiv} style={{ width: "50%" }}>
            <div>
              <p className={tableCss.heading2}>Corporate account access</p>
            </div>
            <div>
              <Button
                onClick={this.handleToggleCorporateModal(true)}
                className={tableCss.seeAllButton}
                style={{ textAlign: "right", paddingRight: "0px" }}
              >
                + invite user
              </Button>
            </div>
          </div>
          <div style={{ width: "50%" }}>
            {this.renderCorporateTableContents()}
          </div>
        </div>

        <div id="template-list" className={tableCss.tableContainer}>
          <p className={tableCss.heading2}>Sub accounts</p>
          <div className={tableCss.filterDiv}>
            <div>
              <Input
                className={gridCss.gridSearchText}
                icon
                iconPosition="left"
                placeholder="Search for an account"
              >
                <input
                  value={this.state.searchSubaccountText}
                  onChange={this.searchSubaccountTextHandler}
                />
                <Icon name="search" />
              </Input>
            </div>
            <div>
              <Button
                onClick={this.handleToggleModal(true)}
                className={tableCss.primaryButton}
              >
                Add subaccount
              </Button>
            </div>
          </div>
        </div>
        {this.renderModal()}
        {this.renderCorporateUserModal()}
        {this.renderTableContents()}
        {this.renderDeleteUserModal()}
      </>
    );
  }
}

const AccountList = (props) => {
  const navigate = useNavigate();
  return <AccountListDetails {...props} navigate={navigate} />;
};
export default AccountList;
