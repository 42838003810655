import React, { useState } from "react";
import PropTypes from 'prop-types';
import _ from "lodash";

import { Form, Input, Checkbox, Modal } from "semantic-ui-react";
import css from "../../assets/styles/editor/labelPicker.module.scss";

import { langs, labels } from "../shared/languages.js";
import { DarkInfoIconComponent } from "../iconComponents/index.jsx";
import { CrossIconComponent } from "../iconComponents/index.jsx";
const { any, func } = PropTypes;

const labelsWithHidden = {
  hidden: ["", "", "", "", ""],
  ...labels
};

class LabelPicker extends React.Component {

  static propTypes = {
    digits: any,
    onChange: func,
    allowMultipleLanguage:any,
    onCheckboxChange:func,
    multipleLanguageEnabled:any,    
    targetUrl:any,
  };

  constructor(props) {
    super(props);    
    this.state = { custom: !this.getLang(), lastLang: this.getLang(), isModalOpen:false, searchQuery:'' };
  }

  updateAndNotify(){
    if(this.props.allowMultipleLanguage){
      this.handleLanguageChanged(null, { value:"en" });
    }
  }

  componentDidUpdate(prevProps){
    if(prevProps.allowMultipleLanguage != this.props.allowMultipleLanguage){
      this.updateAndNotify();
    }
  }

  getLang() {
    const currentLabels = [
      _.get(this.props.digits, "years.label", ""),
      _.get(this.props.digits, "days.label", ""),
      _.get(this.props.digits, "hours.label", ""),
      _.get(this.props.digits, "minutes.label", ""),
      _.get(this.props.digits, "seconds.label", ""),
    ];

    if (_.isEqual(["", "", "", "", ""], currentLabels)) {
      return "hidden";
    }

    var currentLang = _.findKey(labelsWithHidden, function (o) { return _.isEqual(o, currentLabels); });
    if (!currentLang) {
      currentLang = null;
    }

    return currentLang;
  }

  handleLanguageChanged = (e, data) => {
    if (data.value in labelsWithHidden) {      
      this.setState({ custom: false });
      let newDigits = this.props.digits;
      newDigits = _.set(newDigits, ["years", "label"], labelsWithHidden[data.value][0]);
      newDigits = _.set(newDigits, ["days", "label"], labelsWithHidden[data.value][1]);
      newDigits = _.set(newDigits, ["hours", "label"], labelsWithHidden[data.value][2]);
      newDigits = _.set(newDigits, ["minutes", "label"], labelsWithHidden[data.value][3]);
      newDigits = _.set(newDigits, ["seconds", "label"], labelsWithHidden[data.value][4]);
      this.props.onChange(newDigits);
    }
  };

  updateLanguageChanged = (value) => {
    if (value in labelsWithHidden) {      
      this.setState({ custom: false });
      let newDigits = this.props.digits;
      newDigits = _.set(newDigits, ["years", "label"], labelsWithHidden[value][0]);
      newDigits = _.set(newDigits, ["days", "label"], labelsWithHidden[value][1]);
      newDigits = _.set(newDigits, ["hours", "label"], labelsWithHidden[value][2]);
      newDigits = _.set(newDigits, ["minutes", "label"], labelsWithHidden[value][3]);
      newDigits = _.set(newDigits, ["seconds", "label"], labelsWithHidden[value][4]);
      this.props.onChange(newDigits);
    }
  }

  handleLabelChangedCurry = (key) => {
    return (e, data) => {
      let newDigits = this.props.digits;
      newDigits = _.set(newDigits, [key, "label"], data.value);
      this.props.onChange(newDigits);
    };
  };

  handleMultipleLanguageChangeCurry = (value) => {
    this.props.onCheckboxChange(value);
  }

  renderLabelInput = (key) => {
    if (!this.state.custom || _.get(this.props.digits, [key, "hidden"], false)) {
      return;
    }
    return (
      <Input fluid className={css.labels}
        placeholder={key.toUpperCase()}
        onChange={this.handleLabelChangedCurry(key)}
        value={_.get(this.props.digits, [key, "label"], "")} />
    );
  };


  // For language options generations
  getLanguageOptions = () => {
    if(this.props.multipleLanguageEnabled){
      return [{ text: "Adapt to user’s language", value: "en" }]
    }
    return langs;
  }

  renderDropdown() {
    let value = this.getLang();
    if (this.state.custom || value == "hidden") {
      value = null;
    }else if(this.props.multipleLanguageEnabled){
      value = "en";
    }

    return (
      <Form.Dropdown selection fluid
        label="Language"
        labeled
        options={this.getLanguageOptions()}
        value={value}
        placeholder={"Set language..."}      
        onChange={this.handleLanguageChanged} />
    );
  }

  toggleHidden = () => {
    if (this.getLang() == "hidden") {
      this.handleLanguageChanged(null, { value: this.state.lastLang || "english" });
    } else {
      this.setState({ lastLang: this.getLang() });
      this.handleLanguageChanged(null, { value: "hidden" });
    }
  };

  generateTargetUrl = () => {
    let url = this.props.targetUrl + "?lang=en";
    url = url.replace("http:", "https:");
    return url;
  }

  // All about language modal 
  handleCloseModal = () => {
    this.setState({
      isModalOpen: false,
    });
  };

  handleOpenModal = () => {
    this.setState({
      isModalOpen:true,
    });
  }

  handleSearchQuery = (e) => {
    this.setState({searchQuery:e.target.value});
  }

  renderLanguageModal = () => {
    return(
      <Modal size="tiny" open={this.state.isModalOpen} onClose={this.handleCloseModal}>
        <Modal.Content>
          <div style={{display:'flex',justifyContent:'space-between'}}>
            <p style={{fontSize:'20px',fontWeight:'700',lineHeight:'24px'}}>Language tags</p>
            <span onClick={this.handleCloseModal} style={{cursor:'pointer'}}><CrossIconComponent/></span>
          </div>
          <Input
            icon='search'
            iconPosition='left'
            placeholder='Search Languages'
            style={{ borderRadius: 0, width: '100%', height: '40px' }}
            value={this.state.searchQuery}
            onChange={this.handleSearchQuery} // Update the search query
          />
          <div style={{display:'flex',paddingTop:'16px',gap:'8px',borderBottom: '1px solid #DCE2E3',paddingBottom:'16px'}}>
            <div className={css.sliderBox}>
              <div className={css.codeStyle}>en</div>
              <div className={css.languageStyle}>English</div>
            </div>
            <div className={css.sliderBox}>
              <div className={css.codeStyle}>es</div>
              <div className={css.languageStyle}>Spanish</div>
            </div>
            <div className={css.sliderBox}>
              <div className={css.codeStyle}>fr</div>
              <div className={css.languageStyle}>French</div>
            </div>
          </div>
          {/* Add custom styles to adjust slider size using CSS class */}
        <div className={css.customSlider}>
          {/* Slider component with custom styles */}
          <div className={css.sliderContainer}>            
            {langs
              .filter((lang) =>
                lang.text.toLowerCase().includes(this.state.searchQuery.toLowerCase())
              ) // Filter the languages based on the search query
              .map((lang) => (
                <div key={lang.value}>
                  {lang.text} - {lang.value}
                </div>
              ))}
          </div>
        </div>
        </Modal.Content>
      </Modal>
    );
  }

  render() {
    // Code for
    let multipleLanguageEnabled = this.props.multipleLanguageEnabled;
    return (<>
      {this.renderDropdown()}
      {this.props.allowMultipleLanguage && 
        <>
          <div className={css.section} style={{marginBottom:"10px"}}>
            <Checkbox
              toggle
              label="Multi-language timer"
              onChange={this.props.onCheckboxChange}
              checked={multipleLanguageEnabled}
            />
          </div>
          {multipleLanguageEnabled && 
            <>
              <div style={{display:'flex',flexDirection:'row',alignItems:"flex-start",padding:'12px',gap:'8px',background: "#F7F8F8",borderRadius: '4px',marginBottom:'15px'}}>
                <div style={{paddingTop:'5px'}}>
                  <span style={{paddingTop:'10px'}}><DarkInfoIconComponent/></span>
                </div>
                <div style={{fontFamily:'Lato',fontSize:'12px',color:'#606062',textAlign:'justify',fontWeight:'400'}}>
                  <div>To specify the language for your timer, simply add a language tag to the end of the URL.</div>
                  <div style={{paddingTop:'3px',textAlign:'left',wordBreak:'break-all'}}>For ex. {this.generateTargetUrl()}</div>
                  <div style={{paddingTop:'3px'}}>This will ensure that the timer is displayed in the language of your choice.</div>
                  <span style={{paddingTop:'3px',color:'#4E84A1',textDecoration:"underline",cursor:'pointer',fontWeight:'bold'}} onClick={this.handleOpenModal}>Full list of language tags</span>
                </div>
              </div>
              {this.renderLanguageModal()}
            </>
          }
        </>
      }
      <Form.Group widths="equal">
        <Form.Field>
          <Checkbox label="Custom labels"
            checked={this.state.custom}
            onClick={() => { this.setState({ custom: !this.state.custom }); }} />
        </Form.Field>
        <Form.Field>
          <Checkbox label="Hide labels"
            checked={this.getLang() == "hidden"}
            onClick={this.toggleHidden} />
        </Form.Field>
      </Form.Group>
      <Form.Field>
        {this.renderLabelInput("years")}
        {this.renderLabelInput("days")}
        {this.renderLabelInput("hours")}
        {this.renderLabelInput("minutes")}
        {this.renderLabelInput("seconds")}
      </Form.Field>
    </>);
  }
}

export default LabelPicker;