import { connect } from "react-redux";

import AccountList from "../components/accountList";
import { create,fetch,createCorporate,fetchCorporate,deleteUser } from "../actions/accountActions";

const mapStateToProps = (state, props) => ({
    subaccounts: state.subaccounts?.data ?? '',
    users:state.subaccounts?.users ?? '',
    user: state.auth?.data ?? '',
    envContext: state.envContext?.data ?? '',
    auth:state.auth ?? '',
});

const mapDispatchToProps = (dispatch, ownProps) => ({
    fetch: () => {
        dispatch(fetch());
    },
    fetchCorporate: () => {
        dispatch(fetchCorporate());
    },
    create: (name,email,password,subaccount_name) => {
        dispatch(create(name,email,password,subaccount_name));
    },
    createCorporate:(name,email, password) => {
        dispatch(createCorporate(name,email, password));
    },
    deleteUser:(userid) => {
        dispatch(deleteUser(userid));
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(AccountList);