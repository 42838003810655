import { connect } from "react-redux";

import ForgotPassword from "../components/forgotPassword";
import { forgotPassword } from "../actions/authenticationActions";

const mapStateToProps = (state) => {
  return {
    error: state.auth.error,
    isForgotPasswordRequest: state.auth.isForgotPasswordRequest,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  forgotPassword: (email) => {
    dispatch(forgotPassword(email));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
