import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router";
import _ from "lodash";
import moment from "moment-timezone";
import classNames from "classnames";



import { Form, Input, Button, Dropdown, Message, Card, Popup } from "semantic-ui-react";
import Recaptcha from "react-google-recaptcha";

import ColorPicker from "../shared/colorPicker";
import TimezonePicker from "../shared/timezonePicker";
import DatetimePicker from "../shared/datetimePicker";

import "../../assets/styles/global.module.scss";
import css from "../../assets/styles/home/index.module.scss";
import indexCss from "../../assets/styles/layout/index.module.scss";

import { langs } from "../shared/languages";
const { object, string } = PropTypes;

class TimerGenerator extends React.Component {

    static propTypes = {
        timer: object,
        error: string,
        envContext: object,
        user: object,
      };
    
      static defaultProps = {
        timer: {},
        error: null,
        envContext: {},
        user: null,
      };
    
      state = {
        endsAt: null,
        timeZone: null,
        agreeToTerms: false,
        email: _.get(this.props, "user.email", ""),
        colors: {
          digits: "ffffff",
          labels: "ffffff",
          background: "000000",
        },
        previewStyles: {
          background: "1ac6ff",
          labels: "ffffff",
        },
        language: "en",
      };
    
      componentDidMount() {
        // Fix this crap at some point
        if (window.thesaas) {
          window.thesaas.init();
        }
    
        const timezone = moment.tz.guess();
        const now = moment().tz(timezone);
        const nearestHour =
          now.minute() || now.second() || now.millisecond() ? now.add(1, "hour").startOf("hour") : now.startOf("hour");
    
        moment.tz.setDefault(timezone);
    
        this.setState({
          endsAt: nearestHour,
          timeZone: timezone,
        });
      }
    
      componentWillReceiveProps(nextProps) {
        const nextUrl = _.get(nextProps, "timer.url", "");
        const thisUrl = _.get(this.props, "timer.url", "");
    
        if (nextUrl !== thisUrl) {
          this.captcha.reset();
        }
      }
    
      handleGenerate = () => {
        this.captcha.execute();
        // this.props.create(this.state);
      };
    
      handleChangeCurry = (name) => {
        return (event) => {
          this.setState({ [name]: event.target.value });
        };
      };
    
      handleTargetChangeCurry = (key) => {
        return (event, data) => this.setState({ [key]: data.value });
      };
    
      handleDateChanged = (date) => {
        this.setState({
          endsAt: date,
        });
      };
    
      handleTimezoneChanged = (event, data) => {
        this.setState({
          timeZone: data.value,
        });
      };
    
      handleColorChangeCurry = (key) => {
        return (color) => this.setState(_.set(this.state.colors, key, color));
      };
    
      termsClicked = (ev) => {
        ev.stopPropagation();
      };
    
      handleAgree = () => {
        this.setState({
          agreeToTerms: !this.state.agreeToTerms,
        });
      };
    
      handleCaptchaResponse = (response) => {
        this.props.create(this.state, response);
      };
    
      validFormData = () => {
        return this.state.agreeToTerms && !_.isEmpty(this.state.email);
      };
    
      renderColorPicker = (key) => {
        const currentColor = this.state.colors[key];
        return (
          <ColorPicker
            color={currentColor}
            label={_.upperFirst(key)}
            orientation={"vertical"}
            handleChangeColor={this.handleColorChangeCurry(key)}
          />
        );
      };
    
      renderError = () => {
        return (
          <Message error>
            <Message.Header>Generate Error</Message.Header>
            <p>{this.props.error}</p>
          </Message>
        );
      };
    
      renderTosCheckbox = () => {
        const label = (
          <label>
            I agree to the
            <a href="/legal/terms.pdf" target="_blank" rel="noopener noreferrer" onClick={this.termsClicked}>
              {" "}
              Terms of Service
            </a>{" "}
            and{" "}
            <a href="/legal/privacy.pdf" target="_blank" rel="noopener noreferrer" onClick={this.termsClicked}>
              Privacy Policy
            </a>
          </label>
        );
        return (
          <Form.Checkbox
            inline
            className={css.tosCheckbox}
            label={label}
            checked={this.state.agreeToTerms}
            onClick={this.handleAgree}
          />
        );
      };
    
      handleContextMenu = (e) => {
        e.preventDefault();
      };
    
      handlePreviewLoadCurry = (background, labels) => {
        return () => {
          this.setState({
            previewStyles: {
              background: background,
              labels: labels,
            },
          });
        };
      };
    
      renderPreview = () => {
        const url = _.get(this.props.timer, "url", "/example_timer.gif");
        const background = _.get(this.props.timer, "templateData.colors.background", "1ac6ff");
        const labels = _.get(this.props.timer, "templateData.colors.labels", "ffffff");
        const styles = {
          background: `#${this.state.previewStyles.background}`,
          color: `#${this.state.previewStyles.labels}`,
        };
        const buttonStyles = {
          background: `#${this.state.previewStyles.labels}`,
          color: `#${this.state.previewStyles.background}`,
        };
    
        return (
          <div className={css.timerPreview}>
            <div
              className={css.templatePreview}
              onContextMenu={this.handleContextMenu}
              onDragStart={this.handleContextMenu}
            >
              <img src="/sendtric_example.png" alt="Email Live Countdown Timer" draggable={false} />
              <div className={css.templatePreviewBody} style={styles}>
                <span>Limited Time Sale!</span>
                <div className={css.templatePreviewInfo}>
                  Hurry, this offer won't last long. Click below to take advantage of this limited time offer on email
                  countdown timers!
                </div>
                <img
                  className={css.previewImage}
                  src={url}
                  draggable={false}
                  onLoad={this.handlePreviewLoadCurry(background, labels)}
                />
                <button className={css.templatePreviewButton} style={buttonStyles}>
                  Go to Sale
                </button>
              </div>
              <div className={css.templatePreviewMobile} style={styles}>
                <div>Limited Time Sale!</div>
                <img className={css.previewImage} src={url} draggable={false} />
                <button className={css.templatePreviewButton} style={buttonStyles}>
                  Go Now
                </button>
              </div>
            </div>
            <div>{this.renderTimerCode()}</div>
          </div>
        );
      };
    
      renderTimerCode = () => {
        if (!_.isEmpty(this.props.timer) && _.isEmpty(this.props.error)) {
          const url = this.props.timer.url;
          let timerCode = `<table align="center"><tbody><tr><td><img src="${url}" style="display: block;max-width:100%" /></td></tr></tbody></table>`;
          if(!url.includes("https")){
            const httpUrl = url.replace("http", "https");   
            timerCode = `<table align="center"><tbody><tr><td><img src="${httpUrl}" style="display: block;max-width:100%" /></td></tr></tbody></table>`;
          }
          
    
          return (
            <div className={css.timerCodeContainer}>
              <img
                className={css.previewImage}
                src={url}
                draggable={false}
                onContextMenu={this.handleContextMenu}
                onDragStart={this.handleContextMenu}
              />
              <p>Copy this code into your HTML email template.</p>
              <textarea readOnly className={css.timerCode} value={timerCode}></textarea>
            </div>
          );
        } else {
          return (
            <div className={css.timerSteps}>
              <h5>How to create your free email countdown timer:</h5>
              <ol>
                <li>Fill out the form to the left with your desired countdown options</li>
                <li>Click Generate</li>
                <li>Copy and paste the provided code into your HTML email template</li>
                <li>Enjoy your FREE email countdown timer from Sendtric!</li>
              </ol>
            </div>
          );
        }
      };
    
      renderForm = () => {
        const key = _.get(this.props, "envContext.g_site_key", "");
    
        return (
          <Form onSubmit={this.handleGenerate} error={!!this.props.error}>
            {this.renderError()}
            <Form.Input
              type="email"
              placeholder="Email Address"
              value={this.state.email}
              icon="envelope"
              disabled={this.props.user != null}
              iconPosition="left"
              onChange={this.handleChangeCurry("email")}
            />
            <TimezonePicker timezone={this.state.timeZone} handleChange={this.handleTimezoneChanged} />
            <div className="field">
              <label>When does your timer end? (max 30 days)</label>
              <DatetimePicker
                dateTime={moment(this.state.endsAt).tz(this.state.timeZone)}
                maxDays={30}
                handleChange={this.handleDateChanged}
              />
            </div>
            <Form.Dropdown
              selection
              fluid
              label="Language"
              value={this.state.language}
              options={langs}
              onChange={this.handleTargetChangeCurry("language")}
            />
            <div className={css.colorPickers}>
              {this.renderColorPicker("background")}
              {this.renderColorPicker("labels")}
              {this.renderColorPicker("digits")}
            </div>
            <Recaptcha
              ref={(el) => {
                this.captcha = el;
              }}
              sitekey={key}
              size="invisible"
              onChange={this.handleCaptchaResponse}
            /> 
            {this.renderTosCheckbox()}
            <Button fluid primary size={"big"} disabled={!this.validFormData()} className={css.generateButton}>
              GENERATE
            </Button>
          </Form>
        );
      };
    
    
      render() {
        return (
          <div className={ indexCss.layout }>
            <div className="home-layout">
                <div>
                    <header className="header pb-0 h-fullscreen bb-1" id="home" style={{borderBottom:'none',padding:'0'}}>
                    <div className="container">
                        <div className="row h-full">
                        <div className="col-12 text-center align-self-center">                    
                            <div className={css.freeForm}>
                            <div className={css.timerInput}>{this.renderForm()}</div>
                            {this.renderPreview()}
                            </div>
                        </div>
                        </div>
                    </div>
                    </header>
                </div>
            </div>
          </div>
        );
      }
}

export default TimerGenerator;