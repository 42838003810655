// import PropTypes from 'prop-types';
// import React from "react";
// import { Link } from "react-router-dom";

// import { Form, Message } from "semantic-ui-react";
// import css from "../../assets/styles/shared/forms.module.scss";
// const { func, string } = PropTypes;
// class ForgotPassword extends React.Component {
//   static propTypes = {
//     forgotPassword: func.isRequired,
//     error: string
//   };

//   state = {
//     email: ""
//   };

//   render() {
//     return (
//       <Form className={css.loginForm}
//         onSubmit={this.handleSubmit}
//         error={!!this.props.error}>
//         <h1>Reset Password</h1>
//         {this.renderError()}
//         <Form.Input label="Email"
//           type="email"
//           placeholder="Email Address"
//           icon="envelope"
//           iconPosition="left"
//           onChange={this.handleChangeCurry("email")} />
//         <Link to="/login" className={css.forgotPassword}>Return to Login</Link>
//         <Form.Button primary content="Reset" />
//       </Form>
//     );
//   }

//   renderError = () => {
//     return (
//       <Message error>
//         {/* <Message.Header>Password Reset Error</Message.Header> */}
//         {/* <p>{this.props.error}</p> */}
//       </Message>
//     );
//   };

//   handleChangeCurry = (name) => {
//     return (event) => this.setState({ [name]: event.target.value });
//   };

//   handleSubmit = (event) => {
//     event.preventDefault();
//     this.props.forgotPassword(this.state.email);
//   };
// }

// export default ForgotPassword;

// running code 1 chatgpt4o

// import PropTypes from 'prop-types';
// import React from "react";
// import { Link } from "react-router-dom";
// import { Form, Message } from "semantic-ui-react";
// import { CSSTransition, TransitionGroup } from "react-transition-group";
// import css from "../../assets/styles/shared/forms.module.scss";

// const { func, string } = PropTypes;

// class ForgotPassword extends React.Component {
//   static propTypes = {
//     forgotPassword: func.isRequired,
//     error: string
//   };

//   state = {
//     email: "",
//     showToast: false,
//     toastMessage: ""
//   };

//   toastStyles = {
//     position: 'fixed',
//     top: '50px',
//     right: '0',
//     padding: '30px',
//     width: '500px',
//     backgroundColor: '#fcfff5',
//     color:'#2c662d',
//     border: '1px solid #ccc',
//     boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
//     zIndex: 1000,
//     fontSize: '1rem',
//     transition: 'opacity 300ms, transform 300ms'
//   };

//   render() {
//     return (
//       <>
//         <Form className={css.loginForm}
//           onSubmit={this.handleSubmit}
//           error={!!this.props.error}>
//           <h1>Reset Password</h1>
//           {this.renderError()}
//           <Form.Input label="Email"
//             type="email"
//             placeholder="Email Address"
//             icon="envelope"
//             iconPosition="left"
//             onChange={this.handleChangeCurry("email")} />
//           <Link to="/login" className={css.forgotPassword}>Return to Login</Link>
//           <Form.Button primary content="Reset" />
//         </Form>
//         <TransitionGroup>
//           {this.state.showToast && (
//             <CSSTransition
//               classNames={{
//                 enter: 'toast-enter',
//                 enterActive: 'toast-enter-active',
//                 exit: 'toast-exit',
//                 exitActive: 'toast-exit-active'
//               }}
//               timeout={{ enter: 300, exit: 300 }}
//             >
//               <div style={this.toastStyles}>{this.state.toastMessage}</div>
//             </CSSTransition>
//           )}
//         </TransitionGroup>
//       </>
//     );
//   }

//   renderError = () => {
//     return (
//       <Message error>
//         <Message.Header>Password Reset Error</Message.Header>
//         <p>{this.props.error}</p>
//       </Message>
//     );
//   };

//   handleChangeCurry = (name) => {
//     return (event) => this.setState({ [name]: event.target.value });
//   };

//   handleSubmit = (event) => {
//     event.preventDefault();
//     this.props.forgotPassword(this.state.email);
//     this.showToast("Password reset link sent!");
//   };

//   showToast = (message) => {
//     this.setState({ showToast: true, toastMessage: message });
//     setTimeout(() => {
//       this.setState({ showToast: false });
//     }, 3000);
//   };
// }

// export default ForgotPassword;

// running code 2 chatgpt4o

import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";
import { Form, Message } from "semantic-ui-react";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import _ from "lodash";
import css from "../../assets/styles/shared/forms.module.scss";
import { ClipLoader } from "react-spinners";

const { func, string } = PropTypes;

class ForgotPassword extends React.Component {
  static propTypes = {
    forgotPassword: func.isRequired,
    error: string,
  };

  state = {
    email: "",
    toasts: [],
  };

  render() {
    return (
      <>
        <style>
          {`
            .toast-enter {
              opacity: 0;
              transform: translateX(100%);
            }

            .toast-enter-active {
              opacity: 1;
              transform: translateX(0);
              transition: opacity 300ms, transform 300ms;
            }

            .toast-exit {
              opacity: 1;
              transform: translateX(0);
            }

            .toast-exit-active {
              opacity: 0;
              transform: translateX(100%);
              transition: opacity 300ms, transform 300ms;
            }
          `}
        </style>
        <Form
          onSubmit={this.handleSubmit}
          error={!!this.props.error}
          className={css.loginForm}
        >
          <h1>Reset Password</h1>
          {this.renderError()}
          <Form.Input
            label="Email"
            type="email"
            placeholder="Email Address"
            icon="envelope"
            iconPosition="left"
            onChange={this.handleChangeCurry("email")}
            style={{ marginBottom: "1em" }}
          />
          <Link to="/" className={css.forgotPassword}>
            Return to Login
          </Link>
          <Form.Button
            primary
            disabled={this.props.isForgotPasswordRequest}
            content={
              this.props.isForgotPasswordRequest ? (
                <div style={{ display: "flex", gap: "3px" }}>
                  Reset <ClipLoader color="#fff" size={15} />
                </div>
              ) : (
                "Reset"
              )
            }
          />
        </Form>
        <div
          style={{
            position: "fixed",
            top: "70px",
            right: "0",
            width: "500px",
            zIndex: 1000,
          }}
        >
          <TransitionGroup>{this.renderToasts()}</TransitionGroup>
        </div>
      </>
    );
  }

  renderError = () => {
    return (
      <Message error>
        <Message.Header>Password Reset Error</Message.Header>
        <p>{this.props.error}</p>
      </Message>
    );
  };

  handleChangeCurry = (name) => {
    return (event) => this.setState({ [name]: event.target.value });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    this.props.forgotPassword(this.state.email);
    // this.showToast("success", "Password reset link sent!");
  };

  showToast = (type, message) => {
    const toast = {
      id: _.uniqueId("toast_"),
      type,
      header: type.charAt(0).toUpperCase() + type.slice(1),
      message,
    };
    this.setState({ toasts: [...this.state.toasts, toast] });
    setTimeout(() => {
      this.handleRemoveToast(toast.id)();
    }, 3000);
  };

  handleRemoveToast = (id) => () => {
    this.setState({
      toasts: this.state.toasts.filter((toast) => toast.id !== id),
    });
  };

  renderToasts = () => {
    return _.map(this.state.toasts, (toast) => (
      <CSSTransition
        key={toast.id}
        classNames="toast"
        timeout={{ enter: 300, exit: 300 }}
      >
        <div>
          <Message
            info={toast.type === "info"}
            warning={toast.type === "warning"}
            success={toast.type === "success"}
            error={toast.type === "error"}
            onDismiss={this.handleRemoveToast(toast.id)}
            onClick={this.handleRemoveToast(toast.id)}
            header={toast.header}
            content={toast.message}
          />
        </div>
      </CSSTransition>
    ));
  };
}

export default ForgotPassword;
