// import React from "react";
// import { Routes, Route, Navigate } from "react-router-dom";

// import LoginContainer from "../containers/loginContainer";
// import RegisterContainer from "../containers/registerContainer";
// // import HomeLayoutContainer from "../containers/homeLayoutContainer";
// // import HomeContainer from "../containers/homeContainer";
// import AccountActivation from "../components/register/AccountActivation";
// import DashboardContainer from "../containers/dashboardContainer";
// import AppLayoutContainer from "../containers/appLayoutContainer";
// import TemplateListContainer from "../containers/templateListContainer";
// import TimerListContainer from "../containers/timerListContainer";
// import FaqContainer from "../containers/faqContainer";
// import SettingsContainer from "../containers/settingsContainer";
// import FullWidthLayoutContainer from "../containers/fullWidthLayoutContainer";
// import TemplateContainer from "../containers/templateContainer";
// import TimerContainer from "../containers/timerContainer";
// import TimerGeneratorContainer from "../containers/timerGeneratorContainer";
// import LogoutContainer from "../containers/logoutContainer";
// import LayoutContainer from "../containers/layoutContainer";
// import PostPaymentRedirect from "../components/register/PostPaymentRedirect";
// import Toasts from "../components/toasts";
// import RegisterSuccessContainer from "../containers/registerSuccessContainer";
// import ForgotPasswordContainer from "../containers/forgotPasswordContainer";
// import ResetPasswordContainer from "../containers/resetPasswordContainer";
// import SubaccountDetailsContainer from "../containers/subaccountDetailsContainer";
// import AccountListContainer from "../containers/accountListContainer";

// function requireSignedInUser(store) {
//   console.log("hey how are you?", store.getState());
//   return (nextState, replace) => {
//     const state = store.getState();
//     const user = state.auth.data;
//     if (!user) {
//       const { pathname, search } = nextState.location;
//       const query = search && search.length > 0 ? `?${search}` : "";
//       replace({ pathname: "login", query: { redirect: `${pathname}${query}` } });
//     }
//   };
// }

// function requireSignedOutUser(store) {
//   return (_nextState, replace) => {
//     const state = store.getState();
//     const user = state.auth.data;

//     if (user && user.tier !== "trial") {
//       replace({ pathname: "dashboard" });
//     }
//   };
// }

// export default function CreateRoutes({ store }) {
//   return (
//     <>
//       <Toasts />
//       <Routes>
//         <Route element={<LayoutContainer />}>
//           <Route element={<AppLayoutContainer />}>

//           </Route>

//           <Route path="account_activations/:token/edit" element={<AccountActivation />} />
//           <Route path="/post-payment-redirect" element={<PostPaymentRedirect />} />
//           <Route element={<AppLayoutContainer />}>
//             {/* <Route path="login/" element={<LoginContainer />} /> */}
//             <Route name="signedInRoutes" onEnter={requireSignedInUser(store)}>
//               <Route path="logout" element={<LogoutContainer />} />
//               <Route path="dashboard" element={<DashboardContainer />} />
//               <Route path="templates" element={<TemplateListContainer />} />
//               <Route path="timers" element={<TimerListContainer />} />
//               <Route path="faq" element={<FaqContainer />} />
//               <Route path="settings" element={<SettingsContainer />} />
//               <Route path="accounts" element={<AccountListContainer />} />
//               <Route path="subaccount/:id" element={<SubaccountDetailsContainer/>}/>
//             </Route>

//             <Route name="signedOutRoutes" onEnter={requireSignedOutUser(store)}>
//               <Route path="/" element={<LoginContainer />} />
//               <Route path="register" element={<RegisterContainer />} />
//               <Route path="register/success" element={<RegisterSuccessContainer />} />
//               <Route path="forgot_password" element={<ForgotPasswordContainer />} />
//               <Route path="password_resets/:id/edit" element={<ResetPasswordContainer />} />
//             </Route>

//           </Route>

//           <Route element={<FullWidthLayoutContainer />}>
//             <Route path="template/new" element={<TemplateContainer />} />
//             <Route path="template/:id" element={<TemplateContainer />} />
//             <Route path="timer/:id" element={<TimerContainer />} />
//           </Route>
//           <Route path="timer_generator" element={<TimerGeneratorContainer />} />
//         </Route>
//       </Routes>
//     </>
//   );
// }

import React, { useEffect } from "react";
import {
  Routes,
  Route,
  useLocation,
  useNavigate,
  Navigate,
  Outlet,
} from "react-router-dom";

import LoginContainer from "../containers/loginContainer";
import RegisterContainer from "../containers/registerContainer";
import AccountActivation from "../components/register/AccountActivation";
import DashboardContainer from "../containers/dashboardContainer";
import AppLayoutContainer from "../containers/appLayoutContainer";
import TemplateListContainer from "../containers/templateListContainer";
import TimerListContainer from "../containers/timerListContainer";
import FaqContainer from "../containers/faqContainer";
import SettingsContainer from "../containers/settingsContainer";
import FullWidthLayoutContainer from "../containers/fullWidthLayoutContainer";
import TemplateContainer from "../containers/templateContainer";
import TimerContainer from "../containers/timerContainer";
import TimerGeneratorContainer from "../containers/timerGeneratorContainer";
import LogoutContainer from "../containers/logoutContainer";
import LayoutContainer from "../containers/layoutContainer";
import PostPaymentRedirect from "../components/register/PostPaymentRedirect";
import Toasts from "../components/toasts";
import RegisterSuccessContainer from "../containers/registerSuccessContainer";
import ForgotPasswordContainer from "../containers/forgotPasswordContainer";
import ResetPasswordContainer from "../containers/resetPasswordContainer";
import SubaccountDetailsContainer from "../containers/subaccountDetailsContainer";
import AccountListContainer from "../containers/accountListContainer";

function useRequireSignedInUser(store) {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const state = store.getState();
    const user = state.auth.data;
    if (!user) {
      // const { pathname, search } = location;
      // const query = search.length > 0 ? `?${search}` : "";
      navigate("/", { replace: true });
    }
  }, [store, location, navigate]);
}

function useRequireSignedOutUser(store) {
  const navigate = useNavigate();

  useEffect(() => {
    const state = store.getState();
    const user = state.auth.data;

    if (user) {
      navigate("/dashboard", { replace: true });
    }
  }, [store, navigate]);
}

function useCorporateUser(store) {
  const navigate = useNavigate();

  useEffect(() => {
    const state = store.getState();
    const isCorporateUser =
      state.auth.data?.features?.subaccount &&
      state.auth.data?.features?.subaccount &&
      (state.auth.data?.subaccount_id == 0 || state.auth?.data?.is_company);

    if (!isCorporateUser) {
      navigate("/dashboard", { replace: true });
    }
  }, [store, navigate]);
}

function RequireSignedInUser({ store, children }) {
  useRequireSignedInUser(store);
  return children;
}

function RequireSignedOutUser({ store, children }) {
  useRequireSignedOutUser(store);
  return children;
}

function CorporateUser({ store, children }) {
  useCorporateUser(store);
  return children;
}

export default function CreateRoutes({ store }) {
  return (
    <>
      <Toasts />
      <Routes>
        <Route element={<LayoutContainer />}>
          <Route element={<AppLayoutContainer />}></Route>

          <Route
            path="/account_activations/:token/edit"
            element={<AccountActivation />}
          />
          <Route
            path="/post-payment-redirect"
            element={<PostPaymentRedirect />}
          />
          <Route element={<AppLayoutContainer />}>
            <Route>
              <Route
                path="logout"
                element={
                  <RequireSignedInUser store={store}>
                    <LogoutContainer />
                  </RequireSignedInUser>
                }
              />
              <Route
                path="dashboard"
                element={
                  <RequireSignedInUser store={store}>
                    <DashboardContainer />
                  </RequireSignedInUser>
                }
              />
              <Route
                path="templates"
                element={
                  <RequireSignedInUser store={store}>
                    <TemplateListContainer />
                  </RequireSignedInUser>
                }
              />
              <Route
                path="timers"
                element={
                  <RequireSignedInUser store={store}>
                    <TimerListContainer />
                  </RequireSignedInUser>
                }
              />
              <Route
                path="faq"
                element={
                  <RequireSignedInUser store={store}>
                    <FaqContainer />
                  </RequireSignedInUser>
                }
              />
              <Route
                path="settings"
                element={
                  <RequireSignedInUser store={store}>
                    <SettingsContainer />
                  </RequireSignedInUser>
                }
              />
              <Route
                path="accounts"
                element={
                  <CorporateUser store={store}>
                    <AccountListContainer />
                  </CorporateUser>
                }
              />
              <Route
                path="subaccount/:id"
                element={
                  <CorporateUser store={store}>
                    <SubaccountDetailsContainer />
                  </CorporateUser>
                }
              />
            </Route>

            <Route
              element={
                <RequireSignedOutUser store={store}>
                  <Outlet />
                </RequireSignedOutUser>
              }
            >
              <Route path="/" element={<LoginContainer />} />
              <Route path="register" element={<RegisterContainer />} />
              <Route
                path="register/success"
                element={<RegisterSuccessContainer />}
              />
              <Route
                path="forgot_password"
                element={<ForgotPasswordContainer />}
              />
              <Route
                path="password_resets/:id/edit"
                element={<ResetPasswordContainer />}
              />
            </Route>
          </Route>

          <Route element={<FullWidthLayoutContainer />}>
            <Route
              path="template/new"
              element={
                <RequireSignedInUser store={store}>
                  <TemplateContainer />
                </RequireSignedInUser>
              }
            />
            <Route
              path="template/:id"
              element={
                <RequireSignedInUser store={store}>
                  <TemplateContainer />
                </RequireSignedInUser>
              }
            />
            <Route
              path="timer/:id"
              element={
                <RequireSignedInUser store={store}>
                  <TimerContainer />
                </RequireSignedInUser>
              }
            />
          </Route>
          <Route
            path="timer_generator"
            element={
              <RequireSignedInUser store={store}>
                <TimerGeneratorContainer />
              </RequireSignedInUser>
            }
          />
        </Route>
      </Routes>
    </>
  );
}
