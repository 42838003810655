import React, { useState } from "react";
import _ from "lodash";

import BillingContainer from "../../containers/billingContainer";
import { Modal, Button, Popup } from "semantic-ui-react";

const UpgradePlanButton = ({ draftTimer = null, upsell = null, size = undefined, children }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const modalTrigger = (
    <Popup
      basic
      position="bottom center"
      disabled={!upsell}
      trigger={
        <Button
          color="green"
          onClick={(e) => {
            setIsModalOpen(true);
            e.preventDefault();
          }}
          size={size}
        >
          {children}
        </Button>
      }
    >
      {upsell}
    </Popup>
  );

  return (
    <Modal
      size={"large"}
      open={isModalOpen}
      onClose={() => setIsModalOpen(false)}
      closeOnDimmerClick={true}
      closeIcon
      trigger={modalTrigger}
    >
      <Modal.Header>Upgrade your Plan</Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <BillingContainer draftTimer={draftTimer} />
        </Modal.Description>
      </Modal.Content>
    </Modal>
  );
};

export default UpgradePlanButton;