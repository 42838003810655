import { connect } from "react-redux";

import Layout from "../components/layout";
import { notifyCookies } from "../actions/toastActions";
import cookieHelper from "../helpers/cookieHelper";

const mapDispatchToProps = (dispatch, ownProps) => ({
  notifyCookies: () => {
    if (!cookieHelper.getCookieValue("cookie_notification")) {
      dispatch(notifyCookies());
      cookieHelper.setCookie("cookie_notification", 1);
    }
  },
});

export default connect(null, mapDispatchToProps)(Layout);
