import { connect } from "react-redux";

import TimerList from "../components/timerList";
import { fetch as fetchTimers, archive, create, fetchCorporateSubaccount as fetchSubaccounts} from "../actions/timerActions";
import { fetch as fetchTemplates } from "../actions/templateActions";

const mapStateToProps = (state, props) => ({
  timers: state.timers?.data ?? {},
  templates: state.template?.data ?? {},
  envContext: state.envContext?.data ?? {},
  user: state.auth?.data ?? {},
  subaccounts:state.timers?.subaccounts ?? {}
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  fetch: () => {
    dispatch(fetchTimers());
    dispatch(fetchTemplates());
  },
  archive: (timerId, status) => {
    dispatch(archive(timerId, status));
  },
  create: (options) => {
    dispatch(create(options));
  },
  fetchSubaccounts:() => {    
    dispatch(fetchSubaccounts());
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(TimerList);