import PropTypes from 'prop-types';
import React from "react";
import _ from "lodash";
import moment from "moment-timezone";

import Datetime from "react-datetime";
import { Form, Dropdown } from "semantic-ui-react";

const { object, func, number } = PropTypes;

class CustomDatePicker extends React.Component {
  static propTypes = {
    dateTime: object,
    maxDays: number,
    handleChange: func
  };

  static defaultProps = {
    maxDays: 30
  };

  validDates = (current) => {
    const max = moment().add(this.props.maxDays, "day");
    return current.isBefore(max);
  };

  handleDateChanged = (date) => {
    if (_.isObject(date) && this.validDates(date)) {
      this.props.handleChange(date.toISOString());
    }
  };

  render() {
    return (
      <Datetime value={this.props.dateTime}
        isValidDate={this.validDates}
        timeConstraints={{ minutes: { step: 5 } }}
        timeFormat={false}
        onChange={this.handleDateChanged} />
    );
  }
}

export default CustomDatePicker;
