// import PropTypes from 'prop-types';
// import React from "react";
// import { Link } from "react-router-dom";
// import { Dropdown, Button, Checkbox, Form, Icon, Message } from "semantic-ui-react";
// import css from "../../assets/styles/shared/forms.module.scss";

// const { func, string } = PropTypes;

// class Register extends React.Component {
//   static propTypes = {
//     register: func.isRequired,
//     error: string
//   };

//   state = {
//     email: "",
//     password: "",
//     agreeToTerms: false,
//     apiPlandID:"",
//   };

//   componentDidMount() {
//     const { location } = this.props;
//     const queryParams = new URLSearchParams(location.search);
//     // Access and use query string parameters
//     const plan = queryParams.get('plan');
//     if(plan){
//       this.setState({apiPlandID:plan});
//     }
//   }

//   termsClicked = (ev) => {
//     ev.stopPropagation();
//   };

//   handleAgree = () => {
//     this.setState({
//       agreeToTerms: !this.state.agreeToTerms
//     });
//   };

//   renderTosCheckbox = () => {
//     const label = (
//       <label>
//         I agree to the
//         <a href="/legal/terms.pdf" target="_blank" rel="noopener noreferrer" onClick={this.termsClicked}> Terms of Service</a> and <a href="/legal/privacy.pdf" target="_blank" rel="noopener noreferrer" onClick={this.termsClicked}>Privacy Policy</a>
//       </label>
//     );
//     return <Form.Checkbox inline label={label} checked={this.state.agreeToTerms} onClick={this.handleAgree} />;
//   };

//   render() {
//     return (
//       <div className={css.loginForm}>
//         <Message
//           attached
//           header="Welcome to Sendtric!"
//           content="Fill out the form below to sign up for a new account." />
//         <Form className={"attached fluid segment"}
//           onSubmit={this.handleSubmit}
//           error={!!this.props.error}>
//           {this.renderError()}
//           <Form.Input label="Email"
//             type="email"
//             placeholder="Email Address"
//             icon="envelope"
//             iconPosition="left"
//             onChange={this.handleChangeCurry("email")} />
//           <Form.Input label="Password"
//             type="password"
//             icon="lock"
//             iconPosition="left"
//             onChange={this.handleChangeCurry("password")} />
//           {this.renderTosCheckbox()}
//           <Button disabled={!this.state.agreeToTerms} primary>
//             Sign Up
//           </Button>
//         </Form>
//         <Message attached="bottom" warning>
//           <Icon name="help" />
//           Already have an account?&nbsp;<Link to="/login">Login here</Link>.
//         </Message>
//       </div>
//     );
//   }

//   renderError = () => {
//     return (
//       <Message error>
//         <Message.Header>Registration Error</Message.Header>
//         <p>{this.props.error}</p>
//       </Message>
//     );
//   };

//   registerRef = (name) => {
//     return (el) => this[name] = el;
//   };

//   handleChangeCurry = (name) => {
//     return (event, option) => {
//       this.setState({ [name]: option.value })
//     };
//   };

//   handleSubmit = (event) => {
//     event.preventDefault();
//     const { email, password, agreeToTerms, apiPlandID } = this.state;
//     if (email && password && agreeToTerms) {
//       this.props.register(email, password, apiPlandID);
//     } else {
//       // Optionally set an error message in the state or show a toast
//       console.error("Please fill in all fields and agree to the terms.");
//     }
//   };
// }

// export default Register;

import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import {
  Dropdown,
  Button,
  Checkbox,
  Form,
  Icon,
  Message,
} from "semantic-ui-react";
import css from "../../assets/styles/shared/forms.module.scss";
import { ClipLoader } from "react-spinners";

const { func, string } = PropTypes;

const Register = ({ register, error, loader }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [agreeToTerms, setAgreeToTerms] = useState(false);
  const [apiPlandID, setApiPlandID] = useState("");
  const location = useLocation();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const plan = searchParams.get("plan");
    if (plan) {
      setApiPlandID(plan);
    }
  }, [location]);

  const handleAgree = () => {
    setAgreeToTerms(!agreeToTerms);
  };

  const handleChangeCurry =
    (setter) =>
    (event, { value }) => {
      setter(value);
    };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (email && password && agreeToTerms) {
      register(email, password, apiPlandID);
    } else {
      // Optionally set an error message in the state or show a toast
      console.error("Please fill in all fields and agree to the terms.");
    }
  };

  const renderError = () => (
    <Message error>
      <Message.Header>Registration Error</Message.Header>
      <p>{error}</p>
    </Message>
  );

  const renderTosCheckbox = () => {
    const label = (
      <label>
        I agree to the
        <a
          href="/legal/terms.pdf"
          target="_blank"
          rel="noopener noreferrer"
          onClick={(ev) => ev.stopPropagation()}
        >
          {" "}
          Terms of Service
        </a>{" "}
        and{" "}
        <a
          href="/legal/privacy.pdf"
          target="_blank"
          rel="noopener noreferrer"
          onClick={(ev) => ev.stopPropagation()}
        >
          Privacy Policy
        </a>
      </label>
    );
    return (
      <Form.Checkbox
        inline
        label={label}
        checked={agreeToTerms}
        onClick={handleAgree}
      />
    );
  };

  return (
    <div className={css.loginForm}>
      <Message
        attached
        header="Welcome to Sendtric!"
        content="Fill out the form below to sign up for a new account."
      />
      <Form
        className={"attached fluid segment"}
        onSubmit={handleSubmit}
        error={!!error}
      >
        {error && renderError()}
        <Form.Input
          label="Email"
          type="email"
          placeholder="Email Address"
          icon="envelope"
          iconPosition="left"
          onChange={handleChangeCurry(setEmail)}
        />
        <Form.Input
          label="Password"
          type="password"
          icon="lock"
          iconPosition="left"
          onChange={handleChangeCurry(setPassword)}
        />
        {renderTosCheckbox()}

        <Button disabled={!agreeToTerms || loader} primary>
          {loader ? (
            <div style={{ display: "flex", gap: "3px" }}>
              Sign Up <ClipLoader color="#fff" size={15} />
            </div>
          ) : (
            "Sign Up"
          )}
        </Button>
      </Form>
      <Message attached="bottom" warning>
        <Icon name="help" />
        Already have an account?&nbsp;<Link to="/">Login here</Link>.
      </Message>
    </div>
  );
};

Register.propTypes = {
  register: func.isRequired,
  error: string,
};

export default Register;
