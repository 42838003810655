import { connect } from "react-redux";

import {
  TOAST_DISMISSED,
  USER_ACTIVATION_FAILURE,
  USER_ACTIVATION_SUCCESS,
} from "../constants/toastConstants";

import {
  RESET_PASSWORD_FAILURE,
  FORGOT_PASSWORD_SUCCESS,
} from "../constants/authenticationConstants";

import Toasts from "../components/toasts";

const mapStateToProps = (state) => {
  return {
    toasts: state.toasts?.active ?? {},
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  removeToast: (toastId) => {
    dispatch({ type: TOAST_DISMISSED, data: { id: toastId } });
  },
  userActivationFailure: () => {
    dispatch({ type: USER_ACTIVATION_FAILURE });
  },
  userActivationSuccess: () => {
    dispatch({ type: USER_ACTIVATION_SUCCESS });
  },
  resetPasswordFailure: () => {
    dispatch({ type: RESET_PASSWORD_FAILURE });
  },
  forgotPasswordSuccess: () => {
    dispatch({ type: FORGOT_PASSWORD_SUCCESS });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Toasts);
