import AppNew from '../src/stores/routerStore'
import React, { Component } from 'react';

// import '../src/assets/stylesheets/style.module.scss';

import './App.scss'

class App extends Component {
  render() {
    return (
      // <div style={{margin:"5px auto",width:"80%"}}>
      //   <AppNew/>
      // </div>
      <>
      <AppNew/>
      </>
    );
  }
}

export default App;
